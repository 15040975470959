import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { bindActionCreators } from 'redux'
import { StyledFormDiv } from '../../ui/elements/forms'
import { ActionButton } from '../../ui/components/buttons'
import { tablet_max, phone_max } from '../../ui/defaults/media-queries'
import { withRouter } from 'react-router'
import { loginUser } from '../../redux/user/actions'
import { connect } from 'react-redux'
import NavbarHome from './components_shared/NavbarHome'
import AMRLogoForLightBg from '../../app_ui/svgs/logos/AMRLogoForLightBg'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    errors: store.errors
  }),
  dispatch => ({
    actions: bindActionCreators({
      loginUser: loginUser,
    }, dispatch)
  })
)
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calculatedHeight: `${window.innerHeight}px`,
      username: '',
      password: '',
      errorMessage: '',
      error: null,
      errors: {}
    }
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
    if(this.props.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight = () => {
    try {
      let newHeight = window.innerHeight
      if (this.state.calculatedHeight !== newHeight) {
        this.setState({
          calculatedHeight: newHeight,
        })
      }
    } catch (err) {
      //
    }
  }

  handleKeyUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.which === 13) {
      this.triggerSubmit()
    }
  }

  handleSubmit = (e) => {
    if (e) {e.preventDefault();}
    this.triggerSubmit()
  }

  triggerSubmit = () => {
    const user = {
      username: this.state.username,
      password: this.state.password,
    }
    this.props.actions.loginUser(user, this.props.history)
  }

  render() {
    const {
      username,
      password,
      error,
      errorMessage,
      calculatedHeight
    } = this.state
    return (
      <div>
        <Helmet>
          <title>Entrar | As minhas reservas</title>
        </Helmet>
        <NavbarHome isLoginPage={true} />
        <Layout calculatedHeight={calculatedHeight}>
          <div>
            <StyledBox>
              {error && <StyledError>{errorMessage}</StyledError>}
              <StyledFormDiv style={{minWidth: '260px'}}>
                <h2 style={{ marginTop: 0 }}>Login</h2>
                <br />
                <label className='form-label' htmlFor="username">E-MAIL</label>
                <input
                  value={username}
                  type="text"
                  id="username"
                  placeholder="O seu endereço de email"
                  onChange={(e) => this.setState({username: e.target.value})}
                  onKeyUp={this.handleKeyUp}
                />
                <br />
                <label className='form-label' htmlFor="password">Password</label>
                <input
                  value={password}
                  type="password"
                  id="password"
                  placeholder="A sua password"
                  onChange={(e) => this.setState({password: e.target.value})}
                  onKeyUp={this.handleKeyUp}
                />
                <br />
                <ActionButton style={{alignSelf: 'flex-end'}} onClick={this.handleSubmit}>
                  <p>Entrar</p>
                </ActionButton>
              </StyledFormDiv>
            </StyledBox>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <AMRLogoForLightBg />
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default withRouter(Login)

const StyledBox = styled.div({
  borderRadius: '5px',
  margin: '2rem auto',
  background: 'white',
  maxWidth: '400px',
  padding: '40px',
  [tablet_max]: {
    padding: '30px',
  },
  [phone_max]: {
    paddingTop: '50px',
    paddingBottom: '50px',
    width: '100%',
  },
})

const StyledError = styled.div({
  padding: '1rem',
  marginBottom: '1rem',
  border: '1px solid red',
  borderRadius: '5px',
  color: '#2E2E2E',
})

const Layout = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: props.calculatedHeight ? `${props.calculatedHeight}px` : 'auto',
  '& div': {
    position: 'relative',
    top: '-3%',
  }
}))
