import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearCart, clearTemporaryCartAmount, clearClientDetails } from '../redux/shop/actions'
import { withRouter } from 'react-router-dom'
import HeaderComponent from './HeaderComponent'

@connect(
  store => ({
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators({
      clearCart,
      clearTemporaryCartAmount,
      clearClientDetails,
    }, dispatch)
  })
)
class Header extends Component {

  checkIsInsideIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  handleCloseButton = (e) => {
    const {
      storeAccount,
    } = this.props
    if (e) {e.preventDefault()}
    if (e) {e.stopPropagation()}
    if (this.checkIsInsideIframe()) { // is inside iframe
      // clear current cart
      this.props.actions.clearClientDetails()
      // clear any stored client details
      this.props.actions.clearCart()
      // clear temporary cart amount
      this.props.actions.clearTemporaryCartAmount()
      // close overlay
      setTimeout(() => window.parent.postMessage('close-window', document.referrer), 100)
      // send app to first page
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)

    } else {
      window.location = storeAccount.account_hostname
    }
  }

  render() {
    const {
      accountLogo,
      businessName,
      subline,
      omitCloseButton,
    } = this.props
    return (
      <HeaderComponent
        accountLogo={accountLogo}
        businessName={businessName}
        subline={subline}
        omitCloseButton={omitCloseButton}
        handleCloseButton={this.handleCloseButton}
      />
    )
  }
}

export default withRouter(Header)
