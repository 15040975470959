import axios from 'axios'
import { toast } from 'react-toastify'
// import { getTime } from 'date-fns'
import setAuthorizationToken from '../utils/setAuthorizationToken'
// import {
//   getURLFormattedDate
// } from '../../utils/date'
import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  
  GET_PRODUCTS_STORE,
  GET_PRODUCTS_STORE_SUCCESS,
  GET_PRODUCTS_STORE_ERROR,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,

  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,

  UPDATE_PRODUCT_TITLE,
  UPDATE_PRODUCT_TITLE_SUCCESS,
  UPDATE_PRODUCT_TITLE_ERROR,

  UPDATE_PRODUCT_DESCRIPTION,
  UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
  UPDATE_PRODUCT_DESCRIPTION_ERROR,

  UPDATE_PRODUCT_LEAD,
  UPDATE_PRODUCT_LEAD_SUCCESS,
  UPDATE_PRODUCT_LEAD_ERROR,

  GET_STOCK_IN_DAY_FOR_PRODUCT,
  GET_STOCK_IN_DAY_FOR_PRODUCT_SUCCESS,
  GET_STOCK_IN_DAY_FOR_PRODUCT_ERROR,

  GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS,
  GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_SUCCESS,
  GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_ERROR,

  // UPDATE_PRODUCT_STOCK_TOTAL,
  // UPDATE_PRODUCT_STOCK_TOTAL_SUCCESS,
  // UPDATE_PRODUCT_STOCK_TOTAL_ERROR,

  UPDATE_PRODUCT_WEEKDAY_STOCK,
  UPDATE_PRODUCT_WEEKDAY_STOCK_SUCCESS,
  UPDATE_PRODUCT_WEEKDAY_STOCK_ERROR,

  UPDATE_CAN_BUY_HALF,
  UPDATE_CAN_BUY_HALF_SUCCESS,
  UPDATE_CAN_BUY_HALF_ERROR,

  UPLOAD_PRODUCT_IMAGE,
  UPLOAD_PRODUCT_IMAGE_SUCCESS,
  UPLOAD_PRODUCT_IMAGE_ERROR,

  UPDATE_PRODUCT_AVAILABLE_FOR_SALE,
  UPDATE_PRODUCT_AVAILABLE_FOR_SALE_SUCCESS,
  UPDATE_PRODUCT_AVAILABLE_FOR_SALE_ERROR,

  UPDATE_PRODUCT_DAY_AVAILABILITY_STATE,
  UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_SUCCESS,
  UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_ERROR,

  ORDER_SHOP_PRODUCT,
  ORDER_SHOP_PRODUCT_SUCCESS,
  ORDER_SHOP_PRODUCT_ERROR,

  // RESET_RESERVATIONS_COUNTERS,
  // RESET_RESERVATIONS_COUNTERS_SUCCESS,
  // RESET_RESERVATIONS_COUNTERS_ERROR,
} from '../action-types'
// import jwtDecode from 'jwt-decode'

const tokenName = 'reservasToken'

// export function getProducts() {
export function orderProduct(accountId, productId, direction) {
  return dispatch => {
    dispatch({
      type: ORDER_SHOP_PRODUCT,
    })
    axios.get(`/api/v1/order-shop-product/${accountId}/${productId}/${direction}`).then((response) => {
      dispatch({
        type: ORDER_SHOP_PRODUCT_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: ORDER_SHOP_PRODUCT_ERROR, payload: err })
    })
  }
}

export function getStockInDayForProduct(accountId, productId, urlFormattedDate, dayName) {
  return dispatch => {
    dispatch({
      type: GET_STOCK_IN_DAY_FOR_PRODUCT,
    })
    axios.get(`/api/v1/get-stock-in-day-for-product/${accountId}/${productId}/${urlFormattedDate}/${dayName}`).then((response) => {
      dispatch({
        type: GET_STOCK_IN_DAY_FOR_PRODUCT_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_STOCK_IN_DAY_FOR_PRODUCT_ERROR, payload: err })
    })
  }
}

export function getStockInDayForActiveProducts(accountId, urlFormattedDate, dayName) {
  return dispatch => {
    dispatch({
      type: GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS,
    })
    axios.get(`/api/v1/get-stock-in-day-for-active-products/${accountId}/${urlFormattedDate}/${dayName}`).then((response) => {
      dispatch({
        type: GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_ERROR, payload: err })
    })
  }
}

// export function getProducts() {
export function getProducts(accountId) {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: GET_PRODUCTS,
    })
    axios.get(`/api/v1/get-products/${accountId}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_PRODUCTS_ERROR, payload: err })
    })
  }
}

// export function resetReservationCounters(accountId) {
//   return dispatch => {
//     const token = localStorage.getItem(tokenName)
//     setAuthorizationToken(token)
//     dispatch({
//       type: RESET_RESERVATIONS_COUNTERS,
//     })
//     axios.get(`/api/v1/reset-reservation-counter/${accountId}`, {
//     // axios.get(`/api/v1/get-products/${accountId}`, { data: token }, {
//     // axios.get('/api/v1/get-products/', { data: token }, {
//       withCredentials: true,
//     }).then((response) => {
//       dispatch({
//         type: RESET_RESERVATIONS_COUNTERS_SUCCESS,
//         payload: response.data.updatedProducts,
//       })
//     })
//     .catch((err) => {
//       dispatch({type: RESET_RESERVATIONS_COUNTERS_ERROR, payload: err })
//     })
//   }
// }

export function getProductsStore(appId) {
  return dispatch => {
    // const token = localStorage.getItem(tokenName)
    // setAuthorizationToken(token)
    dispatch({
      type: GET_PRODUCTS_STORE,
    })
    axios.get(`/api/v1/get-products-store/${appId}`, {
      withCredentials: false,
    }).then((response) => {
      dispatch({
        type: GET_PRODUCTS_STORE_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_PRODUCTS_STORE_ERROR, payload: err })
    })
  }
}

export function createProduct(accountId) {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: CREATE_PRODUCT,
    })
    axios.get(`/api/v1/create-product/${accountId}/${Date.now()}`, { data: token }, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: response.data.ops[0],
      })
      toast.info('Novo produto criado')
    })
    .catch((err) => {
      dispatch({type: CREATE_PRODUCT_ERROR, payload: err })
      toast.error('Failed to create product.')
    })
  }
}

export function deleteProduct(accountId, _id) {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: DELETE_PRODUCT,
    })
    axios.delete(`/api/v1/delete-product/${accountId}/${_id}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: DELETE_PRODUCT_SUCCESS,
        payload: response.data,
      })
      toast.info('Produto removido')
    })
    .catch((err) => {
      dispatch({type: DELETE_PRODUCT_ERROR, payload: err })
      toast.info('Erro ao remover produto.')
    })
  }
}

export function updateProductTitle(accountId, _id, title_pt) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_PRODUCT_TITLE,
    })
    return axios.post(`/api/v1/update-product-title/${accountId}/${_id}`, { title_pt: title_pt }).then(response => {
      dispatch({
        type: UPDATE_PRODUCT_TITLE_SUCCESS,
        payload: response.data,
      })
      toast.info('Título alterado')
    })
    .catch(err => {
      dispatch({type: UPDATE_PRODUCT_TITLE_ERROR, payload: err })
      toast.info('Erro ao alterar produto.')
    });
  }
}

export function updateProductLead(accountId, _id, lead) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_PRODUCT_LEAD,
    })
    return axios.post(`/api/v1/update-product-lead/${accountId}/${_id}`, { lead: lead }).then(response => {
      dispatch({
        type: UPDATE_PRODUCT_LEAD_SUCCESS,
        payload: response.data,
      })
      toast.info('Lead alterado')
    })
    .catch(err => {
      dispatch({type: UPDATE_PRODUCT_LEAD_ERROR, payload: err })
      toast.info('Erro ao alterar produto.')
    });
  }
}

export function updateProductDescription(accountId, _id, description) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_PRODUCT_DESCRIPTION,
    })
    return axios.post(`/api/v1/update-product-description/${accountId}/${_id}`, { description: description }).then(response => {
      dispatch({
        type: UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
        payload: response.data,
      })
      toast.info('Descrição alterada')
    })
    .catch(err => {
      dispatch({type: UPDATE_PRODUCT_DESCRIPTION_ERROR, payload: err })
      toast.info('Erro ao alterar produto.')
    });
  }
}

// export function updateProductNumInStock(accountId, _id, stock_total) {
//   if (!_id) {
//     return console.log('_id missing')
//   }
//   return dispatch => {
//     dispatch({
//       type: UPDATE_PRODUCT_STOCK_TOTAL,
//     })
//     const token = localStorage.getItem(tokenName)
//     setAuthorizationToken(token)
//     return axios.post(`/api/v1/update-product-num-in-stock/${accountId}/${_id}`, { stock_total: stock_total }).then(response => {
//       dispatch({
//         type: UPDATE_PRODUCT_STOCK_TOTAL_SUCCESS,
//         payload: response.data,
//       })
//       toast.info('Número em stock actualizado')
//     })
//     .catch(err => {
//       dispatch({type: UPDATE_PRODUCT_STOCK_TOTAL_ERROR, payload: err })
//       toast.info('Não foi possível actualizar o stock.')
//     });
//   }
// }

export function updateProductWeekdayStock(accountId, _id, weekday_key, newWeekdayStock) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_WEEKDAY_STOCK,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    return axios.post(`/api/v1/update-product-weekday-stock/${accountId}/${_id}/${weekday_key}`, { newWeekdayStock: newWeekdayStock }).then(response => {
      dispatch({
        type: UPDATE_PRODUCT_WEEKDAY_STOCK_SUCCESS,
        payload: response.data,
      })
      toast.info('Número máximo actualizado')
    })
    .catch(err => {
      dispatch({type: UPDATE_PRODUCT_WEEKDAY_STOCK_ERROR, payload: err })
      toast.info('Não foi possível actualizar o número.')
    });
  }
}

export function changeProductDayAvailabilityState(accountId, _id, dayName, newValue) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_DAY_AVAILABILITY_STATE,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    return axios.post(`/api/v1/update-product-day-availability-state/${accountId}/${_id}`, { dayName: dayName, newValue: newValue }).then(response => {
      dispatch({
        type: UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_SUCCESS,
        payload: response.data,
      })
      // toast.info('Número em stock actualizado')
    })
    .catch(err => {
      dispatch({type: UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_ERROR, payload: err })
      // toast.info('Não foi possível actualizar o stock.')
    });
  }
}

export function updateCanBuyHalf(accountId, _id, can_sell_half) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    dispatch({
      type: UPDATE_CAN_BUY_HALF,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    return axios.post(`/api/v1/update-can-buy-half/${accountId}/${_id}`, { can_sell_half: can_sell_half }).then(response => {
      dispatch({
        type: UPDATE_CAN_BUY_HALF_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: UPDATE_CAN_BUY_HALF_ERROR, payload: err })
    });
  }
}


export function uploadProductImage(accountId, _id, uploadImageFile) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    dispatch({
      type: UPLOAD_PRODUCT_IMAGE,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)

    const data = new FormData() 
    data.append('file', uploadImageFile)

    return axios.post(`/api/v1/upload-product-image/${accountId}/${_id}`, data).then(response => {
      dispatch({
        type: UPLOAD_PRODUCT_IMAGE_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: UPLOAD_PRODUCT_IMAGE_ERROR, payload: err })
    });
  }
}


export function updateProductAvailableForSale(accountId, _id, sale_active) {
  if (!_id) {
    return console.log('_id missing')
  }
  return dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_AVAILABLE_FOR_SALE,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    return axios.post(`/api/v1/update-product-available-for-sale/${accountId}/${_id}`, { sale_active: sale_active }).then(response => {
      dispatch({
        type: UPDATE_PRODUCT_AVAILABLE_FOR_SALE_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: UPDATE_PRODUCT_AVAILABLE_FOR_SALE_ERROR, payload: err })
    });
  }
}
