import {
  format,
  parseISO,
  isBefore,
} from 'date-fns'

export const getURLFormattedDate = (date) => {
  return format(date, 'yyyy:MM:dd')
}

export const getDateFromURLDate = (URLDate) => {
  if (!URLDate) return null
  const trimmedWeekDate = String(URLDate).split(':')
  const year = parseInt(trimmedWeekDate[0])
  const month = parseInt(trimmedWeekDate[1]) - 1
  const day = parseInt(trimmedWeekDate[2])
  const currentDayDate = new Date(year, month, day)
  return currentDayDate
}

export const weekDateIsInPast = (weekDate) => {
  const testDate = getDateFromURLDate(weekDate)
  const newDate = getURLFormattedDate(new Date())
  const newDateFormatted = getDateFromURLDate(newDate)
  const dateIsBefore = isBefore(testDate, newDateFormatted)
  return dateIsBefore
}

export const getDayNumberFromYearMonthDayDate = (yearMonthDay) => {
  if (!yearMonthDay) return null
  let result = String(yearMonthDay).split(':')
  result = result[2]
  if (result.length === 2 && result.charAt(0) === "0") {
    result = result.charAt(1)
  }
  return result
}

export const ensureURLFormattedDate = (date) => {
  if (typeof date === 'string' && String(date).indexOf(':') > -1) {
    return date
  }

  if (typeof date === 'number') {
    return getURLFormattedDate(new Date(date))
  }

  if (date && date.getYear) {
    return getURLFormattedDate(date)
  }

  // console.log('typeof date: ' + typeof date)
  // console.log('date:')
  // console.dir(date)

  try {
    const parsed = parseISO(date)
    if (parsed) return getURLFormattedDate(parsed)
  } catch(err) {
    console.log('ensureURLFormattedDate ::: could not parse date ' + date)
  }

  return null
}

export const ensureGetRegularDate = (date) => {
  // console.log('ensureGetRegularDate')
  // console.log('date: ' + date)
  // console.log('typeof date: ' + typeof date)

  if (!date) return null
  if (typeof date === 'undefined') return null
  // console.log('date: ' + date)

  if (typeof date === 'string' && String(date).indexOf(':') > -1) {
    return getDateFromURLDate(date)
  }

  if (typeof date === 'number') {
    return new Date(date)
  }

  let parsed
  try {
    parsed = parseISO(date)
    if (parsed) return parsed
  } catch(err) {
    console.log('ensureGetRegularDate ::: could not parse date ' + date)
  }
  return null
}

export const getDatesArraySlug = (viewingWeekDates) => {
  let datesRangeSlug = ''
  let counter = 1
  for(const date of viewingWeekDates) {
    datesRangeSlug += date.yearMonthDay
    if (counter !== viewingWeekDates.length) {
      datesRangeSlug += '-'
    }
    counter++
  }
  return datesRangeSlug
}
