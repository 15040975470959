import React from 'react'
import styled from '@emotion/styled'
import { phone_max } from '../../ui/defaults/media-queries'

export const PaddingBox = styled.div({
  paddingLeft: '50px',
  paddingRight: '50px',
  [phone_max]: {
    paddingLeft: '30px',
    paddingRight: '30px',
  }
})

export const WidgetPageRoot = styled.div({
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
  maxWidth: '500px',
  margin: 'auto',
  position: 'relative',
})

export const PoweredBy = () => {
  return (
    <PoweredByElement>
      {/*<div>Made with 🖤 in Braga, Portugal</div>*/}
      <div>By <a href='http://www.antoniobrandao.com' alt='Antonio Brandao' target='_blank' rel="noopener noreferrer">Antonio Brandao</a></div>
    </PoweredByElement>
  )
}

const PoweredByElement = styled.div({
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  bottom: '0',
  fontFamily: 'Satoshi',
  color: '#717171',
  fontSize: '12px',
  marginBottom: '10px',
  '& a': {
    color: '#717171',
    textDecoration: 'none'
  }
})
