import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { format, isSameDay, parseISO } from 'date-fns'
import { H2, BoldSpan, PSmallWhite, PWhite, PWhiteBold, PSmall } from '../../ui/elements/typography'
import { phone_max } from '../../ui/defaults/media-queries'
import Background from '../../components_system/background'
import { WidgetPageRoot, PaddingBox } from '../../app_ui/layout'
import { FlexDiv } from '../../ui/elements/layout'
import Spinner from '../../ui/components/spinner'
import { CustomRoundButtonWhite } from '../../app_ui/buttons'
import { RoundButtonBlack } from '../../ui/elements/buttons'
import CartDisplayByDay from './components/CartDisplayByDay'
import ProductAvailabilityDisplaySmall from './components/ProductAvailabilityDisplaySmall'
import ProductAmountSelector from './components/ProductAmountSelector'
import {
  ProductCardImage,
  ProductCardContent,
  // BottomWarningLabel,
  CustomContent,
  CustomPSmall,
  CustomH4,
} from './components/elements/productSelectorElements'
import {
  changeTemporaryCartAmount,
  clearTemporaryCartAmount,
  addProductToCart,
  setProductViewingIndex,
} from '../../redux/shop/actions'
import {
  getProductsStore,
  getStockInDayForProduct,
} from '../../redux/product/actions'
import {
  prepareReservationsForAPICreation,
  // getAvailabilityString,
} from '../../utils/storeUtils'
import {
  getDayColor,
  getDayState,
  getBusinessDaysArrayForward,
  getMonthDayNumber,
} from '../../utils/dayHelpers'
import {
  translateEnglishWeekdayName,
  translateEnglishWeekdayNameSmall,
} from '../../utils/translation'
import {
  getURLFormattedDate,
} from '../../utils/date'
import Header from '../Header'

// const DAY_RESERVATION_HOUR_LIMIT = 12
// const DAY_RESERVATION_HOUR_LIMIT_MORNING = 9

@connect(
  store => ({
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
    stocks: store.product.stocks,
    loadingStock: store.product.loadingStock,
    cart: store.shop.cart,
    viewingProductIndex: store.shop.viewingProductIndex,
    temporaryCartAmount: store.shop.temporaryCartAmount,
    errors: store.errors
  }),
  dispatch => ({
    actions: bindActionCreators({
      getProductsStore,
      getStockInDayForProduct,
      changeTemporaryCartAmount,
      setProductViewingIndex,
      clearTemporaryCartAmount,
      addProductToCart,
    }, dispatch)
  })
)
class WidgetProductSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDayDate: null,
      temporaryCart: {},
      cartItems: null,
    }
    this.daysSelectorRef = React.createRef()
  }

  componentDidMount() {
    const {
      storeAccount,
      productsStore,
    } = this.props

    if (!storeAccount || !storeAccount.account_logo) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100);  return <Background bgColor='#242424' /> }
    if (!productsStore) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100);  return <Background bgColor='#242424' /> }
  }

  handleLeftArrowClick = () => {
    const {
      viewingProductIndex
    } = this.props
    const new_viewingProductIndex = viewingProductIndex > 0 ? viewingProductIndex - 1 : 0
    this.props.actions.setProductViewingIndex(new_viewingProductIndex)
    this.props.actions.clearTemporaryCartAmount()
    this.setState({selectedDayDate: null})
  }

  handleRightArrowClick = () => {
    const {
      productsStore,
      viewingProductIndex,
    } = this.props
    const new_viewingProductIndex = viewingProductIndex < productsStore.length-2 ? viewingProductIndex + 1 : productsStore.length-1
    this.props.actions.setProductViewingIndex(new_viewingProductIndex)
    this.props.actions.clearTemporaryCartAmount()
    this.setState({selectedDayDate: null})
  }

  handleSelectDay = (dayDate) => {
    const {
      storeAccount,
      productsStore,
      viewingProductIndex,
    } = this.props
    // const sendDate = new Date(dayDate)
    const product = productsStore[viewingProductIndex]
    this.props.actions.getStockInDayForProduct(storeAccount._id, product._id, getURLFormattedDate(dayDate), format(dayDate, 'EEEE'))
    this.setState({selectedDayDate: dayDate})
    if (this.daysSelectorRef.current) {
      this.daysSelectorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleMinusButton = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const {
      productsStore,
      temporaryCartAmount,
      viewingProductIndex,
    } = this.props
    const product = productsStore[viewingProductIndex]
    const newAmount = temporaryCartAmount - (product.can_sell_half ? 0.5 : 1)
    if (newAmount < 0) return
    this.props.actions.changeTemporaryCartAmount(newAmount)
  }

  handlePlusButton = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const {
      stocks,
      productsStore,
      viewingProductIndex,
      temporaryCartAmount,
    } = this.props
    const product = productsStore[viewingProductIndex]
    let availableStock = stocks[product._id]
    if (!availableStock) return
    const newAmount = temporaryCartAmount + (product.can_sell_half ? 0.5 : 1)
    if (newAmount > availableStock) return
    this.props.actions.changeTemporaryCartAmount(newAmount)
  }

  handleAddToCart = () => {
    const {
      selectedDayDate,
    } = this.state
    const {
      temporaryCartAmount,
      productsStore,
      viewingProductIndex,
    } = this.props
    const product = productsStore[viewingProductIndex]
    const addProductToCartData = {
      productId: product._id,
      amount: temporaryCartAmount,
      dayDateURL: format(selectedDayDate, 'yyyy:MM:dd'),
    }

    this.props.actions.addProductToCart(addProductToCartData)
    this.props.history.push(`/shop/${this.props.match.params.app_id}/confirm-cart`)
    this.props.actions.clearTemporaryCartAmount()
  }

  renderCurrentCart = (productsStoreOrdered, confirmReservationsURL) => {
    const {
      cart,
    } = this.props
    if (!cart) return null
    const keys = Object.keys(cart)
    if (keys.length === 0) return null

    let reservationsByDay = prepareReservationsForAPICreation(cart, productsStoreOrdered)

    // filter by date
    if (reservationsByDay) {
      reservationsByDay = reservationsByDay.sort((a, b) => new Date(a.dayDate) - new Date(b.dayDate))
    }
    
    return (
      <PaddingBox>
        <br />
        <PWhiteBold>O seu saco:</PWhiteBold>
        <br />
        <CartDisplayByDay reservations={reservationsByDay} />
        <br />
        <br />
        <Link to={confirmReservationsURL} style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
          <CustomRoundButtonWhite>
            Avançar para a reserva
          </CustomRoundButtonWhite>
        </Link>
        <br />
      </PaddingBox>
    )
  }

  hasReservationForSameDay_returnAmount = (cart, product, selectedDayDate) => {
    try {
      const cartEntryAmount = cart[getURLFormattedDate(selectedDayDate)][product._id].amount
      if (cartEntryAmount) {
        return cartEntryAmount && cartEntryAmount > 0 ? cartEntryAmount : null
      }
    } catch(error) {
      // console.log('hasReservationForSameDay_returnAmount error')
    }
  }

  renderEmpty() {
    const {
      storeAccount
    } = this.props
    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo || 'carai'}
            businessName={storeAccount.business_name}
            omitCloseButton={true}
          />
          <H2 style={{alignSelf: 'flex-start'}}>NØRRE</H2>
          <br />
          <PWhite>A loja está fechada <span role="img" aria-label='emoji'>👩‍🍳</span></PWhite>
          <br />
          <br />
          {/*<PoweredBy />*/}
        </PaddingBox>
        <Background bgColor='#242424' />
      </WidgetPageRoot>
    )
  }

  render() {
    const {
      selectedDayDate,
    } = this.state
    const {
      stocks,
      cart,
      storeAccount,
      loadingStock,
      productsStore,
      temporaryCartAmount,
      viewingProductIndex,
    } = this.props

    if (!storeAccount || !storeAccount.account_logo) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!productsStore) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }

    const confirmReservationsURL = `/shop/${this.props.match.params.app_id}/confirm-cart`

    const product = productsStore[viewingProductIndex]
    if (!product) return <PSmallWhite>No product found</PSmallWhite>

    // const availabilityString = getAvailabilityString(product)

    // const time = getCurrentTime()
    const businessDays = getBusinessDaysArrayForward(storeAccount)
    
    if (businessDays.length === 0) {
      return this.renderEmpty()
    }

    const allAvailableDaysArray = businessDays.filter(businessDay => getDayState(storeAccount, businessDay.dayDate, product, null) === 'available')
    const numAvailableDays = allAvailableDaysArray.length
    const multipleAvailableDays = numAvailableDays > 1
    // const singleAvailableDay = numAvailableDays === 1 ? allAvailableDaysArray[0] : false

    // get pickup day
    // let pickupDay
    // let pickupDate

    // if (singleAvailableDay) {
    //   pickupDay = translateEnglishWeekdayName(format(singleAvailableDay.dayDate, 'EEEE'))
    //   pickupDate = getMonthDayNumber(singleAvailableDay.dayDate)
    // } else if (selectedDayDate) {
    //   pickupDay = translateEnglishWeekdayName(format(selectedDayDate, 'EEEE'))
    // }

    // const selectedDateIsToday = selectedDayDate && isSameDay(new Date(), selectedDayDate)
    
    // generate availabilityArray
    const availabilityArray = businessDays.map((businessDay, index) => {
      const dayState = getDayState(storeAccount, businessDay.dayDate, product, selectedDayDate)
      const dayName = businessDay.dayName
      const dayDate = businessDay.dayDate
      const monthDay = format(dayDate, 'd')
      
      return {
        dayName: dayName,
        selected: isSameDay(selectedDayDate, dayDate), //  || (dayState === 'available' && singleAvailableDay)
        disabled: dayState === 'unavailable',
        state: dayState,
        bgColor: getDayColor(dayState, 'bg'),
        txtColor: getDayColor(dayState, 'txt'),
        isToday: isSameDay(parseISO(dayDate), new Date()),
        dayDate: dayDate,
        monthDay: monthDay,
        customWidth: `${Math.round(90/businessDays.length)}%`,
        shortWeekdayName: translateEnglishWeekdayNameSmall(dayName),
        index: index,
      }
    })

    // const nowWeekdayName = format(new Date(), 'EEEE')
    // let hourLimit = DAY_RESERVATION_HOUR_LIMIT
    // if (availabilityString === 'Hoje' || availabilityArray[availabilityArray.length-1].dayName === nowWeekdayName) {
    //   const lowerCasePickupDay = availabilityArray[0].dayName.toLowerCase()
    //   if (!storeAccount.weekdays_availability[lowerCasePickupDay].afternoon) {
    //     hourLimit = DAY_RESERVATION_HOUR_LIMIT_MORNING
    //   }
    // }

    // const todayNotAnyMore = availabilityString === 'Hoje' && time > hourLimit
    // const currentWeekdayNameIsInAvailabilityString = availabilityString.indexOf(translateEnglishWeekdayName(getTodayWeekName()))

    const title_pt = product.title_pt
    const lead = product.lead
    const description = product.description
    
    const amount = temporaryCartAmount

    const translatedPickupDayName = selectedDayDate ? translateEnglishWeekdayName(format(selectedDayDate, 'EEEE')) : null

    let availableStock
    if (selectedDayDate && stocks[String(product._id)]) {
      availableStock = stocks[String(product._id)]
    }

    if (stocks[product._id] === 0) {
      availableStock = 0
    }

    let monthDayNumber
    if (selectedDayDate) {
      monthDayNumber = getMonthDayNumber(selectedDayDate)
    }

    // if there is selectedDay + amount + cart has same product in same day

    // create sentence

    // Já tem 6 de Pão de Trigo no saco para este dia. Deseja trocar por 1?

    let updateAmountCopy
    let amountAlreadyInCart
    if (selectedDayDate && amount && amount > 0) {
      amountAlreadyInCart = this.hasReservationForSameDay_returnAmount(cart, product, selectedDayDate)
      if (amountAlreadyInCart) {
        updateAmountCopy = `Já tem ${amountAlreadyInCart} de ${title_pt} no seu saco. Deseja substuir por ${amount}?`
      }
    }

    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
        </PaddingBox>
        <Layout>
          <ArrowElement onClick={this.handleLeftArrowClick} disabled={viewingProductIndex === 0}>
            {productsStore && productsStore.length > 1 && <LeftArrowIcon />}
          </ArrowElement>
          <div>
            <ProductCardImage bgImage={product.image_url && product.image_url !== '' ? product.image_url : '/images/empty_states/default_empty_image.png'} />
            <ProductCardContent>
              <CustomContent>
                <H2 style={{alignSelf: 'flex-start'}}>{title_pt}</H2>
                <CustomH4>{lead}</CustomH4>
                <br />
                <CustomPSmall style={{alignSelf: 'flex-start'}}>{description}</CustomPSmall>
                <br />
                {/*
                  <FlexDiv style={{width: '100%'}}>
                  <CustomPSmall>
                    Disponível: <span style={{color: 'green'}}>{availabilityString}</span>.
                  </CustomPSmall>
                </FlexDiv>*/
                }
                {/*<br />*/}

                {multipleAvailableDays && <CustomPSmall style={{marginBottom: '8px'}}>Escolha o dia em que deseja levantar este produto:</CustomPSmall>}
                {multipleAvailableDays && <br />}
                
                <div ref={this.daysSelectorRef} style={{width: '100%'}}>
                  <ProductAvailabilityDisplaySmall 
                    selectedDayDate={selectedDayDate}
                    availabilityArray={availabilityArray}
                    handleSelectDay={this.handleSelectDay}
                  />
                  { loadingStock &&
                    <FlexDiv style={{width: '100%', justifyContent: 'center', marginTop: '20px'}}>
                      <Spinner radius={30} color='black' />
                    </FlexDiv>
                  }
                  { /*selectedDayDate && availableStock > 0 && 
                    <div style={{width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                      <PSmall>{`Stock disponível: ${availableStock}`}</PSmall>
                    </div>*/
                  }
                  { !loadingStock && selectedDayDate && availableStock > 0 && 
                    <div style={{width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                      <PSmall style={{textAlign: 'center' }}>Escolha a quantidade que  deseja reservar:</PSmall>
                    </div>
                  }
                  { !loadingStock && selectedDayDate && availableStock <= 0 && 
                    <div style={{width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                      <PSmall>Este produto está esgotado neste dia <span role="img" aria-label='emoji'>😢</span></PSmall>
                    </div>
                  }
                  { !loadingStock && selectedDayDate && availableStock > 0 && 
                    <ProductAmountSelector
                      handleMinusButton={this.handleMinusButton}
                      amount={amount}
                      handlePlusButton={this.handlePlusButton}
                    />
                  }
                  <br />
                  {!loadingStock && !updateAmountCopy && selectedDayDate && amount > 0 && <CustomPSmall style={{width: '100%', textAlign: 'center'}}>Escolheu: <BoldSpan>{amount} {title_pt}</BoldSpan> para <BoldSpan>{translatedPickupDayName} {monthDayNumber}</BoldSpan></CustomPSmall>}
                  {!loadingStock && updateAmountCopy && <CustomPSmall style={{width: '100%', textAlign: 'center'}}>Já tem no seu saco <BoldSpan>{amountAlreadyInCart} {title_pt}</BoldSpan> para <BoldSpan>{translatedPickupDayName} {monthDayNumber}</BoldSpan>. Deseja substuir por <BoldSpan>{amount}</BoldSpan>?</CustomPSmall>}
                  <br />
                  { !loadingStock && amount > 0 &&
                    <FlexDiv style={{width: '100%', justifyContent: 'center', marginTop: '10px'}}>
                      <Link to={confirmReservationsURL}>
                        <RoundButtonBlack onClick={this.handleAddToCart}>
                          {updateAmountCopy ? 'Sim, substituir' : 'Colocar no saco'}
                        </RoundButtonBlack>
                      </Link>
                    </FlexDiv>
                  }
                </div>
                {/*singleAvailableDay && !currentWeekdayNameIsInAvailabilityString && <CustomPSmall style={{marginBottom: '8px'}}>Este produto só estará disponível no dia: {pickupDay}, {pickupDate}</CustomPSmall>*/}
                { /*todayNotAnyMore &&
                  <div style={{width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                    <BottomWarningLabel>{`Não é possível reservar para o mesmo dia depois das ${hourLimit}:00h`}</BottomWarningLabel>
                  </div>*/
                }
              </CustomContent>
            </ProductCardContent>
          </div>
          <ArrowElement onClick={this.handleRightArrowClick} disabled={viewingProductIndex === productsStore.length-1}>
            {productsStore && productsStore.length > 1 && <RightArrowIcon />}
          </ArrowElement>
        </Layout>
        { this.renderCurrentCart(productsStore, confirmReservationsURL) }
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Background bgColor='#242424' />
        {/*<PoweredBy />*/}
      </WidgetPageRoot>
    )
  }
}

export default withRouter(WidgetProductSelection)

const Layout = styled.div({
  display: 'grid',
  gridTemplateColumns: ' 50px auto 50px',
  [phone_max]: {
    gridTemplateColumns: '30px auto 30px'
  },
  '& h2, h4, br, p, button': {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  }
})

const ArrowElement = styled.div(props => ({
  opacity: props.disabled ? '0.2' : '1',
  pointerEvents: props.disabled ? 'none' : 'auto',
  WebkitTransition: 'all 0.5s ease-out',
  MozTransition: 'all 0.5s ease-out',
  Otransition: 'all 0.5s ease-out',
  transition: 'all 0.5s ease-out',
  height: '100px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  // width: '50px',
  cursor: 'pointer',
  [phone_max]: {
    // width: '30px',
    '& svg': {
      transform: 'scale(0.7)',
    }
  },
}))

function LeftArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      fill="none"
      viewBox="0 0 16 28"
    >
      <path
        fill="#fff"
        d="M14.875 27.75l.438-.438c.312-.312.312-.75 0-1.062L3.124 14 15.313 1.812c.312-.312.312-.75 0-1.062l-.438-.438c-.313-.312-.75-.312-1.063 0L.626 13.5c-.313.313-.313.75 0 1.063L13.813 27.75c.312.313.75.313 1.062 0z"
      ></path>
    </svg>
  );
}

function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      fill="none"
      viewBox="0 0 16 28"
    >
      <path
        fill="#fff"
        d="M1.125.25L.688.687c-.313.313-.313.75 0 1.063L12.875 14 .687 26.188c-.312.312-.312.75 0 1.062l.438.438c.313.312.75.312 1.063 0L15.374 14.5c.313-.313.313-.75 0-1.063L2.187.25c-.312-.313-.75-.313-1.062 0z"
      ></path>
    </svg>
  );
}
