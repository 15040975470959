import React from 'react'
import styled from '@emotion/styled'
import { FlexDiv } from '../../../ui/elements/layout'
import { PTiny, PWhite, H3White, H3WhiteBold, PSmallWhite } from '../../../ui/elements/typography'
import { green_shiny, blue_shiny, orange } from '../../../ui/defaults/colors'
import {
  translateEnglishWeekdayNameLowerCase,
  translateEnglishWeekdayName,
} from '../../../utils/translation'
import { getMonthDayNumber } from '../../../utils/dayHelpers'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

const translateFunction = (dayName, lowerCase) => {
  if (lowerCase) return translateEnglishWeekdayNameLowerCase(dayName)
  return translateEnglishWeekdayName(dayName)
}

const stateLabelStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: '10px',
}

const generateJSX = state => {
  switch (state) {
    case 'prepared':
      return <PTiny style={{ color: orange, ...stateLabelStyle }}>Preparada</PTiny>
    case 'finalised':
      return <PTiny style={{ color: green_shiny, ...stateLabelStyle }}>Finalizada</PTiny>
    default:
      // requested
      return <PTiny style={{ color: blue_shiny, ...stateLabelStyle }}>Pedida</PTiny>
  }
}
export const CartDisplayByDay = ({
  reservations,
  showState,
  lowerCase,
  handleDeleteReservation,
  handleRemoveReservationFromCart,
  showObservations,
}) => (
  <RootDiv style={{ width: '100%' }}>
    {reservations.map((reservation, index) => (
      <div key={index} style={{ width: '100%', marginBottom: '30px', paddingBottom: '12px' }}>
        <WeekdayRow>
          <PWhite>
            Para {translateFunction(reservation.dayName)}, {getMonthDayNumber(reservation.dayDate)}:
          </PWhite>
          {handleDeleteReservation && (
            <FAIcon
              icon="faTimes"
              family="light"
              onClick={() => handleDeleteReservation(reservation._id)}
            />
          )}
          {handleRemoveReservationFromCart && (
            <FAIcon
              icon="faTimes"
              family="light"
              onClick={() => handleRemoveReservationFromCart(reservation.dayDate)}
            />
          )}
        </WeekdayRow>
        {showState && generateJSX(reservation.state)}
        {reservation.products.map((reservationProduct, indexInner) => (
          <CustomFlexDiv
            key={indexInner + 2000}
            style={{ marginTop: indexInner === 0 ? '0' : '6px' }}
          >
            <FlexDiv>
              <H3WhiteBold style={{ marginRight: '6px' }}>{reservationProduct.amount}</H3WhiteBold>
              <H3White>{reservationProduct.title_pt}</H3White>
            </FlexDiv>
            <img src={reservationProduct.image_url} alt={reservationProduct.title_pt} />
          </CustomFlexDiv>
        ))}
        {showObservations && reservation.observations && reservation.observations !== '' && (
          <CustomPSmallWhite>"{reservation.observations}"</CustomPSmallWhite>
        )}
      </div>
    ))}
  </RootDiv>
)

export default CartDisplayByDay

const RootDiv = styled.div({
  '& *': {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  },
})

const CustomFlexDiv = styled(FlexDiv)({
  width: '100%',
  alignItems: 'center',
  '& img': {
    marginLeft: 'auto',
    height: '30px',
    width: '30px',
    borderRadius: '20px',
    display: 'inline',
  },
})

const WeekdayRow = styled(FlexDiv)({
  width: '100%',
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  '& .fa-times': {
    fontSize: '24px',
    marginLeft: '12px',
    color: '#7d7d7d',
    cursor: 'pointer',
    position: 'relative',
    top: '-1px',
    ':hover': {
      color: 'red',
    },
    ':active': {
      color: '#ff7171',
    },
  },
})

const CustomPSmallWhite = styled(PSmallWhite)({
  fontStyle: 'italic',
  opacity: '0.5',
  marginTop: '10px',
  maxWidth: '490px',
})
