import { getURLFormattedDate } from '../../utils/date'
import {
  CLEAR_CART,
  ADD_PRODUCT_TO_CART,
  REMOVE_RESERVATION_FROM_CART,
  CHANGE_TEMPORARY_CART_AMOUNT,
  CLEAR_TEMPORARY_CART_AMOUNT,
  SET_PRODUCT_VIEWING_INDEX,
  SET_CLIENT_DETAILS,
  CLEAR_CLIENT_DETAILS,
  SUBMIT_SHOP_RESERVATIONS,
  SUBMIT_SHOP_RESERVATIONS_SUCCESS,
  SUBMIT_SHOP_RESERVATIONS_ERROR,

  // SUBMIT_RESERVATION_FORM,
  SUBMIT_RESERVATION_FORM_SUCCESS,
  // SUBMIT_RESERVATION_FORM_ERROR,

  // DELETE_RESERVATION_IN_LOCALSTORAGE,
  // DELETE_RESERVATION_IN_LOCALSTORAGE_SUCCESS,
  // DELETE_RESERVATION_IN_LOCALSTORAGE_ERROR,
  CANCEL_REMOTE_RESERVATION,
  CANCEL_REMOTE_RESERVATION_SUCCESS,
  CANCEL_REMOTE_RESERVATION_ERROR,

  // GET_LOCALSTORAGE_RESERVATIONS,
  // GET_LOCALSTORAGE_RESERVATIONS_SUCCESS,
  // GET_LOCALSTORAGE_RESERVATIONS_ERROR,

  // SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE,
  // SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_SUCCESS,
  // SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_ERROR,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_SUCCESS,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_ERROR,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_SUCCESS,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR,
  REMOVE_PRODUCT_FROM_RESERVATION,
  REMOVE_PRODUCT_FROM_RESERVATION_SUCCESS,
  REMOVE_PRODUCT_FROM_RESERVATION_ERROR,
  GET_ACCOUNT_DATA_FOR_CLIENT,
  GET_ACCOUNT_DATA_FOR_CLIENT_SUCCESS,
  GET_ACCOUNT_DATA_FOR_CLIENT_ERROR,

  // GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG,
  // GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_SUCCESS,
  // GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_ERROR,

  // GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG,
  // GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_SUCCESS,
  // GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_ERROR,
} from '../action-types'

// const getProductOutOfStockFromPayload = (payload) => {
//   try {
//     const productOutOfStock = payload.response.data.productOutOfStock
//     if (productOutOfStock && productOutOfStock.length && productOutOfStock.length !== 0) {
//       return productOutOfStock
//     }
//   } catch (err) {
//     console.error('err: getProductOutOfStockFromPayload: ' + err)
//     return null
//   }
// }

// const getErrorMessageFromPayload = (payload) => {
//   try {
//     const errorMessage = payload.response.data.message
//     if (errorMessage && errorMessage.length && errorMessage.length !== 0) {
//       return errorMessage
//     }
//   } catch (err) {
//     console.error('err: getErrorMessageFromPayload: ' + err)
//     return null
//   }
// }

export default function reducer(
  state = {
    cart: null,
    numReservationsInCart: 0,
    product: null,
    active: false,
    successErrorState: 'success',
    temporaryCartAmount: 0,
    viewingProductIndex: 0,
    clientDetails: null,
    submittedReservations: null,
    localStorageReservations: null,
    clientReservations: null,
    loading: false,
    linkToUserPage: null,
  },
  action,
) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART: {
      const productId = action.productId
      const amount = action.amount
      const dayDateURL = action.dayDateURL
      // const dayDate = action.dayDate
      // const dayDate = action.dayDate

      let updatedCart = state.cart
      if (!updatedCart) {
        updatedCart = {}
      }
      if (!updatedCart[dayDateURL]) {
        updatedCart[dayDateURL] = {}
      }
      if (!updatedCart[dayDateURL][productId]) {
        updatedCart[dayDateURL][productId] = {}
      }
      updatedCart[dayDateURL][productId].amount = amount
      // updatedCart[dayDateURL].dayDate = dayDate

      return {
        ...state,
        cart: updatedCart,
        numReservationsInCart: Object.keys(updatedCart).length,
      }
    }
    case REMOVE_RESERVATION_FROM_CART: {
      const dayDate = action.dayDate

      let updatedCart = state.cart

      const urlFormattedDate = getURLFormattedDate(dayDate)
      delete updatedCart[urlFormattedDate]

      // if (!updatedCart) {
      //   updatedCart = {}
      // } else if (updatedCart[weekDayName]) {
      //   delete updatedCart[weekDayName]
      // }

      return {
        ...state,
        cart: updatedCart,
        numReservationsInCart: Object.keys(updatedCart).length,
      }
    }
    case CLEAR_CART: {
      return {
        ...state,
        cart: null,
        viewingProductIndex: 0,
      }
    }
    case SET_CLIENT_DETAILS: {
      return {
        ...state,
        clientDetails: action.clientDetails,
      }
    }
    case CLEAR_CLIENT_DETAILS: {
      return {
        ...state,
        clientDetails: null,
      }
    }
    case CHANGE_TEMPORARY_CART_AMOUNT: {
      return {
        ...state,
        temporaryCartAmount: action.amount,
      }
    }
    case CLEAR_TEMPORARY_CART_AMOUNT: {
      return {
        ...state,
        temporaryCartAmount: 0,
      }
    }
    case SET_PRODUCT_VIEWING_INDEX: {
      return {
        ...state,
        viewingProductIndex: action.index,
      }
    }
    case SUBMIT_RESERVATION_FORM_SUCCESS: {
      return {
        ...state,
        clientDetails: action.payload,
      }
    }
    // case GET_LOCALSTORAGE_RESERVATIONS: { return { ...state, loading: true, successErrorState: 'unset' } }
    // case GET_LOCALSTORAGE_RESERVATIONS_ERROR: { return { ...state, loading: false, successErrorState: 'error'} }
    // case GET_LOCALSTORAGE_RESERVATIONS_SUCCESS: {
    //   return {
    //     ...state,
    //     successErrorState: 'success',
    //     errorMessage: null,
    //     localStorageReservations: action.payload || [] ,
    //     loading: false,
    //   }
    // }
    case SUBMIT_SHOP_RESERVATIONS: {
      return { ...state, loading: true, successErrorState: 'unset' }
    }
    case SUBMIT_SHOP_RESERVATIONS_ERROR: {
      return {
        ...state,
        loading: false,
        successErrorState: 'error',
        // productOutOfStock: getProductOutOfStockFromPayload(action.payload),
        productOutOfStock: action.payload.productOutOfStock,
        errorMessage: action.payload.message,
      }
    }
    case SUBMIT_SHOP_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        successErrorState: 'success',
        errorMessage: null,
        submittedReservations: action.payload.success,
        loading: false,
        linkToUserPage: action.payload.linkToUserPage,
        // submittedReservations: [...state.submittedReservations, action.payload ],
      }
    }
    case GET_CLIENT_DATA_FOR_PERSONAL_PAGE: {
      return { ...state, loading: true, successErrorState: 'unset' }
    }
    case GET_CLIENT_DATA_FOR_PERSONAL_PAGE_ERROR: {
      return { ...state, loading: false, successErrorState: 'error' }
    }
    case GET_CLIENT_DATA_FOR_PERSONAL_PAGE_SUCCESS: {
      return {
        ...state,
        successErrorState: 'success',
        errorMessage: null,
        clientReservations: action.payload.clientReservations,
        shopAccount: action.payload.shopAccount,
        shopProducts: action.payload.shopProducts,
        loading: false,
        // submittedReservations: [...state.submittedReservations, action.payload ],
      }
    }
    case GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE: {
      return { ...state, loading: true, successErrorState: 'unset' }
    }
    case GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR: {
      return { ...state, loading: false, successErrorState: 'error' }
    }
    case GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_SUCCESS: {
      return {
        ...state,
        successErrorState: 'success',
        errorMessage: null,
        clientReservations: action.payload.clientReservations,
        shopAccount: action.payload.shopAccount,
        shopProducts: action.payload.shopProducts,
        loading: false,
        // submittedReservations: [...state.submittedReservations, action.payload ],
      }
    }
    case GET_ACCOUNT_DATA_FOR_CLIENT: {
      return { ...state, loading: true, successErrorState: 'unset' }
    }
    case GET_ACCOUNT_DATA_FOR_CLIENT_ERROR: {
      return { ...state, loading: false, successErrorState: 'error' }
    }
    case GET_ACCOUNT_DATA_FOR_CLIENT_SUCCESS: {
      return {
        ...state,
        successErrorState: 'success',
        errorMessage: null,
        shopAccount: action.payload.shopAccount,
        loading: false,
        // submittedReservations: [...state.submittedReservations, action.payload ],
      }
    }
    // case DELETE_RESERVATION_IN_LOCALSTORAGE: { return { ...state, loading: true, successErrorState: 'unset' } }
    // case DELETE_RESERVATION_IN_LOCALSTORAGE_ERROR: { return { ...state, loading: false, successErrorState: 'error'} }
    // case DELETE_RESERVATION_IN_LOCALSTORAGE_SUCCESS: {
    //   return {
    //     ...state,
    //     successErrorState: 'success',
    //     errorMessage: null,
    //     localStorageReservations: state.localStorageReservations.filter(reservation => reservation._id !== action.payload),
    //     loading: false,
    //   }
    // }
    case REMOVE_PRODUCT_FROM_RESERVATION: {
      return { ...state, loading: true, successErrorState: 'unset' }
    }
    case REMOVE_PRODUCT_FROM_RESERVATION_ERROR: {
      return { ...state, loading: false, successErrorState: 'error' }
    }
    case REMOVE_PRODUCT_FROM_RESERVATION_SUCCESS: {
      return {
        ...state,
        successErrorState: 'success',
        errorMessage: null,
        loading: false,
        clientReservations: state.clientReservations.map(reservation =>
          reservation._id === action.payload._id ? { ...action.payload } : reservation,
        ),
      }
    }
    case CANCEL_REMOTE_RESERVATION: {
      return { ...state, loading: true, successErrorState: 'unset' }
    }
    case CANCEL_REMOTE_RESERVATION_ERROR: {
      return { ...state, loading: false, successErrorState: 'error' }
    }
    case CANCEL_REMOTE_RESERVATION_SUCCESS: {
      return {
        ...state,
        successErrorState: 'success',
        errorMessage: null,
        loading: false,
        clientReservations: state.clientReservations.map(reservation =>
          reservation._id === action.payload._id
            ? { ...reservation, cancelled: true }
            : reservation,
        ),
      }
    }
    // case SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE: { return { ...state, loading: true, successErrorState: 'unset' } }
    // case SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_ERROR: { return { ...state, loading: false, successErrorState: 'error'} }
    // case SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_SUCCESS: {
    //   return {
    //     ...state,
    //     successErrorState: 'success',
    //     errorMessage: null,
    //     loading: false,
    //     cart: null,
    //   }
    // }
    // case GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG: { return { ...state, loading: true } }
    // case GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_ERROR: { return { ...state, loading: false, successErrorState: 'error'} }
    // case GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_SUCCESS: {
    //   return {
    //     ...state,
    //     shopProducts: filterAvailableProducts(action.payload),
    //     loading: false,
    //     // storeAccount: action.payload.storeAccount,
    //   }
    // }
    // case GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG: { return { ...state, loading: true } }
    // case GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_ERROR: { return { ...state, loading: false, successErrorState: 'error'} }
    // case GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_SUCCESS: {
    //   return {
    //     ...state,
    //     shopAccount: action.payload,
    //     loading: false,
    //   }
    // }
    default:
      return state
  }
}
