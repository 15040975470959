import styled from '@emotion/styled'
import { red_light } from '../../ui/defaults/colors'
import { RoundButtonWhite, RoundButtonBlack } from '../../ui/elements/buttons'

export const CustomRoundButtonCancel = styled(RoundButtonBlack)({
  background: '#434343',
  '& p': {
    color: 'white'
  },
  ':hover': {
    background: '#525252',
  },
  ':active': {
    background: '#2f2f2f',
  },
})

export const CustomRoundButtonRed = styled(RoundButtonWhite)({
  background: red_light,
  color: 'white',
  ':hover': {
    background: '#db4040',
  },
  ':active': {
    background: '#cb3030',
  },
})

export const CustomRoundButtonWhite = styled(RoundButtonWhite)({
  ':hover': {
    background: '#fbd8be',
  },
  ':active': {
    background: '#f5c7a5',
  },
})
