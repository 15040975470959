import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { PSmall, PTiny } from '../../../../ui/elements/typography'
import { tablet_max, phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import { red_light, green_shiny, blue_shiny } from '../../../../ui/defaults/colors'

class WeekPagerDay extends Component {
  render() {
    return (
      <RootDiv
        isToday={this.props.isToday}
        boxWidth={this.props.boxWidth}
        active={this.props.selected}
        >
        <ClickDiv to={`/reservas/${this.props.weekDate}`}>
          { this.props.isToday && <GreenRect />}
          <PSmall>{this.props.dayNameShort} {this.props.monthDayNumber}</PSmall>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <RequestedPTiny isEmpty={this.props.numRequested}>{this.props.numRequested}</RequestedPTiny>
            { this.props.hasCancelled ? <CancelledPTiny><Parenthesis>(</Parenthesis>{this.props.numCancelled}<Parenthesis>)</Parenthesis></CancelledPTiny> : null }
            <SeparatorSlash>/</SeparatorSlash>
            <PreparedPTiny isEmpty={this.props.numPrepared}>{this.props.numPrepared}</PreparedPTiny>
            <SeparatorSlash>/</SeparatorSlash>
            <FinalisedPTiny isEmpty={this.props.numFinalised}>{this.props.numFinalised}</FinalisedPTiny>
          </div>
        </ClickDiv>
      </RootDiv>
    )
  }
}

export default WeekPagerDay

const ClickDiv = styled(NavLink)({
  width: '100%',
  display: 'block',
  padding: '10px 18px 7px 18px',
  position: 'relative',
  [tablet_max]:{
    padding: '11px',
  },
  [phablet_max]:{
    padding: '8px',
  },
  [phone_max]:{
    padding: '0',
    paddingTop: '5px',
    paddingLeft: '5px',
  },
})

const GreenRect = styled.div({
  background: green_shiny,
  width: '100%',
  height: '4px',
  position: 'absolute',
  top: '0',
  left: '0',
})

const RootDiv = styled.div(props => ({
  background: props.active ? '#e8eff9' : 'white',
  width: props.boxWidth,
  display: 'flex',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  flexDirection: 'column',
}))

const CustomPSmall = styled(PTiny)({
  color: '#00C1FF',
  fontWeight: 'bold',
})
const RequestedPTiny = styled(CustomPSmall)(props => ({
  color: props.isEmpty ? blue_shiny : '#b0cefb'
}))
const PreparedPTiny = styled(CustomPSmall)(props => ({
  color: props.isEmpty ? '#FF9900': '#b0cefb'
}))
const FinalisedPTiny = styled(CustomPSmall)(props => ({
  color: props.isEmpty ? green_shiny: '#b0cefb'
}))
const CancelledPTiny = styled(CustomPSmall)({
  color: red_light,
  // fontSize: '15px',
})

const SeparatorSlash = styled(PTiny)({
  color: '#d1d0d0',
  margin: '0 1px',
  [phablet_max]:{
    margin: '0',
  },
})

const Parenthesis = styled.span({
  color: '#d1d0d0',
  margin: '0',
  [phablet_max]:{
    margin: '0',
  },
})
