import removeAccents from 'remove-accents'
import {
  compareDesc,
} from 'date-fns'
import {
  ensureGetRegularDate,
  getDateFromURLDate,
} from './date'

export const sortArrayByAlphabeticalPropertyValue = (array, property) => {
  return array.sort(function(a, b) {
    var textA = removeAccents(a[property].toUpperCase())
    var textB = removeAccents(b[property].toUpperCase())
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
  })
}

export const sortArrayByPropertyValue = (array, property) => {
  return array.sort(function(a, b) {
    var textA = a[property].toUpperCase()
    var textB = b[property].toUpperCase()
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
  })
}

export const sortProductsByShopProductsOrder = (productsArray, shop_products_order) => {
  const sortedProducts = []
  for(const prodId of shop_products_order) {
    const productDoc = productsArray.find(prod => String(prod._id) === String(prodId))
    if (productDoc) {
      sortedProducts.push(productDoc)
    }
  }
  return sortedProducts
}

export const sortArrayByDateCreated = (array) => {
  // return array.sort((a, b) => ensureGetRegularDate(a.date_created) - ) < 0 ? 1 : 0
  return array.sort(function(a, b) {
    var dateA = ensureGetRegularDate(a.date_created)
    var dateB = ensureGetRegularDate(b.date_created)
    return (dateA < dateB) ? -1 : (dateA > dateB) ? 1 : 0
  })
}

export const sortArrayByDateCreatedAndUpdated = (array) => {
  for(const item of array) {
    if (!item.date_changed) {
      item.date_changed = item.updated ? new Date(item.date_updated) : new Date(item.date_created)
    }
  }
  return array.sort(function(a, b) {
    var dateA = ensureGetRegularDate(a.date_changed)
    var dateB = ensureGetRegularDate(b.date_changed)
    return (dateA < dateB) ? -1 : (dateA > dateB) ? 1 : 0
  })
}

export const sortArrayByDateProperty = (array, dateProperty) => {
  return array.sort(function(a, b) {
    var dateA = ensureGetRegularDate(a['dateProperty'])
    var dateB = ensureGetRegularDate(b['dateProperty'])
    return (dateA < dateB) ? -1 : (dateA > dateB) ? 1 : 0
  })
}

export const sortArrayByLastChangedDate = (array, datePropertyString) => {
  return array.sort(function(a, b) {
    var dateA = a[datePropertyString]
    var dateB = b[datePropertyString]
    return compareDesc(dateA, dateB)
  })
}

export const sortArrayByNumericProperty = (array, numericProperty) => {
  return array.sort(function(a, b) {
    var valueA = ensureGetRegularDate(a[numericProperty])
    var valueB = ensureGetRegularDate(b[numericProperty])
    return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0
  })
}

export const sortArrayByNumericPropertyReverse = (array, numericProperty) => {
  return array.sort(function(a, b) {
    var valueA = ensureGetRegularDate(a[numericProperty])
    var valueB = ensureGetRegularDate(b[numericProperty])
    return (valueA < valueB) ? 1 : (valueA > valueB) ? -1 : 0
  })
}

export const sortArrayByURLDate = (array) => {
  return array.sort((a, b) => getDateFromURLDate(a['dateProperty']) - getDateFromURLDate(b['dateProperty']))
}

export const arrayMove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}