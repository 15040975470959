import { combineReducers } from 'redux'
import errorReducer from './error/reducer'
import systemReducer from './system/reducer'
import userReducer from './user/reducer'
import productReducer from './product/reducer'
import dialogReducer from './dialog/reducer'
import accountReducer from './account/reducer'
import reservaReducer from './reserva/reducer'
import shopReducer from './shop/reducer'
import clientReducer from './client/reducer'

export default combineReducers({
  system: systemReducer,
  user: userReducer,
  product: productReducer,
  errors: errorReducer,
  dialog: dialogReducer,
  account: accountReducer,
  reserva: reservaReducer,
  shop: shopReducer,
  client: clientReducer,
})