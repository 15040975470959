// types.js

export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'

// export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT'
// export const GET_USER_ACCOUNT_SUCCESS = 'GET_USER_ACCOUNT_SUCCESS'
// export const GET_USER_ACCOUNT_ERROR = 'GET_USER_ACCOUNT_ERROR'

export const TRIGGER_CONFIRM_DIALOG = 'SET_DIALOG'
export const CLOSE_CONFIRM_DIALOG = 'UNSET_DIALOG'

// product

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'

export const GET_PRODUCTS_STORE = 'GET_PRODUCTS_STORE'
export const GET_PRODUCTS_STORE_SUCCESS = 'GET_PRODUCTS_STORE_SUCCESS'
export const GET_PRODUCTS_STORE_ERROR = 'GET_PRODUCTS_STORE_ERROR'

// export const GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG = 'GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG'
// export const GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_SUCCESS = 'GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_SUCCESS'
// export const GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_ERROR = 'GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_ERROR'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR'

export const UPDATE_PRODUCT_TITLE = 'UPDATE_PRODUCT_TITLE'
export const UPDATE_PRODUCT_TITLE_SUCCESS = 'UPDATE_PRODUCT_TITLE_SUCCESS'
export const UPDATE_PRODUCT_TITLE_ERROR = 'UPDATE_PRODUCT_TITLE_ERROR'

export const UPDATE_PRODUCT_LEAD = 'UPDATE_PRODUCT_LEAD'
export const UPDATE_PRODUCT_LEAD_SUCCESS = 'UPDATE_PRODUCT_LEAD_SUCCESS'
export const UPDATE_PRODUCT_LEAD_ERROR = 'UPDATE_PRODUCT_LEAD_ERROR'

export const UPDATE_PRODUCT_DESCRIPTION = 'UPDATE_PRODUCT_DESCRIPTION'
export const UPDATE_PRODUCT_DESCRIPTION_SUCCESS = 'UPDATE_PRODUCT_DESCRIPTION_SUCCESS'
export const UPDATE_PRODUCT_DESCRIPTION_ERROR = 'UPDATE_PRODUCT_DESCRIPTION_ERROR'

export const GET_STOCK_IN_DAY_FOR_PRODUCT = 'GET_STOCK_IN_DAY_FOR_PRODUCT'
export const GET_STOCK_IN_DAY_FOR_PRODUCT_SUCCESS = 'GET_STOCK_IN_DAY_FOR_PRODUCT_SUCCESS'
export const GET_STOCK_IN_DAY_FOR_PRODUCT_ERROR = 'GET_STOCK_IN_DAY_FOR_PRODUCT_ERROR'

export const GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS = 'GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS'
export const GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_SUCCESS =
  'GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_SUCCESS'
export const GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_ERROR =
  'GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_ERROR'

export const UPDATE_PRODUCT_STOCK_TOTAL = 'UPDATE_PRODUCT_STOCK_TOTAL'
export const UPDATE_PRODUCT_STOCK_TOTAL_SUCCESS = 'UPDATE_PRODUCT_STOCK_TOTAL_SUCCESS'
export const UPDATE_PRODUCT_STOCK_TOTAL_ERROR = 'UPDATE_PRODUCT_STOCK_TOTAL_ERROR'

export const UPDATE_PRODUCT_WEEKDAY_STOCK = 'UPDATE_PRODUCT_WEEKDAY_STOCK'
export const UPDATE_PRODUCT_WEEKDAY_STOCK_SUCCESS = 'UPDATE_PRODUCT_WEEKDAY_STOCK_SUCCESS'
export const UPDATE_PRODUCT_WEEKDAY_STOCK_ERROR = 'UPDATE_PRODUCT_WEEKDAY_STOCK_ERROR'

export const UPDATE_CAN_BUY_HALF = 'UPDATE_CAN_BUY_HALF'
export const UPDATE_CAN_BUY_HALF_SUCCESS = 'UPDATE_CAN_BUY_HALF_SUCCESS'
export const UPDATE_CAN_BUY_HALF_ERROR = 'UPDATE_CAN_BUY_HALF_ERROR'

export const UPLOAD_PRODUCT_IMAGE = 'UPLOAD_PRODUCT_IMAGE'
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = 'UPLOAD_PRODUCT_IMAGE_SUCCESS'
export const UPLOAD_PRODUCT_IMAGE_ERROR = 'UPLOAD_PRODUCT_IMAGE_ERROR'

export const UPDATE_PRODUCT_AVAILABLE_FOR_SALE = 'UPDATE_PRODUCT_AVAILABLE_FOR_SALE'
export const UPDATE_PRODUCT_AVAILABLE_FOR_SALE_SUCCESS = 'UPDATE_PRODUCT_AVAILABLE_FOR_SALE_SUCCESS'
export const UPDATE_PRODUCT_AVAILABLE_FOR_SALE_ERROR = 'UPDATE_PRODUCT_AVAILABLE_FOR_SALE_ERROR'

export const UPDATE_PRODUCT_DAY_AVAILABILITY_STATE = 'UPDATE_PRODUCT_DAY_AVAILABILITY_STATE'
export const UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_SUCCESS =
  'UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_SUCCESS'
export const UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_ERROR =
  'UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_ERROR'

export const ORDER_SHOP_PRODUCT = 'ORDER_SHOP_PRODUCT'
export const ORDER_SHOP_PRODUCT_SUCCESS = 'ORDER_SHOP_PRODUCT_SUCCESS'
export const ORDER_SHOP_PRODUCT_ERROR = 'ORDER_SHOP_PRODUCT_ERROR'

// export const RESET_RESERVATIONS_COUNTERS = 'RESET_RESERVATIONS_COUNTERS'
// export const RESET_RESERVATIONS_COUNTERS_SUCCESS = 'RESET_RESERVATIONS_COUNTERS_SUCCESS'
// export const RESET_RESERVATIONS_COUNTERS_ERROR = 'RESET_RESERVATIONS_COUNTERS_ERROR'

// account

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR'

export const GET_SHOP_PRODUCTS_ORDER = 'GET_SHOP_PRODUCTS_ORDER'
export const GET_SHOP_PRODUCTS_ORDER_SUCCESS = 'GET_SHOP_PRODUCTS_ORDER_SUCCESS'
export const GET_SHOP_PRODUCTS_ORDER_ERROR = 'GET_SHOP_PRODUCTS_ORDER_ERROR'

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR'

export const UPDATE_ACCOUNT_BUSINESS_NAME = 'UPDATE_ACCOUNT_BUSINESS_NAME'
export const UPDATE_ACCOUNT_BUSINESS_NAME_SUCCESS = 'UPDATE_ACCOUNT_BUSINESS_NAME_SUCCESS'
export const UPDATE_ACCOUNT_BUSINESS_NAME_ERROR = 'UPDATE_ACCOUNT_BUSINESS_NAME_ERROR'

export const UPDATE_ACCOUNT_EMAIL = 'UPDATE_ACCOUNT_EMAIL'
export const UPDATE_ACCOUNT_EMAIL_SUCCESS = 'UPDATE_ACCOUNT_EMAIL_SUCCESS'
export const UPDATE_ACCOUNT_EMAIL_ERROR = 'UPDATE_ACCOUNT_EMAIL_ERROR'

export const UPDATE_ACCOUNT_HOSTNAME = 'UPDATE_ACCOUNT_HOSTNAME'
export const UPDATE_ACCOUNT_HOSTNAME_SUCCESS = 'UPDATE_ACCOUNT_HOSTNAME_SUCCESS'
export const UPDATE_ACCOUNT_HOSTNAME_ERROR = 'UPDATE_ACCOUNT_HOSTNAME_ERROR'

export const UPDATE_ACCOUNT_ACTIVE = 'UPDATE_ACCOUNT_ACTIVE'
export const UPDATE_ACCOUNT_ACTIVE_SUCCESS = 'UPDATE_ACCOUNT_ACTIVE_SUCCESS'
export const UPDATE_ACCOUNT_ACTIVE_ERROR = 'UPDATE_ACCOUNT_ACTIVE_ERROR'

export const UPLOAD_ACCOUNT_LOGO = 'UPLOAD_ACCOUNT_LOGO'
export const UPLOAD_ACCOUNT_LOGO_SUCCESS = 'UPLOAD_ACCOUNT_LOGO_SUCCESS'
export const UPLOAD_ACCOUNT_LOGO_ERROR = 'UPLOAD_ACCOUNT_LOGO_ERROR'

export const UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR = 'UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR'
export const UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_SUCCESS =
  'UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_SUCCESS'
export const UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_ERROR = 'UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_ERROR'

export const UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR = 'UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR'
export const UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_SUCCESS =
  'UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_SUCCESS'
export const UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_ERROR = 'UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_ERROR'

export const UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR = 'UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR'
export const UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_SUCCESS =
  'UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_SUCCESS'
export const UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_ERROR =
  'UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_ERROR'

export const UPDATE_ACCOUNT_OPENING_DAYS = 'UPDATE_ACCOUNT_OPENING_DAYS'
export const UPDATE_ACCOUNT_OPENING_DAYS_SUCCESS = 'UPDATE_ACCOUNT_OPENING_DAYS_SUCCESS'
export const UPDATE_ACCOUNT_OPENING_DAYS_ERROR = 'UPDATE_ACCOUNT_OPENING_DAYS_ERROR'

export const UPDATE_WEEKDAYS_AVAILABILITY = 'UPDATE_WEEKDAYS_AVAILABILITY'
export const UPDATE_WEEKDAYS_AVAILABILITY_SUCCESS = 'UPDATE_WEEKDAYS_AVAILABILITY_SUCCESS'
export const UPDATE_WEEKDAYS_AVAILABILITY_ERROR = 'UPDATE_WEEKDAYS_AVAILABILITY_ERROR'

export const UPDATE_USER_ACCOUNT_DIRECT = 'UPDATE_USER_ACCOUNT_DIRECT'
export const UPDATE_USER_WEEKDAYS_AVAILABILITY_DIRECT = 'UPDATE_USER_WEEKDAYS_AVAILABILITY_DIRECT'

// reservations

export const GET_RESERVATION = 'GET_RESERVATION'
export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS'
export const GET_RESERVATION_ERROR = 'GET_RESERVATION_ERROR'

export const GET_RESERVATIONS = 'GET_RESERVATIONS'
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS'
export const GET_RESERVATIONS_ERROR = 'GET_RESERVATIONS_ERROR'

export const CANCEL_RESERVATION = 'CANCEL_RESERVATION'
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS'
export const CANCEL_RESERVATION_ERROR = 'CANCEL_RESERVATION_ERROR'

export const UNCANCEL_RESERVATION = 'UNCANCEL_RESERVATION'
export const UNCANCEL_RESERVATION_SUCCESS = 'UNCANCEL_RESERVATION_SUCCESS'
export const UNCANCEL_RESERVATION_ERROR = 'UNCANCEL_RESERVATION_ERROR'

export const GET_RECENTLY_CHANGED_RESERVATIONS = 'GET_RECENTLY_CHANGED_RESERVATIONS'
export const GET_RECENTLY_CHANGED_RESERVATIONS_SUCCESS = 'GET_RECENTLY_CHANGED_RESERVATIONS_SUCCESS'
export const GET_RECENTLY_CHANGED_RESERVATIONS_ERROR = 'GET_RECENTLY_CHANGED_RESERVATIONS_ERROR'

export const ADD_YEARMONTHDAY_TO_RESERVATION = 'ADD_YEARMONTHDAY_TO_RESERVATION'
export const ADD_YEARMONTHDAY_TO_RESERVATION_SUCCESS = 'ADD_YEARMONTHDAY_TO_RESERVATION_SUCCESS'
export const ADD_YEARMONTHDAY_TO_RESERVATION_ERROR = 'ADD_YEARMONTHDAY_TO_RESERVATION_ERROR'

export const SAVE_RESERVATION = 'SAVE_RESERVATION'
export const SAVE_RESERVATION_SUCCESS = 'SAVE_RESERVATION_SUCCESS'
export const SAVE_RESERVATION_ERROR = 'SAVE_RESERVATION_ERROR'

// export const SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE = 'SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE'
// export const SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_SUCCESS = 'SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_SUCCESS'
// export const SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_ERROR = 'SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_ERROR'

export const CREATE_RESERVATION = 'CREATE_RESERVATION'
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS'
export const CREATE_RESERVATION_ERROR = 'CREATE_RESERVATION_ERROR'

export const DELETE_RESERVATION = 'DELETE_RESERVATION'
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS'
export const DELETE_RESERVATION_ERROR = 'DELETE_RESERVATION_ERROR'

export const RESERVATION_STATE_FORWARD = 'RESERVATION_STATE_FORWARD'
export const RESERVATION_STATE_FORWARD_SUCCESS = 'RESERVATION_STATE_FORWARD_SUCCESS'
export const RESERVATION_STATE_FORWARD_ERROR = 'RESERVATION_STATE_FORWARD_ERROR'

export const RESERVATION_STATE_BACKWARD = 'RESERVATION_STATE_BACKWARD'
export const RESERVATION_STATE_BACKWARD_SUCCESS = 'RESERVATION_STATE_BACKWARD_SUCCESS'
export const RESERVATION_STATE_BACKWARD_ERROR = 'RESERVATION_STATE_BACKWARD_ERROR'

// shop

export const CLEAR_CART = 'CLEAR_CART'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const REMOVE_RESERVATION_FROM_CART = 'REMOVE_RESERVATION_FROM_CART'
export const CHANGE_TEMPORARY_CART_AMOUNT = 'CHANGE_TEMPORARY_CART_AMOUNT'
export const SET_PRODUCT_VIEWING_INDEX = 'SET_PRODUCT_VIEWING_INDEX'
export const CLEAR_TEMPORARY_CART_AMOUNT = 'CLEAR_TEMPORARY_CART_AMOUNT'
export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS'
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS'

// export const GET_LOCALSTORAGE_RESERVATIONS = 'GET_LOCALSTORAGE_RESERVATIONS'
// export const GET_LOCALSTORAGE_RESERVATIONS_SUCCESS = 'GET_LOCALSTORAGE_RESERVATIONS_SUCCESS'
// export const GET_LOCALSTORAGE_RESERVATIONS_ERROR = 'GET_LOCALSTORAGE_RESERVATIONS_ERROR'

export const SUBMIT_RESERVATION_FORM = 'SUBMIT_RESERVATION_FORM'
export const SUBMIT_RESERVATION_FORM_SUCCESS = 'SUBMIT_RESERVATION_FORM_SUCCESS'
export const SUBMIT_RESERVATION_FORM_ERROR = 'SUBMIT_RESERVATION_FORM_ERROR'

export const SUBMIT_SHOP_RESERVATIONS = 'SUBMIT_SHOP_RESERVATIONS'
export const SUBMIT_SHOP_RESERVATIONS_SUCCESS = 'SUBMIT_SHOP_RESERVATIONS_SUCCESS'
export const SUBMIT_SHOP_RESERVATIONS_ERROR = 'SUBMIT_SHOP_RESERVATIONS_ERROR'

export const CANCEL_REMOTE_RESERVATION = 'CANCEL_REMOTE_RESERVATION'
export const CANCEL_REMOTE_RESERVATION_SUCCESS = 'CANCEL_REMOTE_RESERVATION_SUCCESS'
export const CANCEL_REMOTE_RESERVATION_ERROR = 'CANCEL_REMOTE_RESERVATION_ERROR'

export const REMOVE_PRODUCT_FROM_RESERVATION = 'REMOVE_PRODUCT_FROM_RESERVATION'
export const REMOVE_PRODUCT_FROM_RESERVATION_SUCCESS = 'REMOVE_PRODUCT_FROM_RESERVATION_SUCCESS'
export const REMOVE_PRODUCT_FROM_RESERVATION_ERROR = 'REMOVE_PRODUCT_FROM_RESERVATION_ERROR'

// export const DELETE_RESERVATION_IN_LOCALSTORAGE = 'DELETE_RESERVATION_IN_LOCALSTORAGE'
// export const DELETE_RESERVATION_IN_LOCALSTORAGE_SUCCESS = 'DELETE_RESERVATION_IN_LOCALSTORAGE_SUCCESS'
// export const DELETE_RESERVATION_IN_LOCALSTORAGE_ERROR = 'DELETE_RESERVATION_IN_LOCALSTORAGE_ERROR'

export const GET_CLIENT_DATA_FOR_PERSONAL_PAGE = 'GET_CLIENT_DATA_FOR_PERSONAL_PAGE'
export const GET_CLIENT_DATA_FOR_PERSONAL_PAGE_SUCCESS = 'GET_CLIENT_DATA_FOR_PERSONAL_PAGE_SUCCESS'
export const GET_CLIENT_DATA_FOR_PERSONAL_PAGE_ERROR = 'GET_CLIENT_DATA_FOR_PERSONAL_PAGE_ERROR'

// clients
export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS'
export const GET_CLIENT_ERROR = 'GET_CLIENT_ERROR'

export const SAVE_CLIENT = 'SAVE_CLIENT'
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS'
export const SAVE_CLIENT_ERROR = 'SAVE_CLIENT_ERROR'

export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR'

export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS'
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR'

// system
export const SET_MOBILE = 'SET_MOBILE'
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV'
export const HISTORY_UPDATE = 'HISTORY_UPDATE'
export const SET_VIEWPORT_DIMENTIONS = 'SET_VIEWPORT_DIMENTIONS'

export const GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE =
  'GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE'
export const GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_SUCCESS =
  'GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_SUCCESS'
export const GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR =
  'GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR'

export const GET_ACCOUNT_DATA_FOR_CLIENT = 'GET_ACCOUNT_DATA_FOR_CLIENT'
export const GET_ACCOUNT_DATA_FOR_CLIENT_SUCCESS = 'GET_ACCOUNT_DATA_FOR_CLIENT_SUCCESS'
export const GET_ACCOUNT_DATA_FOR_CLIENT_ERROR = 'GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR'
