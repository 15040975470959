import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Section, Container } from '../../ui/elements/layout'
import { P } from '../../ui/elements/typography'
import { blue_strong, blue_shiny } from '../../ui/defaults/colors'
import { IconButton } from '../../ui/components/buttons'
import { createProduct, getProducts } from '../../redux/product/actions'
import ProductListItem from './components/products/ProductListItem'
import { sortArrayByAlphabeticalPropertyValue } from '../../utils/array'
import BackofficePage from './components_shared/BackofficePage'
import Spinner from '../../ui/components/spinner'
import PageHeader from './components/PageHeader'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    products: store.product.products,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getProducts,
        createProduct,
      },
      dispatch,
    ),
  }),
)
class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestedProducts: false,
    }
  }

  requestProducts() {
    this.props.actions.getProducts(this.props.user.account._id)
    this.setState({ requestedProducts: true })
  }

  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.account &&
      this.props.user.account._id &&
      !this.state.requestedProducts
    ) {
      this.requestProducts()
    }
  }

  componentDidUpdate() {
    if (
      this.props.user &&
      this.props.user.account &&
      this.props.user.account._id &&
      !this.state.requestedProducts
    ) {
      this.requestProducts()
    }
  }

  renderContent() {
    const { products } = this.props
    if (!products) return null

    let activeForSale = products.filter(product => product.sale_active === true)
    let notActiveForSale = products.filter(product => product.sale_active === false)
    activeForSale = sortArrayByAlphabeticalPropertyValue(activeForSale, 'title_pt')
    notActiveForSale = sortArrayByAlphabeticalPropertyValue(notActiveForSale, 'title_pt')

    return (
      <Section>
        <Container>
          <br />
          <P>Activos</P>
          <br />
          {activeForSale &&
            activeForSale.length > 0 &&
            activeForSale.map((product, index) => (
              <ProductListItem
                key={index}
                _id={product._id}
                date_created={product.date_created}
                title_pt={product.title_pt}
                sale_active={product.sale_active}
                image_url={product.image_url}
              />
            ))}
          <br />
          <P>Inactivos</P>
          <br />
          {notActiveForSale &&
            notActiveForSale.length > 0 &&
            notActiveForSale.map((product, index) => (
              <ProductListItem
                key={index}
                _id={product._id}
                date_created={product.date_created}
                title_pt={product.title_pt}
                sale_active={product.sale_active}
                image_url={product.image_url}
              />
            ))}
          <br />
          <br />
        </Container>
      </Section>
    )
  }

  render() {
    let content = null
    let title = 'Asminhasreservas - Produtos'

    if (this.props.isAuthenticated) {
      content = this.renderContent()
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    const extraFirstElement = (
      <PageHeader
        pageTitle="Produtos"
        rightSideElement={
          <IconButton
            label="Novo produto"
            bgColor="white"
            iconSide="left"
            icon={<FAIcon icon="faPlusCircle" family="solid" />}
            iconColor={blue_strong}
            onClick={() => this.props.actions.createProduct(this.props.user.account._id)}
          />
        }
      />
    )

    return <BackofficePage title={title} content={content} extraFirstElement={extraFirstElement} />
  }
}

export default withRouter(Products)
