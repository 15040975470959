import React, { Component } from 'react'
import styled from '@emotion/styled'
import { tablet_max, phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import Spinner from '../../../../ui/components/spinner'
import { blue_shiny } from '../../../../ui/defaults/colors'

class WeekPagerDayLoading extends Component {
  render() {
    return (
      <RootDiv>
        <ClickDiv to={`/reservas/${this.props.weekDate}`}>
          <div style={{display: 'flex'}}>
            <Spinner radius={20} color={blue_shiny} />
          </div>
        </ClickDiv>
      </RootDiv>
    )
  }
}

export default WeekPagerDayLoading

const ClickDiv = styled.div({
  width: '100%',
  display: 'block',
  padding: '10px 18px 7px 18px',
  position: 'relative',
  [tablet_max]:{
    padding: '11px',
  },
  [phablet_max]:{
    padding: '8px',
  },
  [phone_max]:{
    padding: '0',
    paddingTop: '5px',
    paddingLeft: '5px',
  },
})

const RootDiv = styled.div(props => ({
  background: 'white',
  borderBottom: 'none',
  width: props.boxWidth,
  display: 'flex',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  flexDirection: 'column',
}))
