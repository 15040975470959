import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Header from '../Header'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { WidgetPageRoot, PaddingBox } from '../../app_ui/layout'
import Background from '../../components_system/background'
import WidgetConfirmCartComponent from './WidgetConfirmCartComponent'
import {
  prepareReservationsForAPICreation,
} from '../../utils/storeUtils'
import {
  removeReservationFromCart,
} from '../../redux/shop/actions'

@connect(
  store => ({
    cart: store.shop.cart,
    numReservationsInCart: store.shop.numReservationsInCart,
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators({
      removeReservationFromCart
    }, dispatch)
  })
)
class WidgetConfirmCart extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cart: this.props.cart
    }
  }

  componentDidUpdate(prevProps) {
    const {
      cart,
    } = this.props
    if (cart) {
      if(prevProps.cart !== this.props.cart) {
        this.setState({cart: this.props.cart})
      }
    }
  }

  render() {
    const {
      cart,
      numReservationsInCart,
      storeAccount,
      productsStore,
    } = this.props

    if (!storeAccount || !storeAccount.account_logo) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!productsStore) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!cart) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }

    // if (!storeAccount || !storeAccount.account_logo) { this.props.history.push(`/shop/${this.props.match.params.app_id}`); return null }
    // if (!productsStore) { this.props.history.push(`/shop/${this.props.match.params.app_id}`); return null }
    
    let reservationsByDay = prepareReservationsForAPICreation(cart, productsStore)

    // filter by date
    if (reservationsByDay) {
      reservationsByDay = reservationsByDay.sort((a, b) => new Date(a.dayDate) - new Date(b.dayDate))
    }
    
    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <WidgetConfirmCartComponent
            numReservationsInCart={numReservationsInCart}
            reservations={reservationsByDay}
            handleRemoveReservationFromCart={this.props.actions.removeReservationFromCart}
            handleGotoToClientDetailsForm={() => this.props.history.push(`/shop/${this.props.match.params.app_id}/client-details-form`)}
            handleGotoToProductSelection={() => this.props.history.push(`/shop/${this.props.match.params.app_id}/product-selection`)}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Background bgColor='#242424' />
        </PaddingBox>
      </WidgetPageRoot>
    )
  }
}

export default withRouter(WidgetConfirmCart)
