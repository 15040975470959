import React from 'react'
import styled from '@emotion/styled'
import { phone_max } from '../../../../ui/defaults/media-queries'
import { H4, PSmall } from '../../../../ui/elements/typography'
import { Content } from '../../../../ui/elements/layout'

// export const ProductCard = styled.div({
  // width: 'calc(100% - 100px)',
  // display: 'inline-flex',
  // flexDirection: 'column',
  // webkitOverflowScrolling: 'touch',
  // WebkitTransition: 'all 0.2s ease-out',
  // MozTransition: 'all 0.2s ease-out',
  // Otransition: 'all 0.2s ease-out',
  // transition: 'all 0.2s ease-out',
  // [phone_max]: {
  //   width: 'calc(100% - 60px)',
  // },
// })

export const ProductCardImage = styled.div(props => ({
  // height: `${Math.floor(window.innerHeight/3)}px`,
  width: '100%',
  height: '250px',
  // maxHeight: '330px',
  background: props.bgImage ? `url(${props.bgImage}) center center` : 'black',
  backgroundSize: 'cover',
  border: '3px solid white',
  borderRadius: '18px',
  overflow: 'hidden',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
}))

export const ProductCardContent = styled.div({
  background: 'white',
  position: 'relative',
  top: '-30px',
  borderRadius: '18px',
  overflow: 'hidden',
})

export const BottomWarningLabel = styled(PSmall)({
  color: 'green',
  textAlign: 'center',
  width: '80%',
  lineHeight: '120%',
})

export const CustomContent = styled(Content)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const CustomPSmall = styled(PSmall)({
  fontSize: '16px !important',
  [phone_max]: {
    fontSize: '16px !important',
  }
})

export const CustomH4 = styled(H4)({
  color: 'green',
  alignSelf: 'flex-start',
  marginTop: '10px',
})

export const AmountDisplay = styled.p({
  alignSelf: 'flex-start',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
})

export const MinusButton = styled.div({
  cursor: 'pointer',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
})

export const PlusButton = styled.div({
  cursor: 'pointer',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
})

export const AmountControlDiv = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '150px',
  margin: '0 auto',
})

export const PlusIcon = () =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="#000"
      d="M22.5 14.398c0-.351-.352-.703-.703-.703h-5.742V7.953c0-.351-.352-.703-.703-.703h-.704a.712.712 0 00-.703.703v5.742H8.203a.713.713 0 00-.703.703v.704c0 .41.293.703.703.703h5.742v5.742c0 .41.293.703.703.703h.704a.713.713 0 00.703-.703v-5.742h5.742a.713.713 0 00.703-.703v-.704zm7.031.352C29.531 6.723 23.027.219 15 .219 6.973.219.469 6.723.469 14.75c0 8.027 6.504 14.531 14.531 14.531 8.027 0 14.531-6.504 14.531-14.531zm-1.875 0c0 6.973-5.683 12.656-12.656 12.656-6.973 0-12.656-5.625-12.656-12.656C2.344 7.836 7.969 2.094 15 2.094c6.914 0 12.656 5.683 12.656 12.656z"
    ></path>
  </svg>

export const MinusIcon = () =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="#000"
      d="M8.203 15.805h13.594a.713.713 0 00.703-.703v-.704c0-.351-.352-.703-.703-.703H8.203a.713.713 0 00-.703.703v.704c0 .41.293.703.703.703zm21.328-1.055C29.531 6.723 23.027.219 15 .219 6.973.219.469 6.723.469 14.75c0 8.027 6.504 14.531 14.531 14.531 8.027 0 14.531-6.504 14.531-14.531zm-1.875 0c0 6.973-5.683 12.656-12.656 12.656-6.973 0-12.656-5.625-12.656-12.656C2.344 7.836 7.969 2.094 15 2.094c6.914 0 12.656 5.683 12.656 12.656z"
    ></path>
  </svg>
