import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_ERROR,

  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,

  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,

  UPDATE_ACCOUNT_BUSINESS_NAME,
  UPDATE_ACCOUNT_BUSINESS_NAME_SUCCESS,
  UPDATE_ACCOUNT_BUSINESS_NAME_ERROR,

  UPDATE_ACCOUNT_EMAIL,
  UPDATE_ACCOUNT_EMAIL_SUCCESS,
  UPDATE_ACCOUNT_EMAIL_ERROR,

  UPDATE_ACCOUNT_HOSTNAME,
  UPDATE_ACCOUNT_HOSTNAME_SUCCESS,
  UPDATE_ACCOUNT_HOSTNAME_ERROR,

  UPDATE_ACCOUNT_ACTIVE,
  UPDATE_ACCOUNT_ACTIVE_SUCCESS,
  UPDATE_ACCOUNT_ACTIVE_ERROR,

  UPLOAD_ACCOUNT_LOGO,
  UPLOAD_ACCOUNT_LOGO_SUCCESS,
  UPLOAD_ACCOUNT_LOGO_ERROR,

  UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR,
  UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_SUCCESS,
  UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_ERROR,

  UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR,
  UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_SUCCESS,
  UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_ERROR,

  UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR,
  UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_SUCCESS,
  UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_ERROR,

  UPDATE_ACCOUNT_OPENING_DAYS,
  UPDATE_ACCOUNT_OPENING_DAYS_SUCCESS,
  UPDATE_ACCOUNT_OPENING_DAYS_ERROR,

  UPDATE_WEEKDAYS_AVAILABILITY,
  UPDATE_WEEKDAYS_AVAILABILITY_SUCCESS,
  UPDATE_WEEKDAYS_AVAILABILITY_ERROR,
} from '../action-types'

export default function reducer (state={
    accounts: [],
    loading: false,
  }, action) {

  switch(action.type)
  {
    case GET_ACCOUNTS: { return { ...state, loading: true } }
    case GET_ACCOUNTS_ERROR: { return { ...state, loading: false} }
    case GET_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: action.payload || [] ,
        loading: false,
      }
    }
    case CREATE_ACCOUNT: { return { ...state, loading: true } }
    case CREATE_ACCOUNT_ERROR: { return { ...state, loading: false} }
    case CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        accounts: [...state.accounts, action.payload ],
        loading: false,
      }
    }
    case DELETE_ACCOUNT: { return { ...state, loading: true } }
    case DELETE_ACCOUNT_ERROR: { return { ...state, loading: false} }
    case DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        accounts: state.accounts.filter(account => account._id !== action.payload),
        loading: false,
      }
    }
    case UPDATE_ACCOUNT_BUSINESS_NAME: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_BUSINESS_NAME_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_BUSINESS_NAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, business_name: action.payload.business_name } : 
          account
        )
      }
    }
    case UPDATE_ACCOUNT_OPENING_DAYS: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_OPENING_DAYS_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_OPENING_DAYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        // this is set in user
        // accounts: state.accounts.map(account => account._id === action.payload._id ?
        //   { ...account, opening_days: action.payload.opening_days } : 
        //   account
        // )
      }
    }
    case UPDATE_WEEKDAYS_AVAILABILITY: { return { ...state, loading: true } }
    case UPDATE_WEEKDAYS_AVAILABILITY_ERROR: { return { ...state, loading: false} }
    case UPDATE_WEEKDAYS_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        // accounts: state.accounts.map(account => account._id === action.payload._id ?
        //   { ...account, weekdays_availability: action.payload.weekdays_availability } : 
        //   account
        // )
      }
    }
    case UPDATE_ACCOUNT_EMAIL: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_EMAIL_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, account_email: action.payload.account_email } : 
          account
        )
      }
    }
    case UPDATE_ACCOUNT_HOSTNAME: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_HOSTNAME_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_HOSTNAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, account_hostname: action.payload.account_hostname } : 
          account
        )
      }
    }
    case UPDATE_ACCOUNT_ACTIVE: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_ACTIVE_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_ACTIVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, active: action.payload.active } : 
          account
        )
      }
    }
    case UPLOAD_ACCOUNT_LOGO: { return { ...state, loading: true } }
    case UPLOAD_ACCOUNT_LOGO_ERROR: { return { ...state, loading: false} }
    case UPLOAD_ACCOUNT_LOGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(product => product._id === action.payload._id ?
          { ...product, account_logo: action.payload.account_logo } : 
          product
        )
      }
    }
    case UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, widget_btn_bg_color: action.payload.widget_btn_bg_color } : 
          account
        )
      }
    }
    case UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, widget_btn_txt_color: action.payload.widget_btn_txt_color } : 
          account
        )
      }
    }
    case UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR: { return { ...state, loading: true } }
    case UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_ERROR: { return { ...state, loading: false} }
    case UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map(account => account._id === action.payload._id ?
          { ...account, widget_btn_icon_color: action.payload.widget_btn_icon_color } : 
          account
        )
      }
    }
    default:
      return state
  }
}
