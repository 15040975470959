import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { format, addDays, formatDistanceToNow, getMonth, getYear } from 'date-fns'
import { pt } from 'date-fns/locale'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Section, Container, ContentSmall, WhiteBox } from '../../ui/elements/layout'
import { FormLabelBlue } from '../../ui/elements/forms'
import { H1, P, PSmall } from '../../ui/elements/typography'
import { tablet_max, phablet_max } from '../../ui/defaults/media-queries'
import { getReservations } from '../../redux/reserva/actions'
import PageHeader from './components/PageHeader'
import { blue_shiny, orange, green, red_light, grey_dark_blueish } from '../../ui/defaults/colors'
import Spinner from '../../ui/components/spinner'
import { getURLFormattedDate, getDatesArraySlug } from '../../utils/date'
import { getMonthDaysFromAnyDate } from '../../utils/dayHelpers'
import { cycleDataUpdateInterval } from '../../utils/defaults'
import BackofficePage from './components_shared/BackofficePage'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

const CustomTooltip = ({ payload, label, active, monthName }) => {
  if (active) {
    let hasValues = 0
    payload.map(entry => (hasValues = hasValues + entry.value))
    if (hasValues === 0) return null
    return (
      <WhiteBox style={{ display: 'inline-flex' }}>
        <ContentSmall>
          <FormLabelBlue>
            {monthName} {label}
          </FormLabelBlue>
          {payload.map((entry, index) =>
            entry.value !== 0 ? (
              <PSmall key={index}>
                {entry.name}: {entry.value}
              </PSmall>
            ) : null,
          )}
        </ContentSmall>
      </WhiteBox>
    )
  }

  return null
}

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    reservations: store.reserva.reservations,
    loadingReservations: store.reserva.loadingReservations,
    loading: store.reserva.loading,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getReservations,
      },
      dispatch,
    ),
  }),
)
class StatsReservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      monthYearURLDate: null,
      requestedBasics: false,
      requestedAllData: false,
      currentMonthStartDate: null,
      receivedAllData: false,
    }
    this.containerRef = React.createRef()
  }

  requestaAllData = datesString => {
    try {
      this.props.actions.getReservations(datesString, this.props.user.account._id)
      if (!this.state.requestedAllData) {
        this.setState({ requestedAllData: true })
        this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
      }
    } catch (error) {
      console.log('error: ' + error)
    }
  }

  initFunction = prevProps => {
    const { user, match } = this.props
    const { monthYearURLDate } = this.state

    const hasUser = user && user.account && user.account._id
    if (!hasUser) return

    const year_exists = match && match.params && match.params.year
    const current_year = year_exists ? match.params.year : null
    const month_exists = match && match.params && match.params.month
    const current_month = month_exists ? match.params.month : null

    if (!year_exists || !month_exists) {
      return this.props.history.push(`/relatorios/reservas/${format(new Date(), 'yyyy/MM')}`)
    }

    if (current_year + '/' + current_month !== monthYearURLDate) {
      this.setOpenDays()
    }
  }

  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate(prevProps) {
    this.initFunction(prevProps)
  }

  setOpenDays = () => {
    // get url date
    const year = this.props.match.params.year
    const month = this.props.match.params.month
    const currentWeekDate = new Date(year, parseInt(month) - 1, 1)
    // generate business days
    const viewingMonthDates = getMonthDaysFromAnyDate(currentWeekDate)
    const datesString = getDatesArraySlug(viewingMonthDates)
    this.requestaAllData(datesString)
    const firstDayDate = viewingMonthDates[0].dayDate
    // capitalise monthname
    let monthName = format(firstDayDate, 'MMMM', { locale: pt })
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1)
    this.setState({
      monthYearURLDate: year + '/' + month,
      currentMonthStartDate: firstDayDate,
      currentSelectedDate: firstDayDate,
      monthName: monthName,
      viewingMonthDates: viewingMonthDates,
      numDaysInMonth: viewingMonthDates.length,
      datesString: datesString,
    })
  }

  cycleDataUpdate() {
    const { loadingReservations, loading } = this.props
    if (!loadingReservations && !loading) {
      this.requestaAllData(this.state.datesString)
    }
  }

  resetUpdateCycleTimer = () => {
    clearInterval(this.timerID)
    this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  getFormattedCreatedTime = date_created => {
    const dateCreated = format(new Date(date_created), 'EEEE dd ', { locale: pt })
    const dateCreateAgo = formatDistanceToNow(new Date(date_created), {
      locale: pt,
      addSuffix: true,
    })
    const finalString = `${dateCreated} (${dateCreateAgo})`
    return finalString
  }

  handlePageMonthBackward = () => {
    const { loading, loadingReservations } = this.props
    const { currentSelectedDate } = this.state
    if (!currentSelectedDate) return null
    if (loading) return
    if (loadingReservations) return
    const month = getMonth(currentSelectedDate) + 1
    let year = getYear(currentSelectedDate)
    let nextMonth = month - 1
    if (nextMonth === 0) {
      nextMonth = 12
      year--
    }
    this.props.history.push(`/relatorios/reservas/${year}/${nextMonth}`)
    this.resetUpdateCycleTimer()
  }

  handlePageMonthForward = () => {
    const { loading, loadingReservations, currentSelectedDate } = this.state
    if (!currentSelectedDate) return null
    if (loading) return
    if (loadingReservations) return
    const month = getMonth(currentSelectedDate) + 1
    let year = getYear(currentSelectedDate)
    let nextMonth = month + 1
    if (nextMonth === 13) {
      nextMonth = 1
      year++
    }
    this.props.history.push(`/relatorios/reservas/${year}/${nextMonth}`)
    this.resetUpdateCycleTimer()
  }

  renderMonthPager = () => {
    const { currentSelectedDate, monthName } = this.state
    if (!currentSelectedDate) return null
    return (
      <div style={{ display: 'flex' }}>
        <P style={{ marginLeft: '10px', lineHeight: '100%', marginRight: '10px' }}>{monthName}</P>
        <FAIcon
          icon="faChevronCircleLeft"
          family="light"
          style={{ cursor: 'pointer', color: '#242424' }}
          onClick={this.handlePageMonthBackward}
        />
        <FAIcon
          icon="faChevronCircleRight"
          family="light"
          style={{ cursor: 'pointer', marginLeft: '4px', color: '#242424' }}
          onClick={this.handlePageMonthForward}
        />
      </div>
    )
  }

  generateData() {
    let { currentSelectedDate, numDaysInMonth } = this.state
    const { reservations } = this.props
    const emptyResponse = {}
    if (!currentSelectedDate) return emptyResponse
    if (!reservations) return emptyResponse

    // const reservations = reservations.filter(reservation => isSameMonth(currentSelectedDate, getDateFromURLDate(reservation.yearMonthDay)))
    const finalisedReservationsThisMonth = reservations.filter(
      reservation => reservation.state === 'finalised',
    )
    const requestedReservationsThisMonth = reservations.filter(
      reservation => reservation.state === 'requested',
    )
    const preparedReservationsThisMonth = reservations.filter(
      reservation => reservation.state === 'prepared',
    )
    const cancelledReservationsThisMonth = reservations.filter(reservation => reservation.cancelled)

    const reservations_length = reservations.length
    const requestedReservationsThisMonth_length = requestedReservationsThisMonth.length
    const cancelledReservationsThisMonth_length = cancelledReservationsThisMonth.length
    const preparedReservationsThisMonth_length = preparedReservationsThisMonth.length
    const finalisedReservationsThisMonth_length = finalisedReservationsThisMonth.length

    const currentStartOfMonthDate = currentSelectedDate
    const currentMonthNumDays = numDaysInMonth

    // get month name in pt
    const formatMonthName = format(currentStartOfMonthDate, 'MMMM', { locale: pt })
    // capitalise
    const monthName = formatMonthName.charAt(0).toUpperCase() + formatMonthName.slice(1)

    const daysData = []
    const daysDataStates = []

    for (var i = 1; i <= currentMonthNumDays; i++) {
      const currentDate =
        i === 1 ? currentStartOfMonthDate : addDays(currentStartOfMonthDate, i - 1)
      const reservedThisDay = reservations.filter(
        reservation => reservation.yearMonthDay === getURLFormattedDate(currentDate),
      )
      const finalisedThisDay = finalisedReservationsThisMonth.filter(
        reservation => reservation.yearMonthDay === getURLFormattedDate(currentDate),
      )
      const cancelledThisDay = cancelledReservationsThisMonth.filter(
        reservation => reservation.yearMonthDay === getURLFormattedDate(currentDate),
      )
      const requestedThisDay = requestedReservationsThisMonth.filter(
        reservation => reservation.yearMonthDay === getURLFormattedDate(currentDate),
      )
      const preparedThisDay = preparedReservationsThisMonth.filter(
        reservation => reservation.yearMonthDay === getURLFormattedDate(currentDate),
      )
      const numReservas = reservedThisDay && reservedThisDay.length ? reservedThisDay.length : 0
      const numFinalizadas =
        finalisedThisDay && finalisedThisDay.length ? finalisedThisDay.length : 0
      const numCanceladas =
        cancelledThisDay && cancelledThisDay.length ? cancelledThisDay.length : 0
      const numRequested = requestedThisDay && requestedThisDay.length ? requestedThisDay.length : 0
      const numPrepared = preparedThisDay && preparedThisDay.length ? preparedThisDay.length : 0
      const newDayObject = {
        name: i,
        Reservas: numReservas - numCanceladas,
        Canceladas: numCanceladas,
      }
      const newDayObjectStates = {
        name: i,
        Pedidas: numRequested,
        Preparadas: numPrepared,
        Finalizadas: numFinalizadas,
      }

      daysData.push(newDayObject)
      daysDataStates.push(newDayObjectStates)
    }

    return {
      reservations_length,
      cancelledReservationsThisMonth_length,
      daysData,
      monthName,
      requestedReservationsThisMonth_length,
      preparedReservationsThisMonth_length,
      finalisedReservationsThisMonth_length,
      daysDataStates,
    }
  }

  renderContent = () => {
    const { loading, loadingReservations } = this.props
    const allData = this.generateData()
    const {
      reservations_length,
      cancelledReservationsThisMonth_length,
      daysData,
      monthName,
      requestedReservationsThisMonth_length,
      preparedReservationsThisMonth_length,
      finalisedReservationsThisMonth_length,
      daysDataStates,
    } = allData
    const showSpinners = !monthName || loading || loadingReservations
    return (
      <Section>
        <Container>
          <br />
          <br />
          <P>Todas as reservas</P>
          <br />
          <LayoutTwo ref={this.containerRef}>
            {!showSpinners && (
              <div>
                <CustomWhiteBox>
                  <FormLabelBlue style={{ color: '#627490' }}>TOTAL DE RESERVAS</FormLabelBlue>
                  <H1 style={{ fontWeight: '500' }}>{reservations_length || ''}</H1>
                </CustomWhiteBox>
                <CustomWhiteBox>
                  <FormLabelBlue style={{ color: '#627490' }}>CANCELADAS</FormLabelBlue>
                  <H1 style={{ fontWeight: '500' }}>
                    {cancelledReservationsThisMonth_length || ''}
                  </H1>
                </CustomWhiteBox>
                <br />
                <br />
              </div>
            )}
          </LayoutTwo>
          {showSpinners && <Spinner color={blue_shiny} radius={40} />}
          {!showSpinners && (
            <GraphRootElement style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <ResponsiveContainer>
                <BarChart
                  // width={contentWidth}
                  // height={300}
                  data={daysData}
                  margin={{
                    left: -40,
                  }}
                  // margin={{
                  //   top: 20, right: 0, left: 0, bottom: 5,
                  // }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip monthName={monthName} />} />
                  <Legend />
                  <Bar
                    isAnimationActive={false}
                    dataKey="Reservas"
                    stackId="a"
                    fill={grey_dark_blueish}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey="Canceladas"
                    stackId="a"
                    fill={red_light}
                  />
                </BarChart>
              </ResponsiveContainer>
            </GraphRootElement>
          )}
          <br />
          <br />
          <P>Reservas - por estado</P>
          <LayoutThree ref={this.containerRef}>
            {!showSpinners && (
              <div>
                <br />
                <br />
                <CustomWhiteBox>
                  <FormLabelBlue style={{ color: '#627490' }}>PEDIDAS</FormLabelBlue>
                  <H1 style={{ fontWeight: '500' }}>
                    {requestedReservationsThisMonth_length || ''}
                  </H1>
                </CustomWhiteBox>
                <CustomWhiteBox>
                  <FormLabelBlue style={{ color: '#627490' }}>PREPARADAS</FormLabelBlue>
                  <H1 style={{ fontWeight: '500' }}>
                    {preparedReservationsThisMonth_length || ''}
                  </H1>
                </CustomWhiteBox>
                <CustomWhiteBox>
                  <FormLabelBlue style={{ color: '#627490' }}>FINALIZADAS</FormLabelBlue>
                  <H1 style={{ fontWeight: '500' }}>
                    {finalisedReservationsThisMonth_length || ''}
                  </H1>
                </CustomWhiteBox>
                <br />
                <br />
              </div>
            )}
            <br />
          </LayoutThree>
          {showSpinners && <Spinner color={blue_shiny} radius={40} />}
          {!showSpinners && (
            <GraphRootElement style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <ResponsiveContainer>
                <BarChart
                  data={daysDataStates}
                  margin={{
                    left: -40,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip monthName={monthName} />} />
                  <Legend />
                  <Bar isAnimationActive={false} dataKey="Finalizadas" stackId="a" fill={green} />
                  <Bar isAnimationActive={false} dataKey="Pedidas" stackId="a" fill={blue_shiny} />
                  <Bar isAnimationActive={false} dataKey="Preparadas" stackId="a" fill={orange} />
                </BarChart>
              </ResponsiveContainer>
            </GraphRootElement>
          )}
          <br />
          <br />
        </Container>
      </Section>
    )
  }

  render() {
    let title = 'Relatórios: Reservas | Asminhasreservas'
    let categoryElementsNav = null
    let content = null

    if (this.props.isAuthenticated) {
      content = this.renderContent()
      let addDateToURL = ''
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.year &&
        this.props.match.params.month
      ) {
        addDateToURL = `/${this.props.match.params.year}/${this.props.match.params.month}`
      }
      categoryElementsNav = (
        <CategoryElementsNav>
          <NavLink to={`/relatorios/reservas${addDateToURL}`}>
            <PageHeaderNavLink active={true} first>
              Reservas
            </PageHeaderNavLink>
          </NavLink>
          <NavLink to={`/relatorios/produtos${addDateToURL}`}>
            <PageHeaderNavLink active={false}>Produtos</PageHeaderNavLink>
          </NavLink>
          <NavLink to={`/relatorios/clientes${addDateToURL}`}>
            <PageHeaderNavLink active={false}>Clientes</PageHeaderNavLink>
          </NavLink>
        </CategoryElementsNav>
      )
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    const extraFirstElement = (
      <PageHeader
        key="extraFirstElement"
        pageTitle="Relatórios: Reservas"
        rightSideElement={this.renderMonthPager()}
      />
    )

    const extraFirstElement2 = (
      <PageHeader key="extraFirstElement2" headlineAlternativeElement={categoryElementsNav} />
    )

    const extreElement = [extraFirstElement, extraFirstElement2]

    return <BackofficePage title={title} content={content} extraFirstElement={extreElement} />
  }
}

export default withRouter(StatsReservations)

const LayoutBase = styled.div({
  width: '100%',
  margin: '0 auto',
})

const LayoutTwo = styled(LayoutBase)({
  display: 'flex',
})

const LayoutThree = styled(LayoutBase)({
  display: 'flex',
})

const GraphRootElement = styled.div({
  '& .recharts-legend-item-text': {
    fontSize: '17px',
    [tablet_max]: {
      fontSize: '15px',
    },
    [phablet_max]: {
      fontSize: '13px',
    },
  },
})

const CustomWhiteBox = styled(WhiteBox)({
  padding: '12px',
  paddingBottom: '6px',
  borderRadius: '3px',
  display: 'inline-flex',
  flexDirection: 'column',
  marginRight: '10px',
  marginBottom: '10px',
  '& label, h3': {
    lineHeight: '100%',
  },
  [tablet_max]: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  [phablet_max]: {
    marginRight: '6px',
    marginBottom: '6px',
  },
})

const CategoryElementsNav = styled.div({
  display: 'flex',
})

const PageHeaderNavLink = styled(PSmall)(props => ({
  cursor: 'pointer',
  display: 'inline-flex',
  marginLeft: props.first ? '0' : '14px',
  textDecoration: props.active ? 'underline' : 'none',
}))
