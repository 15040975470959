import React from 'react'
import {
  AmountDisplay,
  MinusButton,
  PlusButton,
  AmountControlDiv,
  PlusIcon,
  MinusIcon,
} from './elements/productSelectorElements'

const ProductAmountSelector = props => 
  <div className='ProductAmountSelector' style={{width: '100%', marginTop: '20px'}}>
    <AmountControlDiv className='AmountControlDiv'>
      <MinusButton onClick={props.handleMinusButton}>
        <MinusIcon />
      </MinusButton>
      <AmountDisplay>
        { props.amount }
      </AmountDisplay>
      <PlusButton onClick={props.handlePlusButton}>
        <PlusIcon />
      </PlusButton>
    </AmountControlDiv>
  </div>

export default ProductAmountSelector