import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { PWhite } from '../../ui/elements/typography'
import { connect } from 'react-redux'
import { parseISO, isBefore, setMinutes, setSeconds, setHours, isToday } from 'date-fns'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Background from '../../components_system/background'
import Header from '../Header'
import { CartDisplayByDayCRMByPhone } from '../widget/components/CartDisplayByDayCRMByPhone'
import { Section, Container } from '../../ui/elements/layout'
import { triggerConfirmDialog } from '../../redux/dialog/actions'
import {
  getClientDataForPersonalPageByPhone,
  cancelReservationFromWidget,
} from '../../redux/shop/actions'
import { WidgetPageRoot } from '../../app_ui/layout'
import { CustomRoundButtonRed } from '../../app_ui/buttons'

const DAY_RESERVATION_HOUR_LIMIT = 12

@connect(
  store => ({
    clientReservations: store.shop.clientReservations,
    shopAccount: store.shop.shopAccount,
    shopProducts: store.shop.shopProducts,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getClientDataForPersonalPageByPhone,
        cancelReservationFromWidget,
        triggerConfirmDialog,
      },
      dispatch,
    ),
  }),
)
class ClientReservationsManagerByPhone extends Component {
  componentDidMount() {
    const { client_phone, shop_slug } = this.props.match.params

    this.props.actions.getClientDataForPersonalPageByPhone(client_phone, shop_slug)
  }

  handleDeleteReservation = reservationId => {
    const { shopAccount } = this.props

    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja apagar esta reserva?',
      confirmText: 'Sim, apagar',
      cancelText: 'Não',
      confirmCallBack: () =>
        this.props.actions.cancelReservationFromWidget(shopAccount._id, reservationId),
    })
  }
  handleLogout = () => {
    localStorage.removeItem('phoneNumber')
    // go to next apge if number was already added
    const nextURL = `/client-webview-start/norre`
    this.props.history.push(nextURL)
  }
  prepareProductsData = clientReservation => {
    const products = clientReservation.products
    const newProducts = []
    for (const prodEntry of products) {
      const newProduct = this.props.shopProducts.find(
        product => String(product._id) === String(prodEntry._id),
      )
      if (!newProduct) {
        // TODO: handle when product is not found
      }

      if (newProduct) {
        newProducts.push({
          _id: prodEntry._id,
          amount: prodEntry.amount,
          title_pt: newProduct.title_pt,
          image_url: newProduct.image_url,
        })
      }
    }
    clientReservation.products = newProducts
    return clientReservation
  }

  render() {
    const { client_phone, shop_slug } = this.props.match.params
    const { shopAccount, shopProducts, clientReservations } = this.props
    if (!clientReservations || !shopAccount || !shopProducts) return null

    let filteredReservations = []
    const nowDate = new Date()
    let pastDate = setHours(nowDate, DAY_RESERVATION_HOUR_LIMIT)
    pastDate = setMinutes(pastDate, 0)
    pastDate = setSeconds(pastDate, 0)
    for (let reservation of clientReservations) {
      if (!reservation.cancelled) {
        const isInPast = isBefore(parseISO(reservation.dayDate), pastDate)
        if (!isInPast || isToday(parseISO(reservation.dayDate))) {
          const preparedReservation = this.prepareProductsData(reservation)
          filteredReservations.push(preparedReservation)
        }
      }
    }

    // deternine if has reservations
    const hasReservations = filteredReservations && filteredReservations.length > 0

    if (hasReservations) {
      // sort by date
      filteredReservations = filteredReservations.sort(
        (a, b) => new Date(a.dayDate) - new Date(b.dayDate),
      )
    }

    return (
      <Section>
        <Container>
          <WidgetPageRoot>
            <Helmet>
              <title>As minhas reservas | asminhasreservas.pt</title>
            </Helmet>
            <div style={{ position: 'relative' }}>
              <br />
              <Header
                accountLogo={shopAccount.account_logo}
                businessName={shopAccount.business_name}
                omitCloseButton={true}
                subline="As minhas reservas"
              />
              {!hasReservations && <PWhite>Não tem reservas activas neste momento.</PWhite>}
              {hasReservations && <PWhite>Tem neste momento as seguintes reservas activas:</PWhite>}
              <br />
              {hasReservations && (
                <CartDisplayByDayCRMByPhone
                  showState
                  reservations={filteredReservations}
                  handleDeleteReservation={this.handleDeleteReservation}
                  showObservations={true}
                  shopSlug={shop_slug}
                  clientPhone={client_phone}
                />
              )}
              {/* <br />
              <br />
              <PWhite>
                Se quiser fazer mais reservas clique no botão abaixo para visitar a loja.
              </PWhite>
              <br />
              <CustomRoundButtonWhite>
                <a href={shopAccount.account_hostname}>Visitar a loja</a>
              </CustomRoundButtonWhite> */}
              <br />
              <br />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <div style={{ height: '2px', width: '100%', background: '#444' }} />
                <br />
                <CustomRoundButtonRed
                  style={{ background: '#318DFF', display: 'inline-flex', marginLeft: 'auto' }}
                  onClick={this.handleLogout}
                >
                  Terminar sessão
                </CustomRoundButtonRed>
              </div>
              <br />
              <br />
              <br />
            </div>
          </WidgetPageRoot>
          <Background bgColor="#242424" />
        </Container>
      </Section>
    )
  }
}

export default withRouter(ClientReservationsManagerByPhone)
