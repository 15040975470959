import React from 'react'
import styled from '@emotion/styled'
import { blue, green, orange, grey, grey_dark, grey_darker } from '../../defaults/colors'
import {
  phone,
  phone_max,
  phablet,
  phablet_max,
  tablet,
  tablet_min,
  tablet_max,
  desktop,
  desktop_min,
  desktop_max,
  hd,
  hd_min,
} from '../../defaults/media-queries'

// section div
export const Section = styled.div(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: props.backgroundColor ? props.backgroundColor : '',
}))

// container div
export const Container = styled.div(props => ({
  maxWidth: props.maxWidth ? props.maxWidth : 'none',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  padding: '0',
  // margin: '0 auto',
  WebkitTransition: 'width 0.3s ease-out',
  MozTransition: 'width 0.3s ease-out',
  Otransition: 'width 0.3s ease-out',
  transition: 'width 0.3s ease-out',
  // width: '1150px',
  [desktop_max]: {
    width: 'calc(100% - 40px)',
  },
  [tablet_max]: {
    width: 'calc(100% - 30px)',
    //   width: '97%',
    //   padding: '0 20px',
  },
  [phablet_max]: {
    width: 'calc(100% - 20px)',
    //   width: '98%',
  },
  [phone_max]: {
    width: 'calc(100% - 16px)',
    //   width: '100%',
    //   padding: '0 10px',
  },
}))

// container div webview
export const ContainerWebview = styled.div(props => ({
  maxWidth: props.maxWidth ? props.maxWidth : 'none',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  padding: '0',
  // margin: '0 auto',
  WebkitTransition: 'width 0.3s ease-out',
  MozTransition: 'width 0.3s ease-out',
  Otransition: 'width 0.3s ease-out',
  transition: 'width 0.3s ease-out',
  // width: '1150px',
  [desktop_max]: {
    width: 'calc(100% - 40px)',
  },
  [tablet_max]: {
    width: 'calc(100% - 30px)',
    //   width: '97%',
    //   padding: '0 20px',
  },
  [phablet_max]: {
    width: '80%',
    //   width: '98%',
  },
  [phone_max]: {
    width: '90%',
    //   width: '100%',
    //   padding: '0 10px',
  },
}))

// container div
export const ContainerShort = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  maxWidth: '1000px',
  padding: '0',
  // margin: '0 auto',
  // width: '1150px',
  // [desktop_max]: {
  //   width: '92%',
  // },
  // [tablet_max]: {
  //   width: '97%',
  //   padding: '0 20px',
  // },
  [desktop_max]: {
    width: '100%',
  },
  // [phone_max]: {
  //   width: '100%',
  // },
})

// container div custom limit
export const ContainerLimited = styled(Container)(props => ({
  maxWidth: props.maxWidth ? props.maxWidth : '1000px',
}))

export const ContainerLarge = styled(Container)({
  maxWidth: '1400px',
  padding: '0',
  width: '90%',
  [desktop_max]: {
    width: '100%',
  },
})

export const ContainerLargeLimited = styled(ContainerLarge)(props => ({
  maxWidth: props.maxWidth ? props.maxWidth : '1400px',
}))

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '40px',
  [desktop_max]: {
    padding: '35px',
  },
  [tablet_max]: {
    padding: '30px',
  },
  [phablet_max]: {
    padding: '25px',
  },
  [phone_max]: {
    padding: '15px',
  },
})

export const ContentSmall = styled(Content)({
  padding: '20px',
  [desktop_max]: {
    padding: '16px',
  },
  [tablet_max]: {
    padding: '12px',
  },
  [phablet_max]: {
    padding: '10px',
  },
  [phone_max]: {
    padding: '8px',
  },
})

export const ContentShortV = styled(Content)({
  padding: '20px 40px',
  [desktop_max]: {
    padding: '18px 30px',
  },
  [tablet_max]: {
    padding: '16px 22px',
  },
  [phablet_max]: {
    padding: '14px 16px',
  },
  [phone_max]: {
    padding: '10px 10px',
  },
})

export const ContentShortestV = styled(ContentShortV)({
  padding: '16px 20px',
  [desktop_max]: {
    padding: '16px 20px',
  },
  [tablet_max]: {
    padding: '14px 20',
  },
  [phablet_max]: {
    padding: '10px 15px',
  },
  [phone_max]: {
    padding: '8px 10px',
  },
})

export const ContentShortVNoH = styled(ContentShortV)({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
})

export const ContentShortestVNoH = styled(ContentShortestV)({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
})

export const ContentNoH = styled(ContentShortV)({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
})

export const ContentNoV = styled(ContentShortV)({
  paddingTop: '0 !important',
  paddingBottom: '0 !important',
})

export const ContentAlignCenter = styled(Content)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const WhiteBox = styled.div({
  background: 'white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
})

export const WhiteBoxRound = styled(WhiteBox)({
  borderRadius: '7px',
  [desktop_max]: { borderRadius: '6px' },
  [tablet_max]: { borderRadius: '5px' },
  [phablet_max]: { borderRadius: '4px' },
  [phone_max]: { borderRadius: '3px' },
})

export const WhiteBoxInline = styled(WhiteBox)({
  display: 'inline-flex',
})

export const WhiteBoxRoundInline = styled(WhiteBoxRound)({
  display: 'inline-flex',
})

// inline-block div
export const IBDiv = styled.div({
  display: 'inline-block',
  verticalAlign: 'top',
})
// becomes 100% width on phone
export const IBDivPhone = styled.div({
  display: 'inline-block',
  verticalAlign: 'top',
  [phablet_max]: { display: 'block', width: '100%' },
})
// becomes 100% width on phablet
export const IBDivPhablet = styled.div({
  display: 'inline-block',
  verticalAlign: 'top',
  [tablet_max]: { display: 'block', width: '100%' },
})

export const FlexDiv = styled.div({
  display: 'flex',
})

export const FlexDivContent = styled(Content)({
  display: 'flex',
})

export const FlexDivCenter = styled.div({
  display: 'flex',
  alignItems: 'center',
})

export const FlexDivColumnTablet = styled.div({
  display: 'flex',
  [tablet_max]: {
    flexDirection: 'column',
  },
})

export const FlexDivColumnTabletContent = styled(Content)({
  display: 'flex',
  [tablet_max]: {
    flexDirection: 'column',
  },
})

export const FlexDivColumnPhablet = styled.div({
  display: 'flex',
  [phablet_max]: {
    flexDirection: 'column',
  },
})

export const FlexDivColumnPhabletContent = styled(Content)({
  display: 'flex',
  [phablet_max]: {
    flexDirection: 'column',
  },
})

export const FlexDivColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const ImageRoundResponsive = styled.img(props => ({
  objectFit: 'cover',
  width: `${props.desktopRadius}px`,
  height: `${props.desktopRadius}px`,
  borderRadius: '50%',
  [desktop_max]: {
    width: `${Math.floor(props.desktopRadius / 1.2)}px`,
    height: `${Math.floor(props.desktopRadius / 1.2)}px`,
  },
  [tablet_max]: {
    width: `${Math.floor(props.desktopRadius / 1.4)}px`,
    height: `${Math.floor(props.desktopRadius / 1.4)}px`,
  },
  [phablet_max]: {
    width: `${Math.floor(props.desktopRadius / 1.6)}px`,
    height: `${Math.floor(props.desktopRadius / 1.6)}px`,
  },
  [phone_max]: {
    width: `${Math.floor(props.desktopRadius / 1.8)}px`,
    height: `${Math.floor(props.desktopRadius / 1.8)}px`,
  },
}))

export const DivHdMin = styled.div({ [desktop_max]: { display: 'none' } })
export const DivDesktopMin = styled.div({ [tablet_max]: { display: 'none' } })
export const DivTabletMin = styled.div({ [phablet_max]: { display: 'none' } })
export const DivPhabletMin = styled.div({ [phone_max]: { display: 'none' } })

export const DivDesktopMax = styled.div({ [hd_min]: { display: 'none' } })
export const DivPhabletMax = styled.div({ [tablet_min]: { display: 'none' } })
export const DivTabletMax = styled.div({ [desktop_min]: { display: 'none' } })

export const DivHdOnly = styled.div({
  [hd]: { display: 'block' },
  [desktop]: { display: 'none' },
  [tablet]: { display: 'none' },
  [phablet]: { display: 'none' },
  [phone]: { display: 'none' },
})
export const DivDesktopOnly = styled.div({
  [hd]: { display: 'none' },
  [desktop]: { display: 'block' },
  [tablet]: { display: 'none' },
  [phablet]: { display: 'none' },
  [phone]: { display: 'none' },
})
export const DivTabletOnly = styled.div({
  [hd]: { display: 'none' },
  [desktop]: { display: 'none' },
  [tablet]: { display: 'block' },
  [phablet]: { display: 'none' },
  [phone]: { display: 'none' },
})
export const DivPhabletOnly = styled.div({
  [hd]: { display: 'none' },
  [desktop]: { display: 'none' },
  [tablet]: { display: 'none' },
  [phablet]: { display: 'block' },
  [phone]: { display: 'none' },
})
export const DivPhoneOnly = styled.div({
  [hd]: { display: 'none' },
  [desktop]: { display: 'none' },
  [tablet]: { display: 'none' },
  [phablet]: { display: 'none' },
  [phone]: { display: 'block' },
})

const colorSelect = function(props) {
  switch (props.color) {
    case 'white':
      return 'white'
    case 'grey':
      return grey
    case 'blue':
      return blue
    case 'green':
      return green
    case 'orange':
      return orange
    case 'grey_dark':
      return grey_dark
    case 'grey_darker':
      return grey_darker
    default:
      return 'transparent'
  }
}

export const Box = styled.div({}, props => ({
  backgroundColor: props.color ? colorSelect(props) : 'white',
  boxShadow: props.shadow ? '0 2px 4px 0 rgba(0,0,0,0.15)' : 'none',
  padding: props.noPadding ? '0px' : '40px',
  marginBottom: props.noMargin ? '0px' : '40px',
  [tablet_max]: {
    padding: props.noMargin ? '0px' : '30px',
    marginBottom: props.noPadding ? '0px' : '30px',
  },
  [phablet_max]: {
    padding: props.noPadding ? '0px' : '25px',
    marginBottom: props.noMargin ? '0px' : '25px',
  },
  [phone_max]: {
    padding: props.noPadding ? '0px' : '15px',
    marginBottom: props.noMargin ? '0px' : '15px',
  },
}))

export const BoxCompact = styled.div({}, props => ({
  textAlign: 'left',
  marginBottom: props.noMargin ? '0' : '12px',
  backgroundColor: props.color ? colorSelect(props) : 'white',
  boxShadow: props.shadow ? '0 2px 4px 0 rgba(0,0,0,0.15)' : 'none',
  padding: props.noPadding ? '0 !important' : '12px 18px 12px 18px',
}))

export const Spacer = styled.div({
  height: '50px',
  [tablet_max]: { height: '40px' },
  [phablet_max]: { height: '30px' },
  [phone_max]: { height: '20px' },
})

export const SpacerSmall = styled.div({
  height: '25px',
  [tablet_max]: { height: '20px' },
  [phablet_max]: { height: '15px' },
  [phone_max]: { height: '10px' },
})

export const SpacerLarge = styled.div({
  height: '90px',
  [desktop_max]: { height: '80px' },
  [tablet_max]: { height: '70px' },
  [phablet_max]: { height: '65px' },
  [phone_max]: { height: '60px' },
})

export const MobileSpaceMaker = styled.div({
  width: '100%',
  height: '0',
  [tablet_max]: {
    height: '80px',
  },
})

export const Separator = props => {
  let style = { marginTop: '50px', marginBottom: '30px', width: '100%' }
  if (props.short) {
    style = { marginTop: '10px', marginBottom: '30px', width: '100%' }
  }
  if (props.noMargin) {
    style = { marginTop: '0', marginBottom: '0', width: '100%' }
  }
  return <hr style={style} />
}

export const SeparatorLine = styled.div({
  backgroundColor: '#F2F2F2',
  width: '100%',
  height: '2px',
  marginTop: '40px',
  marginBottom: '40px',
  [tablet_max]: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  [phablet_max]: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  [phone_max]: {
    marginTop: '14px',
    marginBottom: '14px',
  },
})
