import { 
  SET_CURRENT_USER,
  SET_AUTHENTICATED,
  // GET_USER_ACCOUNT_SUCCESS,
  
  UPDATE_USER_ACCOUNT_DIRECT,
  UPDATE_USER_WEEKDAYS_AVAILABILITY_DIRECT,
} from '../action-types'

// import isEmpty from '../../validation/is-empty'

export default function reducer (state={
    isAuthenticated: false,
    account: null,
    userData: null
  }, action) {

  switch(action.type)
  {
    case SET_CURRENT_USER: {
      return {
        ...state,
        userData: action.payload || null
      }
    }
    case SET_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
      }
    }
    // case GET_USER_ACCOUNT_SUCCESS: {
    //   return {
    //     ...state,
    //     account: action.payload,
    //   }
    // }
    case UPDATE_USER_ACCOUNT_DIRECT: {
      return {
        ...state,
        userData: { ...state.userData, account: action.payload },
        // account: action.payload,
      }
    }
    case UPDATE_USER_WEEKDAYS_AVAILABILITY_DIRECT: {
      return {
        ...state,
        userData: { ...state.userData, account: action.payload },
        // account: { ...state.account, weekdays_availability: action.payload.weekdays_availability },
        // account: action.payload,
      }
    }
    default:
      return state
  }
}
