export const onlyNumbersArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Control', 'v']
export const onlyNumbersArrayStrict = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Control']
export const onlyDecimalNumbersArray = ['.', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Control', 'v']

export const handleLimitToNumbers = (e) => {
  if (onlyNumbersArray.indexOf(e.key) === -1) {
    e.stopPropagation();
    e.preventDefault();
    return false
  }
}

export const handleLimitToNumbersStrict = (e) => {
  if (onlyNumbersArrayStrict.indexOf(e.key) === -1) {
    e.stopPropagation();
    e.preventDefault();
    return false
  }
}