import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { phone_max, tablet_max, phablet_max } from '../../../../ui/defaults/media-queries'
import { PTiny } from '../../../../ui/elements/typography'
import { blue_shiny, green_shiny, orange } from '../../../../ui/defaults/colors'

const getBorderColor = (state) => {
  switch(state) {
    case 'finalised':
      return green_shiny
    case 'prepared':
      return orange
    default:
      return blue_shiny
  }
}

const ActivityFeedReservationItem = ({
  _id,
  name,
  state,
  timeChanged,
  timeAgo,
  weekDate,
  differenceInHours,
  weekDayCreated,
  dayDateWeekDayName,
  products,
  updated,
  cancelled,
}) => {
  return (
    <RootDiv cancelled={cancelled}>
      <CenterContent to={`/reservas/${weekDate}/${_id}`} className='center-content' style={{borderLeft: `4px solid ${getBorderColor(state)}`}}>
        <CustomPTiny style={{lineHight: '110%'}}><span style={{color: '#999999', lineHight: '110%', fontWeight: '500'}}>{differenceInHours >= 2 ? timeChanged : timeAgo}</span> <span style={{textTransform: 'capitalize', fontWeight: 'bold'}}>{name}</span> <span style={{color: updated ? orange : '#2E2E2E'}}>{updated ? 'actualizou' : 'fez'}</span> uma reserva para <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{dayDateWeekDayName}</span></CustomPTiny>
      </CenterContent>
    </RootDiv>
  )
}

export default ActivityFeedReservationItem

const RootDiv = styled.div(props => ({
  background: props.cancelled ? '#fff7f7' : 'transparent',
  marginBottom: '10px',
  position: 'relative',
  width: '100%',
  cursor: 'pointer',
  '& .center-content': {
    opacity: props.cancelled && !props.active ? '0.3' : '1',
  },
  '& .fa-mobile-alt': {
    color: '#becde2',
    fontSize: '16px',
  },
  '& .fa-comment': {
    color: '#becde2',
    fontSize: '16px',
  },
}))

const CenterContent = styled(NavLink)({
  width: '100%',
  display: 'block',
  paddingLeft: '20px',
  [tablet_max]: {
    paddingLeft: '10px',
  },
  [phablet_max]: {
    paddingLeft: '8px',
  },
  [phone_max]: {
    paddingLeft: '6px',
  },
  ':hover': {
    borderWidth: '6px !important',
  }
})

const CustomPTiny = styled(PTiny)({
  lineHeight: '120%'
})
