import React from 'react'
import styled from '@emotion/styled'
import { Content} from '../../../../ui/elements/layout'
import BackofficePage from '../../components_shared/BackofficePage'

const ReservationCreator = () => {
  const content = (
    <RootDiv>
      <Content>
        <p>ReservationCreator</p>
      </Content>
    </RootDiv>
  )

  return (
    <BackofficePage
      title='Editar Reserva'
      content={content}
    />
  )
}

const RootDiv = styled.div({
  width: '400px',
  height: '400px',
  background: 'white',
})

export default ReservationCreator
