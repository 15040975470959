import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Header from '../Header'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Background from '../../components_system/background'
import { RoughNotation } from 'react-rough-notation'
import { FlexDiv } from '../../ui/elements/layout'
import { PWhite, PWhiteBold } from '../../ui/elements/typography'
import { WidgetPageRoot, PaddingBox } from '../../app_ui/layout'
import { CustomRoundButtonWhite } from '../../app_ui/buttons'
import { clearCart, clearClientDetails, clearTemporaryCartAmount } from '../../redux/shop/actions'

@connect(
  store => ({
    cart: store.shop.cart,
    isMobile: store.system.isMobile,
    clientDetails: store.shop.clientDetails,
    submittedReservations: store.shop.submittedReservations,
    linkToUserPage: store.shop.linkToUserPage,
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        clearClientDetails,
        clearCart,
        clearTemporaryCartAmount,
      },
      dispatch,
    ),
  }),
)
class WidgetCreateReservationsSuccess extends Component {
  componentDidMount() {
    const { submittedReservations } = this.props
    if (submittedReservations) {
      this.props.actions.clearCart()
    }
  }

  checkIsInsideIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  handleCloseButton = e => {
    const { storeAccount } = this.props
    if (e) {
      e.preventDefault()
    }
    if (e) {
      e.stopPropagation()
    }
    if (this.checkIsInsideIframe()) {
      // is inside iframe
      // clear current cart
      this.props.actions.clearClientDetails()
      // clear any stored client details
      this.props.actions.clearCart()
      // clear temporary cart amount
      this.props.actions.clearTemporaryCartAmount()
      // close overlay
      setTimeout(() => window.parent.postMessage('close-window', document.referrer), 100)
      // send app to first page
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
    } else {
      window.location = storeAccount.account_hostname
    }
  }

  render() {
    const { storeAccount, productsStore, submittedReservations, linkToUserPage } = this.props
    if (!storeAccount || !storeAccount.account_logo) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }
    if (!productsStore) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }
    if (!submittedReservations) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }

    const numReservations = submittedReservations.length
    const isPlural = numReservations && numReservations.length > 1 ? true : false

    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <br />
          <RoughNotation type="box" show={true} color="#1ed461">
            {numReservations && (
              <PWhiteBold>
                {isPlural
                  ? 'As suas reservas foram efectuadas com sucesso.'
                  : 'A sua reserva foi efectuada com sucesso.'}
              </PWhiteBold>
            )}
            {!numReservations && (
              <PWhiteBold style={{ color: '#1ed461 !important' }}>
                A sua reserva está confirmada!{' '}
                <span role="img" aria-label="Reserva confirmada!">
                  🥳
                </span>
              </PWhiteBold>
            )}
          </RoughNotation>
          <br />
          {/* <PWhite>Deve receber um SMS de confirmação brevemente. <span role="img" aria-label='emoji'>📲</span></PWhite> */}
          {/* <br /> */}
          <PWhite>Pode consultar as suas reservas activas a qualquer momento:</PWhite>
          <PWhite>
            <a href={linkToUserPage} target="_blank" rel="noopener noreferrer">
              Clique aqui para visitar a sua página pessoal de reservas
            </a>
          </PWhite>
          <br />
          <PWhite>
            Recomendamos que guarde o link ou faça "bookmark" para poder consultar mais tarde.
          </PWhite>
          <br />
          <FlexDiv style={{ width: '100%' }}>
            <CustomRoundButtonWhite
              onClick={this.handleCloseButton}
              style={{ marginRight: '10px' }}
            >
              Fechar janela
            </CustomRoundButtonWhite>
          </FlexDiv>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Background bgColor="#242424" />
        </PaddingBox>
        {/*<PoweredBy />*/}
      </WidgetPageRoot>
    )
  }
}

export default withRouter(WidgetCreateReservationsSuccess)
