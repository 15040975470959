import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleMobileNav } from '../..//../redux/system/actions'
import { logoutUser } from '../..//../redux/user/actions'
import { Section } from '../../../ui/elements/layout'
import { phablet_max } from '../../../ui/defaults/media-queries'
import SidebarMobileInstance from './SidebarMobileInstance'

@connect(
  store => ({
    mobileNavOpen: store.system.mobileNavOpen,
    windowHeight: store.system.windowHeight,
  }),
  dispatch => ({
    actions: bindActionCreators({
      toggleMobileNav,
      logoutUser,
    }, dispatch)
  })
)

class BackofficePage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.title}</title>
        </Helmet>
        <BackofficeLayoutRoot mobileNavOpen={this.props.mobileNavOpen}>
          <SidebarMobileInstance
            windowHeight={this.props.windowHeight}
            mobileNavOpen={this.props.mobileNavOpen}
            handleLogoutUser={() => this.props.actions.logoutUser(this.props.history)}
            handleToggleMobileNav={this.props.actions.toggleMobileNav}
            history={this.props.history}
          />
          <CustomSection mobileNavOpen={this.props.mobileNavOpen} windowHeight={this.props.windowHeight}>
            {this.props.extraFirstElement}
            {this.props.content}
          </CustomSection>
        </BackofficeLayoutRoot>
      </div>
    )
  }
}

export default withRouter(BackofficePage)

const BackofficeLayoutRoot = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
}))


const CustomSection = styled(Section)(props => ({
  width: props.mobileNavOpen ?  'calc(100% - 130px)' : 'calc(100% - 60px)',
  [phablet_max]: {
    width: 'calc(100% - 40px) !important',
  },
  height: `${props.windowHeight}px`,
  overflowY: 'scroll',
}))
