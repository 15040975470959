import React from 'react'

import { Global } from '@emotion/core'
import styled from '@emotion/styled'
// import ScrollToTop from 'react-router-scroll-top'
import MagicLayout from './MagicLayout'
import Routes from './Routes'
import { reset, globals } from '../styles'

import ConfirmActionDialog from '../ui/components/dialog/ConfirmActionDialog'

const LayoutRoot = (props) => {
  return (
    <Root>
      <Global styles={reset} />
      <Global styles={globals} />
      <ConfirmActionDialog />
      <LayoutRootDiv id='layout-root'>
        <MagicLayout>
          <Routes {...props} />
        </MagicLayout>
      </LayoutRootDiv>
    </Root>
  )
}
      // <ScrollToTop>
      // </ScrollToTop>
export default LayoutRoot

const LayoutRootDiv = styled.div({
  '*, *:before, *:after': {
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
  },
})

const Root = styled.div({
  width: '100%',
})
