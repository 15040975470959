import {
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  SAVE_CLIENT,
  SAVE_CLIENT_SUCCESS,
  SAVE_CLIENT_ERROR,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
} from '../action-types'

export default function reducer (state={
    clients: null,
    editingClient: null,
    loadingEditingClient: false,
    savingEditingClient: false,
    loading: false,
  }, action) {

  switch(action.type)
  {
    case GET_CLIENT: { return { ...state, loadingEditingClient: true } }
    case GET_CLIENT_ERROR: { return { ...state, loadingEditingClient: false} }
    case GET_CLIENT_SUCCESS: {
      return {
        ...state,
        editingClient: action.payload || null,
        loadingEditingClient: false,
      }
    }
    case SAVE_CLIENT: { return { ...state, savingEditingClient: true } }
    case SAVE_CLIENT_ERROR: { return { ...state, savingEditingClient: false} }
    case SAVE_CLIENT_SUCCESS: {
      return {
        ...state,
        editingClient: action.payload || null,
        savingEditingClient: false,
      }
    }
    case GET_CLIENTS: { return { ...state, loading: true } }
    case GET_CLIENTS_ERROR: { return { ...state, loading: false} }
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: action.payload || [],
        loading: false,
      }
    }
    case DELETE_CLIENT: { return { ...state, loading: true } }
    case DELETE_CLIENT_ERROR: { return { ...state, loading: false} }
    case DELETE_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: action.payload || [],
        loading: false,
      }
    }
    default:
      return state
  }
}
