import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RoughNotation } from 'react-rough-notation'
import Header from '../Header'
import { PWhiteBold, H3White, H4White } from '../../ui/elements/typography'
// import { RoughNotation, RoughNotationGroup } from 'react-rough-notation'
import CartDisplayByDay from './components/CartDisplayByDay'
import ReservationForm from './components/ReservationForm'
import { WidgetPageRoot, PaddingBox } from '../../app_ui/layout'
import { CustomRoundButtonRed, CustomRoundButtonCancel } from '../../app_ui/buttons'
import { FlexDiv } from '../../ui/elements/layout'
import Spinner from '../../ui/components/spinner'
import Background from '../../components_system/background'
import { prepareReservationsForAPICreation } from '../../utils/storeUtils'
import { submitReservationForm } from '../../redux/shop/actions'

@connect(
  store => ({
    cart: store.shop.cart,
    clientDetails: store.shop.clientDetails,
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        submitReservationForm,
      },
      dispatch,
    ),
  }),
)
class WidgetFinalConfirmationScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name:
        this.props.clientDetails && this.props.clientDetails.name
          ? this.props.clientDetails.name
          : '',
      telephone:
        this.props.clientDetails && this.props.clientDetails.telephone
          ? this.props.clientDetails.telephone
          : '',
      observations: '',
      processing: false,
    }
  }

  componentDidMount() {
    const { cart, storeAccount, productsStore } = this.props
    if (!storeAccount || !storeAccount.account_logo) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100)
      return <Background bgColor="#242424" />
    }
    if (!productsStore) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100)
      return <Background bgColor="#242424" />
    }
    if (!cart) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100)
      return <Background bgColor="#242424" />
    }
  }

  handleSubmit = () => {
    this.props.actions.submitReservationForm(this.props.storeAccount._id, this.state)
    this.setState({
      processing: true,
    })
    setTimeout(
      () => this.props.history.push(`/shop/${this.props.match.params.app_id}/create-reservations`),
      500,
    )
  }

  renderReservationForm = () => {
    const { name, telephone, observations } = this.state
    return (
      <ReservationForm
        name={name}
        telephone={telephone}
        observations={observations}
        handleSubmit={this.handleSubmit}
        handleTelephoneKeyDown={this.handleTelephoneKeyDown}
        handleNameChange={e => this.setState({ name: e.target.value })}
        handleTelephoneChange={e =>
          this.setState({ telephone: e.target.value.replace(/[^0-9.]/g, '') })
        }
        handleObservationsChange={e => this.setState({ observations: e.target.value })}
      />
    )
  }

  render() {
    const { cart, storeAccount, productsStore, clientDetails } = this.props
    const { processing } = this.props

    if (!storeAccount || !storeAccount.account_logo) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }
    if (!productsStore) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }
    if (!cart) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }

    let reservationsByDay
    if (cart && Object.keys(cart).length > 0) {
      reservationsByDay = prepareReservationsForAPICreation(cart, productsStore)
    }

    // filter by date
    if (reservationsByDay) {
      reservationsByDay = reservationsByDay.sort(
        (a, b) => new Date(a.dayDate) - new Date(b.dayDate),
      )
    }

    if (processing) {
      return (
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <br />
          <FlexDiv style={{ width: '100%', justifyContent: 'center' }}>
            <Spinner color="white" />
          </FlexDiv>
          <br />
          <Background bgColor="#242424" />
        </PaddingBox>
      )
    }

    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          {reservationsByDay.length > 1 && (
            <div>
              <PWhiteBold style={{ color: '#FFAC25' }}>
                A sua encomenda contém reservas para dias diferentes. Terá que vir à loja levantar
                os produtos nos dias listados abaixo.
              </PWhiteBold>
              <br />
            </div>
          )}
          <RoughNotation type="box" show={true} color="#FFAC25">
            <PWhiteBold style={{ color: '#FFAC25' }}>
              Por favor verifique o pedido e os seus dados pessoais. Se estiver tudo bem, clique em
              "Reservar" para enviar o seu pedido.
            </PWhiteBold>
          </RoughNotation>
          <br />
          <PWhiteBold style={{ marginBottom: '20px' }}>Resumo do pedido:</PWhiteBold>
          <CartDisplayByDay reservations={reservationsByDay} />
          <br />
          <PWhiteBold style={{ marginBottom: '20px' }}>Os seus dados pessoais:</PWhiteBold>
          <H3White>{clientDetails.name}</H3White>
          <H3White>{clientDetails.telephone}</H3White>
          {clientDetails.observations && clientDetails.observations !== '' && (
            <H4White style={{ fontStyle: 'italic', opacity: '0.5' }}>
              "{clientDetails.observations}"
            </H4White>
          )}
          <br />
          <FlexDiv style={{ width: '100%' }}>
            <CustomRoundButtonCancel
              onClick={() =>
                this.props.history.push(`/shop/${this.props.match.params.app_id}/product-selection`)
              }
              style={{ marginRight: '10px' }}
            >
              Fazer alterações
            </CustomRoundButtonCancel>
            <CustomRoundButtonRed
              onClick={() =>
                this.props.history.push(
                  `/shop/${this.props.match.params.app_id}/create-reservations`,
                )
              }
              style={{ marginRight: '10px' }}
            >
              Reservar
            </CustomRoundButtonRed>
          </FlexDiv>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Background bgColor="#242424" />
        </PaddingBox>
      </WidgetPageRoot>
    )
  }
}

export default withRouter(WidgetFinalConfirmationScreen)
