import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../Header'
import { PWhiteBold } from '../../ui/elements/typography'
import ReservationForm from './components/ReservationForm'
import { FlexDiv } from '../../ui/elements/layout'
import Spinner from '../../ui/components/spinner'
import Background from '../../components_system/background'
import { WidgetPageRoot, PaddingBox } from '../../app_ui/layout'
import { submitReservationForm } from '../../redux/shop/actions'
import { handleLimitToNumbers } from '../../utils/inputs'

@connect(
  store => ({
    cart: store.shop.cart,
    clientDetails: store.shop.clientDetails,
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        submitReservationForm,
      },
      dispatch,
    ),
  }),
)
class WidgetReservationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name:
        this.props.clientDetails && this.props.clientDetails.name
          ? this.props.clientDetails.name
          : '',
      telephone:
        this.props.clientDetails && this.props.clientDetails.telephone
          ? this.props.clientDetails.telephone
          : '',
      observations: '',
      processing: false,
      alreadySubmitted: false,
      formIsValid: false,
      nameInvalid: false,
      telephoneInvalid: false,
    }
  }

  componentDidMount() {
    const { cart, storeAccount, productsStore } = this.props
    if (!storeAccount || !storeAccount.account_logo) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100)
      return <Background bgColor="#242424" />
    }
    if (!productsStore) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100)
      return <Background bgColor="#242424" />
    }
    if (!cart) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 100)
      return <Background bgColor="#242424" />
    }
  }

  validateForm = e => {
    let { name, telephone } = this.state
    name = name.trim()
    let nameInvalid = false
    let telephoneInvalid = false
    let nameSplit
    if (String(name) === '') {
      nameInvalid = true
    }
    if (String(name) === '') {
      nameInvalid = true
    }
    if (String(name).indexOf(' ') === -1) {
      nameInvalid = true
    } else {
      nameSplit = name.split(' ')
      if (!nameSplit || !nameSplit.length || nameSplit.length < 2) {
        nameInvalid = true
      } else {
        const test1 = typeof nameSplit[0] === 'string' && nameSplit[0].length > 0
        const test2 = typeof nameSplit[1] === 'string' && nameSplit[1].length > 0
        if (!test1 || !test2) {
          nameInvalid = true
        }
      }
    }
    if (String(telephone).length !== 9) {
      telephoneInvalid = true
    }
    const firstTwoNumbers = String(telephone)[0] + String(telephone)[1]
    if (
      firstTwoNumbers !== '91' &&
      firstTwoNumbers !== '92' &&
      firstTwoNumbers !== '96' &&
      firstTwoNumbers !== '93'
    ) {
      telephoneInvalid = true
    }
    this.setState({
      nameInvalid: nameInvalid,
      telephoneInvalid: telephoneInvalid,
    })

    if (nameInvalid) return false
    if (telephoneInvalid) return false
    return true
  }

  handleSubmit = () => {
    const formIsValid = this.validateForm()
    this.setState({
      alreadySubmitted: true,
      formIsValid: formIsValid,
    })
    if (formIsValid) {
      this.props.actions.submitReservationForm(this.props.storeAccount._id, this.state)
      this.props.history.push(`/shop/${this.props.match.params.app_id}/confirm-reservations`)
    }
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handleTelephoneChange = e => {
    const parsedPhoneNumber = e.target.value.replace(/[^0-9.]/g, '')
    this.setState({
      telephone: parsedPhoneNumber,
    })
  }

  handleObservationsChange = e => {
    this.setState({ observations: e.target.value })
  }

  render() {
    const { cart, storeAccount, productsStore, name, telephone, observations } = this.props
    const { processing, alreadySubmitted, formIsValid, nameInvalid, telephoneInvalid } = this.state

    if (!storeAccount || !storeAccount.account_logo) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }
    if (!productsStore) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }
    if (!cart) {
      setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
      return <Background bgColor="#242424" />
    }

    if (processing) {
      return (
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <br />
          <FlexDiv style={{ width: '100%', justifyContent: 'center' }}>
            <Spinner color="white" />
          </FlexDiv>
          <br />
          <Background bgColor="#242424" />
        </PaddingBox>
      )
    }

    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <PWhiteBold>Insira os seus dados para realizar a reserva:</PWhiteBold>
          <br />
          <ReservationForm
            name={name}
            telephone={telephone}
            observations={observations}
            handleSubmit={this.handleSubmit}
            handleTelephoneKeyDown={handleLimitToNumbers}
            handleNameChange={this.handleNameChange}
            handleTelephoneChange={this.handleTelephoneChange}
            handleObservationsChange={this.handleObservationsChange}
            alreadySubmitted={alreadySubmitted}
            formIsValid={formIsValid}
            nameInvalid={nameInvalid}
            telephoneInvalid={telephoneInvalid}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Background bgColor="#242424" />
        </PaddingBox>
      </WidgetPageRoot>
    )
  }
}

export default withRouter(WidgetReservationForm)
