import React, { Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { logoutUser } from '../../../redux/user/actions'
import { Section, ContainerLimited, ContentShortV, FlexDiv } from '../../../ui/elements/layout'
import { desktop_max, tablet_max, phablet_max, phone_max } from '../../../ui/defaults/media-queries'
import { toggleMobileNav } from '../../../redux/system/actions'
import { isMobileDeviceBrowser } from '../../../utils/screen'
import AMRLogoForDarkBg from '../../../app_ui/svgs/logos/AMRLogoForDarkBg'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        toggleMobileNav,
        logoutUser,
      },
      dispatch,
    ),
  }),
)
class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screenWidth: window.innerWidth,
    }
  }

  handleLogout = e => {
    if (e) {
      e.preventDefault()
    }
    this.props.actions.logoutUser(this.props.history)
  }

  componentDidMount() {
    if (!isMobileDeviceBrowser()) {
      window.addEventListener('resize', this.adjustWidth)
    }
    this.adjustWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustWidth)
  }

  adjustWidth = () => {
    if (this.state.screenWidth !== window.innerWidth) {
      this.setState({
        screenWidth: window.innerWidth,
      })
    }
  }

  renderNavbarMobile = e => {
    return (
      <StyledNavbar>
        <LeftSide>
          <AMRLogoForDarkBg />
        </LeftSide>
        <RightSide>
          <FAIcon
            family="solid"
            onClick={() => this.props.actions.toggleMobileNav()}
            style={{ cursor: 'pointer', color: 'white' }}
            icon="faBars"
          />
        </RightSide>
      </StyledNavbar>
    )
  }

  renderNavbarDesktop = e => {
    const { user, isAuthenticated } = this.props
    const isAdmin = user && user.admin
    return (
      <StyledNavbar>
        <LeftSide>
          <AMRLogoForDarkBg />
        </LeftSide>
        <RightSide>
          {!isAuthenticated && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/">
              Início
            </StyledLinkDesktopOnly>
          )}
          {isAuthenticated && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/vista-geral">
              Vista Geral
            </StyledLinkDesktopOnly>
          )}
          <HorizontalSpace />
          {isAuthenticated && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/reservas">
              Reservas
            </StyledLinkDesktopOnly>
          )}
          <HorizontalSpace />
          {isAuthenticated && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/produtos">
              Produtos
            </StyledLinkDesktopOnly>
          )}
          <HorizontalSpace />
          {isAuthenticated && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/definicoes">
              Opções
            </StyledLinkDesktopOnly>
          )}
          <HorizontalSpace />
          {isAuthenticated && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/relatorios">
              Relatórios
            </StyledLinkDesktopOnly>
          )}
          <HorizontalSpace />
          {isAuthenticated && isAdmin && (
            <StyledLinkDesktopOnly activeClassName="selected" to="/accounts">
              Contas de cliente
            </StyledLinkDesktopOnly>
          )}
          <FAIcon
            family="solid"
            onClick={this.handleLogout}
            style={{ cursor: 'pointer', color: 'white' }}
            icon="faPowerOff"
          />
        </RightSide>
      </StyledNavbar>
    )
  }
  // <StyledP style={{marginRight: '10px'}}>{user && user.account ? user.account.business_name : ''}</StyledP>
  renderLarge = e => {
    const { screenWidth } = this.state
    return (
      <ContentShortV style={{ background: '#3B4C66' }} ref={this.props.parentRef}>
        {screenWidth > 1023 ? this.renderNavbarDesktop() : this.renderNavbarMobile()}
      </ContentShortV>
    )
  }

  renderStandard = () => {
    const { maxWidth } = this.props
    const { screenWidth } = this.state
    let contentStyle = {
      paddingLeft: '0',
      paddingRight: '0',
    }
    return (
      <Section style={{ background: '#3B4C66' }}>
        <ContainerLimited maxWidth={maxWidth ? maxWidth : '1280px'} ref={this.props.parentRef}>
          <CustomContentShortV style={contentStyle}>
            {screenWidth > 1023 ? this.renderNavbarDesktop() : this.renderNavbarMobile()}
          </CustomContentShortV>
        </ContainerLimited>
      </Section>
    )
  }

  render() {
    if (this.props.large) {
      return this.renderLarge()
    } else {
      return this.renderStandard()
    }
  }
}

export default withRouter(Navbar)

const StyledNavbar = styled.nav({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [desktop_max]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
})

const CustomContentShortV = styled.div({
  padding: '20px 40px',
  [desktop_max]: {
    padding: '18px 35px',
  },
  [tablet_max]: {
    padding: '16px 30px',
  },
  [phablet_max]: {
    padding: '14px 25px',
  },
  [phone_max]: {
    padding: '10px 15px',
  },
})

// const StyledP = styled.p({
//   textDecoration: 'none',
//   color: 'white',
//   display: 'inline',
//   WebkitUserSelect: 'none',
//   MozUserSelect: 'none',
//   MsUserSelect: 'none',
//   userSelect: 'none',
// })

// const StyledPDesktopOnly = styled(StyledP)({
//   [phablet_max]: {
//     display: 'none',
//   }
// })

const StyledLink = styled(NavLink)({
  textDecoration: 'none',
  color: 'white',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  fontSize: '16px',
  [tablet_max]: {
    fontSize: '15px',
  },
  [phablet_max]: {
    fontSize: '14px',
  },
  [phone_max]: {
    fontSize: '13px',
  },
  '&.selected': {
    textDecoration: 'underline',
  },
})

const StyledLinkDesktopOnly = styled(StyledLink)({
  [phablet_max]: {
    display: 'none',
  },
})

const HorizontalSpace = styled.div({
  width: '24px',
  [tablet_max]: {
    width: '18px',
  },
  [phablet_max]: {
    width: '12px',
  },
  [phone_max]: {
    width: '6px',
  },
})

const LeftSide = styled.div({
  // marginLeft: 'auto',
  '& svg': {
    position: 'relative',
    top: '3px',
    left: '-6px',
    transform: 'scale(0.9)',
    [phablet_max]: {
      transform: 'scale(0.85)',
      left: '-16px',
      top: '3px',
    },
    [phone_max]: {
      transform: 'scale(0.8)',
    },
  },
})

const RightSide = styled(FlexDiv)({
  // marginLeft: 'auto',
})
