import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import {
  format,
  startOfWeek,
} from 'date-fns'
import Spinner from '../../ui/components/spinner'
import { desktop_max, tablet_max, phablet_max } from '../../ui/defaults/media-queries'
import { blue_shiny } from '../../ui/defaults/colors'
import { getClients } from '../../redux/client/actions'
import {
  getProducts,
} from '../../redux/product/actions'
import {
  getReservations,
  getRecentlyChangedReservations,
} from '../../redux/reserva/actions'
import {
  getProductsOrder,
} from '../../redux/account/actions'
import {
  getBusinessDaysArrayFromStartDateSimpleB,
} from '../../utils/dayHelpers'
import {
  getURLFormattedDate,
  getDateFromURLDate,
  getDatesArraySlug,
} from '../../utils/date'
import ActivityFeed from './components/overview/ActivityFeed'
import StockMonitor from './components/overview/StockMonitor'
import { cycleDataUpdateInterval } from '../../utils/defaults'
import BackofficePage from './components_shared/BackofficePage'
// import { verifyToken } from '../../redux/user/actions'

@connect(
  store => ({
    user: store.user.userData,
    clients: store.client.clients,
    shop_products_order: store.product.shop_products_order,
    isAuthenticated: store.user.isAuthenticated,
    products: store.product.products,
    loadingProducts: store.product.loading,
    loadingClients: store.client.loading,
    loadingReservations: store.reserva.loading,
    reservations: store.reserva.reservations,
    mobileNavOpen: store.system.mobileNavOpen,
    windowHeight: store.system.windowHeight,
    loadingLastChangedReservations: store.reserva.loading,
    recentlyChangedReservations: store.reserva.recentlyChangedReservations,
  }),
  dispatch => ({
    actions: bindActionCreators({
      getClients,
      getProducts,
      getReservations,
      getProductsOrder,
      getRecentlyChangedReservations,
      // verifyToken,
    }, dispatch)
  })
)
class Overview extends Component {

  constructor(props) {
    super(props)
    this.state = {
      repeatedVerifyToken: false,
      requestedBasics: false,
      requestedAllData: false,
      currentWeekStartDate: null,
      viewingWeekDates: null,
      renderSaleActive: true,
      datesString: null,
    }
  }

  addYearMonthDayToString(string, date) {
    const uRLFormattedDate = getURLFormattedDate(date)
    if (string.indexOf(uRLFormattedDate) === -1) {
      return `${string}-${uRLFormattedDate}`
    }
    return string
  }

  requestBasics = () => {
    this.props.actions.getProducts(this.props.user.account._id)
    this.props.actions.getProductsOrder(this.props.user.account._id)
    this.props.actions.getClients(this.props.user.account._id)
    this.setState({requestedBasics: true})
  }

  requestaAllData = (datesString) => {
    try {
      this.props.actions.getReservations(datesString, this.props.user.account._id)
      this.props.actions.getRecentlyChangedReservations(this.props.user.account._id)
      if (!this.state.requestedAllData) {
        this.setState({ requestedAllData: true })
        this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
      }
    } catch(error) {
      console.log('Overview error: ' + error)
    }
  }
  
  initFunction = (prevProps) => {
    const {
      user,
      match,
    } = this.props
    const {
      weekDate,
      requestedBasics,
    } = this.state

    const hasUser = user && user.account && user.account._id
    if (!hasUser) return

    const weekDate_exists = match && match.params && match.params.weekDate
    const current_weekDate = weekDate_exists ? match.params.weekDate : null
    
    if (!current_weekDate) {
      return this.props.history.push(`/vista-geral/${format(new Date(), 'yyyy:MM:dd')}`)
    }

    if (!requestedBasics) {
      this.requestBasics()
    }

    if (current_weekDate !== weekDate) {
      this.setOpenDays()
    }
  }

  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate(prevProps) {
    this.initFunction(prevProps)
  }

  setOpenDays = () => {
    const currentWeekDate = this.props.match.params.weekDate
    // get url date
    const currentUrlWeekDate = startOfWeek(getDateFromURLDate(currentWeekDate))
    // generate business days
    const viewingWeekDates = getBusinessDaysArrayFromStartDateSimpleB(this.props.user.account, currentUrlWeekDate)
    if (viewingWeekDates.length === 0) return
    const datesString = getDatesArraySlug(viewingWeekDates)
    this.requestaAllData(datesString)
    this.setState({
      weekDate: currentWeekDate,
      currentWeekStartDate: currentUrlWeekDate,
      viewingWeekDates: viewingWeekDates,
      datesString: datesString
    })
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  cycleDataUpdate() {
    const {
      loadingReservations,
    } = this.props
    if (loadingReservations) return
    this.requestaAllData(this.state.datesString)
  }

  renderContent() {
    return (
      <div style={{width: '100%'}}>
        <Layout>
          <StockMonitor
            windowHeight={this.props.windowHeight}
            clients={this.props.clients}
            products={this.props.products}
            reservations={this.props.reservations}
            shop_products_order={this.props.shop_products_order}
            viewingWeekDates={this.state.viewingWeekDates}
            currentWeekStartDate={this.state.currentWeekStartDate}
            loadingReservations={this.props.loadingReservations}
            user={this.props.user}
          />
          <ActivityFeed
            windowHeight={this.props.windowHeight}
            user={this.props.user}
            accountId={this.props.user.account._id}
            clients={this.props.clients}
            products={this.props.products}
            recentlyChangedReservations={this.props.recentlyChangedReservations}
            loadingLastChangedReservations={this.props.loadingLastChangedReservations}
          />
        </Layout>
      </div>
    )
  }

  render() {
    let content = null
    if (this.props.isAuthenticated) {
      content = this.renderContent()
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    return (
      <BackofficePage
        title='Vista Geral | As minhas reservas'
        content={content}
      />
    )
  }
}

export default withRouter(Overview)

const Layout = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 26%',
  [desktop_max]: {
    gridTemplateColumns: 'auto 28%',
  },
  [tablet_max]: {
    gridTemplateColumns: 'auto 26%',
  },
  [phablet_max]: {
    gridTemplateColumns: '100%',
  },
})
