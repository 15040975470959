import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import Spinner from '../../../../ui/components/spinner'
import { Content, ContentShortV } from '../../../../ui/elements/layout'
import { PSmall, H6 } from '../../../../ui/elements/typography'
import { blue_strong, grey_border, green_shiny, red_light } from '../../../../ui/defaults/colors'
import { phablet_max } from '../../../../ui/defaults/media-queries'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'
// actions
import { getClient, saveClient } from '../../../../redux/client/actions'
import { Input } from '../../../../ui/elements/forms'
// import { onlyNumbersArray } from '../../../../utils/inputs'
import { handleLimitToNumbersStrict } from '../../../../utils/inputs'

@connect(
  store => ({
    user: store.user.userData,
    editingClient: store.client.editingClient,
    loadingEditingClient: store.client.loadingEditingClient,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getClient,
        saveClient,
      },
      dispatch,
    ),
  }),
)
class ClientEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      formIsValid: true,
      requestedClient: false,
      editingClient: null,
      nameInvalid: false,
      telephoneInvalid: false,
    }
  }

  initFunction = () => {
    const { user, match, editingClient } = this.props

    // get user if not present
    const hasUser = user && user.account && user.account._id
    if (!hasUser) return null

    // get editing reservation if not present
    if (!editingClient && !this.state.requestedClient) {
      this.setState({
        requestedClient: true,
      })
      this.props.actions.getClient(user.account._id, match.params.id)
    } else {
      // test
      if (!this.state.started && this.props.editingClient) {
        this.setState({
          editingClient: this.props.editingClient,
          started: true,
        })
      }
    }
  }

  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate() {
    this.initFunction()
  }

  handleSaveClient = () => {
    const { user } = this.props
    const { editingClient } = this.state

    this.props.actions.saveClient(user.account._id, editingClient)
  }

  handleInputChange = e => {
    const { editingClient } = this.state

    let updatedClient = editingClient
    updatedClient[e.target.name] = e.target.value

    this.setState({
      editingClient: updatedClient,
    })

    setTimeout(() => this.validateForm(), 200)
  }

  handleTelephoneChange = e => {
    const { editingClient } = this.state

    let updatedClient = editingClient

    const parsedPhoneNumber = e.target.value.replace(/[^0-9.]/g, '')
    updatedClient.telephone = parsedPhoneNumber

    this.setState({
      editingClient: updatedClient,
    })

    setTimeout(() => this.validateForm(), 200)
  }

  validateForm = e => {
    const { editingClient } = this.state

    let { name, telephone } = editingClient

    name = name.trim()

    let nameInvalid = false
    let telephoneInvalid = false
    let nameSplit

    if (String(name) === '') {
      nameInvalid = true
    }

    if (!nameInvalid) {
      if (String(name).indexOf(' ') === -1) {
        nameInvalid = true
      }
    }

    if (!nameInvalid) {
      nameSplit = name.split(' ')
      if (!nameSplit || !nameSplit.length || nameSplit.length < 2) {
        nameInvalid = true
      }
    }

    if (!nameInvalid) {
      const test1 = typeof nameSplit[0] === 'string' && nameSplit[0].length > 0
      const test2 = typeof nameSplit[1] === 'string' && nameSplit[1].length > 0
      if (!test1 || !test2) {
        nameInvalid = true
      }
    }

    if (String(telephone).length !== 9) {
      telephoneInvalid = true
    }
    const firstTwoNumbers = String(telephone)[0] + String(telephone)[1]
    if (
      firstTwoNumbers !== '91' &&
      firstTwoNumbers !== '92' &&
      firstTwoNumbers !== '96' &&
      firstTwoNumbers !== '93'
    ) {
      telephoneInvalid = true
    }

    const invalid = nameInvalid || telephoneInvalid

    this.setState({
      formIsValid: !invalid,
      nameInvalid: nameInvalid,
      telephoneInvalid: telephoneInvalid,
    })
  }

  renderContent() {
    const { editingClient, started } = this.state

    if (!started || !editingClient) {
      return (
        <Content>
          <div
            style={{ width: '100px', padding: '50px 0', display: 'flex', justifyContent: 'center' }}
          >
            <Spinner radius={20} color="black" />
          </div>
        </Content>
      )
    }

    return (
      <div>
        <Content>
          <ClientInfo>
            <H6>Nome e telefone:</H6>
            <br />
            <CustomInput
              type="text"
              name="name"
              placeholder="Nome"
              // onKeyDown={this.handleTitleKeyDown}
              value={editingClient.name}
              onChange={this.handleInputChange}
              hasChanges={this.state.editingClient.name !== this.props.editingClient.name}
            />
            <br />
            <CustomInput
              type="text"
              name="telephone"
              placeholder="Telefone"
              maxLength="9"
              // onKeyDown={this.handleTitleKeyDown}
              onKeyDown={handleLimitToNumbersStrict}
              value={editingClient.telephone}
              onChange={this.handleTelephoneChange}
              hasChanges={this.state.editingClient.telephone !== this.props.editingClient.telephone}
            />
          </ClientInfo>
        </Content>
      </div>
    )
  }

  render() {
    const { requestedClient, formIsValid } = this.state

    return (
      <RootDiv>
        <Header>
          <HeaderContent>
            <p>Editar Cliente</p>
            <div>
              <CancelEditingButton onClick={() => (window.location = '/relatorios/clientes/')}>
                <PSmall>Voltar</PSmall>
                <FAIcon icon="faArrowLeft" family="solid" />
              </CancelEditingButton>
              <SaveButton onClick={this.handleSaveClient} formIsValid={formIsValid}>
                <PSmall>Salvar</PSmall>
                <FAIcon icon="faSave" family="solid" />
              </SaveButton>
            </div>
          </HeaderContent>
        </Header>
        {requestedClient && this.renderContent()}
      </RootDiv>
    )
  }
}

export default withRouter(ClientEditor)

const RootDiv = styled.div({
  width: '100%',
  '& .ProductAmountSelector': {
    margin: '0 20px 0 0 !important',
    width: '10% !important',
    minWidth: '100px',
    maxWidth: '120px',
    height: '30px !important',
  },
  '& .AmountControlDiv': {
    margin: '0',
  },
})

const Header = styled.div({
  width: '100%',
  height: 'auto',
  background: 'white',
  borderBottom: '2px solid #D1E6FD',
})

const ClientInfo = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '100px',
})

const HeaderContent = styled(ContentShortV)({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  [phablet_max]: {
    flexDirection: 'column',
  },
})

const Button = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  padding: '6px 14px 4px 14px',
  cursor: 'pointer',
  borderRadius: '4px',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  svg: {
    fontSize: '16px',
    marginLeft: '10px',
    position: 'relative',
    top: '-2px',
  },
  ':hover': {
    'p, svg': {
      color: 'white',
    },
  },
})

const CancelEditingButton = styled(Button)({
  backgroundColor: grey_border,
  ':hover': {
    backgroundColor: grey_border,
    'p, svg': {
      color: 'black',
    },
  },
})

const SaveButton = styled(Button)(props => ({
  marginLeft: '10px',
  marginRight: '10px',
  backgroundColor: props.formIsValid ? green_shiny : red_light,
  ':hover': {
    backgroundColor: props.formIsValid ? green_shiny : red_light,
  },
  'p, svg': {
    color: 'white',
  },
}))

const CustomInput = styled(Input)(props => ({
  width: '50%',
  padding: '10px',
  color: props.hasChanges ? `${blue_strong} !important` : 'auto',
  [phablet_max]: {
    width: '100%',
  },
}))
