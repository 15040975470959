import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { bindActionCreators } from 'redux'
import { StyledFormDiv } from '../../ui/elements/forms'
import { ActionButton } from '../../ui/components/buttons'
import { tablet_max, phablet_max, phone_max } from '../../ui/defaults/media-queries'
import { withRouter } from 'react-router'
import { loginUser, registerUser } from '../../redux/user/actions'
import { connect } from 'react-redux'
import NavbarHome from './components_shared/NavbarHome'
import AMRLogoForLightBg from '../../app_ui/svgs/logos/AMRLogoForLightBg'

@connect(
  store => ({
    user: store.user,
    errors: store.errors
  }),
  dispatch => ({
    actions: bindActionCreators({
      loginUser,
      registerUser,
    }, dispatch)
  })
)
class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calculatedHeight: `${window.innerHeight}px`,
      username: '',
      password: '',
      confirm: '',
    }
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
    if(this.props.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight = () => {
    try {
      let newHeight = window.innerHeight
      if (this.state.calculatedHeight !== newHeight) {
        this.setState({
          calculatedHeight: newHeight,
        })
      }
    } catch (err) {
      //
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleKeyUp = (e) => {
    if (e.which === 13) {
      this.handleSubmit()
    }
  }

  handleSubmit = (e) => {
    if (e) {e.preventDefault();}
    this.triggerRegister()
  }

  triggerRegister = (e) => {
    const user = {
      username: this.state.username,
      password: this.state.password,
    }
    this.props.actions.registerUser(user, this.props.history)
  }

  render() {
    const {
      username,
      password,
      confirm,
      error,
      errorMessage,
      calculatedHeight
    } = this.state
    return (
      <div>
        <Helmet>
          <title>Registo | As minhas reservas</title>
        </Helmet>
        <NavbarHome />
        <Layout calculatedHeight={calculatedHeight}>
          <div>
            <StyledBox>
              {error && <StyledError>{errorMessage}</StyledError>}
              <StyledFormDiv onSubmit={this.handleSubmit}>
                <h2 style={{ marginTop: 0 }}>Registo</h2>
                <br />
                <label className='form-label' htmlFor="username">Email</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="O seu e-mail"
                  onKeyUp={this.handleKeyUp}
                  onChange={this.handleInput}
                  value={username}
                />
                <br />
                <label className='form-label' htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="A sua password"
                  onKeyUp={this.handleKeyUp}
                  onChange={this.handleInput}
                  value={password}
                />
                <br />
                <label className='form-label' htmlFor="confirm">Confirm Password</label>
                <input
                  type="password"
                  id="confirm"
                  name="confirm"
                  placeholder="Confirme a sua password"
                  onKeyUp={this.handleKeyUp}
                  onChange={this.handleInput}
                  value={confirm}
                />
                <br />
                <ActionButton style={{alignSelf: 'flex-end'}} onClick={this.triggerRegister}>
                  <p>Registar</p>
                </ActionButton>
              </StyledFormDiv>
            </StyledBox>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <AMRLogoForLightBg />
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default withRouter(Register)

const StyledBox = styled.div({
  borderRadius: '5px',
  margin: '2rem auto',
  background: 'white',
  boxSizing: 'border-box',
  padding: '40px',
  width: '400px',
  [tablet_max]: {
    width: '360px',
  },
  [phablet_max]: {
    width: '300px',
  },
  [phone_max]: {
    margin: '0',
    width: '100%',
  },
})

const StyledError = styled.div({
  padding: '1rem',
  marginBottom: '1rem',
  border: '1px solid red',
  borderRadius: '5px',
  color: '#2E2E2E',
})

const Layout = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: props.calculatedHeight ? `${props.calculatedHeight}px` : 'auto',
  '& div': {
    position: 'relative',
    top: '-3%',
  }
}))
