export const fullWeekdayNames = {
  'Monday': 'Segunda-feira',
  'Tuesday': 'Terça-feira',
  'Wednesday': 'Quarta-feira',
  'Thursday': 'Quinta-feira',
  'Friday': 'Sexta-feira',
  'Saturday': 'Sábado',
  'Sunday': 'Domingo',
}

export const fullWeekdayNamesLowercase = {
  'monday': 'Segunda-feira',
  'tuesday': 'Terça-feira',
  'wednesday': 'Quarta-feira',
  'thursday': 'Quinta-feira',
  'friday': 'Sexta-feira',
  'saturday': 'Sábado',
  'sunday': 'Domingo',
}

export const mediumWeekdayNames = {
  'Monday': 'Segunda',
  'Tuesday': 'Terça',
  'Wednesday': 'Quarta',
  'Thursday': 'Quinta',
  'Friday': 'Sexta',
  'Saturday': 'Sábado',
  'Sunday': 'Domingo',
}

export const mediumWeekdayNamesLowercase = {
  'monday': 'Segunda',
  'tuesday': 'Terça',
  'wednesday': 'Quarta',
  'thursday': 'Quinta',
  'friday': 'Sexta',
  'saturday': 'Sábado',
  'sunday': 'Domingo',
}

export const shortWeekdayNames = {
  'Monday': 'Seg',
  'Tuesday': 'Ter',
  'Wednesday': 'Qua',
  'Thursday': 'Qui',
  'Friday': 'Sex',
  'Saturday': 'Sab',
  'Sunday': 'Dom',
}

export const shortWeekdayNamesLowercase = {
  'monday': 'Seg',
  'tuesday': 'Ter',
  'wednesday': 'Qua',
  'thursday': 'Qui',
  'friday': 'Sex',
  'saturday': 'Sab',
  'sunday': 'Dom',
}

export const lowercaseWeekdayNames = {
  'Monday': 'segunda-feira',
  'Tuesday': 'terça-feira',
  'Wednesday': 'quarta-feira',
  'Thursday': 'quinta-feira',
  'Friday': 'sexta-feira',
  'Saturday': 'sábado',
  'Sunday': 'domingo',
}

export const fullMonthNames = {
  'January': 'Janeiro',
  'February': 'Fevereiro',
  'March': 'Março',
  'April': 'Abril',
  'May': 'Maio',
  'June': 'Junho',
  'July': 'Julho',
  'August': 'Agosto',
  'September': 'Setembro',
  'October': 'Outubro',
  'November': 'Novembro',
  'December': 'Dezembro',
}

export const translateEnglishWeekdayName = (dayName) => {
  return fullWeekdayNames[dayName] || fullWeekdayNamesLowercase[dayName]
}
export const translateEnglishWeekdayNameMedium = (dayName) => {
  return mediumWeekdayNames[dayName] || mediumWeekdayNamesLowercase[dayName]
}
export const translateEnglishMonthName = (monthName) => {
  return fullMonthNames[monthName]
}
export const translateEnglishWeekdayNameSmall = (dayName) => {
  return shortWeekdayNames[dayName] || shortWeekdayNamesLowercase[dayName]
}
export const translateEnglishWeekdayNameLowerCase = (dayName) => {
  return lowercaseWeekdayNames[dayName]
}
