import { createStore, applyMiddleware, compose } from 'redux';

import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import reducer from '../redux'

const middlewareArray = []
middlewareArray.push(promise)
middlewareArray.push(thunk)

if (process.env.NODE_ENV !== 'production') {
  middlewareArray.push(createLogger())
}

const middleware = compose(applyMiddleware(...middlewareArray));

export default createStore(reducer, middleware)