import React, { Component } from 'react'
import styled from '@emotion/styled'
import { getTime } from 'date-fns'
import { FlexDiv } from '../../../ui/elements/layout'
import { PSmall } from '../../../ui/elements/typography'

class ProductAvailabilityDisplaySmall extends Component {

  render() {
    const {
      handleSelectDay,
      availabilityArray,
    } = this.props
    if (!availabilityArray) return null
    return (
      <div>
        <FlexDiv style={{width: '100%', justifyContent: 'space-between'}}>
          { availabilityArray.map((businessDay, index) => 
            <DaySelector
              customWidth={businessDay.customWidth}
              key={index}
              className={'date: ' + getTime(businessDay.dayDate)}
              onClick={businessDay.state !== 'unavailable' ? (e) => { e.stopPropagation(); e.preventDefault(); handleSelectDay(businessDay.dayDate); } : null}
              >
              <DaySelectorBox href='#card-content-div' bgColor={businessDay.bgColor}>
                <PSmall style={{color: businessDay.txtColor, textAlign:'center', lineHeight: '110%'}}>{businessDay.shortWeekdayName}<br />{businessDay.monthDay}</PSmall>
              </DaySelectorBox>
              {businessDay.isToday && <Today>Hoje</Today>}
            </DaySelector>
          )}
        </FlexDiv>
      </div>
    )
  }
}

export default ProductAvailabilityDisplaySmall

const DaySelector = styled.div(props => ({
  width: props.customWidth,
}))

const DaySelectorBox = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  cursor: 'pointer',
  width: '100%',
  height: '50px',
  borderRadius: '7px',
  background: props.bgColor,
  justifyContent: 'center',
  alignItems: 'center',
  '& p': {
    fontSize: '13px',
  },
}))

const Today = styled.p({
  width: '100%',
  textAlign: 'center',
  fontSize: '13px',
  color: '#acacac',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
})
