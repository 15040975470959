import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { format, isSameDay, parseISO, subDays, addDays, startOfWeek } from 'date-fns'
import { Section, Container, ContainerLargeLimited } from '../../ui/elements/layout'
import { blue_shiny } from '../../ui/defaults/colors'
import {
  getReservations,
  reservationStateForward,
  reservationStateBackward,
  deleteReservation,
} from '../../redux/reserva/actions'
import {
  getProducts,
} from '../../redux/product/actions'
import ReservationsKanban from './components/reservations/ReservationsKanban'
import WeekPager from './components/reservations/WeekPager'
import Spinner from '../../ui/components/spinner'
import { FormLabelBlueDark } from '../../ui/elements/forms'
import { translateEnglishWeekdayName, translateEnglishMonthName } from '../../utils/translation'
import {
  getTodayDate,
  // getBusinessDaysArrayFromStartDateAndReservations,
  getBusinessDaysArrayFromStartDateSimpleB,
} from '../../utils/dayHelpers'
import {
  getDateFromURLDate,
  getURLFormattedDate,
  ensureGetRegularDate,
  getDatesArraySlug,
} from '../../utils/date'
import {
  sortArrayByNumericProperty,
  sortArrayByAlphabeticalPropertyValue,
} from '../../utils/array'
import {
  triggerConfirmDialog,
} from '../../redux/dialog/actions'
import { desktop_max } from '../../ui/defaults/media-queries'
import { getClients } from '../../redux/client/actions'
import { cycleDataUpdateInterval } from '../../utils/defaults'
import BackofficePage from './components_shared/BackofficePage'

@connect(
  store => ({
    user: store.user.userData,
    clients: store.client.clients,
    products: store.product.products,
    isAuthenticated: store.user.isAuthenticated,
    reservations: store.reserva.reservations,
    reservasLoading: store.reserva.loading,
    movingStateReservationId: store.reserva.movingStateReservationId,
    loadingReservations: store.reserva.loading,
    windowHeight: store.system.windowHeight,
  }),
  dispatch => ({
    actions: bindActionCreators({
      getClients,
      getReservations,
      getProducts,
      reservationStateForward,
      reservationStateBackward,
      deleteReservation,
      triggerConfirmDialog,
    }, dispatch)
  })
)
class Reservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // openDays: null,
      mountedInterface: false,
      requestedBasics: false,
      requestedAllData: false,
      currentWeekStartDate: null,
      sortType: 1, // 1: alphabetically - 2: numerically
      weekDate: null,
      viewingWeekDates: null,
      datesString: null
    }

    this.weekPagerRef = React.createRef()
    this.formLabelRef = React.createRef()
    this.currentWeekDisplayRef = React.createRef()
    this.requestedScrollRef = React.createRef()
    this.preparedScrollRef = React.createRef()
    this.finalisedScrollRef = React.createRef()
  }

  requestBasics = () => {
    this.props.actions.getProducts(this.props.user.account._id)
    this.props.actions.getClients(this.props.user.account._id)
    this.setState({requestedBasics: true})
  }

  requestaAllData = (datesString) => {
    try {
      this.props.actions.getReservations(datesString, this.props.user.account._id)
      if (!this.state.requestedAllData) {
        this.setState({ requestedAllData: true })
        this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
      }
    } catch(error) {
      console.log('error: ' + error)
    }
  }
  
  initFunction = () => {
    const {
      user,
      match,
    } = this.props
    const {
      weekDate,
      // requestedAllData,
      // viewingWeekDates,
      // datesString,
      requestedBasics,
    } = this.state

    const hasUser = user && user.account && user.account._id
    if (!hasUser) return

    const weekDate_exists = match && match.params && match.params.weekDate
    const current_weekDate = weekDate_exists ? match.params.weekDate : null
    
    if (!current_weekDate) {
      return this.props.history.push(`/reservas/${format(new Date(), 'yyyy:MM:dd')}`)
    }

    if (!requestedBasics) {
      this.requestBasics()
    }

    if (current_weekDate !== weekDate) {
      this.setOpenDays()
    }

    if (!this.state.mountedInterface && this.state.requestedAllData) {
      this.mountInterface()
    }
  }
  
  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate() {
    this.initFunction()
  }

  mountInterface = () => {
    const {
      reservations
    } = this.props
    if (!reservations) return null
    const currentWeekDate = this.props.match.params.weekDate
    const currentUrlWeekDate = startOfWeek(getDateFromURLDate(currentWeekDate))
    const viewingWeekDatesA = getBusinessDaysArrayFromStartDateSimpleB(this.props.user.account, currentUrlWeekDate)
    // const viewingWeekDatesA = this.getCurrentWeekDaysFromURL()
    // const viewingWeekDates = getBusinessDaysArrayFromStartDateAndReservations(this.props.user.account, viewingWeekDatesA, reservations)
    // const currentWeekStartDate = viewingWeekDates && viewingWeekDates.length > 1 ? viewingWeekDates[0].dayDate : null
    // const datesString = getDatesArraySlug(viewingWeekDates)
    this.setState({
      currentWeekStartDate: viewingWeekDatesA[0].dayDate,
      viewingWeekDates: viewingWeekDatesA,
      mountedInterface: true,
    })
  }

  setOpenDays = () => {
    const currentWeekDate = this.props.match.params.weekDate
    const currentUrlWeekDate = startOfWeek(getDateFromURLDate(currentWeekDate))
    const viewingWeekDatesA = getBusinessDaysArrayFromStartDateSimpleB(this.props.user.account, currentUrlWeekDate)
    const datesStringA = getDatesArraySlug(viewingWeekDatesA)
    this.setState({
      weekDate: currentWeekDate,
      datesString: datesStringA,
    })
    if (!this.state.requestedAllData) {
      this.requestaAllData(datesStringA)
    }
  }

  cycleDataUpdate() {
    const {
      loadingReservations,
      reservasLoading,
      movingStateReservationId,
    } = this.props
    if (reservasLoading) return
    if (loadingReservations) return
    if (movingStateReservationId) return
    if (!this.props.user && !this.props.user.account) return
    this.requestaAllData(this.state.datesString)
  }

  resetUpdateCycleTimer = () => {
    clearInterval(this.timerID)
    this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  handleDeleteReservation = (_id) => {
    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja remover esta reserva?',
      confirmText: 'Sim, remover',
      cancelText: 'Cancelar',
      confirmCallBack: () => this.props.actions.deleteReservation(this.props.user.account._id, _id),
    })
  }

  handleMoveStateForward = (_id, state) => {
    if (state === 'finalised') return
    const {
      loadingReservations,
      movingStateReservationId,
    } = this.props
    if (loadingReservations) return
    if (movingStateReservationId) return
    this.props.actions.reservationStateForward(this.props.user.account._id, _id)
    this.resetUpdateCycleTimer()
  }

  handleMoveStateBackward = (_id, state) => {
    if (state === 'requested') return
    const {
      loadingReservations,
      movingStateReservationId,
    } = this.props
    if (loadingReservations) return
    if (movingStateReservationId) return
    this.props.actions.reservationStateBackward(this.props.user.account._id, _id)
    this.resetUpdateCycleTimer()
  }

  getReservationsForDay = (dayDate, reservations) => {
    if (!dayDate) return []
    const reservationsThisDay = []
    for (var i = 0; i < reservations.length; i++) {
      if (isSameDay(dayDate, parseISO(reservations[i].dayDate))) {
        reservationsThisDay.push(reservations[i])
      }
    }
    return reservationsThisDay
  }

  prepareDaysOfWeekData = () => {
    const {
      reservations,
    } = this.props
    const {
      viewingWeekDates,
    } = this.state

    const daysOfTheWeekData = []
    
    if (!reservations) return daysOfTheWeekData

    for (const viewing_date of viewingWeekDates) {
      let reservationsForDay = reservations.filter(reservation => reservation.yearMonthDay === viewing_date.yearMonthDay)

      // assign dailyCountId's by date_created
      reservationsForDay = sortArrayByNumericProperty(reservationsForDay, 'date_created')
      for (var i = 0; i < reservationsForDay.length; i++) {
        reservationsForDay[i].dailyCountId = i + 1
      }

      // const reservationsForDay = reservations

      let requestedReservations = []
      let preparedReservations = []
      let finalisedReservations = []
      let cancelledReservations = []
      if (reservationsForDay && reservationsForDay.length > 0) {
        requestedReservations = reservationsForDay.filter(reservation => reservation.state === 'requested')
        preparedReservations = reservationsForDay.filter(reservation => reservation.state === 'prepared')
        finalisedReservations = reservationsForDay.filter(reservation => reservation.state === 'finalised')
        cancelledReservations = reservationsForDay.filter(reservation => reservation.cancelled)
      }
      
      const dayOfMonthString = viewing_date.monthDayNumber
      const dayOfWeekWithMonthDayNumberString = `${translateEnglishWeekdayName(viewing_date.dayName)} ${dayOfMonthString}`
      const dayOfWeekShortParts = `${translateEnglishWeekdayName(viewing_date.dayName)}`
      const dayOfWeekShort = dayOfWeekShortParts.slice(0, 3)

      daysOfTheWeekData.push({
        dayName: dayOfWeekWithMonthDayNumberString,
        dayNameShort: dayOfWeekShort,
        // dayDate: viewing_date.dayDate,
        yearMonthDay: viewing_date.yearMonthDay,
        monthDayNumber: viewing_date.monthDayNumber,
        isToday: getURLFormattedDate(getTodayDate()) === viewing_date.yearMonthDay,
        weekDate: viewing_date.yearMonthDay,
        selected: this.props.match.params.weekDate === viewing_date.yearMonthDay,
        requested: requestedReservations,
        prepared: preparedReservations,
        finalised: finalisedReservations,
        numRequested: requestedReservations ? requestedReservations.length : 0,
        numPrepared: preparedReservations ? preparedReservations.length : 0,
        numFinalised: finalisedReservations ? finalisedReservations.length : 0,
        numCancelled: cancelledReservations.length || 0,
        hasCancelled: cancelledReservations.length && cancelledReservations.length > 0,
      })
    }
    return daysOfTheWeekData
  }

  addProductDataToReservationProduct = (reservation, storeProducts) => {
    if (!reservation) { return null }
    if (!reservation.products) { return reservation }

    for(const index in reservation.products) {
      const currentProduct = reservation.products[index]
      const relatedProductDoc = storeProducts.find(productDoc => productDoc._id === currentProduct._id)
      if (!relatedProductDoc) {
        console.error('product ::: product not found !')
      } else {
        const newProductData = {}
        newProductData.title_pt = relatedProductDoc.title_pt
        newProductData._id = currentProduct._id
        newProductData.amount = currentProduct.amount
        reservation.products[index] = newProductData
      }
    }

    return reservation
  }

  prepareReservationsForKanban = (daysOfTheWeekData, parsedURLWeekDate, urlWeekDate) => {
    const {
      products,
      clients,
    } = this.props

    const dayData = {
      requested: [],
      prepared: [],
      finalised: [],
    }

    if (!products) return dayData
    if (!clients) return dayData
    if (daysOfTheWeekData.length === 0) return dayData

    const dayReservations = daysOfTheWeekData.find(dayData => dayData.yearMonthDay === urlWeekDate)

    if (dayReservations) {
      if (dayReservations.requested && dayReservations.requested.length && dayReservations.requested.length > 0) {
        dayReservations.requested.forEach(requestedReservation => {
          const processedReservation = this.addProductDataToReservationProduct(requestedReservation, products)
          const whichdatetime = processedReservation.updated && processedReservation.date_updated ? processedReservation.date_updated : processedReservation.date_created
          const regularDate = ensureGetRegularDate(whichdatetime)
          processedReservation.time = format(regularDate, 'HH:mm')
          const client = clients.find(client => client.shortId === processedReservation.client_short_id)
          processedReservation.name = client ? client.name : processedReservation.name + ' (client missing)'
          if (processedReservation) {
            dayData.requested.push(processedReservation)
          }
        })
        if (this.state.sortType === 1) {
          dayData.requested = sortArrayByAlphabeticalPropertyValue(dayData.requested, 'name')
        } else {
          dayData.requested = sortArrayByNumericProperty(dayData.requested, 'dailyCountId')
        }
      }
      if (dayReservations.prepared && dayReservations.prepared.length && dayReservations.prepared.length > 0) {
        dayReservations.prepared.forEach(preparedReservation => {
          const processedReservation = this.addProductDataToReservationProduct(preparedReservation, products)
          const whichdatetime = processedReservation.updated && processedReservation.date_updated ? processedReservation.date_updated : processedReservation.date_created
          const regularDate = ensureGetRegularDate(whichdatetime)
          processedReservation.time = format(regularDate, 'HH:mm')
          const client = clients.find(client => client.shortId === processedReservation.client_short_id)
          processedReservation.name = client ? client.name : processedReservation.name + ' (client missing)'
          if (processedReservation) {
            dayData.prepared.push(processedReservation)
          }
        })
        if (this.state.sortType === 1) {
          dayData.prepared = sortArrayByAlphabeticalPropertyValue(dayData.prepared, 'name')
        } else {
          dayData.prepared = sortArrayByNumericProperty(dayData.prepared, 'dailyCountId')
        }
      }
      if (dayReservations.finalised && dayReservations.finalised.length && dayReservations.finalised.length > 0) {
        dayReservations.finalised.forEach(finalisedReservation => {
          console.log('finalisedReservation', finalisedReservation)
          const processedReservation = this.addProductDataToReservationProduct(finalisedReservation, products)
          const whichdatetime = processedReservation.updated && processedReservation.date_updated ? processedReservation.date_updated : processedReservation.date_created
          const regularDate = ensureGetRegularDate(whichdatetime)
          processedReservation.time = format(regularDate, 'HH:mm')
          const client = clients.find(client => client.shortId === processedReservation.client_short_id)
          processedReservation.name = client ? client.name : processedReservation.name + ' (client missing)'
          if (processedReservation) {
            dayData.finalised.push(processedReservation)
          }
        })
        if (this.state.sortType === 1) {
          dayData.finalised = sortArrayByAlphabeticalPropertyValue(dayData.finalised, 'name')
        } else {
          dayData.finalised = sortArrayByNumericProperty(dayData.finalised, 'dailyCountId')
        }
      }
    }

    return dayData
  }

  renderFormLabel(viewingWeekDates, updating) {
    if (!viewingWeekDates || viewingWeekDates.length === 0) return null
    const firstDayNumber = viewingWeekDates[0].monthDayNumber
    const lastDayNumber = viewingWeekDates[viewingWeekDates.length-1].monthDayNumber
    const firstDayNumberMonth = translateEnglishMonthName(viewingWeekDates[0].monthName)
    const lastDayNumberMonth = translateEnglishMonthName(viewingWeekDates[viewingWeekDates.length-1].monthName)
    const specifyFirstMonthName = firstDayNumberMonth !== lastDayNumberMonth

    let textString
    if (updating) {
      textString = 'A ACTUALIZAR'
    } else {
      textString = `SEMANA DE ${firstDayNumber} `
      if (specifyFirstMonthName) {
        textString += ` de ${firstDayNumberMonth} `
      }
      textString += ` a ${lastDayNumber} de ${lastDayNumberMonth}`
    }

    return(
      <FormLabelBlueDarkSection ref={this.currentWeekDisplayRef}>
        <FormLabelBlueDark>
          { textString }
        </FormLabelBlueDark>
      </FormLabelBlueDarkSection>
    )
  }

  handleMoveWeekForward = () => {
    const {
      currentWeekStartDate,
      loadingReservations,
    } = this.state
    if (loadingReservations) return
    const newWeekStartDate = addDays(currentWeekStartDate, 7)
    this.setState({requestedAllData: false, mountedInterface: false})
    this.props.history.push(`/reservas/${getURLFormattedDate(newWeekStartDate)}`)
    this.resetUpdateCycleTimer()
  }

  handleMoveWeekBackward = () => {
    const {
      currentWeekStartDate,
      loadingReservations,
    } = this.state
    if (loadingReservations) return
    const newWeekStartDate = subDays(currentWeekStartDate, 8)
    this.setState({requestedAllData: false, mountedInterface: false})
    this.props.history.push(`/reservas/${getURLFormattedDate(newWeekStartDate)}`)
    this.resetUpdateCycleTimer()
  }

  toggleSortType = () => {
    const newSortType = this.state.sortType === 1 ? 2 : 1
    this.setState({
      sortType: newSortType
    })
  }

  renderContent() {
    const {
      viewingWeekDates,
      currentWeekStartDate,
      // mountedInterface,
    } = this.state
    const {
      // user,
      movingStateReservationId,
      loadingReservations,
      reservasLoading,
      windowHeight,
    } = this.props

    // if (!mountedInterface) return null
    if (!viewingWeekDates) return null
    if (!currentWeekStartDate) return null
    // if (!user) return null
    // if (!user.account) return null
    if (!this.props.match.params.weekDate) return null
    let urlWeekDate = this.props.match.params.weekDate
    let parsedURLWeekDate = getDateFromURLDate(urlWeekDate)

    const daysOfTheWeekData = this.prepareDaysOfWeekData()
    const reservationsForKanban = this.prepareReservationsForKanban(daysOfTheWeekData, parsedURLWeekDate, urlWeekDate)

    const forwardWeekStartDate = addDays(currentWeekStartDate, 7)
    const backwardWeekStartDate = subDays(currentWeekStartDate, 7)
    
    const previousWeekdateURL = `/reservas/${format(backwardWeekStartDate, 'yyyy:MM:dd')}`
    const nextWeekdateURL = `/reservas/${format(forwardWeekStartDate, 'yyyy:MM:dd')}`

    let addHeight = this.weekPagerRef.current && this.weekPagerRef.current.clientHeight ? this.weekPagerRef.current.clientHeight : 0
    addHeight += this.formLabelRef.current && this.formLabelRef.current.clientHeight ? this.formLabelRef.current.clientHeight : 0

    return (
      <Section>
        <Section style={{background: '#2b3442'}} ref={this.formLabelRef}>
          <CustomContainerLargeLimitedHeader maxWidth='1400px'>
            <div style={{width: '50px', opacity: '0'}}>
              <Spinner radius={20} color='white' />
            </div>
            { this.renderFormLabel(viewingWeekDates, loadingReservations) }
            <div style={{width: '50px', marginTop: '10px', opacity: loadingReservations ? '1' : '0'}}>
              <Spinner radius={20} color='white' />
            </div>
          </CustomContainerLargeLimitedHeader>
        </Section>

        <WeekPagerSection ref={this.weekPagerRef}>
          <WeekPager
            numDays={5}
            days={daysOfTheWeekData}
            previousWeekdateURL={previousWeekdateURL}
            nextWeekdateURL={nextWeekdateURL}
            selectedDay={this.state.selectedDay}
            handleMoveWeekForward={this.handleMoveWeekForward}
            handleMoveWeekBackward={this.handleMoveWeekBackward}
          />
        </WeekPagerSection>

        <Container maxWidth={'1400px'}>
          <ReservationsKanban
            history={this.props.history}
            loading={reservasLoading}
            weekDate={this.props.match.params.weekDate}
            loadingReservations={loadingReservations}
            toggleSortType={this.toggleSortType}
            kanbanHeight={windowHeight - addHeight}
            movingStateReservationId={movingStateReservationId}
            reservations={reservationsForKanban}
            handleMoveStateForward={this.handleMoveStateForward}
            handleMoveStateBackward={this.handleMoveStateBackward}
            handleDeleteReservation={this.handleDeleteReservation}
            requestedScrollRef={this.requestedScrollRef}
            preparedScrollRef={this.preparedScrollRef}
            finalisedScrollRef={this.finalisedScrollRef}
            resetUpdateCycleTimer={this.resetUpdateCycleTimer}
          />
        </Container>
      </Section>
    )
  }

  render() {
    let content = null
    let pageTitle = 'Reservas | Asminhasreservas'

    if (this.props.isAuthenticated) {
      content = this.renderContent()
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    return (
      <BackofficePage
        title={pageTitle}
        content={content}
      />
    )
  }
}

export default withRouter(Reservations)

const WeekPagerSection = styled(Section)({
  width: '100%',
  background:'white',
  position:'relative',
  '& > div': {
    margin: '0 auto',
    maxWidth: '1400px',
    width: '90%',
    [desktop_max]: {
      width: '100%',
    }
  },
})

const CustomContainerLargeLimitedHeader = styled(ContainerLargeLimited)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const FormLabelBlueDarkSection = styled(Section)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '14px 10px 10px 10px',
  background: '#2b3442',
  '& label': {
    color: 'white',
    margin: '0'
  }
})
