import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { ActionButton } from '../buttons'
import { FlexDiv, Content } from '../../elements/layout'
import { closeConfirmDialog } from '../../../redux/dialog/actions'

@connect(
  store => ({
    question: store.dialog.question,
    cancelText: store.dialog.cancelText,
    confirmText: store.dialog.confirmText,
    confirmCallback: store.dialog.confirmCallback,
  }),
  dispatch => ({
    actions: bindActionCreators({
      closeConfirmDialog,
    }, dispatch)
  })
)

class ConfirmActionDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      newHeight: window.innerHeight,
      newWidth: window.innerWidth,
    }
    this.rootDiv = React.createRef()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setSize)
    this.setSize()
  }

  componentDidUpdate() {
    this.setSize()
  }

  setSize = () => {
    if (this.rootDiv.current) {
      if (this.state.newHeight !== window.innerHeight) {
        this.setState({
          newHeight: window.innerHeight,
          newWidth: window.innerWidth,
        })
      }
    }
  }

  handleConfirmClick = () => {
    this.props.confirmCallback()
    this.props.actions.closeConfirmDialog()
  }

  render() {
    const {
      newHeight,
      newWidth,
    } = this.state
    const {
      question,
      confirmCallback,
    } = this.props

    if (!question || !confirmCallback) {
      // if (!question) { console.log('question missing') }
      // if (!confirmCallback) { console.log('confirmCallback missing') }
      return null
    }

    return (
      <RootDiv ref={this.rootDiv} newHeight={newHeight} newWidth={newWidth}>
        <DialogWindow>
          <Content>
            <h3>{question}</h3>
            <br />
            <FlexDiv style={{justifyContent: 'space-between', width: '100%'}}>
              <ActionButton onClick={() => this.props.actions.closeConfirmDialog()} style={{marginRight: '10px'}}>
                <p>{this.props.cancelText || 'Cancelar'}</p>
              </ActionButton>
              <ActionButton onClick={this.handleConfirmClick}>
                <p>{this.props.confirmText || 'Confirmar'}</p>
              </ActionButton>
            </FlexDiv>
          </Content>
        </DialogWindow>
      </RootDiv>
    )
  }
}

export default ConfirmActionDialog

const RootDiv = styled.div(props => ({
  position: 'fixed',
  zIndex: '99999',
  width: `${props.newWidth}px`,
  height: `${props.newHeight}px`,
  background: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const DialogWindow = styled.div(props => ({
  width: '380px',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
}))
