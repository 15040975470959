import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { PWhite } from '../../ui/elements/typography'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Background from '../../components_system/background'
import Header from '../Header'
import { Section, ContainerWebview } from '../../ui/elements/layout'
import { WidgetPageRoot } from '../../app_ui/layout'
import { getAccountData } from '../../redux/shop/actions'

const isInvalidTelephone = telephone => {
  let telephoneInvalid = false
  if (String(telephone).length !== 9) {
    telephoneInvalid = true
  }
  const firstTwoNumbers = String(telephone)[0] + String(telephone)[1]
  if (
    firstTwoNumbers !== '91' &&
    firstTwoNumbers !== '92' &&
    firstTwoNumbers !== '96' &&
    firstTwoNumbers !== '93'
  ) {
    telephoneInvalid = true
  }
  const onlyNumbers = /^\d+$/.test(telephone)
  if (!onlyNumbers) {
    telephoneInvalid = true
  }
  return telephoneInvalid
}

@connect(
  store => ({
    shopAccount: store.shop.shopAccount,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getAccountData,
      },
      dispatch,
    ),
  }),
)
class ClientWebviewStart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: '',
      invalidTelephone: true,
    }
  }
  componentDidMount() {
    const { shop_slug } = this.props.match.params
    this.props.actions.getAccountData(shop_slug)

    const phoneNumberInLocalStorage = localStorage.getItem('phoneNumber')

    if (phoneNumberInLocalStorage) {
      // go to next apge if number was already added
      console.log('found phone number in local storage:', phoneNumberInLocalStorage)
      const nextURL = `/reservas-cliente-webview/norre/${phoneNumberInLocalStorage}`
      //window.location = nextURL
      this.props.history.push(nextURL)
      //setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
    }
  }

  handleChangeTelephone = (e, thisComponent) => {
    const { value } = e.target
    this.setState({
      phoneNumber: value,
      invalidTelephone: isInvalidTelephone(value),
    })
  }

  savePhoneNumberFromStateIntoLocalStorage = () => {
    const { phoneNumber } = this.state
    localStorage.setItem('phoneNumber', phoneNumber)
  }

  render() {
    //const { shop_slug } = this.props.match.params
    const { shopAccount } = this.props
    console.log('this.props', this.props)

    if (!shopAccount) return null

    console.log('shopAccount.business_name', shopAccount.business_name)
    const showWarning = this.state.phoneNumber !== '' && this.state.invalidTelephone
    const showButton = this.state.phoneNumber !== '' && !showWarning
    return (
      <Section>
        <ContainerWebview>
          <WidgetPageRoot>
            <Helmet>
              <title>As minhas reservas | asminhasreservas.pt</title>
            </Helmet>
            <div style={{ position: 'relative' }}>
              <br />
              <Header
                accountLogo={shopAccount.account_logo}
                businessName={shopAccount.business_name}
                omitCloseButton={true}
                subline="As minhas reservas"
              />
              <br />
              <FormDiv>
                <h4 style={{ marginTop: 0, marginBottom: '20px' }}>
                  Insira o seu telemóvel para consultar as suas reservas
                </h4>
                <Input
                  type="text"
                  id="username"
                  name="username"
                  value={this.state.phoneNumber}
                  placeholder="Nº telemóvel"
                  onChange={e => this.handleChangeTelephone(e, this)}
                  //onKeyUp={this.handleKeyUp}
                />
                {showWarning && (
                  <PWhite style={{ color: '#f96767', marginTop: '8px', fontSize: '16px' }}>
                    O número de telemóvel inserido é inválido - por favor verifique
                  </PWhite>
                )}
                <br />
                {showButton && (
                  <BtnA
                    onClick={this.savePhoneNumberFromStateIntoLocalStorage}
                    href={`/reservas-cliente-webview/norre/${this.state.phoneNumber}`}
                  >
                    <SubmitButton>Ver reservas</SubmitButton>
                  </BtnA>
                )}
              </FormDiv>
              <br />
              <br />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <div style={{ height: '2px', width: '100%', background: '#444' }} />
                <br />
                <PWhite style={{ marginTop: '8px', fontSize: '16px', opacity: '0.8' }}>
                  O seu número de telemóvel será guardado no seu dispositivo para que não tenha de o
                  voltar a inserir. Pode sempre terminar sessão e voltar a entrar.
                </PWhite>
              </div>
              <br />
            </div>
          </WidgetPageRoot>
          <Background bgColor="#242424" />
        </ContainerWebview>
      </Section>
    )
  }
}

export default withRouter(ClientWebviewStart)

const FormDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
})
const Input = styled.input({
  background: 'white',
  borderRadius: '4px',
  padding: '10px',
  fontSize: '30px',
})
const BtnA = styled.a({
  display: 'inline-flex',
  alignSelf: 'flex-end',
})
const SubmitButton = styled.button({
  alignSelf: 'flex-end',
  background: '#318DFF',
  color: '#fff',
  borderRadius: '4px',
  fontWeight: '500',
  padding: '12px 16px',
  fontSize: '18px',
  border: '0',
  outline: '0',
  boxShadow: 'none',
})
