import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { PaddingBox } from '../../app_ui/layout'
import { FlexDiv } from '../../ui/elements/layout'
import Spinner from '../../ui/components/spinner'
import { PWhite } from '../../ui/elements/typography'
import Background from '../../components_system/background'
import {
  getProductsStore,
} from '../../redux/product/actions'
import { setMobile } from '../../redux/system/actions'
// import { isMobileDeviceBrowser } from '../../utils/screen'

@connect(
  store => ({
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators({
      getProductsStore,
      setMobile,
    }, dispatch)
  })
)
class WidgetStart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appStarted: false,
      requestedProducts: false,
    }
  }

  componentDidMount() {
    // console.log('window.innerHeight: ' + window.innerHeight)
    this.props.actions.getProductsStore(this.props.match.params.app_id);
    this.setState({ requestedProducts: true })
  }

  componentDidUpdate() {
    const {
      storeAccount,
      productsStore,
    } = this.props
    const {
      appStarted,
      requestedProducts,
    } = this.state

    if (!storeAccount) return
    if (!productsStore) return
    
    if (!requestedProducts) {
      this.props.actions.getProductsStore(this.props.match.params.app_id);
      this.setState({ requestedProducts: true })
    }
    // check if products and reservations arrived
    const productsArrived = productsStore ? true : false
    const hasProducts = productsArrived && productsStore.length > 0 ? true : false
    
    const appId = this.props.match.params.app_id
    
    const parsed = queryString.parse(window.location.search);
    if (parsed.mobile) {
      this.props.actions.setMobile()
    }

    if (!appStarted && hasProducts) {
      // return start product selection page
      this.setState({appStarted: true})
      return this.props.history.push(`/shop/${appId}/product-selection`)
    }
    if ((!appStarted && !hasProducts) || productsStore.length === 0) {
      // return start sold out screen
      this.setState({appStarted: true})
      return this.props.history.push(`/shop/${appId}/sold-out`)
    }

    // TODO: improve handling of exceptions here
    return console.log('Returned unknown state!')
  }

  render() {
    return(
      <PaddingBox>
        <br />
        <br />
        <FlexDiv style={{width: '100%', alignItems: 'center', flexDirection: 'column'}}>
          <PWhite>Loading</PWhite>
          <br />
          <Spinner color='white' />
        </FlexDiv>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Background bgColor='#242424' />
      </PaddingBox>
    )
  }
}

export default withRouter(WidgetStart)
