import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { format, isSameMonth, formatDistanceToNow, endOfMonth, getMonth, getYear } from 'date-fns'
import { pt } from 'date-fns/locale'
import { Section, Container, WhiteBox } from '../../ui/elements/layout'
import { FormLabelBlue } from '../../ui/elements/forms'
import { H1, P, PSmall } from '../../ui/elements/typography'
import { tablet_max, phablet_max } from '../../ui/defaults/media-queries'
import { getClients } from '../../redux/client/actions'
import PageHeader from './components/PageHeader'
import { blue_shiny } from '../../ui/defaults/colors'
import { sortArrayByAlphabeticalPropertyValue, sortArrayByDateCreated } from '../../utils/array'
import { getMonthDaysFromAnyDate } from '../../utils/dayHelpers'
import { getDatesArraySlug } from '../../utils/date'
import BackofficePage from './components_shared/BackofficePage'
import Spinner from '../../ui/components/spinner'
import { triggerConfirmDialog } from '../../redux/dialog/actions'
import { deleteClient } from '../../redux/client/actions'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    clients: store.client.clients,
    loadingClients: store.client.loading,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getClients,
        triggerConfirmDialog,
        deleteClient,
      },
      dispatch,
    ),
  }),
)
class StatsClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelectedDate: null,
      requestedAllData: false,
      seeAll: false,
      sortBy: 'name',
      sortDirection: 'down',
      monthYearURLDate: null,
      monthName: null,
      viewingMonthDates: null,
      numDaysInMonth: null,
      datesString: null,
    }
  }

  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate() {
    this.initFunction()
  }

  initFunction = () => {
    const { user, match } = this.props
    const { monthYearURLDate } = this.state

    const hasUser = user && user.account && user.account._id
    if (!hasUser) return

    const year_exists = match && match.params && match.params.year
    const current_year = year_exists ? match.params.year : null
    const month_exists = match && match.params && match.params.month
    const current_month = month_exists ? match.params.month : null

    if (!year_exists || !month_exists) {
      return this.props.history.push(`/relatorios/clientes/${format(new Date(), 'yyyy/MM')}`)
    }

    if (current_year + '/' + current_month !== monthYearURLDate) {
      this.setOpenDays()
    }
  }

  setOpenDays = () => {
    // get url date
    const year = this.props.match.params.year
    const month = this.props.match.params.month
    const currentWeekDate = new Date(year, parseInt(month) - 1, 1)
    // generate business days
    const viewingMonthDates = getMonthDaysFromAnyDate(currentWeekDate)
    const datesString = getDatesArraySlug(viewingMonthDates)
    const firstDayDate = viewingMonthDates[0].dayDate
    // capitalise monthname
    let monthName = format(firstDayDate, 'MMMM', { locale: pt })
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1)
    this.setState({
      monthYearURLDate: year + '/' + month,
      currentMonthStartDate: firstDayDate,
      currentSelectedDate: firstDayDate,
      monthName: monthName,
      viewingMonthDates: viewingMonthDates,
      numDaysInMonth: viewingMonthDates.length,
      datesString: datesString,
    })
    this.requestaAllData(datesString)
  }

  requestaAllData = () => {
    if (this.state.requestedAllData) return
    try {
      this.props.actions.getClients(this.props.user.account._id)
      if (!this.state.requestedAllData) {
        this.setState({ requestedAllData: true })
      }
    } catch (error) {
      console.log('error: ' + error)
    }
  }

  getFormattedCreatedTime = date_created => {
    const dateCreated = format(new Date(date_created), 'EEEE dd ', { locale: pt })
    const dateCreateAgo = formatDistanceToNow(new Date(date_created), {
      locale: pt,
      addSuffix: true,
    })
    const finalString = `${dateCreated} (${dateCreateAgo})`
    return finalString
  }

  handlePageMonthBackward = () => {
    const { currentSelectedDate, loadingClients } = this.state
    if (loadingClients) return
    const month = getMonth(currentSelectedDate) + 1
    let year = getYear(currentSelectedDate)
    let nextMonth = month - 1
    if (nextMonth === 0) {
      nextMonth = 12
      year--
    }
    this.props.history.push(`/relatorios/clientes/${year}/${nextMonth}`)
  }

  handlePageMonthForward = () => {
    const { currentSelectedDate, loadingClients } = this.state
    if (loadingClients) return
    const month = getMonth(currentSelectedDate) + 1
    let year = getYear(currentSelectedDate)
    let nextMonth = month + 1
    if (nextMonth === 13) {
      nextMonth = 1
      year++
    }
    this.props.history.push(`/relatorios/clientes/${year}/${nextMonth}`)
  }

  setTimeDriven = () => {
    const { currentSelectedDate } = this.state
    const startOfMonthDate = endOfMonth(currentSelectedDate)
    const month = getMonth(startOfMonthDate)
    const year = getYear(startOfMonthDate)
    this.props.history.push(`/relatorios/clientes/${year}/${month}`)
  }

  setSeeAll = () => {
    this.setState({
      seeAll: !this.state.seeAll,
    })
  }

  renderMonthPager = () => {
    const { monthName } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <P
          style={{
            marginLeft: '10px',
            lineHeight: '100%',
            userSelect: 'none',
            marginRight: '10px',
          }}
        >
          {monthName}
        </P>
        <FAIcon
          icon="faChevronCircleLeft"
          family="light"
          style={{ cursor: 'pointer', color: '#242424' }}
          onClick={this.handlePageMonthBackward}
        />
        <FAIcon
          icon="faChevronCircleRight"
          family="light"
          style={{ cursor: 'pointer', marginLeft: '4px', color: '#242424' }}
          onClick={this.handlePageMonthForwawrd}
        />
        <FAIcon
          icon="faLightSwitchOff"
          family="regular"
          style={{ cursor: 'pointer', marginLeft: '10px', color: '#242424' }}
          onClick={this.setSeeAll}
        />
      </div>
    )
  }

  renderTudoPager = () => {
    return (
      <div style={{ display: 'flex' }}>
        <P style={{ marginLeft: '10px', lineHeight: '100%' }}>Tudo</P>
        <FAIcon
          icon="faLightSwitchOn"
          family="regular"
          style={{ cursor: 'pointer', marginLeft: '10px', color: '#242424' }}
          onClick={this.setTimeDriven}
        />
      </div>
    )
  }

  handlePropertyClick = property => {
    const { sortBy, sortDirection } = this.state
    if (sortBy === property) {
      this.setState({
        sortDirection: sortDirection === 'down' ? 'up' : 'down',
      })
    } else {
      this.setState({
        sortBy: property,
        sortDirection: 'down',
      })
    }
  }

  hasDateParams = () => {
    return (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.year &&
      this.props.match.params.month
    )
  }

  handleDeleteClient = shortId => {
    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja remover este cliente?',
      confirmText: 'Remover cliente',
      cancelText: 'Cancelar',
      confirmCallBack: () => this.props.actions.deleteClient(this.props.user.account._id, shortId),
    })
  }

  renderRow = (client, shopSlug) => {
    return (
      <ClientRow key={client.shortId}>
        <div className="client-name">
          <PSmall>{client.name}</PSmall>
        </div>
        <div className="client-telephone">
          <PSmall>{client.telephone}</PSmall>
        </div>
        <div className="client-date-created">
          <PSmall>{this.getFormattedCreatedTime(client.date_created)}</PSmall>
        </div>
        <div className="client-actions">
          <Link key={client._id} to={`/editar-cliente/${client._id}`}>
            <FAIcon icon="faPenSquare" family="solid" />
          </Link>
          <Link key={client.shortId} to={`/loja/${shopSlug}/${client.shortId}`}>
            <FAIcon icon="faExternalLinkSquareAlt" family="regular" />
          </Link>
          <FAIcon
            icon="faTrashAlt"
            family="regular"
            onClick={() => this.handleDeleteClient(client.shortId)}
          />
        </div>
      </ClientRow>
    )
  }

  renderContent() {
    let { requestedAllData, currentSelectedDate, sortBy, sortDirection, seeAll } = this.state
    const { user, clients, loadingClients } = this.props
    if (!clients) return null
    if (!requestedAllData) return null

    let clientsCopy = clients
    if (!seeAll) {
      clientsCopy = clientsCopy.filter(c =>
        isSameMonth(currentSelectedDate, new Date(c.date_created)),
      )
    }
    if (sortBy === 'date_created') {
      clientsCopy = sortArrayByDateCreated(clientsCopy)
      if (sortDirection === 'up') {
        clientsCopy.reverse()
      }
    } else if (sortBy === 'name') {
      clientsCopy = sortArrayByAlphabeticalPropertyValue(clientsCopy, 'name')
      if (sortDirection === 'up') {
        clientsCopy.reverse()
      }
    }
    const shopSlug = user.account.slug
    const sortIconUp = (
      <span style={{ position: 'relative' }}>
        <FAIcon
          icon="faCaretUp"
          family="light"
          style={{ cursor: 'pointer', marginLeft: '6px', color: 'white' }}
        />
      </span>
    )
    const sortIconDown = (
      <span style={{ position: 'relative' }}>
        <FAIcon
          icon="faCaretDown"
          family="light"
          style={{ cursor: 'pointer', marginLeft: '6px', color: 'white' }}
        />
      </span>
    )
    const sortIcon = sortDirection === 'up' ? sortIconUp : sortIconDown

    return (
      <Section>
        <Container>
          <br />
          <br />
          {loadingClients && <Spinner color={blue_shiny} radius={40} />}
          {!loadingClients && (
            <LayoutThree>
              <CustomWhiteBox>
                <FormLabelBlue style={{ color: '#627490' }}>
                  {!seeAll ? 'CLIENTES NOVOS ESTE MÊS' : 'NUM. TOTAL DE CLIENTES'}
                </FormLabelBlue>
                <H1 style={{ fontWeight: '500' }}>{clientsCopy.length}</H1>
              </CustomWhiteBox>
            </LayoutThree>
          )}
          <br />
          <br />
          {!loadingClients && clientsCopy && clientsCopy.length > 0 && (
            <TableRoot>
              <TableHeader>
                <PSmall
                  style={{ width: '30%', paddingLeft: '10px' }}
                  onClick={() => this.handlePropertyClick('name')}
                >
                  Nome {sortBy === 'name' && sortIcon}
                </PSmall>
                <PSmall style={{ width: '18%', paddingLeft: '10px' }}>Telefone</PSmall>
                <PSmall
                  style={{ width: '37%', paddingLeft: '10px' }}
                  onClick={() => this.handlePropertyClick('date_created')}
                >
                  Criado {sortBy === 'date_created' && sortIcon}
                </PSmall>
                <PSmall style={{ width: '15%', paddingRight: '16px', textAlign: 'right' }}>
                  Operações
                </PSmall>
              </TableHeader>
              {clientsCopy.map(client => this.renderRow(client, shopSlug))}
            </TableRoot>
          )}
          <br />
          <br />
        </Container>
      </Section>
    )
  }

  render() {
    let title = 'Relatórios: Clients | Asminhasreservas'
    let categoryElementsNav = null
    let content = null

    if (this.props.isAuthenticated) {
      content = this.renderContent()
      let addDateToURL = ''
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.year &&
        this.props.match.params.month
      ) {
        addDateToURL = `/${this.props.match.params.year}/${this.props.match.params.month}`
      }
      categoryElementsNav = (
        <CategoryElementsNav>
          <NavLink to={`/relatorios/reservas${addDateToURL}`}>
            <PageHeaderNavLink active={false} first>
              Reservas
            </PageHeaderNavLink>
          </NavLink>
          <NavLink to={`/relatorios/produtos${addDateToURL}`}>
            <PageHeaderNavLink active={false}>Produtos</PageHeaderNavLink>
          </NavLink>
          <NavLink to={`/relatorios/clientes${addDateToURL}`}>
            <PageHeaderNavLink active={true}>Clientes</PageHeaderNavLink>
          </NavLink>
        </CategoryElementsNav>
      )
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    const extraFirstElement = (
      <PageHeader
        key="extraFirstElement"
        pageTitle="Relatórios: Clientes"
        rightSideElement={this.renderMonthPager()}
      />
    )

    const extraFirstElement2 = (
      <PageHeader key="extraFirstElement2" headlineAlternativeElement={categoryElementsNav} />
    )

    const extreElement = [extraFirstElement, extraFirstElement2]

    return <BackofficePage title={title} content={content} extraFirstElement={extreElement} />
  }
}

export default StatsClients

const LayoutBase = styled.div({
  width: '100%',
  margin: '0 auto',
})

const LayoutThree = styled(LayoutBase)({
  display: 'flex',
})

const TableRoot = styled.div({
  // display: 'flex',
})

const ClientRow = styled.div({
  display: 'flex',
  '& > div': {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    borderBottom: '1px solid #eee',
  },
  '& .client-name': {
    width: '30%',
    paddingLeft: '10px',
  },
  '& .client-telephone': {
    width: '18%',
    borderLeft: '1px solid #eee',
    paddingLeft: '10px',
  },
  '& .client-date-created': {
    width: '37%',
    borderLeft: '1px solid #eee',
    paddingLeft: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& .client-actions': {
    width: '15%',
    borderLeft: '1px solid #eee',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    '& svg': {
      cursor: 'pointer',
      fontSize: '20px',
      marginLeft: '15px',
      [tablet_max]: {
        fontSize: '18px',
        marginLeft: '10px',
      },
      [phablet_max]: {
        fontSize: '16px',
        marginLeft: '8px',
      },
    },
    [phablet_max]: {
      justifyContent: 'space-between',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    '& a': {
      position: 'relative',
      top: '3px',
      [phablet_max]: {
        top: '1px',
      },
    },
  },
})

const TableHeader = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: '#46556B',
  width: '100%',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  height: '50px',
  '& p': {
    color: 'white',
    lineHeight: '100%',
    fontWeight: props.active ? '600' : '500',
  },
  '& svg': {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  ':hover': {
    '& p': {
      textDecoration: props.interactive ? 'underline' : 'none',
    },
  },
  cursor: props.interactive ? 'pointer' : 'auto',
}))

const CustomWhiteBox = styled(WhiteBox)({
  padding: '12px',
  paddingBottom: '6px',
  borderRadius: '3px',
  display: 'inline-flex',
  flexDirection: 'column',
  marginRight: '10px',
  marginBottom: '10px',
  '& label, h3': {
    lineHeight: '100%',
  },
  [tablet_max]: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  [phablet_max]: {
    marginRight: '6px',
    marginBottom: '6px',
  },
})

const CategoryElementsNav = styled.div({
  display: 'flex',
})

const PageHeaderNavLink = styled(PSmall)(props => ({
  cursor: 'pointer',
  display: 'inline-flex',
  marginLeft: props.first ? '0' : '14px',
  textDecoration: props.active ? 'underline' : 'none',
}))
