import axios from 'axios'
import { toast } from 'react-toastify'
import setAuthorizationToken from '../utils/setAuthorizationToken'
import {
  updateUserAccountDirect,
  updateUserAccountWeekdaysAvailabilityDirect,
} from '../user/actions'
import { 
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_ERROR,

  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,

  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,

  UPDATE_ACCOUNT_BUSINESS_NAME,
  UPDATE_ACCOUNT_BUSINESS_NAME_SUCCESS,
  UPDATE_ACCOUNT_BUSINESS_NAME_ERROR,

  UPDATE_ACCOUNT_EMAIL,
  UPDATE_ACCOUNT_EMAIL_SUCCESS,
  UPDATE_ACCOUNT_EMAIL_ERROR,

  UPDATE_ACCOUNT_HOSTNAME,
  UPDATE_ACCOUNT_HOSTNAME_SUCCESS,
  UPDATE_ACCOUNT_HOSTNAME_ERROR,

  UPDATE_ACCOUNT_ACTIVE,
  UPDATE_ACCOUNT_ACTIVE_SUCCESS,
  UPDATE_ACCOUNT_ACTIVE_ERROR,

  UPLOAD_ACCOUNT_LOGO,
  UPLOAD_ACCOUNT_LOGO_SUCCESS,
  UPLOAD_ACCOUNT_LOGO_ERROR,

  UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR,
  UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_SUCCESS,
  UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_ERROR,

  UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR,
  UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_SUCCESS,
  UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_ERROR,

  UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR,
  UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_SUCCESS,
  UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_ERROR,

  UPDATE_ACCOUNT_OPENING_DAYS,
  UPDATE_ACCOUNT_OPENING_DAYS_SUCCESS,
  UPDATE_ACCOUNT_OPENING_DAYS_ERROR,

  UPDATE_WEEKDAYS_AVAILABILITY,
  UPDATE_WEEKDAYS_AVAILABILITY_SUCCESS,
  UPDATE_WEEKDAYS_AVAILABILITY_ERROR,

  GET_SHOP_PRODUCTS_ORDER,
  GET_SHOP_PRODUCTS_ORDER_SUCCESS,
  GET_SHOP_PRODUCTS_ORDER_ERROR,
} from '../action-types'

const tokenName = 'reservasToken'


export function uploadAccountLogo(_id, uploadImageFile) {
  if (!_id) {
    return console.log('uploadAccountLogo ::: _id missing')
  }
  return dispatch => {
    dispatch({
      type: UPLOAD_ACCOUNT_LOGO,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)

    const data = new FormData() 
    data.append('file', uploadImageFile)

    return axios.post(`/api/v1/upload-account-logo/${_id}`, data).then(response => {
      dispatch({
        type: UPLOAD_ACCOUNT_LOGO_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: UPLOAD_ACCOUNT_LOGO_ERROR, payload: err })
    });
  }
}


export function getAccounts() {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({ type: GET_ACCOUNTS })
    axios.get('/api/v1/get-accounts', { data: token }, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: GET_ACCOUNTS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_ACCOUNTS_ERROR, payload: err })
    })
  }
}

export function createAccount() {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: CREATE_ACCOUNT,
    })
    axios.get('/api/v1/create-account', { data: token }, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: CREATE_ACCOUNT_SUCCESS,
        payload: response.data.ops[0],
      })
      toast.info('Nova conta criada')
    })
    .catch((err) => {
      dispatch({type: CREATE_ACCOUNT_ERROR, payload: err })
      toast.error('Erro ao criar conta.')
    })
  }
}

export function deleteAccount(_id) {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: DELETE_ACCOUNT,
    })
    axios.delete(`/api/v1/delete-account/${_id}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: DELETE_ACCOUNT_SUCCESS,
        payload: response.data,
      })
      toast.info('Conta removida')
    })
    .catch((err) => {
      dispatch({type: DELETE_ACCOUNT_ERROR, payload: err })
      toast.info('Erro ao remover conta.')
    })
  }
}

export function updateBusinessName(_id, business_name) {
  if (!_id) {
    return console.log('updateBusinessName ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_BUSINESS_NAME,
    })
    return axios.post('/api/v1/update-account-business-name', { _id: _id, business_name: business_name }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_BUSINESS_NAME_SUCCESS,
        payload: response.data,
      })
      toast.info('Nome alterado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_BUSINESS_NAME_ERROR, payload: err })
      toast.info('Erro ao alterar conta.')
    });
  }
}

export function updateAccountOpeningDays(account, newOpeningDays) {
  if (!account || !account._id) {
    return console.log('updateAccountOpeningDays ::: account._id missing')
  }
  return dispatch => {
    dispatch({
      type: UPDATE_ACCOUNT_OPENING_DAYS,
    })
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    return axios.post(`/api/v1/update-account-opening-days/${account._id}`, { opening_days: newOpeningDays }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_OPENING_DAYS_SUCCESS,
        payload: response,
      })
      account.opening_days = newOpeningDays
      dispatch(updateUserAccountDirect(account))
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_OPENING_DAYS_ERROR, payload: err })
    });
  }
}

export function updateAccountWeekdaysAvailability(account, newWeekdaysAvailability) {
  if (!account) { return console.log('account missing') }
  return dispatch => {
    dispatch({ type: UPDATE_WEEKDAYS_AVAILABILITY })
    axios.post(`/api/v1/update-weekdays-availability/${account._id}`, {weekdays_availability: newWeekdaysAvailability}, {
      withCredentials: false,
    }).then((response) => {
      dispatch({
        type: UPDATE_WEEKDAYS_AVAILABILITY_SUCCESS,
        payload: response.data,
      })
      account.weekdays_availability = newWeekdaysAvailability
      dispatch(updateUserAccountWeekdaysAvailabilityDirect(account))
    })
    .catch((err) => {
      dispatch({type: UPDATE_WEEKDAYS_AVAILABILITY_ERROR, payload: err })
    })
  }
}


export function updateAccountEmail(_id, account_email) {
  if (!_id) {
    return console.log('updateAccountEmail ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_EMAIL,
    })
    return axios.post('/api/v1/update-account-email', { _id: _id, account_email: account_email }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_EMAIL_SUCCESS,
        payload: response.data,
      })
      toast.info('Email da conta alterado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_EMAIL_ERROR, payload: err })
      toast.info('Erro ao alterar conta.')
    });
  }
}

export function updateAccountHostname(_id, account_hostname) {
  if (!_id) {
    return console.log('updateAccountHostname ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_HOSTNAME,
    })
    return axios.post('/api/v1/update-account-hostname', { _id: _id, account_hostname: account_hostname }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_HOSTNAME_SUCCESS,
        payload: response.data,
      })
      toast.info('Hostname da conta alterado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_HOSTNAME_ERROR, payload: err })
      toast.info('Erro ao alterar Hostname.')
    });
  }
}

export function updateAccountActive(_id, active) {
  if (!_id) {
    return console.log('updateAccountActive ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_ACTIVE,
    })
    return axios.post('/api/v1/update-account-active', { _id: _id, active: active }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_ACTIVE_SUCCESS,
        payload: response.data,
      })
      toast.info('Estado da conta alterado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_ACTIVE_ERROR, payload: err })
      toast.info('Erro ao alterar estado da conta.')
    });
  }
}

// widget settings

export function updateAccountWidgetBtnBgColor(_id, widget_btn_bg_color) {
  if (!_id) {
    return console.log('updateAccountWidgetBtnBgColor ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR,
    })
    return axios.post('/api/v1/update-account-widget_btn_bg_color', { _id: _id, widget_btn_bg_color: widget_btn_bg_color }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_SUCCESS,
        payload: response.data,
      })
      toast.info('Guardado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_WIDGET_BTN_BG_COLOR_ERROR, payload: err })
      toast.info('Erro na operação.')
    });
  }
}
export function updateAccountWidgetBtnTxtColor(_id, widget_btn_txt_color) {
  if (!_id) {
    return console.log('updateAccountWidgetBtnTxtColor ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR,
    })
    return axios.post('/api/v1/update-account-widget_btn_txt_color', { _id: _id, widget_btn_txt_color: widget_btn_txt_color }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_SUCCESS,
        payload: response.data,
      })
      toast.info('Guardado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_WIDGET_BTN_TXT_COLOR_ERROR, payload: err })
      toast.info('Erro na operação.')
    });
  }
}
export function updateAccountWidgetBtnIconColor(_id, widget_btn_icon_color) {
  if (!_id) {
    return console.log('updateAccountWidgetBtnIconColor ::: _id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR,
    })
    return axios.post('/api/v1/update-account-widget_btn_icon_color', { _id: _id, widget_btn_icon_color: widget_btn_icon_color }).then(response => {
      dispatch({
        type: UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_SUCCESS,
        payload: response.data,
      })
      toast.info('Guardado')
    })
    .catch(err => {
      dispatch({type: UPDATE_ACCOUNT_WIDGET_BTN_ICON_COLOR_ERROR, payload: err })
      toast.info('Erro na operação.')
    });
  }
}
export function getProductsOrder(account_id) {
  if (!account_id) {
    return console.log('getProductsOrder ::: account_id missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: GET_SHOP_PRODUCTS_ORDER,
    })
    return axios.get(`/api/v1/get-shop-products-order/${account_id}`).then(response => {
      dispatch({
        type: GET_SHOP_PRODUCTS_ORDER_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: GET_SHOP_PRODUCTS_ORDER_ERROR, payload: err })
    });
  }
}
