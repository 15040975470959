import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { PWhite } from '../../ui/elements/typography'
import { connect } from 'react-redux'
//import styled from '@emotion/styled'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import Background from '../../components_system/background'
//import Header from '../Header'
import SingleReservatioDisplay from '../widget/components/SingleReservatioDisplay'
import { CustomRoundButtonRed, CustomRoundButtonWhite } from '../../app_ui/buttons'
import { Section, Container } from '../../ui/elements/layout'
import { red_light } from '../../ui/defaults/colors'
//import { phablet_max } from '../../ui/defaults/media-queries'
import { triggerConfirmDialog } from '../../redux/dialog/actions'
import {
  getClientDataForPersonalPageByPhone,
  cancelReservationFromWidget,
  removeProductFromReservation,
} from '../../redux/shop/actions'
import { WidgetPageRoot } from '../../app_ui/layout'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    clientReservations: store.shop.clientReservations,
    shopAccount: store.shop.shopAccount,
    shopProducts: store.shop.shopProducts,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getClientDataForPersonalPageByPhone,
        cancelReservationFromWidget,
        triggerConfirmDialog,
        removeProductFromReservation,
      },
      dispatch,
    ),
  }),
)
class ClientSingleReservationManagerByPhoneWebView extends Component {
  componentDidMount() {
    const { client_phone, shop_slug } = this.props.match.params

    this.props.actions.getClientDataForPersonalPageByPhone(client_phone, shop_slug)
  }

  handleDeleteReservation = reservationId => {
    const { shopAccount } = this.props

    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja apagar esta reserva?',
      confirmText: 'Sim, apagar',
      cancelText: 'Não',
      confirmCallBack: () =>
        this.props.actions.cancelReservationFromWidget(shopAccount._id, reservationId),
    })
  }

  handleInvokeRemoveProductFromReservation = (reservationId, productId) => {
    const { shopAccount } = this.props
    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja remover este produto da reserva?',
      confirmText: 'Sim, remover',
      cancelText: 'Cancelar operação',
      confirmCallBack: () =>
        this.props.actions.removeProductFromReservation(shopAccount._id, reservationId, productId),
    })
  }

  prepareProductsData = clientReservation => {
    for (const index in clientReservation.products) {
      const prodEntry = clientReservation.products[index]
      const newProduct = this.props.shopProducts.find(product => product._id === prodEntry._id)
      clientReservation.products[index].title_pt = newProduct.title_pt
      clientReservation.products[index].image_url = newProduct.image_url
    }
    return clientReservation
  }

  render() {
    const { client_phone, shop_slug, reservation_id } = this.props.match.params

    const { shopAccount, clientReservations } = this.props
    if (!clientReservations || !shopAccount) return null
    const reservationInURL = clientReservations.find(r => r._id === reservation_id)
    let preparedReservation
    if (reservationInURL) {
      preparedReservation = this.prepareProductsData(reservationInURL)
    }
    return (
      <Section>
        <Container>
          <WidgetPageRoot>
            <Helmet>
              <title>As minhas reservas | asminhasreservas.pt</title>
            </Helmet>
            <div style={{ position: 'relative' }}>
              <br />
              <br />
              <div>
                <Link
                  to={'/reservas-cliente-webview/' + shop_slug + '/' + client_phone}
                  style={{ display: 'inline-flex' }}
                >
                  <CustomRoundButtonWhite>
                    <FAIcon icon="faArrowLeft" family="light" />
                    &nbsp;Voltar para a lista
                  </CustomRoundButtonWhite>
                </Link>
                <br />
                <br />
                <div style={{ height: '2px', width: '100%', background: '#444' }} />
                <br />
              </div>
              {/* <Header
                accountLogo={shopAccount.account_logo}
                businessName={shopAccount.business_name}
                omitCloseButton={true}
                subline="As minhas reservas"
              /> */}
              {!preparedReservation && <PWhite>Não foi encontrada a reserva.</PWhite>}
              {preparedReservation && !preparedReservation.cancelled && (
                <PWhite>Detalhe da reserva:</PWhite>
              )}
              {preparedReservation && preparedReservation.cancelled && (
                <PWhite style={{ color: red_light }}>Esta reserva foi cancelada.</PWhite>
              )}
              <br />
              {preparedReservation && (
                <SingleReservatioDisplay
                  showState={!preparedReservation.cancelled}
                  cancelled={preparedReservation.cancelled}
                  reservations={[preparedReservation]}
                  handleDeleteProductFromReservation={this.handleInvokeRemoveProductFromReservation}
                  showObservations={true}
                />
              )}
              {preparedReservation && !preparedReservation.cancelled && (
                <CustomRoundButtonRed
                  className="btn2x"
                  onClick={() => this.handleDeleteReservation(reservation_id)}
                >
                  Cancelar reserva
                </CustomRoundButtonRed>
              )}
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </WidgetPageRoot>
          <Background bgColor="#242424" />
        </Container>
      </Section>
    )
  }
}

export default withRouter(ClientSingleReservationManagerByPhoneWebView)

// const ButtonsFlexDiv = styled(FlexDiv)({
//   '& .btn2x': {
//     display: 'inline-flex',
//     marginLeft: '10px',
//   },
//   [phablet_max]: {
//     flexDirection: 'column',
//     '& .btn2x': {
//       display: 'inline-flex',
//       marginLeft: '0',
//       marginRight: 'auto',
//       order: '-1',
//       marginBottom: '10px',
//     },
//   },
// })
