// import { filterAvailableProducts } from '../../utils/dayHelpers'
import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,

  GET_PRODUCTS_STORE,
  GET_PRODUCTS_STORE_SUCCESS,
  GET_PRODUCTS_STORE_ERROR,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,

  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,

  UPDATE_PRODUCT_TITLE,
  UPDATE_PRODUCT_TITLE_SUCCESS,
  UPDATE_PRODUCT_TITLE_ERROR,

  UPDATE_PRODUCT_LEAD,
  UPDATE_PRODUCT_LEAD_SUCCESS,
  UPDATE_PRODUCT_LEAD_ERROR,

  UPDATE_PRODUCT_DESCRIPTION,
  UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
  UPDATE_PRODUCT_DESCRIPTION_ERROR,

  UPDATE_PRODUCT_WEEKDAY_STOCK,
  UPDATE_PRODUCT_WEEKDAY_STOCK_SUCCESS,
  UPDATE_PRODUCT_WEEKDAY_STOCK_ERROR,

  // UPDATE_PRODUCT_STOCK_TOTAL,
  // UPDATE_PRODUCT_STOCK_TOTAL_SUCCESS,
  // UPDATE_PRODUCT_STOCK_TOTAL_ERROR,

  GET_STOCK_IN_DAY_FOR_PRODUCT,
  GET_STOCK_IN_DAY_FOR_PRODUCT_SUCCESS,
  GET_STOCK_IN_DAY_FOR_PRODUCT_ERROR,

  GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS,
  GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_SUCCESS,
  GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_ERROR,

  UPDATE_CAN_BUY_HALF,
  UPDATE_CAN_BUY_HALF_SUCCESS,
  UPDATE_CAN_BUY_HALF_ERROR,

  UPLOAD_PRODUCT_IMAGE,
  UPLOAD_PRODUCT_IMAGE_SUCCESS,
  UPLOAD_PRODUCT_IMAGE_ERROR,

  UPDATE_PRODUCT_AVAILABLE_FOR_SALE,
  UPDATE_PRODUCT_AVAILABLE_FOR_SALE_SUCCESS,
  UPDATE_PRODUCT_AVAILABLE_FOR_SALE_ERROR,

  UPDATE_PRODUCT_DAY_AVAILABILITY_STATE,
  UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_SUCCESS,
  UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_ERROR,

  GET_SHOP_PRODUCTS_ORDER,
  GET_SHOP_PRODUCTS_ORDER_SUCCESS,
  GET_SHOP_PRODUCTS_ORDER_ERROR,

  ORDER_SHOP_PRODUCT,
  ORDER_SHOP_PRODUCT_SUCCESS,
  ORDER_SHOP_PRODUCT_ERROR,

  // RESET_RESERVATIONS_COUNTERS,
  // RESET_RESERVATIONS_COUNTERS_SUCCESS,
  // RESET_RESERVATIONS_COUNTERS_ERROR,
} from '../action-types'

// import isEmpty from '../../validation/is-empty'

export default function reducer (state={
    products: null,
    productsStore: null,
    storeAccount: null,
    loading: false,
    loadingStock: false,
    loadingActiveProductsStock: false,
    activeProductsStock: null,
    shop_products_order: null,
    productOutOfStock: null,
    stocks: {},
  }, action) {

  switch(action.type)
  {
    case ORDER_SHOP_PRODUCT: { return { ...state, loading: true } }
    case ORDER_SHOP_PRODUCT_ERROR: { return { ...state, loading: false} }
    case ORDER_SHOP_PRODUCT_SUCCESS: {
      return {
        ...state,
        // products: action.payload || [] ,
        storeAccount: action.payload,
        shop_products_order: action.payload.shop_products_order,
        loading: false,
      }
    }
    case GET_PRODUCTS: { return { ...state, loading: true } }
    case GET_PRODUCTS_ERROR: { return { ...state, loading: false} }
    case GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.payload || [] ,
        loading: false,
      }
    }
    // case RESET_RESERVATIONS_COUNTERS: { return { ...state, loading: true } }
    // case RESET_RESERVATIONS_COUNTERS_ERROR: { return { ...state, loading: false} }
    // case RESET_RESERVATIONS_COUNTERS_SUCCESS: {
    //   return {
    //     ...state,
    //     products: action.payload,
    //     loading: false,
    //   }
    // }
    case GET_PRODUCTS_STORE: { return { ...state, loading: true } }
    case GET_PRODUCTS_STORE_ERROR: { return { ...state, loading: false} }
    case GET_PRODUCTS_STORE_SUCCESS: {
      return {
        ...state,
        // productsStore: filterAvailableProducts(action.payload.storeProducts),
        productsStore: action.payload.storeProducts,
        storeAccount: action.payload.storeAccount,
        loading: false,
      }
    }
    case DELETE_PRODUCT: { return { ...state, loading: true } }
    case DELETE_PRODUCT_ERROR: { return { ...state, loading: false} }
    case DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        // products: action.payload,
        products: state.products.filter(product => product._id !== action.payload),
        loading: false,
      }
    }
    case GET_SHOP_PRODUCTS_ORDER: { return { ...state, loading: true } }
    case GET_SHOP_PRODUCTS_ORDER_ERROR: { return { ...state, loading: false} }
    case GET_SHOP_PRODUCTS_ORDER_SUCCESS: {
      return {
        ...state,
        // products: action.payload,
        shop_products_order: action.payload,
        loading: false,
      }
    }
    case CREATE_PRODUCT: { return { ...state, loading: true } }
    case CREATE_PRODUCT_ERROR: { return { ...state, loading: false} }
    case CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        products: [...state.products, action.payload ],
        // products: action.payload, , // apply spread op to update specific product
        loading: false,
      }
    }
    case UPDATE_PRODUCT_TITLE: { return { ...state, loading: true } }
    case UPDATE_PRODUCT_TITLE_ERROR: { return { ...state, loading: false} }
    case UPDATE_PRODUCT_TITLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, title_pt: action.payload.title_pt } : 
          product
        )
      }
    }
    case UPDATE_PRODUCT_LEAD: { return { ...state, loading: true } }
    case UPDATE_PRODUCT_LEAD_ERROR: { return { ...state, loading: false} }
    case UPDATE_PRODUCT_LEAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, lead: action.payload.lead } : 
          product
        )
      }
    }
    case UPDATE_PRODUCT_DESCRIPTION: { return { ...state, loading: true } }
    case UPDATE_PRODUCT_DESCRIPTION_ERROR: { return { ...state, loading: false} }
    case UPDATE_PRODUCT_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, description: action.payload.description } : 
          product
        )
      }
    }
    case UPDATE_PRODUCT_WEEKDAY_STOCK: { return { ...state, loading: true } }
    case UPDATE_PRODUCT_WEEKDAY_STOCK_ERROR: { return { ...state, loading: false} }
    case UPDATE_PRODUCT_WEEKDAY_STOCK_SUCCESS: {
      const updateObj = {}
      updateObj[action.payload.weekday_key] = action.payload.value
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, ...updateObj } : 
          product
        )
      }
    }
    case GET_STOCK_IN_DAY_FOR_PRODUCT: { return { ...state, loadingStock: true } }
    case GET_STOCK_IN_DAY_FOR_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        loadingStock: false,
        productOutOfStock: action.payload.productOutOfStock,
        message: action.payload.message,
      }
    }
    case GET_STOCK_IN_DAY_FOR_PRODUCT_SUCCESS: {
      const updatedStocks = { ...state.stocks }
      updatedStocks[action.payload.productId] = action.payload.availableStock
      return {
        ...state,
        loadingStock: false,
        stocks: updatedStocks
      }
    }

    case GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS: {
      return {
        ...state,
        loadingActiveProductsStock: true
      }
    }
    case GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_ERROR: {
      return {
        ...state,
        activeProductsStock: null,
        loadingActiveProductsStock: false,
      }
    }
    case GET_STOCK_IN_DAY_FOR_ACTIVE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        activeProductsStock: action.payload,
        loadingActiveProductsStock: false,
      }
    }

    case UPDATE_CAN_BUY_HALF: { return { ...state, loading: true } }
    case UPDATE_CAN_BUY_HALF_ERROR: { return { ...state, loading: false} }
    case UPDATE_CAN_BUY_HALF_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, can_sell_half: action.payload.can_sell_half } : 
          product
        )
      }
    }
    case UPLOAD_PRODUCT_IMAGE: { return { ...state, loading: true } }
    case UPLOAD_PRODUCT_IMAGE_ERROR: { return { ...state, loading: false} }
    case UPLOAD_PRODUCT_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, image_url: action.payload.image_url } : 
          product
        )
      }
    }
    case UPDATE_PRODUCT_AVAILABLE_FOR_SALE: { return { ...state, loading: true } }
    case UPDATE_PRODUCT_AVAILABLE_FOR_SALE_ERROR: { return { ...state, loading: false} }
    case UPDATE_PRODUCT_AVAILABLE_FOR_SALE_SUCCESS: {
      return {
        ...state,
        loading: false,
        shop_products_order: action.payload.shop_products_order,
        products: state.products.map(product => product._id === action._id ?
          { ...product, sale_active: action.payload.sale_active } : 
          product
        )
      }
    }
    case UPDATE_PRODUCT_DAY_AVAILABILITY_STATE: { return { ...state, loading: true } }
    case UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_ERROR: { return { ...state, loading: false} }
    case UPDATE_PRODUCT_DAY_AVAILABILITY_STATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: state.products.map(product => product._id === action.payload._id ?
          { ...product, weekdays_availability: action.payload.weekdays_availability } : 
          product
        )
      }
    }
    default:
      return state
  }
}

