// const { weekdaysArray } = require('../values')
import {
  getURLFormattedDate
} from './date'
import {
  addDays,
  // getHours,
  isBefore,
  isSameDay,
  format,
  startOfMonth,
  getDaysInMonth,
  parseISO,
  startOfDay,
  // startOfWeek,
  // addWeeks,
  differenceInDays,
  // isAfter,
  // setHours,
  addHours,
  // isToday,
  // isTomorrow,
  isPast,
  // isSameDay
} from 'date-fns'

const DAY_RESERVATION_HOUR_LIMIT = 12
const DAY_RESERVATION_HOUR_LIMIT_MORNING = 9
// const DAY_RESERVATION_HOUR_LIMIT_PICKUP_AFTERNOON = 18
// const DAY_RESERVATION_HOUR_LIMIT_PICKUP_MORNING = 12

const dayColors = {
  available: {
    txt: '#242424',
    bg: '#eeeeee',
  },
  unavailable: {
    txt: '#d2d2d2',
    bg: '#f6f6f6',
  },
  selected: {
    txt: 'white',
    bg: '#242424',
  },
  unselected: {
    txt: '#242424',
    bg: '#eeeeee',
  },
}

function outputStateName(state) {
  return state
}

function getTodayDateFunction() {
  const nowDate = new Date()
  // nowDate.setDate(nowDate.getDate() - 3) // for testing
  // const todayWeekdayName = format(nowDate, "EEEE")
  // console.log('returning dayDate today as : ' + todayWeekdayName)
  return nowDate
}

function getDayStateFunction(account, dayDate, cartProduct, selectedDay) {
  // console.log('--------getDayStateFunction')
  // console.log('cartProduct:')
  // console.dir(cartProduct)
  // console.log('selectedDay:')
  // console.dir(selectedDay)
  // console.log('dayDate:')
  // console.dir(dayDate)
  // console.log('getDayStateFunction() -----------------------')


  // selected day is same weekday
  if (selectedDay && isSameDay(dayDate, selectedDay)) return outputStateName('selected')

  // console.log('dayDate: ' + dayDate)

  const weekdayName = format(dayDate, 'EEEE')
  // console.log('weekdayName: ' + weekdayName)
  
  const availabilityFromProduct = cartProduct[`stock_${weekdayName.toLowerCase()}`]
  // console.log('availabilityFromProduct: ' + availabilityFromProduct)
  
  if (!availabilityFromProduct) return outputStateName('unavailable')
  
  const hasAfternoonShift = account.weekdays_availability[weekdayName.toLowerCase()].afternoon
  // console.log('hasAfternoonShift: ' + hasAfternoonShift)
  
  const hourLimit = hasAfternoonShift ? DAY_RESERVATION_HOUR_LIMIT : DAY_RESERVATION_HOUR_LIMIT_MORNING
  // console.log('hourLimit: ' + hourLimit)

  dayDate = addHours(startOfDay(dayDate), hourLimit)
  // console.log('dayDate: ' + dayDate)
  
  const resIsInPast = isPast(dayDate)
  // console.log('resIsInPast: ' + resIsInPast)

  if (resIsInPast) {
    // console.log('is in past: ' + dayDate)
    return outputStateName('unavailable')
  }
  // let selectedDayFormatted = selectedDay ? format(selectedDay, 'EEEE') : null
  // console.log('selectedDayFormatted: ' + selectedDayFormatted)

  // is available but not selected
  if (selectedDay && !isSameDay(dayDate, selectedDay)) return outputStateName('unselected')
  // show available
  return outputStateName('available')
}

function isOpenAtLeastOneDay(account) {
  if(account.opening_days.monday) { return true }
  if(account.opening_days.tuesday) { return true }
  if(account.opening_days.wednesday) { return true }
  if(account.opening_days.thursday) { return true }
  if(account.opening_days.friday) { return true }
  if(account.opening_days.saturday) { return true }
  if(account.opening_days.sunday) { return true }
  return false
}

function getTodayWeekNameFunction() {
  const nowDate = getTodayDateFunction()
  const todayWeekdayName = format(nowDate, "EEEE")
  return todayWeekdayName
}

function getLastOpenWeekdayNameFunction(account) {
  const opening_days = account.opening_days
  const days = []
  if (opening_days.monday) { days.push('Monday') }
  if (opening_days.tuesday) { days.push('Tuesday') } 
  if (opening_days.wednesday) { days.push('Wednesday') } 
  if (opening_days.thursday) { days.push('Thursday') }
  if (opening_days.friday) { days.push('Friday') }
  if (opening_days.saturday) { days.push('Saturday') }
  if (opening_days.sunday) { days.push('Sunday') }
  if (days.length > 0) {
    const lastWeekdayName = days[days.length-1]
    // console.log('lastWeekdayName: ' + lastWeekdayName)
    return lastWeekdayName
  } else {
    return null
  }
}







/*###########*/
/*###########*/
/*###########*/
/*###########*/
/*###########*/
/*###########*/
/*###########*/
/*###########*/
/*###########*/






export const getDayState = (account, dayDate, cartProduct, selectedDay, offset) => {
  return getDayStateFunction(account, dayDate, cartProduct, selectedDay, offset)
}

export const getTodayDate = () => {
  return getTodayDateFunction()
}

export const isBeforeToday = (date) => {
  return isBefore( parseISO(date), getTodayDateFunction() )
}

export const getCurrentTime = () => {
  const nowDate = getTodayDateFunction()
  const todayWeekdayTime = format(nowDate, "H")
  return parseInt(todayWeekdayTime)
}

export const getTodayWeekName = () => {
  return getTodayWeekNameFunction()
}

export const getMonthDayNumber = (dayDate) => {
  let dateValue
  if (typeof dayDate === 'string') {
    dayDate = parseISO(dayDate)
  }
  dateValue = format(dayDate, "dd")
  if (dateValue.length === 2 && dateValue.charAt(0) === '0') {
    dateValue = dateValue.charAt(1)
  }
  return dateValue
}

export const getLastOpenWeekdayName = (account) => {
  return getLastOpenWeekdayNameFunction(account)
}

export const getBusinessDaysArrayForward = (storeAccount) => {
  let initialNowDate = new Date()
  let nowDate = initialNowDate
  let firstValidDay = false
  if (!isOpenAtLeastOneDay(storeAccount)) return []
  while(firstValidDay === false) {
    nowDate = addDays(nowDate, 1)
    const nowDateDayName = format(nowDate, 'EEEE')
    const nowDateDayNameLowerCase = nowDateDayName.toLowerCase()

    const doesMorningThisDay = storeAccount.weekdays_availability[nowDateDayNameLowerCase].morning
    const doesAfternoonThisDay = storeAccount.weekdays_availability[nowDateDayNameLowerCase].afternoon
    
    if (doesMorningThisDay || doesAfternoonThisDay) {
      firstValidDay = nowDate
    }
  }

  nowDate = firstValidDay
  
  const businessDaysArray = []
  while(businessDaysArray.length < 5 && differenceInDays(nowDate, initialNowDate) < 8) {
    const dayName = format(nowDate, 'EEEE')
    const dayNameLowerCase = String(dayName).toLowerCase()

    const doesMorningThisDay = storeAccount.weekdays_availability[dayNameLowerCase].morning
    const doesAfternoonThisDay = storeAccount.weekdays_availability[dayNameLowerCase].afternoon

    let validDate = null

    if (doesMorningThisDay || doesAfternoonThisDay) {
      validDate = nowDate
    }

    if (validDate) {
      businessDaysArray.push({
        dayDate: nowDate,
        yearMonthDay: getURLFormattedDate(nowDate),
        dayName: dayName,
        day_availability: storeAccount.weekdays_availability[dayNameLowerCase],
      })
    }

    nowDate = addDays(nowDate, 1)
  }

  return businessDaysArray
}

export const getBusinessDaysArrayFromStartDateSimple = (storeAccount, customStartDate) => {
  const weekDates = []
  const startDate = customStartDate
  let count = 0
  while(weekDates.length <= 6) {
    const currentDate = count === 0 ? startDate : addDays(startDate, count)
    // if current date is === Monday and weekdates already has > 0 item
    const weekdayName = format(currentDate, 'EEEE')
    if (weekdayName === 'Monday' && weekDates.length > 0) {
      break
    }
    weekDates.push({
      dayName: weekdayName,
      dayDate: currentDate,
      monthDayNumber: currentDate.getDate(), 
      monthNumber: currentDate.getMonth() + 1,
      monthName: format(currentDate, 'MMMM'),
      yearMonthDay: getURLFormattedDate(currentDate),
    })
    count++
  }

  return weekDates
}

export const getBusinessDaysArrayFromStartDateSimpleB = (storeAccount, customStartDate) => {
  const weekDates = []
  const startDate = customStartDate
  let count = 0
  const numOpenDays = getAccountOpenDays(storeAccount).length
  const hasOpenDays = numOpenDays > 0
  if (hasOpenDays) {
    while(weekDates.length < numOpenDays) {
      const currentDate = count === 0 ? startDate : addDays(startDate, count)
      // if current date is === Monday and weekdates already has > 0 item // obsolete ???
      const weekdayName = format(currentDate, 'EEEE')
      const weekdayNameLowercase = weekdayName.toLowerCase()
      const shouldHaveThisDay = storeAccount.opening_days[weekdayNameLowercase]
      if (shouldHaveThisDay) {
        weekDates.push({
          dayName: weekdayName,
          dayDate: currentDate,
          monthDayNumber: currentDate.getDate(), 
          monthNumber: currentDate.getMonth() + 1,
          monthName: format(currentDate, 'MMMM'),
          yearMonthDay: getURLFormattedDate(currentDate),
        })
      }
      count++
    }
  }

  return weekDates
}

export const getBusinessDaysArrayFromStartDateAndReservations = (storeAccount, viewingWeekDates, reservations) => {
  const weekDates = []
  for(const date of viewingWeekDates) {
    const reservationInDay = reservations.find(res => res.yearMonthDay === date.yearMonthDay)
    const weekdayName = format(date.dayDate, 'EEEE')
    const weekdayNameLowercase = weekdayName.toLowerCase()
    const shouldHaveThisDay = storeAccount.opening_days[weekdayNameLowercase]
    if (reservationInDay) {
      weekDates.push({
        isOpen: shouldHaveThisDay,
        dayName: weekdayName,
        dayDate: date.dayDate,
        monthDayNumber: date.dayDate.getDate(), 
        monthNumber: date.dayDate.getMonth() + 1,
        monthName: format(date.dayDate, 'MMMM'),
        yearMonthDay: getURLFormattedDate(date.dayDate),
      })
    }
  }
  return weekDates
}


export const getMonthDaysFromAnyDate = (anyDate, openDaysOnly, storeAccount) => {
  const monthDates = []
  const firstDayOfMonth = startOfMonth(anyDate)
  const numDaysInMonth = getDaysInMonth(firstDayOfMonth)
  let count = 0
  while(count <= numDaysInMonth) {
    const currentDate = addDays(firstDayOfMonth, count)
    const weekdayName = format(currentDate, 'EEEE')
    const skipThisDay = openDaysOnly && !storeAccount.opening_days[weekdayName.toLowerCase()]
    if (!skipThisDay) {
      monthDates.push({
        dayName: weekdayName,
        dayDate: currentDate,
        monthDayNumber: currentDate.getDate(), 
        monthNumber: currentDate.getMonth() + 1,
        monthName: format(currentDate, 'MMMM'),
        yearMonthDay: getURLFormattedDate(currentDate),
      })
    }
    count++
  }

  return monthDates
}

export const getDayColor = (buttonState, type) => {
  return dayColors[buttonState][type]
}

export const getAccountOpenDays = (account) => {
  const openDays = []
  if(account.opening_days.monday) { openDays.push('Monday') }
  if(account.opening_days.tuesday) { openDays.push('Tuesday') }
  if(account.opening_days.wednesday) { openDays.push('Wednesday') }
  if(account.opening_days.thursday) { openDays.push('Thursday') }
  if(account.opening_days.friday) { openDays.push('Friday') }
  if(account.opening_days.saturday) { openDays.push('Saturday') }
  if(account.opening_days.sunday) { openDays.push('Sunday') }
  return openDays
}
