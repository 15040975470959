import styled from '@emotion/styled'
import { green_shiny } from '../../defaults/colors'

export const Checkbox = styled.div(props => ({
  borderRadius: '3px',
  cursor: 'pointer',
  height: '18px',
  width: '18px',
  border: props.checkActive ? 'none' : '2px solid #ccc',
  background: props.checkActive ? green_shiny : 'white',
  position: 'relative',
  '& svg': {
    color: 'white',
    fontSize: '10px',
    position: 'absolute',
    top: '4px',
    left: '4px',
    opacity: props.checkActive ? '1' : '0',
  }
}))
