import axios from 'axios'
import setAuthorizationToken from '../utils/setAuthorizationToken'
import { toast } from 'react-toastify'
import {
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  SAVE_CLIENT,
  SAVE_CLIENT_SUCCESS,
  SAVE_CLIENT_ERROR,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
} from '../action-types'

const tokenName = 'reservasToken'

export function getClient(accountId, id) {
  return dispatch => {
    dispatch({
      type: GET_CLIENT,
    })
    axios.get(`/api/v1/get-client/${accountId}/${id}`, {
      withCredentials: false,
    }).then((response) => {
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_CLIENT_ERROR, payload: err })
    })
  }
}

export function saveClient(accountId, client) {
  if (!accountId) { return console.log('accountId missing') }
  if (!client) { return console.log('client missing') }

  return dispatch => {
    dispatch({ type: SAVE_CLIENT })
    axios.post(`/api/v1/save-client/${accountId}`, { client: client }, {
      withCredentials: false,
    }).then((response) => {
      dispatch({
        type: SAVE_CLIENT_SUCCESS,
        payload: response.data,
      })
      toast.info('Cliente actualizado!')
    })
    .catch((err) => {
      dispatch({type: SAVE_CLIENT_ERROR, payload: err.response.data })
      toast.error('Erro ao actualizar cliente!!')
    })
  }
}

export function getClients(accountId) {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: GET_CLIENTS,
    })
    axios.get(`/api/v1/get-clients/${accountId}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: GET_CLIENTS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_CLIENTS_ERROR, payload: err })
    })
  }
}

export function deleteClient(accountId, shortId) {
  
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: DELETE_CLIENT,
    })
    axios.delete(`/api/v1/delete-client/${accountId}/${shortId}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: DELETE_CLIENT_SUCCESS,
        payload: response.data,
      })
      toast.info('Cliente removido')
    })
    .catch((err) => {
      dispatch({type: DELETE_CLIENT_ERROR, payload: err })
      toast.info('Erro ao remover cliente.')
    })
  }
}

