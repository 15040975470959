import React, { useState } from 'react'
import styled from '@emotion/styled'
import Spinner from '../../../../ui/components/spinner'
import { H6, H4, PSmall } from '../../../../ui/elements/typography'
import { WhiteBox, FlexDiv } from '../../../../ui/elements/layout'
import {
  red_light,
  blue_shiny,
  orange_gold,
  orange,
  green_shiny,
} from '../../../../ui/defaults/colors'
import { tablet_max, phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import { weekDateIsInPast } from '../../../../utils/date'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

const ReservationBox = ({
  _id,
  state,
  dailyCountId,
  index,
  time,
  cancelled,
  disableLeft,
  disableRight,
  updated,
  name,
  telephone,
  history,
  observations,
  products,
  data,
  handleMoveStateForward,
  handleMoveStateBackward,
  handleDeleteReservation,
  movingStateReservationId,
  style,
  weekDate,
}) => {
  const [active, setActive] = useState(false)
  const inThePast = weekDateIsInPast(weekDate)
  return (
    <RootDiv active={active} cancelled={cancelled} style={style}>
      {state === 'requested' && <StateIndicatorRequested cancelled={cancelled} />}
      {state === 'prepared' && <StateIndicatorPrepared cancelled={cancelled} />}
      {state === 'finalised' && <StateIndicatorFinalised cancelled={cancelled} />}
      <MobileOnly className="MobileOnly">
        <ArrowLeft onClick={() => handleMoveStateBackward(_id, state)} disabled={disableLeft}>
          <FAIcon icon="faChevronLeft" family="solid" />
        </ArrowLeft>
        <ArrowRight onClick={() => handleMoveStateForward(_id, state)} disabled={disableRight}>
          <FAIcon icon="faChevronRight" family="solid" />
        </ArrowRight>
      </MobileOnly>
      <FlexDiv className="CenterFlexDiv" style={{ position: 'relative', width: '100%' }}>
        <DesktopOnly>
          <ArrowLeft onClick={() => handleMoveStateBackward(_id, state)} disabled={disableLeft}>
            <FAIcon icon="faChevronLeft" family="solid" />
          </ArrowLeft>
        </DesktopOnly>
        <CenterContent className="center-content" onClick={() => setActive(!active)}>
          <FlexDiv style={{ width: '100%', justifyContent: 'space-between' }}>
            <FlexDiv style={{ marginBottom: '0' }}>
              <H6 style={{ fontWeight: 'bold' }}>
                <NumberSpan>#</NumberSpan>
                {dailyCountId}
              </H6>
              {observations && observations.length && observations.length > 0 && (
                <FAIcon
                  icon="faComment"
                  family="solid"
                  style={{
                    color: '#444',
                    marginLeft: '8px',
                    position: 'relative',
                    top: '-1px',
                  }}
                />
              )}
            </FlexDiv>
            <div>{movingStateReservationId === _id && <Spinner color="black" radius={20} />}</div>
          </FlexDiv>
          <PSmall style={{ marginBottom: '8px', fontWeight: 'bold' }}>{name}</PSmall>
          {!active && (
            <div>
              {products.map((product, index) => (
                <PSmall key={index}>
                  <span style={{ fontWeight: 'bold' }}>{product.amount}</span> {product.title_pt}
                </PSmall>
              ))}
            </div>
          )}
          {active && (
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              {products.map((product, index) => (
                <H4 key={index} style={{ fontWeight: '300' }}>
                  <span style={{ fontWeight: 'bold' }}>{product.amount}</span> {product.title_pt}
                </H4>
              ))}
            </div>
          )}
          {observations && observations.length > 0 && active && (
            <div>
              <PSmall style={{ fontStyle: 'italic', color: '#999999', marginTop: '10px' }}>
                "{observations}"
              </PSmall>
            </div>
          )}
          {active && telephone && telephone.length > 0 && (
            <FlexDiv style={{ marginTop: '12px', alignItems: 'center' }}>
              <PSmall style={{ marginTop: '2px !important' }}>
                Telefone <span style={{ color: '#999999' }}>{telephone}</span>
              </PSmall>
            </FlexDiv>
          )}
          {active && !updated && !cancelled && (
            <PSmall style={{ color: '#999999', marginTop: '2px !important' }}>
              Criada às {time}h
            </PSmall>
          )}
          {active && updated && !cancelled && (
            <PSmall style={{ color: '#999999', marginTop: '2px !important' }}>
              <span style={{ color: orange }}>Actualizada</span> às {time}h
            </PSmall>
          )}
          {active && cancelled && (
            <PSmall style={{ color: '#999999', marginTop: '2px !important' }}>
              <span style={{ color: red_light }}>Cancelada</span> às {time}h
            </PSmall>
          )}
          {/*cancelled && active &&
            <DeletePSmall onClick={() => handleDeleteReservation(_id)}>
              ARQUIVAR
            </DeletePSmall>
          */}
          {active && !inThePast && (
            <EditPSmall onClick={() => history.push(`/editar-reserva/${_id}/${weekDate}`)}>
              EDITAR
            </EditPSmall>
          )}
        </CenterContent>
        <DesktopOnly className="DesktopOnly">
          <ArrowRight onClick={() => handleMoveStateForward(_id, state)} disabled={disableRight}>
            <FAIcon icon="faChevronRight" family="solid" />
          </ArrowRight>
        </DesktopOnly>
      </FlexDiv>
    </RootDiv>
  )
}

const RootDiv = styled(WhiteBox)(props => ({
  background: props.cancelled ? '#fff7f7' : 'white',
  boxShadow: 'none',
  marginBottom: '10px',
  borderRadius: '5px',
  // alignSelf: 'flex-start',
  position: 'relative',
  width: '100%',
  '& .center-content': {
    opacity: props.cancelled && !props.active ? '0.3' : '1',
  },
  '& .fa-mobile-alt': {
    color: '#becde2',
    fontSize: '16px',
  },
  '& .fa-comment': {
    color: '#becde2',
    fontSize: '16px',
  },
}))

const CenterContent = styled.div({
  width: 'calc(100% - 80px)',
  padding: '10px 20px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& p': {
    margin: '0 !important',
  },
  [tablet_max]: {
    width: '100%',
    padding: '8px 16px',
  },
  [phablet_max]: {
    padding: '6px 12px',
    '& .fa-mobile-alt': {
      fontSize: '10px',
    },
  },
  [phone_max]: {
    padding: '4px 8px',
    '& p': {
      fontSize: '9px',
    },
  },
})

const Arrow = styled.div(props => ({
  width: '40px',
  height: '100%',
  background: '#F5F5F5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '& svg': {
    opacity: props.disabled ? 0.1 : '1',
  },
  ':hover': {
    background: props.disabled ? '#F5F5F5' : '#f2f1f1',
  },
  ':active': {
    background: props.disabled ? '#F5F5F5' : '#eae6e6',
  },
  [tablet_max]: {
    height: '26px',
    width: '50%',
    '& svg': {
      fontSize: '16px',
    },
  },
}))

const ArrowLeft = styled(Arrow)({
  left: '0',
  borderRadius: '0 0 0 5px',
})

const ArrowRight = styled(Arrow)({
  right: '0',
  borderRadius: '0 0 5px 0',
})

// const DeletePSmall = styled(PSmall)({
//   lineHeight: '130%',
//   color: red_light,
//   width: '100px',
//   marginLeft: '0',
//   fontWeight: 'bold',
//   marginTop: '10px',
// })

const EditPSmall = styled(PSmall)({
  lineHeight: '130%',
  color: blue_shiny,
  width: '100px',
  marginLeft: '0',
  fontWeight: 'bold',
  marginTop: '10px',
})

const DesktopOnly = styled.div({
  display: 'block',
  [tablet_max]: {
    display: 'none',
  },
})

const MobileOnly = styled.div({
  display: 'none',
  [tablet_max]: {
    display: 'flex',
    '& > div': {
      width: '50%',
    },
  },
})

const NumberSpan = styled.span({
  color: '#becde2',
  fontWeight: '300',
  marginRight: '3px',
  position: 'relative',
  top: '1px',
})

const StateIndicator = styled.div({
  borderRadius: '5px 5px 0 0',
  height: '6px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  '& svg': {
    fontSize: '16px',
    color: 'white',
    marginTop: '12px',
  },
  [tablet_max]: {
    height: '6px',
  },
  [phablet_max]: {
    height: '4px',
  },
  [phone_max]: {
    height: '2px',
  },
})
const StateIndicatorRequested = styled(StateIndicator)(props => ({
  background: props.cancelled ? red_light : blue_shiny,
}))
const StateIndicatorPrepared = styled(StateIndicator)(props => ({
  background: props.cancelled ? red_light : orange_gold,
}))
const StateIndicatorFinalised = styled(StateIndicator)(props => ({
  background: props.cancelled ? red_light : green_shiny,
}))

export default ReservationBox
