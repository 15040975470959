import React, { Component } from 'react'
import { FlexDiv } from '../../ui/elements/layout'
import { CustomRoundButtonWhite, CustomRoundButtonCancel } from '../../app_ui/buttons'
import { PWhite } from '../../ui/elements/typography'
import CartDisplayByDay from './components/CartDisplayByDay'

class WidgetConfirmCartComponent extends Component {

  render() {
    const {
      numReservationsInCart,
      reservations,
      handleRemoveReservationFromCart,
      handleGotoToClientDetailsForm,
      handleGotoToProductSelection
    } = this.props
    
    return (
      <div>
        <PWhite>O seu saco: ({numReservationsInCart} reserva{ numReservationsInCart === 1 ? '' : 's'})</PWhite>
        <br />
        <CartDisplayByDay
          reservations={reservations}
          handleRemoveReservationFromCart={handleRemoveReservationFromCart}
          lowerCase />
        <br />
        <br />
        <FlexDiv style={{width: '100%'}}>
          { numReservationsInCart !== 0 &&
            <CustomRoundButtonWhite onClick={handleGotoToClientDetailsForm} style={{marginRight: '10px'}}>
              Avançar
            </CustomRoundButtonWhite>
          }
          <CustomRoundButtonCancel onClick={handleGotoToProductSelection} style={{marginRight: '10px'}}>
            Fazer mais compras
          </CustomRoundButtonCancel>
        </FlexDiv>
      </div>
    )
  }
}

export default WidgetConfirmCartComponent
