import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-util'
// import { history, BrowserRouter } from 'react-router-util'
import LayoutRoot from './LayoutRoot'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';
// import { historyUpdate } from '../redux/system/actions'
import { verifyToken } from '../redux/user/actions'
import { setViewportDimentions } from '../redux/system/actions'

export default @connect(
  store => ({
    user: store.user.user,
    mobileNavOpen: store.system.mobileNavOpen,
  }),
  dispatch => ({
    actions: bindActionCreators({
      // historyUpdate: systemActions.historyUpdate,
      verifyToken,
      setViewportDimentions
    }, dispatch),
  }),
)
class App extends Component {

  componentDidMount() {
    // this.props.actions.historyUpdate(history.location);
    // history.listen((location) => {
    //   this.props.actions.historyUpdate(location);
    // })
    // do not check login in Loja route
    const isLoja = window.location.pathname.indexOf('/shop') > -1
    if (!isLoja) {
      this.props.actions.verifyToken()
    }

    // var myDiv = $('.myDiv');
    // myDiv.css('-webkit-overflow-scrolling','auto');
    // function fn(){
    //     myDiv.css('-webkit-overflow-scrolling','touch');
    // }
    // setTimeout(fn,500);
    window.addEventListener('resize', this.props.actions.setViewportDimentions)
    this.props.actions.setViewportDimentions()
  }

  componentDidUpdate() {
    const isLoja = window.location.pathname.indexOf('/shop') > -1
    if (!isLoja) {
      this.props.actions.verifyToken()
    }
  }

  render() {
    const isLoja = window.location.pathname.indexOf('/shop') > -1
    return (
      <BrowserRouter>
        {!isLoja &&
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        }
        <LayoutRoot {...this.props} />
      </BrowserRouter>
    )
  }
}
