import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'
import Spinner from '../../../../ui/components/spinner'
import { Content, ContentShortV } from '../../../../ui/elements/layout'
import { P, PSmall, H6, H4, H3 } from '../../../../ui/elements/typography'
import { grey_border, green_shiny, red_light, blue_shiny } from '../../../../ui/defaults/colors'
import { phablet_max } from '../../../../ui/defaults/media-queries'
import { translateEnglishWeekdayName } from '../../../../utils/translation'
import { onlyDecimalNumbersArray } from '../../../../utils/inputs'
import ProductAmountSelector from '../../../widget/components/ProductAmountSelector'
import { capitaliseFirstLetter } from '../../../../utils/string'
// actions
import {
  getReservation,
  deleteReservation,
  cancelReservation,
  unCancelReservation,
  saveReservation,
} from '../../../../redux/reserva/actions'
import { getProductsStore, getStockInDayForActiveProducts } from '../../../../redux/product/actions'
import { triggerConfirmDialog } from '../../../../redux/dialog/actions'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    productsStore: store.product.productsStore,
    activeProductsStock: store.product.activeProductsStock,
    editingReservation: store.reserva.editingReservation,
    loadingEditingReservation: store.reserva.loadingEditingReservation,
    mobileNavOpen: store.system.mobileNavOpen,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getReservation,
        saveReservation,
        getProductsStore,
        deleteReservation,
        triggerConfirmDialog,
        cancelReservation,
        unCancelReservation,
        getStockInDayForActiveProducts,
      },
      dispatch,
    ),
  }),
)
class ReservationEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      requestedProducts: false,
      requestedReservation: false,
      updatingReservationState: false,
      editingReservation: null,
      selectedProductTitlePt: null,
      initialReservationStateProducts: null,
      updatedProducts: [],
    }
    this.productSelectRef = React.createRef()
    this.unconfirmedProductInputRef = React.createRef()
  }

  initFunction = () => {
    const { user, match, editingReservation, productsStore } = this.props
    const { requestedProducts, updatingReservationState } = this.state

    // get user if not present
    const hasUser = user && user.account && user.account._id
    if (!hasUser) return null

    // get productsStore if not present
    if (!productsStore && requestedProducts) return null
    if (!productsStore && !requestedProducts) {
      this.props.actions.getProductsStore(user.accountAppId)
      this.setState({
        requestedProducts: true,
      })
    }

    // get editing reservation if not present
    if (!editingReservation && !this.state.requestedReservation) {
      this.props.actions.getReservation(user.account._id, match.params.reservation_id)
      this.setState({
        requestedReservation: true,
      })
    } else {
      // test
      if (
        !this.state.started &&
        this.props.editingReservation &&
        this.props.editingReservation.products
      ) {
        const originalProductsArray = []
        for (const p of this.props.editingReservation.products) {
          originalProductsArray.push({
            _id: p._id,
            amount: p.amount,
            title_pt: p.title_pt,
          })
        }
        this.setState({
          editingReservation: this.props.editingReservation,
          initialReservationStateProducts: originalProductsArray,
          started: true,
        })
        this.timerID = setInterval(() => this.getActiveProductsStock(), 3000)
      }
    }

    if (updatingReservationState) {
      const updatedDatesDiffer =
        this.props.editingReservation.date_updated !== this.state.editingReservation.date_updated
      if (updatedDatesDiffer) {
        this.setState({
          updatingReservationState: false,
          editingReservation: this.props.editingReservation,
          // initialReservationStateProducts: this.props.editingReservation.products,
        })
      }
    }
  }

  componentDidMount() {
    this.initFunction()
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID)
    }
  }

  componentDidUpdate() {
    this.initFunction()
    if (this.state.editingReservation && !this.props.activeProductsStock) {
      this.getActiveProductsStock()
    }
  }

  handleAddProduct = () => {
    const { editingReservation } = this.state
    const updatedReservation = editingReservation
    updatedReservation.products.push({ amount: 1 })
    const productsToShow = this.getProductsNotAlreadyInReservation()
    this.setState({
      selectedProductTitlePt: productsToShow[0].title_pt,
      editingReservation: updatedReservation,
    })
  }

  handleNumInStockKeyDown = (e, key) => {
    if (onlyDecimalNumbersArray.indexOf(e.key) === -1) {
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }

  renderProductImage = imageUrl => {
    if (imageUrl) {
      return <img src={imageUrl} alt="Imagem do produto" />
    }
    return null
  }

  getActiveProductsStock = product => {
    const { user } = this.props
    const { editingReservation } = this.state
    this.props.actions.getStockInDayForActiveProducts(
      user.account._id,
      editingReservation.yearMonthDay,
      editingReservation.dayName,
    )
  }

  onChangeProductSelect = e => {
    this.setState({
      selectedProductTitlePt: e.target.value,
    })
  }

  getProductsNotAlreadyInReservation = () => {
    const { editingReservation } = this.state
    const { productsStore } = this.props
    // const dayNameLC = editingReservation.dayName.toLowerCase()
    const productsToShow = []
    for (const product of productsStore) {
      // if (product.weekdays_availability[dayNameLC] === true) {
      // if (product['stock_' + dayNameLC] > 0) {
      const prodInRes = editingReservation.products.find(
        productInRes => productInRes._id === product._id,
      )
      if (!prodInRes) {
        productsToShow.push(product)
      }

      // }
    }

    return productsToShow
  }

  renderProductSelector = () => {
    const productsToShow = this.getProductsNotAlreadyInReservation()
    return (
      <select ref={this.productSelectRef} onChange={this.onChangeProductSelect}>
        {productsToShow.map((product, index) => {
          return <option key={index}>{product.title_pt}</option>
        })}
      </select>
    )
  }

  renderProductSelectorOK = product => {
    if (!product.amount || product.amount === 0) {
      return null
    }
    return (
      <button className="confirm-add-product" onClick={() => this.handleConfirmAddProduct(product)}>
        <FAIcon icon="faCheck" family="solid" />
      </button>
    )
  }

  handleConfirmAddProduct = product => {
    const { editingReservation, selectedProductTitlePt } = this.state
    const { productsStore } = this.props

    const addProductAmount = product.amount
    const productToAdd = productsStore.find(product => product.title_pt === selectedProductTitlePt)
    const updatedReservation = editingReservation

    for (var i = 0; i < updatedReservation.products.length; i++) {
      if (!updatedReservation.products[i]._id) {
        updatedReservation.products[i].amount = addProductAmount
        updatedReservation.products[i]._id = productToAdd._id
        updatedReservation.products[i].title_pt = productToAdd.title_pt
        updatedReservation.products[i].image_url = productToAdd.image_url
        break
      }
    }
    this.setState({
      editingReservation: updatedReservation,
    })
  }

  removeProductFromReservation(index) {
    const { editingReservation } = this.state
    const updatedReservation = editingReservation
    updatedReservation.products.splice(index, 1)
    this.setState({
      editingReservation: updatedReservation,
    })
  }

  handleChangeProductAmount(index, newAmount) {
    const { editingReservation } = this.state
    const updatedReservation = editingReservation
    updatedReservation.products[index].amount = newAmount
    this.setState({
      editingReservation: updatedReservation,
    })
  }

  handleCancelReservation = () => {
    const { user, match } = this.props
    this.setState({
      updatingReservationState: true,
    })
    this.props.actions.cancelReservation(user.account._id, match.params.reservation_id)
  }

  handleUncancelReservation = () => {
    const { user, match } = this.props
    this.setState({
      updatingReservationState: true,
    })
    this.props.actions.unCancelReservation(user.account._id, match.params.reservation_id)
  }

  handleSaveReservation = () => {
    const { user } = this.props
    const { editingReservation } = this.state
    this.props.actions.saveReservation(user.account._id, editingReservation)
  }

  renderStockAmount = product => {
    const { activeProductsStock } = this.props
    const { initialReservationStateProducts } = this.state

    const initialProductState = initialReservationStateProducts.find(p => p._id === product._id)
    const initialRelatedProductAmount =
      initialProductState && initialProductState.amount ? initialProductState.amount : 0

    let stockContent
    if (activeProductsStock) {
      const relatedProductStock = activeProductsStock.find(stock => stock._id === product._id)
      if (relatedProductStock) {
        const remoteStockValue = relatedProductStock.stock
        let calculatedStockValue
        if (product.amount === '') {
          calculatedStockValue = remoteStockValue + initialRelatedProductAmount
        } else {
          calculatedStockValue =
            remoteStockValue - (parseFloat(product.amount) - initialRelatedProductAmount)
        }
        const negativeStockValue = calculatedStockValue < 0
        stockContent = (
          <StockP style={{ color: negativeStockValue ? red_light : 'black' }}>
            Stock: {calculatedStockValue}
          </StockP>
        )
      }
    }
    return stockContent
  }

  changeProductAmount = (direction, product, index) => {
    const { editingReservation, initialReservationStateProducts } = this.state
    const { activeProductsStock, productsStore } = this.props

    const storeProductDocument = productsStore.find(p => p._id === product._id)
    const initialProductState = initialReservationStateProducts.find(p => p._id === product._id)
    const initialRelatedProductAmount =
      initialProductState && initialProductState.amount ? initialProductState.amount : 0

    const updatedReservation = editingReservation
    const currentValue = editingReservation.products[index].amount
    const currentProductId = editingReservation.products[index]._id
    const canSellHalf = storeProductDocument.can_sell_half
    const prodDoc = activeProductsStock.find(p => p._id === currentProductId)
    const prodStock = prodDoc.stock

    const freeStock = prodStock
    const maxAmount = freeStock + initialRelatedProductAmount

    if (direction === 'minus') {
      if (currentValue === 0) {
        return
      } else {
        const newValue = currentValue - (canSellHalf ? 0.5 : 1)
        if (newValue !== 0) {
          updatedReservation.products[index].amount = newValue
        }
      }
    } else {
      if (currentValue === maxAmount) {
        return
      } else {
        updatedReservation.products[index].amount = currentValue + (canSellHalf ? 0.5 : 1)
      }
    }

    this.setState({
      editingReservation: updatedReservation,
    })
  }

  renderContent() {
    const { editingReservation, started } = this.state
    const { activeProductsStock } = this.props

    if (!started || !activeProductsStock) {
      return (
        <Content>
          <div
            style={{ width: '100px', padding: '50px 0', display: 'flex', justifyContent: 'center' }}
          >
            <Spinner radius={20} color="black" />
          </div>
        </Content>
      )
    }

    const data = new Date(editingReservation.dayDate)
    const diaDaSemana = translateEnglishWeekdayName(editingReservation.dayName)
    const diaDoMes = format(data, 'dd')
    const mes = capitaliseFirstLetter(format(data, 'MMMM', { locale: pt }))
    const products = editingReservation.products
    const productsStillNotInReservation = this.getProductsNotAlreadyInReservation()
    let hasUnconfirmedProduct = false
    for (const product of editingReservation.products) {
      if (!product._id) {
        hasUnconfirmedProduct = true
        break
      }
    }
    const allPossibleProductsAdded = productsStillNotInReservation.length === 0
    // console.log('editingReservation', editingReservation)
    // console.log('productsStore', productsStore)
    // console.log('productsStillNotInReservation', productsStillNotInReservation)
    // console.log('allPossibleProductsAdded', allPossibleProductsAdded)
    // console.log('hasUnconfirmedProduct', hasUnconfirmedProduct)

    const contentStyle = {
      opacity: editingReservation.cancelled ? '0.3' : '1',
      pointerEvents: editingReservation.cancelled ? 'none' : 'auto',
    }

    return (
      <div>
        {editingReservation.cancelled && (
          <ContentShortV>
            <TranslucentBar>
              <H3 style={{ color: red_light }}>Reserva Cancelada</H3>
            </TranslucentBar>
          </ContentShortV>
        )}
        <Content style={contentStyle}>
          <ReservationInfo>
            <div>
              <H6>Cliente:</H6>
              <H3>{editingReservation.name}</H3>
              <H4 className="seconday-field">{editingReservation.telephone}</H4>
            </div>
            <div>
              <H6>Data:</H6>
              <H3>{diaDaSemana}</H3>
              <H4 className="seconday-field">{`${diaDoMes} ${mes}`}</H4>
            </div>
          </ReservationInfo>
          <ListaProdutos>
            {products.map((product, index) => (
              <ProductItem key={index} unconfirmed={!product._id}>
                {product._id && (
                  <ProductAmountSelector
                    amount={product.amount}
                    handleMinusButton={() => this.changeProductAmount('minus', product, index)}
                    handlePlusButton={() => this.changeProductAmount('plus', product, index)}
                  />
                )}
                {this.renderProductImage(product.image_url)}
                {product._id && <P style={{ lineHeight: '100%' }}>{product.title_pt}</P>}
                {!product._id && this.renderProductSelector()}
                {!product._id && this.renderProductSelectorOK(product)}
                <RightSide>
                  {product._id && this.renderStockAmount(product)}
                  <DeleteIconButton
                    className="delete-button"
                    onClick={() => this.removeProductFromReservation(index)}
                  >
                    <FAIcon icon="faTrash" family="solid" />
                  </DeleteIconButton>
                </RightSide>
              </ProductItem>
            ))}
          </ListaProdutos>
          {!hasUnconfirmedProduct && !allPossibleProductsAdded && !editingReservation.cancelled && (
            <TranslucentBar>
              <AddProductButton onClick={this.handleAddProduct}>
                <PSmall>Adicionar</PSmall>
                <FAIcon icon="faPlus" family="solid" />
              </AddProductButton>
            </TranslucentBar>
          )}
        </Content>
      </div>
    )
  }

  render() {
    const { requestedReservation, editingReservation } = this.state

    let cancelReservationButtonContent = null
    if (editingReservation) {
      cancelReservationButtonContent = editingReservation.cancelled ? (
        <UncancelReservationButton onClick={this.handleUncancelReservation}>
          <PSmall>Activar Reserva</PSmall>
          <FAIcon icon="faUndo" family="solid" />
        </UncancelReservationButton>
      ) : (
        <CancelReservationButton onClick={this.handleCancelReservation}>
          <PSmall>Cancelar Reserva</PSmall>
          <FAIcon icon="faTimes" family="solid" />
        </CancelReservationButton>
      )
    }

    return (
      <RootDiv>
        <Header>
          <HeaderContent>
            <p>Editar Reserva</p>
            <div>
              <CancelEditingButton
                onClick={() => (window.location = '/reservas/' + this.props.match.params.weekDate)}
              >
                <PSmall>Voltar</PSmall>
                <FAIcon icon="faArrowLeft" family="solid" />
              </CancelEditingButton>
              <SaveButton onClick={this.handleSaveReservation}>
                <PSmall>Salvar</PSmall>
                <FAIcon icon="faSave" family="solid" />
              </SaveButton>
              {cancelReservationButtonContent}
            </div>
          </HeaderContent>
        </Header>
        {requestedReservation && this.renderContent()}
      </RootDiv>
    )
  }
}

export default withRouter(ReservationEditor)

const RootDiv = styled.div({
  width: '100%',
  '& .ProductAmountSelector': {
    margin: '0 20px 0 0 !important',
    width: '10% !important',
    minWidth: '100px',
    maxWidth: '120px',
    height: '30px !important',
  },
  '& .AmountControlDiv': {
    margin: '0',
  },
})

const Header = styled.div({
  width: '100%',
  height: 'auto',
  background: 'white',
  borderBottom: '2px solid #D1E6FD',
})

const ReservationInfo = styled.div({
  width: '100%',
  display: 'flex',
  paddingBottom: '100px',
  '& > *': {
    width: '50%',
  },
  '& .seconday-field': {
    color: '#73787C',
  },
})

const ListaProdutos = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '0',
})

const RightSide = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
})

const DeleteIconButton = styled.button({
  width: '50px',
  height: '50px',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  background: '#4F4F4F',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    color: 'white',
  },
})

const ProductItem = styled.div(props => ({
  width: '100%',
  display: 'flex',
  marginBottom: '10px',
  height: 'auto',
  background: 'white',
  borderRadius: '5px',
  padding: '8px 12px',
  alignItems: 'center',
  // justifyContent: 'space-between',
  border: !props.unconfirmed ? 'none' : `2px solid ${blue_shiny}`,
  '& .product-item-info-section': {
    display: 'flex',
    alignItems: 'center',
  },
  '& input': {
    border: 'none',
    outline: 'none',
    width: '70px',
    height: '50px',
    background: '#E8EFF9',
    borderRadius: '3px',
    fontSize: '22px',
    marginRight: '10px',
    textAlign: 'right',
    padding: '8px',
  },
  '& img': {
    width: '50px',
    height: '50px',
    background: '#E8EFF9',
    borderRadius: '3px',
    marginRight: '10px',
  },
  '& .no-image-placeholder': {
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& select': {
    background: '#E8EFF9',
    outline: 'none',
    border: 'none',
    height: '50px',
    fontSize: '20px',
    fontFamily: 'Satoshi',
    padding: '0 7px',
  },
  '& .confirm-add-product': {
    height: '50px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    background: blue_shiny,
    padding: '0 16px',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    '& svg': {
      color: 'white',
    },
  },
  '& .delete-button': {
    marginLeft: 'auto',
  },
}))

const TranslucentBar = styled.div({
  width: '100%',
  display: 'flex',
  marginBottom: '10px',
  height: 'auto',
  background: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '5px',
  padding: '8px 12px',
  justifyContent: 'center',
})

const HeaderContent = styled(ContentShortV)({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  [phablet_max]: {
    flexDirection: 'column',
  },
})

const Button = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  padding: '6px 14px 4px 14px',
  cursor: 'pointer',
  borderRadius: '4px',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  svg: {
    fontSize: '16px',
    marginLeft: '10px',
    position: 'relative',
    top: '-2px',
  },
  ':hover': {
    'p, svg': {
      color: 'white',
    },
  },
})

const CancelEditingButton = styled(Button)({
  backgroundColor: grey_border,
  ':hover': {
    backgroundColor: grey_border,
    'p, svg': {
      color: 'black',
    },
  },
})

const CancelReservationButton = styled(Button)({
  backgroundColor: red_light,
  alignSelf: 'center',
  ':hover': {
    backgroundColor: red_light,
  },
  'p, svg': {
    color: 'white',
  },
})

const UncancelReservationButton = styled(Button)({
  backgroundColor: blue_shiny,
  alignSelf: 'center',
  ':hover': {
    backgroundColor: blue_shiny,
  },
  'p, svg': {
    color: 'white',
  },
})

const StockP = styled(P)({
  marginLeft: 'auto',
  marginRight: '10px',
  lineHeight: '100%',
})

const SaveButton = styled(Button)({
  marginLeft: '10px',
  marginRight: '10px',
  backgroundColor: green_shiny,
  ':hover': {
    backgroundColor: green_shiny,
  },
  'p, svg': {
    color: 'white',
  },
})

const AddProductButton = styled(Button)({
  marginLeft: '10px',
  backgroundColor: blue_shiny,
  ':hover': {
    backgroundColor: blue_shiny,
  },
  'p, svg': {
    color: 'white',
  },
})
