import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ContainerLimited, ContentNoH, FlexDiv } from '../../ui/elements/layout'
import { P } from '../../ui/elements/typography'
import { blue_shiny } from '../../ui/defaults/colors'
import Spinner from '../../ui/components/spinner'
import { getProducts } from '../../redux/product/actions'
import ProductEditBox from './components/products/ProductEditBox'
import BackofficePage from './components_shared/BackofficePage'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    products: store.product.products,
    mobileNavOpen: store.system.mobileNavOpen,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getProducts,
      },
      dispatch,
    ),
  }),
)
class ProductDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestedProducts: false,
    }
  }

  requestProducts() {
    this.props.actions.getProducts(this.props.user.account._id)
    this.setState({ requestedProducts: true })
  }

  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.account &&
      this.props.user.account._id &&
      !this.state.requestedProducts
    ) {
      this.requestProducts()
    }
  }

  componentDidUpdate() {
    if (
      this.props.user &&
      this.props.user.account &&
      this.props.user.account._id &&
      !this.state.requestedProducts
    ) {
      this.requestProducts()
    }
  }

  renderContent(viewingProduct, openingDays) {
    return (
      <ContainerLimited maxWidth="1280px">
        <NavLink to="/produtos" style={{ display: 'block' }}>
          <ContentNoH>
            <FlexDiv style={{ alignItems: 'center' }}>
              <FAIcon icon="faChevronLeft" family="light" />
              <P style={{ marginLeft: '10px', position: 'relative', top: '3px' }}>
                Todos os produtos
              </P>
            </FlexDiv>
          </ContentNoH>
        </NavLink>
        {viewingProduct && (
          <ProductEditBox
            _id={viewingProduct._id}
            openingDays={openingDays}
            date_created={viewingProduct.date_created}
            title_pt={viewingProduct.title_pt}
            sale_active={viewingProduct.sale_active}
            can_sell_half={viewingProduct.can_sell_half}
            lead={viewingProduct.lead}
            description={viewingProduct.description}
            image_url={viewingProduct.image_url}
            weekdays_availability={viewingProduct.weekdays_availability}
            stock_monday={viewingProduct.stock_monday}
            stock_tuesday={viewingProduct.stock_tuesday}
            stock_wednesday={viewingProduct.stock_wednesday}
            stock_thursday={viewingProduct.stock_thursday}
            stock_friday={viewingProduct.stock_friday}
            stock_saturday={viewingProduct.stock_saturday}
            stock_sunday={viewingProduct.stock_sunday}
          />
        )}
      </ContainerLimited>
    )
  }

  // handleLogout = () => {
  //   this.props.actions.toggleMobileNav()
  //   this.props.actions.logoutUser(this.props.history)
  // }

  // render() {
  //   const {
  //     user,
  //     products,
  //   } = this.props

  //   // try to get product from params
  //   let viewingProduct
  //   if (products && this.props.match && this.props.match.params && this.props.match.params.productId) {
  //     viewingProduct = products.find(product => product._id === this.props.match.params.productId)
  //   }
  //   return (
  //     <Section>
  //       <Helmet>
  //         <title>{viewingProduct && viewingProduct.title_pt ? (viewingProduct.title_pt + ' - detalhe') : 'As minhas reservas - detalhe de produto'}</title>
  //       </Helmet>
  //       <Navbar maxWidth='1280px' />
  //       <Section style={{background: 'white'}}>
  //         <ContainerLimited maxWidth='1280px'>
  //           <PageHeader
  //             pageTitle={viewingProduct ? `Produto: ${viewingProduct.title_pt}` : 'Produto sem título'}
  //           />
  //         </ContainerLimited>
  //       </Section>

  //       {!this.props.isAuthenticated && <P>You must be logged in to view this page</P>}
  //       {this.props.isAuthenticated && this.renderContent(viewingProduct, user.account.opening_days)}
  //       {this.props.isAuthenticated &&
  //         <SidebarMobileInstance
  //           mobileNavOpen={this.props.mobileNavOpen}
  //           handleToggleMobileNav={() => this.props.actions.toggleMobileNav()}
  //           handleLogout={this.handleLogout}
  //         />
  //       }
  //     </Section>
  //   )
  // }

  render() {
    const { user, products } = this.props

    // try to get product from params
    let viewingProduct
    if (
      user &&
      products &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.productId
    ) {
      viewingProduct = products.find(product => product._id === this.props.match.params.productId)
    }

    let content = null
    let title = ''
    if (this.props.isAuthenticated && viewingProduct) {
      content = this.renderContent(viewingProduct, user.account.opening_days)
      title = viewingProduct.title_pt + ' - detalhe'
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
      title = 'As minhas reservas - detalhe de produto'
    }

    return <BackofficePage title={title} content={content} />
  }
}

export default withRouter(ProductDetail)
