import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import NavbarHome from './components_shared/NavbarHome'
import AMRLogoForLightBg from '../../app_ui/svgs/logos/AMRLogoForLightBg'

@connect(
  store => ({
    windowHeight: store.system.windowHeight,
  }),
  null
)
class Home extends Component {

  render() {
    const {
      windowHeight
    } = this.props
    return (
      <div>
        <Helmet>
          <title>Asminhasreservas.pt - Início</title>
        </Helmet>
        <NavbarHome />
        <Layout calculatedHeight={windowHeight}>
          <AMRLogoForLightBg />
        </Layout>
      </div>
    )
  }
}

export default Home

const Layout = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: props.calculatedHeight ? `${props.calculatedHeight}px` : 'auto',
  '& svg': {
    position: 'relative',
    top: '-3%',
  }
}))
