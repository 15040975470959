import {
  GET_RESERVATION,
  GET_RESERVATION_SUCCESS,
  GET_RESERVATION_ERROR,

  GET_RESERVATIONS,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_ERROR,

  CANCEL_RESERVATION,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_ERROR,

  UNCANCEL_RESERVATION,
  UNCANCEL_RESERVATION_SUCCESS,
  UNCANCEL_RESERVATION_ERROR,

  GET_RECENTLY_CHANGED_RESERVATIONS,
  GET_RECENTLY_CHANGED_RESERVATIONS_SUCCESS,
  GET_RECENTLY_CHANGED_RESERVATIONS_ERROR,

  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_ERROR,

  SAVE_RESERVATION,
  SAVE_RESERVATION_SUCCESS,
  SAVE_RESERVATION_ERROR,

  DELETE_RESERVATION,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_ERROR,

  RESERVATION_STATE_FORWARD,
  RESERVATION_STATE_FORWARD_SUCCESS,
  RESERVATION_STATE_FORWARD_ERROR,

  RESERVATION_STATE_BACKWARD,
  RESERVATION_STATE_BACKWARD_SUCCESS,
  RESERVATION_STATE_BACKWARD_ERROR,
} from '../action-types'

export default function reducer (state={
    reservations: null,
    localStorageReservations: null,
    loading: false,
    movingStateReservationId: null,
    recentlyChangedReservations: null,
    loadingRecentlyChangedReservations: null,
    editingReservation: null,
    loadingEditingReservation: false,
    savingEditingReservation: false,
  }, action) {

  switch(action.type)
  {
    case GET_RESERVATION: { return { ...state, loadingEditingReservation: true } }
    case GET_RESERVATION_ERROR: { return { ...state, loadingEditingReservation: false} }
    case GET_RESERVATION_SUCCESS: {
      return {
        ...state,
        editingReservation: action.payload || null,
        loadingEditingReservation: false,
      }
    }
    case SAVE_RESERVATION: { return { ...state, savingEditingReservation: true } }
    case SAVE_RESERVATION_ERROR: { return { ...state, savingEditingReservation: false} }
    case SAVE_RESERVATION_SUCCESS: {
      return {
        ...state,
        editingReservation: action.payload || null,
        savingEditingReservation: false,
      }
    }
    case GET_RESERVATIONS: { return { ...state, loading: true } }
    case GET_RESERVATIONS_ERROR: { return { ...state, loading: false} }
    case GET_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        reservations: action.payload || [] ,
        loading: false,
      }
    }
    case GET_RECENTLY_CHANGED_RESERVATIONS: { return { ...state, loadingRecentlyChangedReservations: true } }
    case GET_RECENTLY_CHANGED_RESERVATIONS_ERROR: { return { ...state, loadingRecentlyChangedReservations: false} }
    case GET_RECENTLY_CHANGED_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        recentlyChangedReservations: action.payload || [] ,
        loadingRecentlyChangedReservations: false,
      }
    }
    case CREATE_RESERVATION: { return { ...state, loading: true } }
    case CREATE_RESERVATION_ERROR: { return { ...state, loading: false} }
    case CREATE_RESERVATION_SUCCESS: {
      return {
        ...state,
        reservations: [...state.reservations, action.payload ],
        loading: false,
      }
    }
    case RESERVATION_STATE_FORWARD: { return { ...state, loading: true, movingStateReservationId: action.reservationId } }
    case RESERVATION_STATE_FORWARD_ERROR: { return { ...state, loading: false, movingStateReservationId: null } }
    case RESERVATION_STATE_FORWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        movingStateReservationId: null,
        reservations: state.reservations.map(reservation => reservation._id === action.payload._id ?
          { ...reservation, state: action.payload.newState } : 
          reservation
        )
      }
    }
    case CANCEL_RESERVATION: { return { ...state, loading: true } }
    case CANCEL_RESERVATION_ERROR: { return { ...state, loading: false } }
    case CANCEL_RESERVATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        editingReservation: action.payload
      }
    }
    case UNCANCEL_RESERVATION: { return { ...state, loading: true } }
    case UNCANCEL_RESERVATION_ERROR: { return { ...state, loading: false } }
    case UNCANCEL_RESERVATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        editingReservation: action.payload
      }
    }
    case RESERVATION_STATE_BACKWARD: { return { ...state, loading: true, movingStateReservationId: action.reservationId } }
    case RESERVATION_STATE_BACKWARD_ERROR: { return { ...state, loading: false, movingStateReservationId: null} }
    case RESERVATION_STATE_BACKWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        movingStateReservationId: null,
        reservations: state.reservations.map(reservation => reservation._id === action.payload._id ?
          { ...reservation, state: action.payload.newState } : 
          reservation
        )
      }
    }
    case DELETE_RESERVATION: { return { ...state, loading: true } }
    case DELETE_RESERVATION_ERROR: { return { ...state, loading: false} }
    case DELETE_RESERVATION_SUCCESS: {
      return {
        ...state,
        // products: action.payload,
        reservations: state.reservations.filter(reservation => reservation._id !== action.payload),
        loading: false,
      }
    }
    default:
      return state
  }
}

