import axios from 'axios'
import setAuthorizationToken from '../utils/setAuthorizationToken'
import { toast } from 'react-toastify'
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  // GET_USER_ACCOUNT,
  // GET_USER_ACCOUNT_SUCCESS,
  // GET_USER_ACCOUNT_ERROR,
  
  UPDATE_USER_ACCOUNT_DIRECT,
  UPDATE_USER_WEEKDAYS_AVAILABILITY_DIRECT,
} from '../action-types'

const tokenName = 'reservasToken'


// ########  ########  ######   ####  ######  ######## ######## ########  
// ##     ## ##       ##    ##   ##  ##    ##    ##    ##       ##     ## 
// ##     ## ##       ##         ##  ##          ##    ##       ##     ## 
// ########  ######   ##   ####  ##   ######     ##    ######   ########  
// ##   ##   ##       ##    ##   ##        ##    ##    ##       ##   ##   
// ##    ##  ##       ##    ##   ##  ##    ##    ##    ##       ##    ##  
// ##     ## ########  ######   ####  ######     ##    ######## ##     ## 


export const registerUser = (user, history) => dispatch => {
  axios.post('/api/v1/register', user)
    .then(res => {
      toast.info('You have registered successfully', {
        hideProgressBar: true
      })
      toast.info('Novo utilizador registado')
      history.push('/entrar')
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      })
      toast.error(err.message)
    })
}


//  ######  ######## ########     ######  ##     ## ########  ########  ######## ##    ## ########    ##     ##  ######  ######## ########  
// ##    ## ##          ##       ##    ## ##     ## ##     ## ##     ## ##       ###   ##    ##       ##     ## ##    ## ##       ##     ## 
// ##       ##          ##       ##       ##     ## ##     ## ##     ## ##       ####  ##    ##       ##     ## ##       ##       ##     ## 
//  ######  ######      ##       ##       ##     ## ########  ########  ######   ## ## ##    ##       ##     ##  ######  ######   ########  
//       ## ##          ##       ##       ##     ## ##   ##   ##   ##   ##       ##  ####    ##       ##     ##       ## ##       ##   ##   
// ##    ## ##          ##       ##    ## ##     ## ##    ##  ##    ##  ##       ##   ###    ##       ##     ## ##    ## ##       ##    ##  
//  ######  ########    ##        ######   #######  ##     ## ##     ## ######## ##    ##    ##        #######   ######  ######## ##     ## 


export const setCurrentUser = userData => {
  return {
    type: SET_CURRENT_USER,
    payload: userData
  }
}


// ##        #######   ######    #######  ##     ## ######## 
// ##       ##     ## ##    ##  ##     ## ##     ##    ##    
// ##       ##     ## ##        ##     ## ##     ##    ##    
// ##       ##     ## ##   #### ##     ## ##     ##    ##    
// ##       ##     ## ##    ##  ##     ## ##     ##    ##    
// ##       ##     ## ##    ##  ##     ## ##     ##    ##    
// ########  #######   ######    #######   #######     ##    


export const logoutUser = (history) => dispatch => {
  localStorage.removeItem(tokenName)
  setAuthorizationToken(false)
  dispatch(setCurrentUser({}))
  dispatch({
    type: 'SET_AUTHENTICATED',
    payload: false,
  })
  toast.info('Sessão terminada')
  history.push('/')
}


//  ######   ######## ########    ##     ##  ######  ######## ########        ###     ######   ######   #######  ##     ## ##    ## ######## 
// ##    ##  ##          ##       ##     ## ##    ## ##       ##     ##      ## ##   ##    ## ##    ## ##     ## ##     ## ###   ##    ##    
// ##        ##          ##       ##     ## ##       ##       ##     ##     ##   ##  ##       ##       ##     ## ##     ## ####  ##    ##    
// ##   #### ######      ##       ##     ##  ######  ######   ########     ##     ## ##       ##       ##     ## ##     ## ## ## ##    ##    
// ##    ##  ##          ##       ##     ##       ## ##       ##   ##      ######### ##       ##       ##     ## ##     ## ##  ####    ##    
// ##    ##  ##          ##       ##     ## ##    ## ##       ##    ##     ##     ## ##    ## ##    ## ##     ## ##     ## ##   ###    ##    
//  ######   ########    ##        #######   ######  ######## ##     ##    ##     ##  ######   ######   #######   #######  ##    ##    ##    


// export function getUserAccount(userId) {
//   return dispatch => {
//     const token = localStorage.getItem(tokenName)
//     setAuthorizationToken(token)
//     dispatch({
//       type: GET_USER_ACCOUNT,
//     })
//     axios.get(`/api/v1/get-user-account/${userId}`, {
//       withCredentials: true,
//     }).then((response) => {
//       dispatch({
//         type: GET_USER_ACCOUNT_SUCCESS,
//         payload: response.data,
//       })
//     })
//     .catch((err) => {
//       dispatch({type: GET_USER_ACCOUNT_ERROR, payload: err })
//     })
//   }
// }

export function updateUserAccountDirect(accountData) {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_ACCOUNT_DIRECT,
      payload: accountData,
    })
  }
}

export function updateUserAccountWeekdaysAvailabilityDirect(accountData) {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_WEEKDAYS_AVAILABILITY_DIRECT,
      payload: accountData,
    })
  }
}


// ##        #######   ######   #### ##    ## 
// ##       ##     ## ##    ##   ##  ###   ## 
// ##       ##     ## ##         ##  ####  ## 
// ##       ##     ## ##   ####  ##  ## ## ## 
// ##       ##     ## ##    ##   ##  ##  #### 
// ##       ##     ## ##    ##   ##  ##   ### 
// ########  #######   ######   #### ##    ## 


export function loginUser(data, history) {
  return dispatch => {
    return axios.post('/api/v1/login', data).then(res => {
      const token = res.data.token
      localStorage.setItem(tokenName, token)
      setAuthorizationToken(token)
      dispatch(setCurrentUser(res.data.user))
      // dispatch(getUserAccount(res.data.user.account))
      dispatch({
        type: 'SET_AUTHENTICATED',
        payload: true,
      })
      // {
      //   user: res.data.user, 
      //   token: jwtDecode(token)
      // }))
      toast.info('Sessão iniciada', {
        hideProgressBar: true,
      })
      history.push('/vista-geral')
    })
    .catch(err => {
      console.log('err.message: ' + err.message)
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      })
      dispatch({
        type: 'SET_AUTHENTICATED',
        payload: false,
      })
      toast.error('Login failed', {
        hideProgressBar: true,
      })
    })
  }
}



// ##     ## ######## ########  #### ######## ##    ##    ########  #######  ##    ## ######## ##    ## 
// ##     ## ##       ##     ##  ##  ##        ##  ##        ##    ##     ## ##   ##  ##       ###   ## 
// ##     ## ##       ##     ##  ##  ##         ####         ##    ##     ## ##  ##   ##       ####  ## 
// ##     ## ######   ########   ##  ######      ##          ##    ##     ## #####    ######   ## ## ## 
//  ##   ##  ##       ##   ##    ##  ##          ##          ##    ##     ## ##  ##   ##       ##  #### 
//   ## ##   ##       ##    ##   ##  ##          ##          ##    ##     ## ##   ##  ##       ##   ### 
//    ###    ######## ##     ## #### ##          ##          ##     #######  ##    ## ######## ##    ## 


export function verifyToken() {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    axios.get('/api/v1/check', { data: token },
    {
      withCredentials: true,
    }).then((response) => {
      dispatch(setCurrentUser(response.data.user))
      // dispatch(getUserAccount(response.data.user.accountId))
      dispatch({
        type: 'SET_AUTHENTICATED',
        payload: true,
      })
      // toast.info('Online', {
      //   hideProgressBar: true,
      // })
    })
    .catch((err) => {
      dispatch({type: 'VERIFY_TOKEN_ERROR', payload: err })
    })
  }
}
