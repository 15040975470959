import React, { Component } from 'react'
import styled from '@emotion/styled'
import {
  format,
  formatDistanceToNow,
  differenceInHours,
} from 'date-fns'
import { pt } from 'date-fns/locale'
import { Content } from '../../../../ui/elements/layout'
import { PSmall } from '../../../../ui/elements/typography'
import Spinner from '../../../../ui/components/spinner'
import { desktop_max, tablet_max, phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import ActivityFeedReservationItem from '../../components/overview/ActivityFeedReservationItem'
import {
  capitaliseFirstLetter,
} from '../../../../utils/string'
import {
  sortArrayByDateProperty,
  sortArrayByLastChangedDate,
} from '../../../../utils/array'
import {
  ensureURLFormattedDate,
  ensureGetRegularDate,
} from '../../../../utils/date'
import PageHeader from '../../components/PageHeader'

class ActivityFeed extends Component {

  getFormattedTimeAgo = (date) => {
    return formatDistanceToNow(date, {locale: pt, addSuffix: true})
  }

  getClientName = (clients, reservation) => {
    const client = clients.find(client => client.shortId === reservation.client_short_id)

    if (client && client.name) {
      return client.name
    }

    if (reservation.name) {
      return reservation.name
    }

    return '(could not get name)'
  }

  getActivityFeedData = () => {
    const {
      clients,
      products,
      recentlyChangedReservations,
      loadingLastChangedReservations,
    } = this.props

    const emptyResponse = {
      items: [],
      clients: [],
      products: [],
      loadingSomething: true,
    }

    // empty response
    if (!clients || !products || !recentlyChangedReservations) {
      // console.log('return emptyResponse')
      return emptyResponse
    }
    const reservations = recentlyChangedReservations
    const loadingSomething = loadingLastChangedReservations
    
    // // create last_changed property
    for(const item of reservations) {
      item.last_changed = item.updated && item.date_updated ? item.date_updated : item.date_created
      item.last_changed_Date = new Date(item.last_changed)
    }
    
    const sortedReservations = sortArrayByLastChangedDate(reservations, 'last_changed_Date')

    // get dates by day
    const dates = []
    const datesSaved = []

    for(const res of sortedReservations) {
      const currentDateKey = ensureURLFormattedDate(res.last_changed_Date)
      const dateText = format(res.last_changed_Date, 'EEEE dd', {locale: pt})
      const dateTextCapitalised = capitaliseFirstLetter(dateText)

      if (datesSaved.indexOf(currentDateKey) === -1) {
        datesSaved.push(currentDateKey)
        dates.push({
          dateTime: res.last_changed,
          dateObject: res.last_changed_Date,
          dateKey: currentDateKey,
          dateTextCapitalised: dateTextCapitalised,
        })
      }
    }
    
    let items = []
    for(const date of dates) {
      let thisDayReservations = reservations.filter(reservation => format(new Date(reservation.last_changed), 'EEEE dd') === format(new Date(date.dateTime), 'EEEE dd'))
      thisDayReservations = sortArrayByLastChangedDate(thisDayReservations, 'last_changed_Date')
      items.push({
        date: date.dateObject,
        label: date.dateTextCapitalised,
        reservations: thisDayReservations
      })
    }

    items = sortArrayByDateProperty(items, 'date')

    return {
      items: items,
      clients: clients,
      loadingSomething: loadingSomething,
    }
  }

  render() {
    const {
      windowHeight,
    } = this.props
    const activityFeedData = this.getActivityFeedData()
    const items = activityFeedData.items
    const loadingSomething = activityFeedData.loadingSomething
    const clients = activityFeedData.clients
    const titleString = items.length === 0 ? (loadingSomething ? 'A carregar...' : 'Nenhuma actividade nas últimas 24h.') : 'Actividade'
    return (
      <ActivityFeedFrame windowHeight={windowHeight}>
        <PageHeader
          pageTitle={titleString}
        />
        <CustomMonitorContent>
          <div style={{opacity: loadingSomething ? '1' : '0', position: 'relative', marginBottom: '6px'}}>
            <Spinner radius={20} color='black' style={{position: 'absolute', top: '4px', right: '4px'}} />
          </div>
          {items && items.length > 0 && items.map(item =>
            <div key={item.label} style={{marginBottom: '16px'}}>
              <PSmall style={{marginBottom: '16px'}}>{item.label}</PSmall>
              {item && item.reservations && item.reservations.length && item.reservations.map(reservation =>
                <ActivityFeedReservationItem
                  key={reservation._id}
                  _id={reservation._id}
                  state={reservation.state}
                  name={this.getClientName(clients, reservation)}
                  timeChanged={format(ensureGetRegularDate(reservation.last_changed), 'HH:mm')}
                  weekDate={reservation.yearMonthDay}
                  dayDateWeekDayName={format(ensureGetRegularDate(reservation.yearMonthDay), 'EEEE dd', {locale: pt})}
                  differenceInHours={differenceInHours(new Date(), reservation.last_changed)}
                  timeAgo={this.getFormattedTimeAgo(new Date(reservation.last_changed))}
                  products={reservation.products}
                  updated={reservation.updated}
                  cancelled={reservation.cancelled}
                />
              )}
            </div>
          )}
          <br />
          <br />
        </CustomMonitorContent>
      </ActivityFeedFrame>
    )
  }
}

export default ActivityFeed

const CustomMonitorContent = styled(Content)({
  padding: '20px',
  [desktop_max]: {
    padding: '15px',
  },
  [tablet_max]: {
    padding: '10px',
  },
  [phablet_max]: {
    padding: '10px',
  },
  [phone_max]: {
    padding: '10px',
  },
})

const ActivityFeedFrame = styled.div(props => ({
  overflow: 'hidden',
  overflowY: 'scroll',
  background: 'white',
  height: props.windowHeight ? `${props.windowHeight}px` : 'auto',
  borderLeft: '1px solid #d2e5ff',
  [phablet_max]: {
    display: 'none',
  },
}))
