import React from 'react'
import { Route, Switch } from 'react-router-dom'

// backoffice
import Home from '../containers/backoffice/Home'
import Login from '../containers/backoffice/Login'
import Register from '../containers/backoffice/Register'
import Overview from '../containers/backoffice/Overview'
import Products from '../containers/backoffice/Products'
import ProductDetail from '../containers/backoffice/ProductDetail'
import Reservations from '../containers/backoffice/Reservations'
import Settings from '../containers/backoffice/Settings'
import Accounts from '../containers/backoffice/Accounts'
import Stats from '../containers/backoffice/Stats'
import StatsReservations from '../containers/backoffice/StatsReservations'
import StatsProducts from '../containers/backoffice/StatsProducts'
import StatsClients from '../containers/backoffice/StatsClients'
import ReservationEditor from '../containers/backoffice/components/reservations/ReservationEditor'
import ReservationCreator from '../containers/backoffice/components/reservations/ReservationCreator'
import ClientEditor from '../containers/backoffice/components/clients/ClientEditor'

// widget
import WidgetStart from '../containers/widget/WidgetStart'
import WidgetProductSelection from '../containers/widget/WidgetProductSelection'
import WidgetConfirmCart from '../containers/widget/WidgetConfirmCart'
import WidgetReservationForm from '../containers/widget/WidgetReservationForm'
import WidgetFinalConfirmationScreen from '../containers/widget/WidgetFinalConfirmationScreen'
import WidgetCreateReservations from '../containers/widget/WidgetCreateReservations'
import WidgetCreateReservationsSuccess from '../containers/widget/WidgetCreateReservationsSuccess'
import WidgetCreateReservationsError from '../containers/widget/WidgetCreateReservationsError'
import WidgetSoldOut from '../containers/widget/WidgetSoldOut'
import ClientReservationsManager from '../containers/client/ClientReservationsManager'
import ClientSingleReservationManager from '../containers/client/ClientSingleReservationManager'

// direct helper links
import ClientReservationsManagerByPhoneWebView from '../containers/client/ClientReservationsManagerByPhoneWebView'
import ClientSingleReservationManagerByPhoneWebView from '../containers/client/ClientSingleReservationManagerByPhoneWebView'
import ClientWebViewStart from '../containers/client/ClientWebViewStart'

const Routes = () => (
  <div>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/entrar" component={Login} />
      <Route exact path="/registo" component={Register} />
      <Route exact path="/vista-geral" component={Overview} />
      <Route exact path="/vista-geral/:weekDate" component={Overview} />
      <Route exact path="/produtos" component={Products} />
      <Route exact path="/produtos/:productId" component={ProductDetail} />
      <Route exact path="/relatorios" component={Stats} />
      <Route exact path="/relatorios/reservas" component={StatsReservations} />
      <Route exact path="/relatorios/reservas/:year/:month" component={StatsReservations} />
      <Route exact path="/relatorios/produtos" component={StatsProducts} />
      <Route exact path="/relatorios/produtos/:year/:month" component={StatsProducts} />
      <Route exact path="/relatorios/clientes" component={StatsClients} />
      <Route exact path="/relatorios/clientes/:year/:month" component={StatsClients} />
      <Route exact path="/reservas" component={Reservations} />
      <Route exact path="/reservas/:weekDate" component={Reservations} />
      <Route exact path="/reservas/:weekDate/:id" component={Reservations} />
      <Route exact path="/definicoes" component={Settings} />
      <Route exact path="/editar-reserva/:reservation_id/:weekDate" component={ReservationEditor} />
      <Route exact path="/editar-cliente/:id" component={ClientEditor} />
      <Route exact path="/nova-reserva" component={ReservationCreator} />
      <Route exact path="/accounts" component={Accounts} />
      <Route exact path="/shop/:app_id" component={WidgetStart} />
      <Route exact path="/shop/:app_id/product-selection" component={WidgetProductSelection} />
      <Route exact path="/shop/:app_id/confirm-cart" component={WidgetConfirmCart} />
      <Route exact path="/shop/:app_id/client-details-form" component={WidgetReservationForm} />
      <Route
        exact
        path="/shop/:app_id/confirm-reservations"
        component={WidgetFinalConfirmationScreen}
      />
      <Route exact path="/shop/:app_id/create-reservations" component={WidgetCreateReservations} />
      <Route
        exact
        path="/shop/:app_id/create-reservations-success"
        component={WidgetCreateReservationsSuccess}
      />
      <Route
        exact
        path="/shop/:app_id/create-reservations-error"
        component={WidgetCreateReservationsError}
      />
      <Route exact path="/shop/:app_id/sold-out" component={WidgetSoldOut} />
      <Route exact path="/loja/:shop_slug/:client_short_id" component={ClientReservationsManager} />
      <Route
        exact
        path="/loja/:shop_slug/:client_short_id/:reservation_id"
        component={ClientSingleReservationManager}
      />
      <Route
        exact
        path="/reservas-cliente-webview/:shop_slug/:client_phone"
        component={ClientReservationsManagerByPhoneWebView}
      />
      <Route
        exact
        path="/reserva-webview/:shop_slug/:client_phone/:reservation_id"
        component={ClientSingleReservationManagerByPhoneWebView}
      />
      <Route exact path="/client-webview-start/:shop_slug" component={ClientWebViewStart} />
    </Switch>
  </div>
)

export default Routes
