import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { blue_strong } from '../../../../ui/defaults/colors'
import { WhiteBox, Content, FlexDiv } from '../../../../ui/elements/layout'
import Switch from '../../../../ui/components/switch'
import { red_light, grey_border, green_shiny } from '../../../../ui/defaults/colors'
import { phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import { onlyNumbersArray } from '../../../../utils/inputs'
// forms
import { Input, TextArea, StyledFormDiv } from '../../../../ui/elements/forms'
// type
import {
  P,
  PSmall,
  // PTiny,
} from '../../../../ui/elements/typography'
// actions
import {
  updateProductTitle,
  deleteProduct,
  // updateProductNumInStock,
  updateProductWeekdayStock,
  updateCanBuyHalf,
  uploadProductImage,
  updateProductAvailableForSale,
  updateProductLead,
  updateProductDescription,
  changeProductDayAvailabilityState,
} from '../../../../redux/product/actions'
import { triggerConfirmDialog } from '../../../../redux/dialog/actions'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        updateProductTitle,
        updateProductLead,
        // updateProductNumInStock,
        updateProductWeekdayStock,
        updateCanBuyHalf,
        uploadProductImage,
        updateProductAvailableForSale,
        deleteProduct,
        triggerConfirmDialog,
        updateProductDescription,
        changeProductDayAvailabilityState,
      },
      dispatch,
    ),
  }),
)
class ProductEditBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _id: this.props._id,
      date_created: this.props.date_created,
      openingDays: this.props.openingDays,
      title_pt: this.props.title_pt,
      sale_active: this.props.sale_active,
      can_sell_half: this.props.can_sell_half,
      lead: this.props.lead,
      description: this.props.description,
      image_url: this.props.image_url,
      stock_monday: this.props.stock_monday,
      stock_tuesday: this.props.stock_tuesday,
      stock_wednesday: this.props.stock_wednesday,
      stock_thursday: this.props.stock_thursday,
      stock_friday: this.props.stock_friday,
      stock_saturday: this.props.stock_saturday,
      stock_sunday: this.props.stock_sunday,
    }
  }

  componentDidUpdate(prevProps) {
    const updates = {}
    let hasUpdates = false
    if (prevProps._id !== this.props._id) {
      hasUpdates = true
      updates._id = this.props._id
    }
    if (prevProps.date_created !== this.props.date_created) {
      hasUpdates = true
      updates.date_created = this.props.date_created
    }
    if (prevProps.openingDays !== this.props.openingDays) {
      hasUpdates = true
      updates.openingDays = this.props.openingDays
    }
    if (prevProps.title_pt !== this.props.title_pt) {
      hasUpdates = true
      updates.title_pt = this.props.title_pt
    }
    if (prevProps.sale_active !== this.props.sale_active) {
      hasUpdates = true
      updates.sale_active = this.props.sale_active
    }
    if (prevProps.can_sell_half !== this.props.can_sell_half) {
      hasUpdates = true
      updates.can_sell_half = this.props.can_sell_half
    }
    if (prevProps.lead !== this.props.lead) {
      hasUpdates = true
      updates.lead = this.props.lead
    }
    if (prevProps.description !== this.props.description) {
      hasUpdates = true
      updates.description = this.props.description
    }
    if (prevProps.image_url !== this.props.image_url) {
      hasUpdates = true
      updates.image_url = this.props.image_url
    }
    if (prevProps.stock_monday !== this.props.stock_monday) {
      hasUpdates = true
      updates.stock_monday = this.props.stock_monday
    }
    if (prevProps.stock_tuesday !== this.props.stock_tuesday) {
      hasUpdates = true
      updates.stock_tuesday = this.props.stock_tuesday
    }
    if (prevProps.stock_wednesday !== this.props.stock_wednesday) {
      hasUpdates = true
      updates.stock_wednesday = this.props.stock_wednesday
    }
    if (prevProps.stock_thursday !== this.props.stock_thursday) {
      hasUpdates = true
      updates.stock_thursday = this.props.stock_thursday
    }
    if (prevProps.stock_friday !== this.props.stock_friday) {
      hasUpdates = true
      updates.stock_friday = this.props.stock_friday
    }
    if (prevProps.stock_saturday !== this.props.stock_saturday) {
      hasUpdates = true
      updates.stock_saturday = this.props.stock_saturday
    }
    if (prevProps.stock_sunday !== this.props.stock_sunday) {
      hasUpdates = true
      updates.stock_sunday = this.props.stock_sunday
    }
    if (hasUpdates) {
      this.setState(updates)
    }
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleTitleKeyDown = e => {
    if (e.which === 13) {
      if (this.state._id) {
        this.props.actions.updateProductTitle(
          this.props.user.accountId,
          this.state._id,
          this.state.title_pt,
        )
      }
    }
  }

  handleLeadKeyDown = e => {
    if (e.which === 13) {
      if (this.state._id) {
        this.props.actions.updateProductLead(
          this.props.user.accountId,
          this.state._id,
          this.state.lead,
        )
      }
    }
  }

  handleDescriptionKeyDown = e => {
    if (e.which === 13) {
      e.preventDefault()
      e.stopPropagation()
      if (this.state._id) {
        this.props.actions.updateProductDescription(
          this.props.user.accountId,
          this.state._id,
          this.state.description,
        )
      }
    }
  }

  handleConfirmDeleteProductCallBack = () => {
    this.props.actions.deleteProduct(this.props.user.accountId, this.state._id)
    this.props.history.push('/produtos')
  }

  handleToggleCanBuyHalf = e => {
    this.props.actions.updateCanBuyHalf(
      this.props.user.accountId,
      this.state._id,
      !this.state.can_sell_half,
    )
  }

  handleImageUploadTrigger = imageFileData => {
    if (!this.state._id) return console.log('this.state._id missing')
    this.props.actions.uploadProductImage(this.props.user.accountId, this.state._id, imageFileData)
  }

  handleToggleAvailableForSale = e => {
    this.props.actions.updateProductAvailableForSale(
      this.props.user.accountId,
      this.state._id,
      !this.state.sale_active,
    )
  }

  handleDeleteProduct = e => {
    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja remover este produto?',
      confirmText: 'Sim, remover',
      cancelText: 'Cancelar',
      confirmCallBack: this.handleConfirmDeleteProductCallBack,
    })
  }

  toggleDayAvailabilityState = (dayName, newValue) => {
    this.props.actions.changeProductDayAvailabilityState(
      this.props.user.accountId,
      this.state._id,
      dayName,
      newValue,
    )
  }

  handleDayStockInputChange = (e, key) => {
    const obj = {}
    // let hasChanges = false
    let newValue = 0
    let newKey = `stock_${key}`
    if (parseFloat(e.target.value)) {
      newValue = parseFloat(e.target.value)
    }
    obj[newKey] = newValue
    // const propss = this.props[`stock_${key}`]
    // if (propss !== newValue) {
    //   hasChanges = true
    // }
    this.setState(obj)
  }

  handleNumInStockKeyDown = (e, key) => {
    if (e.which === 13) {
      let newWeekdayStock
      if (!this.state[`stock_${key}`] || this.state[`stock_${key}`] === '') {
        newWeekdayStock = 0
      } else {
        newWeekdayStock = parseFloat(this.state[`stock_${key}`])
      }
      this.props.actions.updateProductWeekdayStock(
        this.props.user.accountId,
        this.props._id,
        `stock_${key}`,
        newWeekdayStock,
      )
      const obj = {}
      obj[`stock_${key}`] = newWeekdayStock
      this.setState(obj)
    }
    if (onlyNumbersArray.indexOf(e.key) === -1) {
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }

  render() {
    const {
      title_pt,
      sale_active,
      can_sell_half,
      lead,
      description,
      openingDays,
      stock_monday,
      stock_tuesday,
      stock_wednesday,
      stock_thursday,
      stock_friday,
      stock_saturday,
      stock_sunday,
    } = this.state

    // const weekdaysAvailability = this.props.weekdays_availability

    const stockDays = []

    if (openingDays.monday) {
      stockDays.push({ key: 'monday', label: 'Segunda', value: stock_monday })
    }
    if (openingDays.tuesday) {
      stockDays.push({ key: 'tuesday', label: 'Terça', value: stock_tuesday })
    }
    if (openingDays.wednesday) {
      stockDays.push({ key: 'wednesday', label: 'Quarta', value: stock_wednesday })
    }
    if (openingDays.thursday) {
      stockDays.push({ key: 'thursday', label: 'Quinta', value: stock_thursday })
    }
    if (openingDays.friday) {
      stockDays.push({ key: 'friday', label: 'Sexta', value: stock_friday })
    }
    if (openingDays.saturday) {
      stockDays.push({ key: 'saturday', label: 'Sábado', value: stock_saturday })
    }
    if (openingDays.sunday) {
      stockDays.push({ key: 'sunday', label: 'Domingo', value: stock_sunday })
    }

    return (
      <Layout>
        <DarkRectRoot bgImage={this.props.image_url}>
          <FileInputElement
            type="file"
            onChange={e => this.handleImageUploadTrigger(e.target.files[0])}
          />
          <IconHolder>
            <FAIcon icon="faCamera" family="solid" />
          </IconHolder>
        </DarkRectRoot>
        <CustomWhiteBox>
          <Content>
            <StyledFormDiv>
              <label className="form-label">NOME</label>
              <CustomInput
                type="text"
                name="title_pt"
                placeholder="Sem título"
                onKeyDown={this.handleTitleKeyDown}
                value={title_pt}
                onChange={this.handleInputChange}
                hasChanges={this.state.title_pt !== this.props.title_pt}
              />
              <br />
              <label className="form-label">SUBTÍTULO</label>
              <CustomInput
                type="text"
                name="lead"
                placeholder="Exemplo: Preço / Peso..."
                onKeyDown={this.handleLeadKeyDown}
                value={lead}
                onChange={this.handleInputChange}
                hasChanges={this.state.lead !== this.props.lead}
              />
              <br />
              <label className="form-label">Descrição</label>
              <TextArea
                style={{ height: '150px' }}
                type="text"
                name="description"
                placeholder="Descrição (breve)"
                onKeyDown={this.handleDescriptionKeyDown}
                value={description}
                onChange={this.handleInputChange}
                hasChanges={this.state.description !== this.props.description}
              />
              <br />
              <FlexDiv style={{ width: '100%', justifyContent: 'space-between' }}>
                <P>Disponível para venda</P>
                <Switch active={sale_active} onClick={this.handleToggleAvailableForSale} />
              </FlexDiv>
              <br />
              <FlexDiv style={{ width: '100%', justifyContent: 'space-between' }}>
                <P>Permitir reservar 1/2</P>
                <Switch active={can_sell_half} onClick={this.handleToggleCanBuyHalf} />
              </FlexDiv>
              <br />
              <DeleteButton onClick={this.handleDeleteProduct}>
                <PSmall>Remover</PSmall>
                <FAIcon
                  icon="faTrash"
                  family="solid"
                  style={{
                    height: '16px',
                    width: '16px',
                    marginLeft: '10px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
              </DeleteButton>
            </StyledFormDiv>
            <br />
            <br />
            <label className="form-label">Stock para cada dia da semana:</label>
          </Content>
          <LayoutDays numColumns={stockDays.length}>
            {stockDays.map((dayData, index) => (
              <WeekdayBlock key={dayData.label} first={index === 0}>
                <WeekdayBlockHeader active={dayData.value > 0}>
                  <PSmall>{dayData.label}</PSmall>
                </WeekdayBlockHeader>
                <WeekdayBlockContent>
                  <PSmall style={{ marginBottom: '8px' }}>Max.</PSmall>
                  <StockNumberDisplay>
                    <StockTotalInput
                      name="stock_total"
                      type="text"
                      maxLength="3"
                      value={dayData.value}
                      onChange={e => this.handleDayStockInputChange(e, dayData.key)}
                      placeholder={
                        this.state[`stock_${dayData.key}`] +
                        ' - ' +
                        this.props[`stock_${dayData.key}`]
                      }
                      onKeyDown={e => this.handleNumInStockKeyDown(e, dayData.key)}
                      hasChanges={
                        this.state[`stock_${dayData.key}`] !== this.props[`stock_${dayData.key}`]
                      }
                    />
                  </StockNumberDisplay>
                </WeekdayBlockContent>
              </WeekdayBlock>
            ))}
          </LayoutDays>
        </CustomWhiteBox>
      </Layout>
    )
  }
}

export default withRouter(ProductEditBox)

const CustomWhiteBox = styled(WhiteBox)({
  borderRadius: '5px',
  display: 'inline-flex',
  flexDirection: 'column',
})

const Layout = styled.div({
  display: 'grid',
  gridTemplateColumns: '40% auto',
  columnGap: '20px',
  [phablet_max]: {
    gridTemplateColumns: '100%',
  },
})

const CustomInput = styled(Input)(props => ({
  color: props.hasChanges ? `${blue_strong} !important` : 'auto',
}))

const DeleteButton = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  padding: '6px 14px 4px 14px',
  cursor: 'pointer',
  backgroundColor: grey_border,
  borderRadius: '4px',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  svg: {
    fontSize: '16px',
  },
  ':hover': {
    backgroundColor: red_light,
    'p, svg': {
      color: 'white',
    },
  },
})

const DarkRectRoot = styled.div(props => ({
  position: 'relative',
  background: props.bgImage ? `#242424 url(${props.bgImage}) no-repeat center center` : '#242424',
  WebkitBackgroundSize: 'cover',
  MozBackgroundSize: 'cover',
  OBackgroundSize: 'cover',
  backgroundSize: 'cover',
  cursor: 'pointer',
  [phablet_max]: {
    height: '300px',
    overflow: 'hidden',
  },
  [phone_max]: {
    height: '200px',
    overflow: 'hidden',
  },
  ':hover': {
    '& svg': {
      opacity: '0.9',
    },
  },
}))

const IconHolder = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '0',
  pointerEvents: 'none',
  cursor: 'pointer',
  '& svg': {
    color: 'white',
    opacity: '0.7',
    fontSize: '64px',
    zIndex: '2px',
  },
})

const FileInputElement = styled.input({
  height: '100%',
  width: '100%',
  border: 'none',
  opacity: '0',
  cursor: 'pointer',
})

const LayoutDays = styled.div(props => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.numColumns}, auto)`,
}))

const WeekdayBlock = styled.div(props => ({
  border: '2px solid #E3EDFD',
  borderRight: '2px solid #E3EDFD',
  borderLeft: props.first ? '2px solid #E3EDFD' : 'none',
  '& p': {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  },
}))

const BlockHeader = styled.div({
  position: 'relative',
  zIndex: '1',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '20px',
  [phablet_max]: {
    paddingLeft: '10px',
  },
})
const WeekdayBlockHeader = styled(BlockHeader)(props => ({
  background: props.active ? green_shiny : '#3B4C66',
  '& p': {
    color: 'white',
  },
}))

const WeekdayBlockContent = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '14px',
  paddingBottom: '6px',
  background: props.bgImage ? `url(${props.bgImage}) no-repeat center center` : 'white',
  WebkitBackgroundSize: 'cover',
  MozBackgroundSize: 'cover',
  OBackgroundSize: 'cover',
  backgroundSize: 'cover',
  '& p': {
    lineHeight: '100%',
  },
  [phablet_max]: {
    paddingLeft: '10px',
  },
}))

const StockNumberDisplay = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
})

const StockTotalInput = styled.input(props => ({
  fontWeight: 'normal',
  fontSize: '27px',
  lineHeight: '30px',
  width: 'calc(100% - 10px)',
  color: props.hasChanges ? `${blue_strong} !important` : '#242424',
  background: 'none',
  outline: 'none',
  boxShadow: 'none',
  border: 'none',
  fontFamily: 'Satoshi',
  [phablet_max]: {
    fontSize: '19px',
    lineHeight: '21px',
  },
}))

// const AvailabilityControl = styled.div({
//   borderRadius: '0 0 5px 5px',
//   background: '#3B4C66',
//   '& p': {
//     color: 'white',
//   }
// })

// const WeekdayCirclesRoot = styled.div({
//   margin: '10px 0',
//   display: 'flex',
//   justifyContent: 'space-between',
// })

// const WeekdayCircle = styled.div(props => ({
//   cursor: 'pointer',
//   color: props.active ? green_shiny : 'white',
//   display: 'inline-flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   WebkitUserSelect: 'none',
//   MozUserSelect: 'none',
//   MsUserSelect: 'none',
//   userSelect: 'none',
// }))

// const CustomPTiny = styled(PTiny)({
//   textAlign: 'center',
//   marginTop: '5px',
//   WebkitUserSelect: 'none',
//   MozUserSelect: 'none',
//   MsUserSelect: 'none',
//   userSelect: 'none',
// })
