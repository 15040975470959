import axios from 'axios'
import {
  CLEAR_CART,
  ADD_PRODUCT_TO_CART,
  REMOVE_RESERVATION_FROM_CART,
  CHANGE_TEMPORARY_CART_AMOUNT,
  CLEAR_TEMPORARY_CART_AMOUNT,
  SET_PRODUCT_VIEWING_INDEX,
  SET_CLIENT_DETAILS,
  CLEAR_CLIENT_DETAILS,
  SUBMIT_SHOP_RESERVATIONS,
  SUBMIT_SHOP_RESERVATIONS_SUCCESS,
  SUBMIT_SHOP_RESERVATIONS_ERROR,

  // SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE,
  // SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_SUCCESS,
  // SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_ERROR,
  SUBMIT_RESERVATION_FORM,
  SUBMIT_RESERVATION_FORM_SUCCESS,
  SUBMIT_RESERVATION_FORM_ERROR,

  // GET_LOCALSTORAGE_RESERVATIONS,
  // GET_LOCALSTORAGE_RESERVATIONS_SUCCESS,
  // GET_LOCALSTORAGE_RESERVATIONS_ERROR,

  // DELETE_RESERVATION_IN_LOCALSTORAGE,
  // DELETE_RESERVATION_IN_LOCALSTORAGE_SUCCESS,
  // DELETE_RESERVATION_IN_LOCALSTORAGE_ERROR,
  CANCEL_REMOTE_RESERVATION,
  CANCEL_REMOTE_RESERVATION_SUCCESS,
  CANCEL_REMOTE_RESERVATION_ERROR,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_SUCCESS,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_ERROR,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_SUCCESS,
  GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR,
  REMOVE_PRODUCT_FROM_RESERVATION,
  REMOVE_PRODUCT_FROM_RESERVATION_SUCCESS,
  REMOVE_PRODUCT_FROM_RESERVATION_ERROR,
  GET_ACCOUNT_DATA_FOR_CLIENT,
  GET_ACCOUNT_DATA_FOR_CLIENT_SUCCESS,
  GET_ACCOUNT_DATA_FOR_CLIENT_ERROR,

  // GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG,
  // GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_SUCCESS,
  // GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_ERROR,

  // GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG,
  // GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_SUCCESS,
  // GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_ERROR,
} from '../action-types'
import { getURLFormattedDate } from '../../utils/date'

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  }
}

export const setClientDetails = clientDetails => {
  return {
    type: SET_CLIENT_DETAILS,
    clientDetails: clientDetails,
  }
}

export const clearClientDetails = () => {
  return {
    type: CLEAR_CLIENT_DETAILS,
  }
}

export const addProductToCart = productData => {
  return {
    type: ADD_PRODUCT_TO_CART,
    productId: productData.productId,
    amount: productData.amount,
    // dayDate: productData.dayDate,
    dayDateURL: productData.dayDateURL,
  }
}

export const removeReservationFromCart = dayDate => {
  return {
    type: REMOVE_RESERVATION_FROM_CART,
    dayDate: dayDate,
  }
}

export const setProductViewingIndex = index => {
  return {
    type: SET_PRODUCT_VIEWING_INDEX,
    index: index,
  }
}

export const changeTemporaryCartAmount = newAmount => {
  return {
    type: CHANGE_TEMPORARY_CART_AMOUNT,
    amount: newAmount,
  }
}

export const clearTemporaryCartAmount = () => {
  return {
    type: CLEAR_TEMPORARY_CART_AMOUNT,
  }
}

export function submitReservationForm(accountId, clientDetails, appId, history) {
  if (!accountId) {
    return console.log('accountId missing')
  }

  return dispatch => {
    dispatch({ type: SUBMIT_RESERVATION_FORM })
    try {
      dispatch({
        type: SUBMIT_RESERVATION_FORM_SUCCESS,
        payload: clientDetails,
      })
    } catch (err) {
      dispatch({ type: SUBMIT_RESERVATION_FORM_ERROR, payload: err })
    }
  }
}

export function submitReservations(accountId, reservationsByDay, clientDetails, history, appId) {
  if (!accountId) {
    return console.log('accountId missing')
  }

  return dispatch => {
    dispatch({ type: SUBMIT_SHOP_RESERVATIONS })
    axios
      .post(
        `/api/v1/create-reservations/${accountId}`,
        { reservations: reservationsByDay, clientDetails: clientDetails, timeCreated: Date.now() },
        {
          withCredentials: false,
        },
      )
      .then(response => {
        // addReservationToLocalStorage(accountId, response.data)
        dispatch({
          type: SUBMIT_SHOP_RESERVATIONS_SUCCESS,
          payload: response.data,
        })
        history.push(`/shop/${appId}/create-reservations-success`)
      })
      .catch(err => {
        dispatch({ type: SUBMIT_SHOP_RESERVATIONS_ERROR, payload: err.response.data })
        history.push(`/shop/${appId}/create-reservations-error`)
      })
  }
}

export function removeProductFromReservation(accountId, reservationId, productId) {
  if (!accountId) {
    return console.log('accountId missing')
  }
  if (!reservationId) {
    return console.log('reservationId missing')
  }
  if (!productId) {
    return console.log('productId missing')
  }

  return dispatch => {
    dispatch({ type: REMOVE_PRODUCT_FROM_RESERVATION })
    axios
      .get(
        `/api/v1/remove-product-from-reservation/${accountId}/${reservationId}/${productId}/${getURLFormattedDate(
          new Date(),
        )}`,
      )
      .then(response => {
        dispatch({
          type: REMOVE_PRODUCT_FROM_RESERVATION_SUCCESS,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: REMOVE_PRODUCT_FROM_RESERVATION_ERROR, payload: err })
      })
  }
}

// export const getLocalStorageReservations = (accountId) => {
//   return dispatch => {
//     dispatch({ type: GET_LOCALSTORAGE_RESERVATIONS })

//     const localStorageReservations = getReservationsFromLocalStorage(accountId)

//     if (localStorageReservations) {
//       return dispatch({
//         type: GET_LOCALSTORAGE_RESERVATIONS_SUCCESS,
//         payload: localStorageReservations,
//       })
//     }

//     return dispatch({
//       type: GET_LOCALSTORAGE_RESERVATIONS_ERROR,
//       payload: null,
//     })
//   }
// }

// export function saveNewReservationsInLocalStorage(accountId, sanitisedReservations) {
//   if (!accountId) { return console.log('accountId missing') }

//   return dispatch => {
//     dispatch({ type: SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE })
//     try {
//       addMultipleReservationsToLocalStorage(accountId, sanitisedReservations)
//       dispatch({
//         type: SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_SUCCESS,
//         payload: sanitisedReservations,
//       })
//     } catch(err) {
//       dispatch({type: SAVE_NEW_RESERVATIONS_IN_LOCALSTORAGE_ERROR, payload: err })
//     }
//   }
// }

export function cancelReservationFromWidget(accountId, reservationId) {
  if (!accountId) {
    return console.log('accountId missing')
  }

  return dispatch => {
    dispatch({ type: CANCEL_REMOTE_RESERVATION })
    axios
      .get(`/api/v1/cancel-reservation/${accountId}/${reservationId}/${Date.now()}`, {
        withCredentials: false,
      })
      .then(response => {
        // addReservationToLocalStorage(accountId, response.data)
        dispatch({
          type: CANCEL_REMOTE_RESERVATION_SUCCESS,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: CANCEL_REMOTE_RESERVATION_ERROR, payload: err })
      })
  }
}

// export function deleteReservationFromLocalStorage(accountId, reservationId) {
//   if (!accountId) { return console.log('accountId missing') }

//   return dispatch => {
//     dispatch({ type: DELETE_RESERVATION_IN_LOCALSTORAGE })
//     try {
//       deleteLocalStorageReservation(accountId, reservationId)
//       dispatch({
//         type: DELETE_RESERVATION_IN_LOCALSTORAGE_SUCCESS,
//         payload: reservationId,
//       })
//     } catch(err) {
//       dispatch({
//         type: DELETE_RESERVATION_IN_LOCALSTORAGE_ERROR,
//         payload: err
//       })
//     }
//   }
// }

export function getClientDataForPersonalPage(clientShortId, shopSlug) {
  if (!clientShortId) {
    return console.log('clientShortId missing')
  }

  return dispatch => {
    dispatch({ type: GET_CLIENT_DATA_FOR_PERSONAL_PAGE })
    axios
      .get(`/api/v1/clients/${shopSlug}/${clientShortId}`, {
        withCredentials: false,
      })
      .then(response => {
        dispatch({
          type: GET_CLIENT_DATA_FOR_PERSONAL_PAGE_SUCCESS,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: GET_CLIENT_DATA_FOR_PERSONAL_PAGE_ERROR, payload: err })
      })
  }
}

export function getClientDataForPersonalPageByPhone(clientPhone, shopSlug) {
  if (!clientPhone) {
    return console.log('clientPhone missing')
  }

  return dispatch => {
    dispatch({ type: GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE })
    axios
      .get(`/api/v1/client_reservations_by_phone/${shopSlug}/${clientPhone}`, {
        withCredentials: false,
      })
      .then(response => {
        dispatch({
          type: GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_SUCCESS,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: GET_CLIENT_DATA_FOR_PERSONAL_PAGE_BY_PHONE_ERROR, payload: err })
      })
  }
}
export function getAccountData(shopSlug) {
  if (!shopSlug) {
    return console.log('clientPhone missing')
  }

  return dispatch => {
    dispatch({ type: GET_ACCOUNT_DATA_FOR_CLIENT })
    axios
      .get(`/api/v1/account_data/${shopSlug}`, {
        withCredentials: false,
      })
      .then(response => {
        dispatch({
          type: GET_ACCOUNT_DATA_FOR_CLIENT_SUCCESS,
          payload: response.data,
        })
      })
      .catch(err => {
        dispatch({ type: GET_ACCOUNT_DATA_FOR_CLIENT_ERROR, payload: err })
      })
  }
}

// export function getShopProductsByBusinessNameSlug(businessName) {
//   return dispatch => {
//     // const token = localStorage.getItem(tokenName)
//     // setAuthorizationToken(token)
//     dispatch({
//       type: GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG,
//     })
//     axios.get(`/api/v1/get-shop-products-by-business-name-slug/${businessName}`, {
//       withCredentials: false,
//     }).then((response) => {
//       dispatch({
//         type: GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_SUCCESS,
//         payload: response.data,
//       })
//     })
//     .catch((err) => {
//       dispatch({type: GET_SHOP_PRODUCTS_BY_BUSINESS_NAME_SLUG_ERROR, payload: err })
//     })
//   }
// }

// export function getShopAccountByBusinessNameSlug(businessName) {
//   return dispatch => {
//     // const token = localStorage.getItem(tokenName)
//     // setAuthorizationToken(token)
//     dispatch({
//       type: GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG,
//     })
//     axios.get(`/api/v1/get-shop-account-by-business-name-slug/${businessName}`, {
//       withCredentials: false,
//     }).then((response) => {
//       dispatch({
//         type: GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_SUCCESS,
//         payload: response.data,
//       })
//     })
//     .catch((err) => {
//       dispatch({type: GET_SHOP_ACCOUNT_BY_BUSINESS_NAME_SLUG_ERROR, payload: err })
//     })
//   }
// }
