import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { WhiteBox, FlexDiv } from '../../../../ui/elements/layout'
import { green } from '../../../../ui/defaults/colors'
import { tablet_max } from '../../../../ui/defaults/media-queries'
import { P } from '../../../../ui/elements/typography'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

class ProductListItem extends Component {
  renderWithLink() {
    const { _id } = this.props
    return <NavLink to={`/produtos/${_id}`}>{this.renderContent()}</NavLink>
  }
  renderContent() {
    const { _id, title_pt, sale_active, image_url, handleProductOrderChange } = this.props
    return (
      <CustomWhiteBox>
        <CustomFlexDiv>
          <ImageElement bgImage={image_url} />
          {sale_active && <SaleActiveCircle />}
          <CustomP>{title_pt}</CustomP>
        </CustomFlexDiv>
        <div>
          {handleProductOrderChange && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <ArrowUpContainer onClick={() => handleProductOrderChange(_id, 'up')}>
                <FAIcon icon="faChevronUp" family="solid" />
              </ArrowUpContainer>
              <ArrowDownContainer onClick={() => handleProductOrderChange(_id, 'down')}>
                <FAIcon icon="faChevronDown" family="solid" />
              </ArrowDownContainer>
            </div>
          )}
        </div>
      </CustomWhiteBox>
    )
  }
  render() {
    const { handleProductOrderChange } = this.props
    if (!handleProductOrderChange) {
      return this.renderWithLink()
    }
    return this.renderContent()
  }
}

export default ProductListItem

const CustomWhiteBox = styled(WhiteBox)({
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
  // alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px',
  padding: '0',
  overflow: 'hidden',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
})

const CustomFlexDiv = styled(FlexDiv)({
  height: '80px',
  alignItems: 'center',
  [tablet_max]: {
    height: '50px',
  },
})

const CustomP = styled(P)({
  lineHeight: '100%',
  marginLeft: '14px',
  textAlign: 'center',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  [tablet_max]: {
    marginLeft: '10px',
    fontSize: '15px',
  },
})

const ArrowContainer = styled.div({
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  cursor: 'pointer',
  background: '#F5F5F5',
  height: '50%',
  width: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ':hover': {
    background: '#f2f1f1',
  },
  [tablet_max]: {
    '& svg': {
      fontSize: '16px',
    },
  },
})

const ArrowUpContainer = styled(ArrowContainer)({})

const ArrowDownContainer = styled(ArrowContainer)({})

const SaleActiveCircle = styled.div({
  background: green,
  borderRadius: '10px',
  height: '20px',
  width: '20px',
  marginLeft: '16px',
  [tablet_max]: {
    height: '10px',
    width: '10px',
    marginLeft: '10px',
  },
})

const ImageElement = styled.div(props => ({
  width: '80px',
  height: '80px',
  background: props.bgImage ? `url(${props.bgImage}) no-repeat center center` : '#E8EFF9',
  backgroundSize: 'cover',
  [tablet_max]: {
    width: '50px',
    height: '50px',
  },
}))
