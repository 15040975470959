import React, { Component } from 'react'
import styled from '@emotion/styled'
import {
  Input,
  StyledFormDiv,
  TextArea,
  placeholderStyleTextareaDark,
  placeholderStyleDark,
} from '../../../ui/elements/forms'
import { FlexDiv } from '../../../ui/elements/layout'
import { PSmall } from '../../../ui/elements/typography'
import { orange } from '../../../ui/defaults/colors'
import { RoundButtonWhite } from '../../../ui/elements/buttons'
import { Checkbox } from '../../../ui/elements/checkbox'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

const maxObservationsChars = 200

class ReservationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activatedMessageBox: false,
    }
  }

  renderMessageBox = () => {
    const { observations, handleObservationsChange } = this.props
    return (
      <div>
        <br />
        <CustomTextArea
          style={{ height: '150px' }}
          type="text"
          name="observations"
          maxLength={maxObservationsChars}
          placeholder="Observações (opcional)"
          value={observations}
          onChange={handleObservationsChange}
        />
        <CustomPSmall>
          {observations && observations.length
            ? `${maxObservationsChars - observations.length} caracteres disponíveis`
            : null}
        </CustomPSmall>
      </div>
    )
  }

  render() {
    const {
      name,
      telephone,
      handleSubmit,
      handleNameChange,
      handleTelephoneChange,
      handleTelephoneKeyDown,
      alreadySubmitted,
      formIsValid,
      nameInvalid,
      telephoneInvalid,
    } = this.props
    const { activatedMessageBox } = this.state
    return (
      <RootDiv>
        <StyledFormDiv style={{ width: '100%' }}>
          <label className="form-label" style={{ color: '#929292' }}>
            PRIMEIRO E ÚLTIMO NOME
          </label>
          <CustomInput
            type="text"
            value={name}
            placeholder="O seu primeiro e último nome"
            onChange={handleNameChange}
          />
          {alreadySubmitted && !formIsValid && nameInvalid && (
            <PSmall style={{ color: orange, marginTop: '8px' }}>
              Tem que introduzir o seu primeiro e último nome.
            </PSmall>
          )}
          <br />
          <label className="form-label" style={{ color: '#929292' }}>
            Telemóvel (Necessário para identificação do cliente)
          </label>
          <CustomInput
            type="text"
            value={telephone}
            placeholder="O seu telemóvel"
            onKeyDown={handleTelephoneKeyDown}
            onChange={handleTelephoneChange}
          />
          {alreadySubmitted && !formIsValid && telephoneInvalid && (
            <PSmall style={{ color: orange, marginTop: '8px' }}>
              Por favor verifique o número de telemóvel.
            </PSmall>
          )}
          <br />
          <FlexDiv style={{ alignItems: 'center' }}>
            <Checkbox
              checkActive={activatedMessageBox}
              onClick={() => this.setState({ activatedMessageBox: !activatedMessageBox })}
            >
              <FAIcon icon="faCheck" family="solid" />
            </Checkbox>
            <label
              className="form-label"
              style={{ color: '#929292', margin: '0', marginLeft: '6px' }}
            >
              OBSERVAÇÕES (OPCIONAL)
            </label>
          </FlexDiv>
          {activatedMessageBox && this.renderMessageBox()}
          <br />
          <CustomRoundButtonWhite onClick={handleSubmit}>Confirmar dados</CustomRoundButtonWhite>
          <br />
          <br />
        </StyledFormDiv>
      </RootDiv>
    )
  }
}

export default ReservationForm

const RootDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
})

const CustomInput = styled(Input)({
  background: '#242424',
  color: 'white !important',
  borderBottom: '2px solid white !important',
  ...placeholderStyleDark,
})

const CustomPSmall = styled(PSmall)({
  color: 'white !important',
  marginTop: '10px',
})

const CustomTextArea = styled(TextArea)({
  background: 'white',
  color: '#242424 !important',
  borderBottom: 'none !important',
  height: '100px !important',
  ...placeholderStyleTextareaDark,
})

const CustomRoundButtonWhite = styled(RoundButtonWhite)({
  ':hover': {
    background: '#fbd8be',
  },
  ':active': {
    background: '#f5c7a5',
  },
})
