import { 
  TRIGGER_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
} from '../action-types'

export default function reducer (state={
    question: null,
    confirmText: null,
    cancelText: null,
    confirmCallback: null,
  }, action) {

  switch(action.type)
  {
    case TRIGGER_CONFIRM_DIALOG: {
      return {
        ...state,
        question: action.question,
        confirmText: action.confirmText,
        cancelText: action.cancelText,
        confirmCallback: action.confirmCallback,
      }
    }
    case CLOSE_CONFIRM_DIALOG: {
      return {
        ...state,
        question: null,
        confirmCallback: null,
        confirmText: null,
        cancelText: null,
      }
    }
    default:
      return state
  }
}
