import React, { Component } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router'
import Header from '../Header'
import { phone_max } from '../../ui/defaults/media-queries'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlexDiv } from '../../ui/elements/layout'
import Spinner from '../../ui/components/spinner'
import Background from '../../components_system/background'
import {
  prepareReservationsForAPICreation,
} from '../../utils/storeUtils'
import {
  submitReservations,
} from '../../redux/shop/actions'

@connect(
  store => ({
    cart: store.shop.cart,
    clientDetails: store.shop.clientDetails,
    productsStore: store.product.productsStore,
    storeAccount: store.product.storeAccount,
  }),
  dispatch => ({
    actions: bindActionCreators({
      submitReservations
    }, dispatch)
  })
)

class WidgetCreateReservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: true
    }
  }

  componentDidMount() {
    const {
      cart,
      clientDetails,
      storeAccount,
      productsStore,
    } = this.props
    if (!storeAccount || !storeAccount.account_logo) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!productsStore) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!cart) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }

    const reservationsPreparedForAPICreation = prepareReservationsForAPICreation(cart, productsStore)

    // submit reservations via redux
    this.props.actions.submitReservations(storeAccount._id, reservationsPreparedForAPICreation, clientDetails, this.props.history, this.props.match.params.app_id)
  }


  render() {
    const {
      processing,
    } = this.state
    const {
      cart,
      storeAccount,
      productsStore,
    } = this.props
    if (!storeAccount || !storeAccount.account_logo) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!productsStore) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!cart) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }

    if (processing) {
      return (
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <br />
          <FlexDiv style={{width: '100%', justifyContent: 'center'}}>
            <Spinner color='white' />
          </FlexDiv>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Background bgColor='#242424' />
        </PaddingBox>
      )
    }

    return null
  }
}

const PaddingBox = styled.div({
  paddingLeft: '50px',
  paddingRight: '50px',
  [phone_max]: {
    paddingLeft: '30px',
    paddingRight: '30px',
  }
})

export default withRouter(WidgetCreateReservations)
