import { css } from '@emotion/core'

export const reset = css`
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`

export const globals = css`
  * {
    -webkit-font-smoothing: antialiased;
  }
  @keyframes pulse {
    50% {
      filter: brightness(1.2);
      transform: scale(1.1);
    }
  }
  ::selection {
    background: #fff7c7; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: #fff7c7; /* Gecko Browsers */
  }
  @keyframes pulse {
    50% {
      filter: brightness(1.2);
    }
  }
  @-moz-keyframes rotate {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  /*
 * Globals
 */
  body {
    padding: 0;
    background: #e8eff9;
    color: #353535;
    line-height: 1.5;
    font-family: 'Satoshi', serif;
    font-size: 20px;
    font-weight: 500;
  }
  a {
    color: #353535;
    text-decoration: none;
    background-repeat: repeat-x;
    background-size: 2px 2px;
    background-position: 0 23px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Satoshi', sans-serif;
  }
  h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
  }
  h2 {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }
  h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
  }
  p {
    margin: 0;
    line-height: 150%;
    font-family: 'Satoshi';
    font-weight: 500;
  }
  p a {
    color: #63c1e8;
    font-weight: bold;
    text-decoration: underline;
  }
  pre,
  ul,
  ol {
    margin-bottom: 20px;
  }
  strong {
    font-weight: bold;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  em {
    font-style: italic;
  }
  .fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
  }

  .fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8);
  }

  @-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
