import React from 'react'
import {
  GoogleButtonContainer,
  GoogleLogo,
  SquaredButton,
  ButtonGreenSmall,
  FatButtonElement,
  IconButtonElement,
} from '../../elements/buttons'
import { P } from '../../elements/typography'
import { blue } from '../../defaults/colors'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

export const ActionButton = ({
  loading,
  onClick,
  icon,
  disabled,
  color,
  bgColor,
  style,
  children,
}) => (
  <SquaredButton
    disabled={disabled}
    color={color}
    bgColor={loading ? blue : bgColor}
    addIconMargin={icon || loading}
    style={style}
    onClick={onClick}
  >
    {children}
    {!loading && icon}
    {loading && <FAIcon className="fa-spin" icon="faSpinnerThird" family="solid" />}
  </SquaredButton>
)

export const RoundGreenActionButton = ({ loading, onClick, icon, disabled, children }) => (
  <ButtonGreenSmall disabled={disabled} onClick={onClick}>
    {children}
    {!loading && icon}
    {loading && <FAIcon className="fa-spin" icon="faSpinnerThird" family="solid" />}
  </ButtonGreenSmall>
)

export const GoogleButton = props => (
  <GoogleButtonContainer onClick={props.onClick} extend={props.extend}>
    <div>
      <GoogleLogo />
    </div>
    {props.children}
  </GoogleButtonContainer>
)

export const FatButton = props => (
  <FatButtonElement
    color={props.color}
    bgColor={props.bgColor}
    iconSide={props.iconSide}
    iconColor={props.iconColor}
    icon={props.icon}
    onClick={props.onClick}
  >
    {props.iconSide === 'left' && props.icon}
    {props.label && <P>{props.label}</P>}
    {props.iconSide === 'right' && props.icon}
  </FatButtonElement>
)

export const IconButton = props => (
  <IconButtonElement
    color={props.color}
    bgColor={props.bgColor}
    iconSide={props.iconSide}
    iconColor={props.iconColor}
    icon={props.icon}
    onClick={props.onClick}
  >
    {props.iconSide === 'left' && props.icon}
    {props.label && <P>{props.label}</P>}
    {props.iconSide === 'right' && props.icon}
  </IconButtonElement>
)
