import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Header from '../Header'
import { PWhite } from '../../ui/elements/typography'
import Background from '../../components_system/background'
import { PaddingBox } from '../../app_ui/layout'
@connect(
  store => ({
    storeAccount: store.product.storeAccount,
    // productsStore: store.product.productsStore,
  }),
  null
)
class WidgetSoldOut extends Component {

  render() {
    const {
      storeAccount,
      // productsStore,
    } = this.props
    return (
      <PaddingBox>
        <Header
          accountLogo={storeAccount.account_logo}
          businessName={storeAccount.business_name}
        />
        <PWhite>
          A loja está fechada. Por favor volte mais tarde <span role="img" aria-label='emoji'>👩‍🍳</span>
        </PWhite>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Background bgColor='#242424' />
      </PaddingBox>
    )
  }
}

export default withRouter(WidgetSoldOut)
