import { 
  SET_MOBILE,
  TOGGLE_MOBILE_NAV,
  SET_VIEWPORT_DIMENTIONS,
} from '../action-types'

export const setMobile = dialogData => {
  return {
    type: SET_MOBILE,
  }
}

export const toggleMobileNav = dialogData => {
  return {
    type: TOGGLE_MOBILE_NAV,
  }
}

export const setViewportDimentions = windowHeight => {
  return {
    type: SET_VIEWPORT_DIMENTIONS,
  }
}


// const appModeActions = {
//   SET_MOBILE: 'SET_MOBILE',
//   HISTORY_UPDATE: 'HISTORY_UPDATE',
//   TOGGLE_MOBILE_NAV: 'TOGGLE_MOBILE_NAV',

//   setMobile: (location) => ({
//     type: appModeActions.HISTORY_UPDATE,
//     payload: true,
//   }),

//   historyUpdate: (location) => ({
//     type: appModeActions.HISTORY_UPDATE,
//     payload:location,
//   }),

//   toggleMobileNav: () => ({
//     type: appModeActions.TOGGLE_MOBILE_NAV,
//   }),
// }

// export default appModeActions