import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FlexDiv } from '../../../ui/elements/layout'
import { PTiny, PWhite, H3White, H3WhiteBold, PSmallWhite } from '../../../ui/elements/typography'
import { green_shiny, blue_shiny, orange, red_light } from '../../../ui/defaults/colors'
import {
  translateEnglishWeekdayNameLowerCase,
  translateEnglishWeekdayName,
} from '../../../utils/translation'
import { getMonthDayNumber } from '../../../utils/dayHelpers'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

const translateFunction = (dayName, lowerCase) => {
  if (lowerCase) return translateEnglishWeekdayNameLowerCase(dayName)
  return translateEnglishWeekdayName(dayName)
}

const stateLabelStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: '10px',
}

const generateJSX = state => {
  switch (state) {
    case 'prepared':
      return <PTiny style={{ color: orange, ...stateLabelStyle }}>Preparada</PTiny>
    case 'finalised':
      return <PTiny style={{ color: green_shiny, ...stateLabelStyle }}>Finalizada</PTiny>
    default:
      // requested
      return <PTiny style={{ color: blue_shiny, ...stateLabelStyle }}>Pedida</PTiny>
  }
}
export const SingleReservatioDisplay = ({
  reservations,
  showState,
  lowerCase,
  handleDeleteReservation,
  showObservations,
  cancelled,
  handleDeleteProductFromReservation,
}) => {
  const [editingSettings, setEditingSettings] = useState(false)
  return (
    <RootDiv style={{ width: '100%' }}>
      {reservations.map((reservation, index) => (
        <div
          key={index}
          style={{
            width: '100%',
            marginBottom: '30px',
            borderBottom: '1px solid #ffffff69',
            paddingBottom: '12px',
          }}
        >
          <WeekdayRow>
            <PWhite>
              Para {translateFunction(reservation.dayName)},{' '}
              {getMonthDayNumber(reservation.dayDate)}
            </PWhite>
            {!cancelled && reservation.state === 'requested' && (
              <HeaderIconHolder
                editingSettings={editingSettings}
                onClick={() => setEditingSettings(!editingSettings)}
              >
                <FAIcon icon="faTrash" family="solid" />
              </HeaderIconHolder>
            )}
          </WeekdayRow>
          {showState && generateJSX(reservation.state)}
          {reservation.products.map((reservationProduct, indexInner) => (
            <CustomFlexDiv
              key={indexInner + 2000}
              style={{ marginTop: indexInner === 0 ? '0' : '6px' }}
            >
              <FlexDiv style={{ alignItems: 'center' }}>
                {editingSettings && (
                  <ProductTrashIconHolder>
                    <FAIcon
                      icon="faTimes"
                      family="solid"
                      onClick={() =>
                        handleDeleteProductFromReservation(reservation._id, reservationProduct._id)
                      }
                    />
                  </ProductTrashIconHolder>
                )}
                <H3WhiteBold style={{ marginRight: '6px' }}>
                  {reservationProduct.amount}
                </H3WhiteBold>
                <H3White>{reservationProduct.title_pt}</H3White>
              </FlexDiv>
              <img src={reservationProduct.image_url} alt={reservationProduct.title_pt} />
            </CustomFlexDiv>
          ))}
          {showObservations && reservation.observations && reservation.observations !== '' && (
            <CustomPSmallWhite>"{reservation.observations}"</CustomPSmallWhite>
          )}
        </div>
      ))}
    </RootDiv>
  )
}

export default SingleReservatioDisplay

const RootDiv = styled.div({
  '& *': {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  },
})

const HeaderIconHolder = styled.div(props => ({
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: '3px',
  width: '30px',
  height: '30px',
  background: props.editingSettings ? red_light : 'white',
  justifyContent: 'center',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '3px',
  '& svg': {
    color: props.editingSettings ? '#242424' : '#242424',
    fontSize: '16px',
  },
  ':hover': {
    background: props.editingSettings ? red_light : blue_shiny,
  },
}))

const ProductTrashIconHolder = styled.div({
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: '10px',
  width: '24px',
  height: '24px',
  // background: 'white',
  justifyContent: 'center',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '6px',
  '& svg': {
    color: red_light,
    fontSize: '25px',
  },
})

const CustomFlexDiv = styled(FlexDiv)({
  width: '100%',
  alignItems: 'center',
  '& img': {
    marginLeft: 'auto',
    height: '30px',
    width: '30px',
    borderRadius: '20px',
    display: 'inline',
  },
})

const WeekdayRow = styled(FlexDiv)({
  width: '100%',
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  '& .fa-times': {
    fontSize: '24px',
    marginLeft: '12px',
    color: '#7d7d7d',
    cursor: 'pointer',
    position: 'relative',
    top: '0px',
    ':hover': {
      color: 'red',
    },
    ':active': {
      color: '#ff7171',
    },
  },
})

const CustomPSmallWhite = styled(PSmallWhite)({
  fontStyle: 'italic',
  opacity: '0.5',
  marginTop: '10px',
  maxWidth: '490px',
})
