import React, { Component } from 'react'
import styled from '@emotion/styled'
import {
  translateEnglishWeekdayNameMedium,
  translateEnglishWeekdayNameSmall,
} from '../../../../utils/translation'
import { phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import { grey_border, green_shiny, red_light } from '../../../../ui/defaults/colors'
import { PSmall } from '../../../../ui/elements/typography'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

class WeekdaysAvailability extends Component {
  render() {
    const { openingDays, weekdays_availability, handleDayPartClick } = this.props
    if (!weekdays_availability) return null
    const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    return (
      <div>
        <AvailabilityControl>
          <GridRoot>
            {weekDays.map((weekDay, index) => (
              <WeekdayElement key={index}>
                <GridColumnElementHeader>
                  <CustomPSmallWhiteDesktop>
                    {translateEnglishWeekdayNameMedium(weekDays[index])}
                  </CustomPSmallWhiteDesktop>
                  <CustomPSmallWhiteMobile>
                    {translateEnglishWeekdayNameSmall(weekDays[index])}
                  </CustomPSmallWhiteMobile>
                  {!openingDays[weekDay] && (
                    <FAIcon icon="faTimesCircle" family="solid" style={{ color: red_light }} />
                  )}
                </GridColumnElementHeader>
                <GridColumnElementValue
                  hasBorderRight={index !== weekDays.length - 1}
                  isActive={weekdays_availability[weekDay].morning}
                  onClick={() =>
                    handleDayPartClick(weekDay, 'morning', !weekdays_availability[weekDay].morning)
                  }
                >
                  <CustomPSmall>Manhã</CustomPSmall>
                </GridColumnElementValue>
                <GridColumnElementValue
                  bottom
                  hasBorderRight={index !== weekDays.length - 1}
                  isActive={weekdays_availability[weekDay].afternoon}
                  onClick={() =>
                    handleDayPartClick(
                      weekDay,
                      'afternoon',
                      !weekdays_availability[weekDay].afternoon,
                    )
                  }
                >
                  <CustomPSmall>Tarde</CustomPSmall>
                </GridColumnElementValue>
              </WeekdayElement>
            ))}
          </GridRoot>
        </AvailabilityControl>
      </div>
    )
  }
}

export default WeekdaysAvailability

const AvailabilityControl = styled.div({
  borderRadius: '0 0 5px 5px',
})

const GridRoot = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, auto)',
  border: `2px solid ${grey_border}`,
  boxSizing: 'border-box',
  borderRadius: '2px',
  [phablet_max]: {
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: '10px',
  },
  [phone_max]: {
    gridTemplateColumns: 'repeat(2, auto)',
    gridGap: '6px',
  },
})

const GridColumnElement = styled.div(props => ({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  borderTop: props.bottom ? `2px solid ${grey_border}` : 'none',
  borderRight: props.hasBorderRight ? `2px solid ${grey_border}` : 'none',
}))

const GridColumnElementValue = styled.div(props => ({
  width: '100%',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  background: props.isActive ? green_shiny : 'white',
  '& p': {
    color: props.isActive ? 'white' : '#242424',
  },
}))

const GridColumnElementHeader = styled(GridColumnElement)(props => ({
  background: '#46556B',
  flexDirection: 'row',
  '& svg': {
    color: green_shiny,
    fontSize: '16px',
    marginLeft: '8px',
    position: 'relative',
    top: '-1px',
    [phablet_max]: {
      fontSize: '12px',
      marginLeft: '4px',
    },
  },
}))

const WeekdayElement = styled.div(props => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
}))

const CustomPSmall = styled(PSmall)({
  textAlign: 'center',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  lineHeight: '100%',
  display: 'inline-flex',
  marginTop: '0',
})

const CustomPSmallWhite = styled(CustomPSmall)({
  color: 'white',
})

const CustomPSmallWhiteMobile = styled(CustomPSmallWhite)({
  display: 'none',
  [phablet_max]: {
    display: 'inline-flex',
  },
})

const CustomPSmallWhiteDesktop = styled(CustomPSmallWhite)({
  display: 'inline-flex',
  [phablet_max]: {
    display: 'none',
  },
})
