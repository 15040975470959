import { 
  SET_MOBILE,
  TOGGLE_MOBILE_NAV,
  SET_VIEWPORT_DIMENTIONS,
  HISTORY_UPDATE,
} from '../action-types'

let lastLocation = {}

export default function reducer(state = {
  mode: 'public',
  isMobile: false,
  mobileNavOpen: false,
}, action) {
  switch (action.type) {
    case HISTORY_UPDATE: {
      // calculate last window location
      const lastLocationState = { ...lastLocation }
      lastLocation = action.payload
      return {
        ...state,
        lastLocation: lastLocationState,
      }
    }
    case TOGGLE_MOBILE_NAV: {
      return {
        ...state,
        mobileNavOpen: !state.mobileNavOpen,
      }
    }
    case SET_MOBILE: {
      return {
        ...state,
        isMobile: true,
      }
    }
    case SET_VIEWPORT_DIMENTIONS: {
      return {
        ...state,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      }
    }
    default:
      break
  }
  return state
}
