import {
  format,
  // parseISO,
  // setHours,
  // setYear,
  // setMonth,
  // setDay,
} from 'date-fns'
import {
  getTodayDate,
  // queryWeekStartOffset,
} from './dayHelpers'
import {
  getDateFromURLDate,
} from './date'

export const getAvailabilityString = (product) => {
  let numDays = 0
  const daysTest = {
    monday: product.stock_monday > 0,
    tuesday: product.stock_tuesday > 0,
    wednesday: product.stock_wednesday > 0,
    thursday: product.stock_thursday > 0,
    friday: product.stock_friday > 0,
    saturday: product.stock_saturday > 0,
    sunday : product.stock_sunday > 0,
  }
  if (daysTest.monday) { numDays++ }
  if (daysTest.tuesday) { numDays++ }
  if (daysTest.wednesday) { numDays++ }
  if (daysTest.thursday) { numDays++ }
  if (daysTest.friday) { numDays++ }
  if (daysTest.saturday) { numDays++ }
  if (daysTest.sunday) { numDays++ }

  if (numDays >= 6) {
    return 'Todos os dias'
  }

  // determine if it's TODAY and show "Hoje" instead
  if (numDays === 1) {
    const todayWeekdayName = format(getTodayDate(), "EEEE")
    if (daysTest.monday && todayWeekdayName === 'Monday') return 'Hoje'
    if (daysTest.tuesday && todayWeekdayName === 'Tuesday') return 'Hoje'
    if (daysTest.wednesday && todayWeekdayName === 'Wednesday') return 'Hoje'
    if (daysTest.thursday && todayWeekdayName === 'Thursday') return 'Hoje'
    if (daysTest.friday && todayWeekdayName === 'Friday') return 'Hoje'
    if (daysTest.saturday && todayWeekdayName === 'Saturday') return 'Hoje'
    if (daysTest.sunday && todayWeekdayName === 'Sunday') return 'Hoje'
  }

  let string = ''
  let entriesAdded = 0
  const needsE = numDays > 1
  if (daysTest.monday) {
    string += 'Segunda-feira'
    entriesAdded++
  }
  if (daysTest.tuesday) {
    if (string !== '') { string += ', ' }
    if (needsE && entriesAdded === numDays-1) { string += ' e ' }
    string += 'Terça-feira'
    entriesAdded++
  }
  if (daysTest.wednesday) {
    if (string !== '') { string += ', ' }
    if (needsE && entriesAdded === numDays-1) { string += ' e ' }
    string += 'Quarta-feira'
    entriesAdded++
  }
  if (daysTest.thursday) {
    if (string !== '') { string += ', ' }
    if (needsE && entriesAdded === numDays-1) { string += ' e ' }
    string += 'Quinta-feira'
    entriesAdded++
  }
  if (daysTest.friday) {
    if (string !== '') { string += ', ' }
    if (needsE && entriesAdded === numDays-1) { string += ' e ' }
    string += 'Sexta-feira'
    entriesAdded++
  }
  if (daysTest.saturday) {
    if (string !== '') { string += ', ' }
    if (needsE && entriesAdded === numDays-1) { string += ' e ' }
    string += 'Sábado'
    entriesAdded++
  }
  if (daysTest.sunday) {
    if (string !== '') { string += ', ' }
    if (needsE && entriesAdded === numDays-1) { string += ' e ' }
    string += 'Domingo'
    entriesAdded++
  }

  return string
}

export const prepareReservationProducts = (cartEntry, productIds, productsStore) => {
  
  const dayProductsArray = []
  for(const _id of productIds) {
    const foundProduct = productsStore.find(product => product._id === _id)
    if (foundProduct) {
      dayProductsArray.push({
        _id: _id,
        title_pt: foundProduct.title_pt,
        image_url: foundProduct.image_url,
        amount: cartEntry[_id].amount,
      })
    }
  }

  return dayProductsArray
}

export const prepareReservationsForAPICreation = (cart, productsStore) => {
  
  const reservationsToSend = []

  const dateEntries = Object.keys(cart)

  for(const dateEntry of dateEntries) {
    const currentDayDate = getDateFromURLDate(dateEntry)
    const cartEntry = cart[dateEntry]
    const productIds = Object.keys(cartEntry)
    const products = prepareReservationProducts(cartEntry, productIds, productsStore)

    reservationsToSend.push({
      yearMonthDay: dateEntry,
      dayDate: currentDayDate,
      dayName: format(currentDayDate, 'EEEE'),
      products: products,
    })
  }

  return reservationsToSend
}
