import React, { Component } from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { phablet_max } from '../../../ui/defaults/media-queries'
import { red_light } from '../../../ui/defaults/colors'
import { pStyle } from '../../../ui/elements/typography'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

export const Badge = props => {
  if (props.eventCount && props.eventCount > 0) {
    return <EventsBadge>{props.eventCount}</EventsBadge>
  }
  return null
}

class SidebarMobileInstance extends Component {
  renderItemsOpen() {
    return [
      <SidebarItem key="vistageral" to="/vista-geral" onClick={this.props.toggleMobileNav}>
        Vista geral
      </SidebarItem>,
      <SidebarItem key="reservas" to="/reservas" onClick={this.props.toggleMobileNav}>
        Reservas
      </SidebarItem>,
      <SidebarItem key="produtos" to="/produtos" onClick={this.props.toggleMobileNav}>
        Produtos
      </SidebarItem>,
      <SidebarItem key="relatorios" to="/relatorios" onClick={this.props.toggleMobileNav}>
        Relatórios
      </SidebarItem>,
      <SidebarItem key="options" to="/definicoes" onClick={this.props.toggleMobileNav}>
        Opções
      </SidebarItem>,
    ]
  }

  renderItemsClosed() {
    return [
      <SidebarIcon
        key="vistageral"
        to="/vista-geral"
        onClick={this.props.toggleMobileNav}
        activeClassName="active"
      >
        <FAIcon icon="faHome" family="solid" />
      </SidebarIcon>,
      <SidebarIcon
        key="reservas"
        to="/reservas"
        onClick={this.props.toggleMobileNav}
        activeClassName="active"
      >
        <FAIcon icon="faTh" family="solid" />
      </SidebarIcon>,
      <SidebarIcon
        key="produtos"
        to="/produtos"
        onClick={this.props.toggleMobileNav}
        activeClassName="active"
      >
        <FAIcon icon="faStar" family="solid" />
      </SidebarIcon>,
      <SidebarIcon
        key="relatorios"
        to="/relatorios"
        onClick={this.props.toggleMobileNav}
        activeClassName="active"
      >
        <FAIcon icon="faChartBar" family="solid" />
      </SidebarIcon>,
      <SidebarIcon
        key="options"
        to="/definicoes"
        onClick={this.props.toggleMobileNav}
        activeClassName="active"
      >
        <FAIcon icon="faCog" family="solid" />
      </SidebarIcon>,
    ]
  }
  render() {
    return (
      <SidebarRoot
        className="SidebarRoot"
        open={this.props.mobileNavOpen}
        windowHeight={this.props.windowHeight}
      >
        <div>
          {/*<TopToggleBox onClick={this.props.handleToggleMobileNav}>
              <FAIcon icon='faBars' family='solid' />
            </TopToggleBox>*/}
          <ItemsContainer>
            {this.props.mobileNavOpen ? this.renderItemsOpen() : this.renderItemsClosed()}
          </ItemsContainer>
        </div>
        <BottomLogoutBox onClick={this.props.handleLogoutUser}>
          <FAIcon icon="faPowerOff" family="solid" />
        </BottomLogoutBox>
      </SidebarRoot>
    )
  }
}

export default SidebarMobileInstance

const SidebarRoot = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: props.open ? '130px' : '60px',
  justifyContent: 'space-between',
  background: '#273140',
  height: `${props.windowHeight}px`,
  '& svg': {
    fontSize: '19px',
    // color: 'white',
  },
  [phablet_max]: {
    width: '40px',
    '& svg': {
      fontSize: '16px',
      // color: 'white',
    },
  },
}))

const BottomLogoutBox = styled.div({
  justifyContent: 'center',
  display: 'flex',
  background: '#313F54',
  paddingTop: '20px',
  paddingBottom: '20px',
  [phablet_max]: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  cursor: 'pointer',
  '& svg': {
    fontSize: '19px',
    color: 'white',
  },
})

const EventsBadge = styled.div({
  background: red_light,
  marginRight: '8px',
  color: 'white',
  borderRadius: '9px',
  marginLeft: '6px',
  lineHeight: '100%',
  fontSize: '15px',
  fontWeight: '900',
  position: 'relative',
  height: '19px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 6px',
  display: 'inline-flex',
})

const SidebarItemStyle = {
  width: '100%',
  ...pStyle,
  textAlign: 'left',
  fontSize: '17px',
  cursor: 'pointer',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  WebkitTransition: 'all 0.2s ease-out',
  MozTransition: 'all 0.2s ease-out',
  Otransition: 'all 0.2s ease-out',
  transition: 'all 0.2s ease-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '10px',
  color: '#E8EFF9',
  // ':last-child': {
  //   marginBottom: '0'
  // },
  '&.active': {
    textDecoration: 'underline',
  },
  '& span': {
    color: '#8297B8',
    marginRight: '8px',
  },
  '& svg': {
    fontSize: '13px',
    marginLeft: '3px',
    position: 'relative',
    top: '-6px',
  },
}

export const SidebarItem = styled(NavLink)(SidebarItemStyle)
export const SidebarItemA = styled.a(SidebarItemStyle)

export const SidebarIcon = styled(NavLink)({
  padding: '10px 0',
  [phablet_max]: {
    padding: '5px 0',
  },
  display: 'block',
  '& svg': {
    color: '#86909f',
  },
  '&.active': {
    '& svg': {
      color: 'white',
    },
  },
})

const ItemsContainer = styled.div({
  padding: '20px',
  paddingTop: '5px',
  [phablet_max]: {
    padding: '10px',
    paddingTop: '20px',
  },
})
