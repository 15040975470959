import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { PWhite } from '../../ui/elements/typography'
import { connect } from 'react-redux'
import { parseISO, isBefore, setMinutes, setSeconds, setHours, isToday } from 'date-fns'
import styled from '@emotion/styled'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Background from '../../components_system/background'
import Header from '../Header'
import { CartDisplayByDayCRM } from '../widget/components/CartDisplayByDayCRM'
import { RoundButtonWhite } from '../../ui/elements/buttons'
import { Section, Container } from '../../ui/elements/layout'
import {
  triggerConfirmDialog,
} from '../../redux/dialog/actions'
import {
  getClientDataForPersonalPage, 
  cancelReservationFromWidget
} from '../../redux/shop/actions'
import { WidgetPageRoot } from '../../app_ui/layout'

const DAY_RESERVATION_HOUR_LIMIT = 12

@connect(
  store => ({
    clientReservations: store.shop.clientReservations,
    shopAccount: store.shop.shopAccount,
    shopProducts: store.shop.shopProducts,
  }),
  dispatch => ({
    actions: bindActionCreators({
      getClientDataForPersonalPage,
      cancelReservationFromWidget,
      triggerConfirmDialog,
    }, dispatch)
  })
)

class ClientReservationsManager extends Component {

  componentDidMount() {
    const {
      client_short_id,
      shop_slug,
    } = this.props.match.params

    this.props.actions.getClientDataForPersonalPage(client_short_id, shop_slug)
  }

  handleDeleteReservation = (reservationId) => {
    const {
      shopAccount,
    } = this.props

    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja apagar esta reserva?',
      confirmText: 'Sim, apagar',
      cancelText: 'Cancelar operação',
      confirmCallBack: () => this.props.actions.cancelReservationFromWidget(shopAccount._id, reservationId),
    })
  }

  prepareProductsData = (clientReservation) => {
    const products = clientReservation.products
    const newProducts = []
    for(const prodEntry of products) {
      const newProduct = this.props.shopProducts.find(product => String(product._id) === String(prodEntry._id))
      if (!newProduct) {
        // TODO: handle when product is not found
      }

      if (newProduct) {
        newProducts.push({
          _id: prodEntry._id,
          amount: prodEntry.amount,
          title_pt: newProduct.title_pt,
          image_url: newProduct.image_url,
        })
      }
    }
    clientReservation.products = newProducts
    return clientReservation
  }

  render() {
    const {
      client_short_id,
      shop_slug,
    } = this.props.match.params
    const {
      shopAccount,
      shopProducts,
      clientReservations,
    } = this.props
    if (!clientReservations || !shopAccount || !shopProducts) return null

    let filteredReservations = []
    const nowDate = new Date()
    let pastDate = setHours(nowDate, DAY_RESERVATION_HOUR_LIMIT)
    pastDate = setMinutes(pastDate, 0)
    pastDate = setSeconds(pastDate, 0)
    for (let reservation of clientReservations) {
      if (!reservation.cancelled) {
        const isInPast = isBefore(parseISO(reservation.dayDate), pastDate)
        if (!isInPast || isToday(parseISO(reservation.dayDate))) {
          const preparedReservation = this.prepareProductsData(reservation)
          filteredReservations.push(preparedReservation)
        }
      }
    }

    // deternine if has reservations
    const hasReservations = filteredReservations && filteredReservations.length > 0

    if (hasReservations) {
      // sort by date
      filteredReservations = filteredReservations.sort((a, b) => new Date(a.dayDate) - new Date(b.dayDate))
    }

    return (
      <Section>
        <Container>
          <WidgetPageRoot>
            <Helmet>
              <title>As minhas reservas | asminhasreservas.pt</title>
            </Helmet>
            <div style={{position: 'relative'}}>
              <Header
                accountLogo={shopAccount.account_logo}
                businessName={shopAccount.business_name}
                omitCloseButton={true}
                subline='As minhas reservas'
              />
              {!hasReservations && <PWhite>Não tem reservas activas neste momento.</PWhite>}
              {hasReservations && <PWhite>Tem neste momento as seguintes reservas activas:</PWhite>}
              <br />
              { hasReservations &&
                <CartDisplayByDayCRM
                  showState
                  reservations={filteredReservations}
                  handleDeleteReservation={this.handleDeleteReservation}
                  showObservations={true}
                  shopSlug={shop_slug}
                  clientShortId={client_short_id}
                />
              }
              <br />
              <br />
              <PWhite>Se quiser fazer mais reservas clique no botão abaixo para visitar a loja.</PWhite>
              <br />
              <CustomRoundButtonWhite>
                <a href={shopAccount.account_hostname}>Visitar a loja</a>
              </CustomRoundButtonWhite>
              <br />
              <br />
              <br />
            </div>
          </WidgetPageRoot>
        <Background bgColor='#242424' />
        </Container>
      </Section>
    )
  }
}

export default withRouter(ClientReservationsManager)

const CustomRoundButtonWhite = styled(RoundButtonWhite)({
  ':hover': {
    background: '#fbd8be',
  },
  ':active': {
    background: '#f5c7a5',
  },
})
