import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  translateEnglishWeekdayName,
  translateEnglishMonthName,
} from '../../../../utils/translation'
import { format, addDays, subDays } from 'date-fns'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'
import { ContentShortV } from '../../../../ui/elements/layout'
import { PSmall } from '../../../../ui/elements/typography'
import Spinner from '../../../../ui/components/spinner'
import { SpinnerBlock } from '../../../../ui/components/spinner'
import { phablet_max } from '../../../../ui/defaults/media-queries'
import PageHeader from '../../components/PageHeader'
import ProductWeekAvailabilityControl from '../../components/overview/ProductWeekAvailabilityControl'
import { sortProductsByShopProductsOrder } from '../../../../utils/array'
import { getDateFromURLDate } from '../../../../utils/date'

class StockMonitor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInactive: false,
    }
    this.BRRef = React.createRef()
  }

  getStockReservedForProductAtDay = (product, yearMonthDay) => {
    let { reservations } = this.props
    reservations = reservations.filter(
      reservation => !reservation.deleted && !reservation.cancelled,
    )
    const reservationsThisDay = reservations.filter(
      reservation => reservation.yearMonthDay === yearMonthDay,
    )
    const productId = product._id
    let reservedAmountCounter = 0
    for (const reservation of reservationsThisDay) {
      for (const prod of reservation.products) {
        if (prod._id === productId) {
          reservedAmountCounter = reservedAmountCounter + prod.amount
        }
      }
    }
    return reservedAmountCounter
  }

  getProductWeekdaysData = (product, date, weekDayLowerCase) => {
    const sm = product[`stock_${weekDayLowerCase}`]
    const sr = this.getStockReservedForProductAtDay(product, date.yearMonthDay)
    const sa = sm - sr
    return {
      URLDate: date.yearMonthDay,
      weekDayNum: format(getDateFromURLDate(date.yearMonthDay), 'dd'),
      key: product._id,
      label: translateEnglishWeekdayName(date.dayName).replace('-feira', ''),
      stock_total: sm,
      inactive: sm === 0,
      stock_reserved: sr,
      stock_available: sa,
    }
  }

  generateProductsWeekdaysdata = type => {
    let { products, reservations, shop_products_order, viewingWeekDates } = this.props
    if (!products || !reservations || !viewingWeekDates || !shop_products_order) {
      return { items: [], loading: true }
    }
    const selectedProducts =
      type === 'inactive'
        ? products.filter(product => shop_products_order.indexOf(product._id) === -1)
        : sortProductsByShopProductsOrder(products, shop_products_order)
    const items = []
    const openingDays = this.props.user.account.opening_days
    for (const currentProduct of selectedProducts) {
      const productEntry = {}
      productEntry._id = currentProduct._id
      productEntry.title_pt = currentProduct.title_pt
      productEntry.image_url = currentProduct.image_url
      productEntry.weekDaysData = []
      for (const date of viewingWeekDates) {
        const weekDayLowerCase = date.dayName.toLowerCase()
        if (openingDays[weekDayLowerCase]) {
          productEntry.weekDaysData.push(
            this.getProductWeekdaysData(currentProduct, date, weekDayLowerCase),
          )
        }
      }
      items.push(productEntry)
    }
    return { items: items, loading: false }
  }

  handleClickProductTitle = productId => {
    this.props.history.push(`/produtos/${productId}`)
  }

  handleMoveWeekForward = () => {
    const { loadingReservations, currentWeekStartDate } = this.props
    if (loadingReservations) return
    const newWeekStartDate = addDays(currentWeekStartDate, 7)
    this.props.history.push(`/vista-geral/${format(newWeekStartDate, 'yyyy:MM:dd')}`)
  }

  handleMoveWeekBackward = () => {
    const { loadingReservations, currentWeekStartDate } = this.props
    if (loadingReservations) return
    const newWeekStartDate = subDays(currentWeekStartDate, 7)
    this.props.history.push(`/vista-geral/${format(newWeekStartDate, 'yyyy:MM:dd')}`)
  }

  handleToggleInactive = () => {
    const newState = !this.state.showInactive
    this.setState({ showInactive: newState })
    if (newState) {
      setTimeout(() => window.scrollBy(0, 100), 500)
      // this.BRRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  renderDaysPager = () => {
    const { loadingReservations, viewingWeekDates } = this.props
    if (!viewingWeekDates) return null
    const firstDayNumber = viewingWeekDates[0].monthDayNumber
    const lastDayNumber = viewingWeekDates[viewingWeekDates.length - 1].monthDayNumber
    const firstDayNumberMonth = viewingWeekDates[0].monthName
    const lastDayNumberMonth = viewingWeekDates[viewingWeekDates.length - 1].monthName
    let specifyFirstMonthName = firstDayNumberMonth !== lastDayNumberMonth
    let content
    if (specifyFirstMonthName) {
      content = (
        <PSmall style={{ marginRight: '10px', lineHeight: '100%' }}>
          Semana de {firstDayNumber}
          {` de ${translateEnglishMonthName(firstDayNumberMonth)}`} a {lastDayNumber}
          {` de ${translateEnglishMonthName(lastDayNumberMonth)}`}
        </PSmall>
      )
    } else {
      content = (
        <PSmall style={{ marginRight: '10px', lineHeight: '100%' }}>
          Semana de {firstDayNumber} a {lastDayNumber}
          {` de ${translateEnglishMonthName(lastDayNumberMonth)}`}
        </PSmall>
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          opacity: loadingReservations ? '0.2' : '1',
        }}
      >
        {content}
        <FAIcon
          icon="faChevronCircleLeft"
          family="light"
          style={{ lineHeight: '100%', cursor: 'pointer', color: '#242424', marginRight: '5px' }}
          onClick={this.handleMoveWeekBackward}
        />
        <FAIcon
          icon="faChevronCircleRight"
          family="light"
          style={{ lineHeight: '100%', cursor: 'pointer', color: '#242424' }}
          onClick={this.handleMoveWeekForward}
        />
        {/* this.renderTudoPager() */}
      </div>
    )
  }

  render() {
    const { windowHeight, loadingReservations } = this.props
    const { shop_products_order } = this.props
    if (!shop_products_order) {
      return (
        <StockMonitorFrame windowHeight={windowHeight}>
          <ContentShortV style={{ background: 'white', paddingTop: '0', paddingBottom: '0' }}>
            <PageHeader pageTitle="Monitor de stock" />
          </ContentShortV>
          <ContentShortV>
            <SpinnerBlock>
              <Spinner />
            </SpinnerBlock>
          </ContentShortV>
        </StockMonitorFrame>
      )
    }
    const productsWeekdaysData = this.generateProductsWeekdaysdata()
    const productsWeekdaysDataInactive = this.generateProductsWeekdaysdata('inactive')
    const { items, loading } = productsWeekdaysData
    const itemsInactive = productsWeekdaysDataInactive.items

    let content = null
    let contentInactive = null
    if (loading) {
      content = (
        <div style={{ position: 'relative' }}>
          <Spinner
            radius={20}
            color="black"
            style={{ position: 'absolute', top: '4px', right: '4px' }}
          />
        </div>
      )
    } else if (items && items.length > 0) {
      content = items.map(product => (
        <div key={product.title_pt}>
          <ProductWeekAvailabilityControl
            loadingReservations={loadingReservations}
            title_pt={product.title_pt}
            image_url={product.image_url}
            weekDaysData={product.weekDaysData}
            handleClickTitle={() => this.handleClickProductTitle(product._id)}
          />
        </div>
      ))
      contentInactive = itemsInactive.map(product => (
        <div key={product.title_pt}>
          <ProductWeekAvailabilityControl
            loadingReservations={loadingReservations}
            title_pt={product.title_pt}
            image_url={product.image_url}
            weekDaysData={product.weekDaysData}
            handleClickTitle={() => this.handleClickProductTitle(product._id)}
          />
        </div>
      ))
    }

    const iconShowInactiveState = (
      <FAIcon
        icon={this.state.showInactive ? 'faChevronUp' : 'faChevronDown'}
        family="light"
        style={{ position: 'relative', top: '4px' }}
      />
    )
    return (
      <StockMonitorFrame windowHeight={windowHeight}>
        <PageHeader hidePageTitleOnMobile pageTitle="Monitor de stock" />
        <PageHeader pageTitle="" rightSideElement={this.renderDaysPager()} />
        <ContentShortV>
          {content}
          <br />
        </ContentShortV>
        {!loading && (
          <PSmall
            style={{
              cursor: 'pointer',
              width: '100%',
              color: 'white',
              background: '#2E2E2E',
              padding: '10px 16px',
            }}
            onClick={this.handleToggleInactive}
          >
            Ver inactivos {iconShowInactiveState}
          </PSmall>
        )}
        {!loading && this.state.showInactive && (
          <ContentShortV>
            <br ref={this.BRRef} />
            {contentInactive}
          </ContentShortV>
        )}
      </StockMonitorFrame>
    )
  }
}

export default withRouter(StockMonitor)

const StockMonitorFrame = styled.div(props => ({
  overflowY: 'scroll',
  height: props.windowHeight ? `${props.windowHeight}px` : 'auto',
  [phablet_max]: {
    overflowY: 'auto',
  },
}))
