import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import Header from '../Header'
import { connect } from 'react-redux'
import { PWhite } from '../../ui/elements/typography'
import { FlexDiv } from '../../ui/elements/layout'
import { WidgetPageRoot, PaddingBox } from '../../app_ui/layout'
import { CustomRoundButtonWhite, CustomRoundButtonCancel } from '../../app_ui/buttons'
import Background from '../../components_system/background'
import {
  clearClientDetails,
  clearCart,
  clearTemporaryCartAmount,
} from '../../redux/shop/actions'

@connect(
  store => ({
    cart: store.shop.cart,
    storeAccount: store.product.storeAccount,
    clientDetails: store.shop.clientDetails,
    productsStore: store.product.productsStore,
    successErrorState: store.shop.successErrorState,
    productOutOfStock: store.shop.productOutOfStock,
    errorMessage: store.shop.errorMessage,
  }),
  dispatch => ({
    actions: bindActionCreators({
      clearClientDetails,
      clearCart,
      clearTemporaryCartAmount,
    }, dispatch)
  })
)

class WidgetCreateReservationsError extends Component {

  showProductOutOfStock = (productOutOfStock) => {
    if (!productOutOfStock) return null
    // if (!productOutOfStock || !productOutOfStock[0]) return null
    // const product = productsStore.find(product => product._id === productOutOfStock[0])
    return <PWhite>Pedimos desculpa, este produto esgotou enquanto preparava a sua reserva: <span style={{fontWeight: 'bold'}}>{productOutOfStock.title_pt}</span>.</PWhite>
  }

  // handleCloseWindow = () => {
  //   this.props.actions.clearClientDetails()
  //   window.parent.postMessage('close-window', document.referrer)
  //   setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 500)
  // }

  runResetApp = (e) => {
    // clear current cart
    this.props.actions.clearClientDetails()
    // clear any stored client details
    this.props.actions.clearCart()
    // clear temporary cart amount
    this.props.actions.clearTemporaryCartAmount()
    // send app to first page
    setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000)
  }

  checkIsInsideIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  handleCloseButton = (e) => {
    const {
      storeAccount,
    } = this.props
    if (e) {e.preventDefault()}
    if (e) {e.stopPropagation()}
    if (this.checkIsInsideIframe()) { // is inside iframe
      this.runResetApp()// close overlay
      setTimeout(() => window.parent.postMessage('close-window', document.referrer), 100)
    } else {
      window.location = storeAccount.account_hostname
    }
  }

  handleBackToShop = (e) => {
    const {
      storeAccount,
    } = this.props
    if (e) {e.preventDefault()}
    if (e) {e.stopPropagation()}
    if (this.checkIsInsideIframe()) { // is inside iframe
      this.runResetApp()
    } else {
      window.location = storeAccount.account_hostname
    }
  }

  showButtons = () => {
    return (
      <FlexDiv style={{justifyContent: 'center', width: '100%'}}>
        <CustomRoundButtonCancel onClick={this.handleCloseButton} style={{marginRight: '10px'}}>
          Fechar janela
        </CustomRoundButtonCancel>
        <CustomRoundButtonWhite onClick={this.handleBackToShop}>
          Voltar à loja
        </CustomRoundButtonWhite>
      </FlexDiv>
    )
  }

  showCloseButton = () => {
    return (
      <FlexDiv style={{justifyContent: 'center', width: '100%'}}>
        <CustomRoundButtonCancel onClick={this.handleCloseButton} style={{marginRight: '10px'}}>
          Fechar janela
        </CustomRoundButtonCancel>
      </FlexDiv>
    )
  }

  render() {
    const {
      cart,
      storeAccount,
      productsStore,
      productOutOfStock,
      errorMessage,
    } = this.props
    if (!storeAccount || !storeAccount.account_logo) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!productsStore) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    if (!cart) { setTimeout(() => this.props.history.push(`/shop/${this.props.match.params.app_id}`), 1000);  return <Background bgColor='#242424' /> }
    return (
      <WidgetPageRoot>
        <PaddingBox>
          <Header
            accountLogo={storeAccount.account_logo}
            businessName={storeAccount.business_name}
          />
          <br />
          { /* errorMessage === 'Products are out of stock' && <PWhite>Um dos produtos que tentou reservar está esgotado. <span role="img" aria-label='emoji'>😢</span></PWhite> */ }
          { /*  errorMessage !== 'Products are out of stock' && <PWhite>{errorMessage} <span role="img" aria-label='emoji'>😢</span></PWhite>  */ }
          { /* TODO: send alert to admin when getting unexpected error  */ }
          { errorMessage !== 'Products are out of stock' && <PWhite>Ocorreu um erro inesperado <span role="img" aria-label='emoji'>😢</span> pedimos desculpa pela inconveniência.</PWhite> }
          { errorMessage === 'Products are out of stock' && productOutOfStock && productOutOfStock.title_pt && this.showProductOutOfStock(productOutOfStock) }
          <br />
          { errorMessage === 'Products are out of stock' && productsStore && productsStore.length > 0 && this.showButtons()}
          { errorMessage !== 'Products are out of stock' && this.showCloseButton()}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Background bgColor='#242424' />
        </PaddingBox>
      </WidgetPageRoot>
    )
  }
}

export default withRouter(WidgetCreateReservationsError)
