import React from 'react'
import styled from '@emotion/styled'
import { H3 } from '../../../ui/elements/typography'
import { Section, Container, ContentShortV } from '../../../ui/elements/layout'
import { tablet_max, phablet_max, phone_max } from '../../../ui/defaults/media-queries'

function PageHeader(props) {
  const style={}
  style.paddingLeft = 0
  style.paddingRight = 0
  // if (!props.large) {
  // }
  return (
    <Section style={{background: 'white', borderBottom: '1px solid #d2e5ff'}}>
      <Container>
        <CustomContentShortV style={style} hidePageTitleOnMobile>
          <div>
            {!props.headlineAlternativeElement && <CustomH3>{props.pageTitle}</CustomH3>}
            {props.headlineAlternativeElement}
          </div>
          {props.rightSideElement}
        </CustomContentShortV>
      </Container>
    </Section>
  )
}

export default PageHeader

const CustomContentShortV = styled(ContentShortV)(props => ({
  display: 'flex',
  width: '100%',  
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'white',
  flexDirection: 'row',
  [phone_max]: {
    '& h3':{
      display: props.hidePageTitleOnMobile ? 'none' : 'block',
    }
  },
}))


const CustomH3 = styled(H3)({
  lineHeight: '100%',
  fontSize: '19px',
  [tablet_max]: {
    fontSize: '19px',
  },
  [phablet_max]: {
    fontSize: '17px',
  },
  [phone_max]: {
    fontSize: '13px',
  },
})
