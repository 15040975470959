import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { FlexDiv } from '../../../ui/elements/layout'
import { PTiny, PWhite, H3White, H3WhiteBold, PSmallWhite } from '../../../ui/elements/typography'
import { green_shiny, blue_shiny, orange } from '../../../ui/defaults/colors'
import {
  translateEnglishWeekdayNameLowerCase,
  translateEnglishWeekdayName,
} from '../../../utils/translation'
import { getMonthDayNumber } from '../../../utils/dayHelpers'

const translateFunction = (dayName, lowerCase) => {
  if (lowerCase) return translateEnglishWeekdayNameLowerCase(dayName)
  return translateEnglishWeekdayName(dayName)
}

const stateLabelStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: '10px',
}

const generateJSX = state => {
  switch (state) {
    case 'prepared':
      return <PTiny style={{ color: orange, ...stateLabelStyle }}>Preparada</PTiny>
    case 'finalised':
      return <PTiny style={{ color: green_shiny, ...stateLabelStyle }}>Finalizada</PTiny>
    default:
      // requested
      return <PTiny style={{ color: blue_shiny, ...stateLabelStyle }}>Pedida</PTiny>
  }
}
export const CartDisplayByDayCRMByPhone = ({
  reservations,
  showState,
  showObservations,
  shopSlug,
  clientPhone,
}) => {
  return (
    <RootDiv style={{ width: '100%' }}>
      {reservations.map((reservation, index) => (
        <Item key={index} to={`/reserva-webview/${shopSlug}/${clientPhone}/${reservation._id}`}>
          <WeekdayRow>
            <PWhite>
              Para {translateFunction(reservation.dayName)},{' '}
              {getMonthDayNumber(reservation.dayDate)}:
            </PWhite>
          </WeekdayRow>
          {showState && generateJSX(reservation.state)}
          {reservation.products.map((reservationProduct, indexInner) => (
            <CustomFlexDiv
              key={indexInner + 2000}
              style={{ marginTop: indexInner === 0 ? '0' : '6px' }}
            >
              <FlexDiv>
                <H3WhiteBold style={{ marginRight: '6px' }}>
                  {reservationProduct.amount}
                </H3WhiteBold>
                <H3White>{reservationProduct.title_pt}</H3White>
              </FlexDiv>
              <img src={reservationProduct.image_url} alt={reservationProduct.title_pt} />
            </CustomFlexDiv>
          ))}
          {showObservations && reservation.observations && reservation.observations !== '' && (
            <CustomPSmallWhite>"{reservation.observations}"</CustomPSmallWhite>
          )}
        </Item>
      ))}
    </RootDiv>
  )
}
export default CartDisplayByDayCRMByPhone

const RootDiv = styled.div({
  paddingBottom: '12px',
  '& *': {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  },
})

const Item = styled(Link)({
  borderLeft: '2px solid #242424',
  cursor: 'pointer',
  display: 'block',
  width: '100%',
  marginBottom: '30px',
  WebkitTransition: 'all 0.2s ease-out',
  MozTransition: 'all 0.2s ease-out',
  Otransition: 'all 0.2s ease-out',
  transition: 'all 0.2s ease-out',
  ':hover': {
    paddingLeft: '18px',
    borderLeft: '2px solid white',
  },
})

const CustomFlexDiv = styled(FlexDiv)({
  width: '100%',
  alignItems: 'center',
  '& img': {
    marginLeft: 'auto',
    height: '30px',
    width: '30px',
    borderRadius: '20px',
    display: 'inline',
  },
})

const WeekdayRow = styled(FlexDiv)({
  width: '100%',
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  '& .fa-times': {
    fontSize: '24px',
    marginLeft: '12px',
    color: '#7d7d7d',
    cursor: 'pointer',
    position: 'relative',
    top: '0px',
    ':hover': {
      color: 'red',
    },
    ':active': {
      color: '#ff7171',
    },
  },
})

const CustomPSmallWhite = styled(PSmallWhite)({
  fontStyle: 'italic',
  opacity: '0.5',
  marginTop: '10px',
  maxWidth: '490px',
})
