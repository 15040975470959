import React, { Component } from 'react'
import styled from '@emotion/styled'
import ReservationBox from './ReservationBox'
import { FormLabelBlueDark } from '../../../../ui/elements/forms'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { tablet_max, phablet_max, phone_max } from '../../../../ui/defaults/media-queries'
import { blue_shiny } from '../../../../ui/defaults/colors'
import Spinner from '../../../../ui/components/spinner'

class ReservationsKanban extends Component {
  prepareContent = (history, reservations, scrollRef) => {
    const {
      weekDate,
      resetUpdateCycleTimer,
      handleMoveStateForward,
      handleMoveStateBackward,
      handleDeleteReservation,
      movingStateReservationId
    } = this.props
    if (!reservations) {
      return null
    }
    if (reservations.length === 0) {
      return null
    }

    return(
      <PerfectScrollbar ref={scrollRef} style={{paddingRight: '0'}} onScrollY={() => resetUpdateCycleTimer()}>
        {reservations.map(reservation => (
          <ReservationBox
            key={reservation._id}
            _id={reservation._id}
            time={reservation.time}
            history={history}
            weekDate={weekDate}
            dailyCountId={reservation.dailyCountId}
            movingStateReservationId={movingStateReservationId}
            name={reservation.name}
            cancelled={reservation.cancelled}
            data={reservation}
            state={reservation.state}
            updated={reservation.updated}
            disableLeft={reservation.state === 'requested'}
            disableRight={reservation.state === 'finalised'}
            telephone={reservation.telephone}
            observations={reservation.observations}
            products={reservation.products}
            handleMoveStateForward={handleMoveStateForward}
            handleMoveStateBackward={handleMoveStateBackward}
            handleDeleteReservation={handleDeleteReservation}
          />
        ))}
      </PerfectScrollbar>
    )
  }

  render() {
    const {
      loading,
      toggleSortType,
      reservations,
      kanbanHeight,
      history,
      requestedScrollRef,
      preparedScrollRef,
      finalisedScrollRef,
      gettingReservations,
    } = this.props

    const spinner = <Spinner radius={20} color={blue_shiny} />

    let requestedReservationsContent = null
    let preparedReservationsContent = null
    let finalisedReservationsContent = null
    if (loading || gettingReservations) {
      requestedReservationsContent = spinner
      preparedReservationsContent = spinner
      finalisedReservationsContent = spinner
    } else {
      requestedReservationsContent = this.prepareContent(history, reservations.requested, requestedScrollRef)
      preparedReservationsContent = this.prepareContent(history, reservations.prepared, preparedScrollRef)
      finalisedReservationsContent = this.prepareContent(history, reservations.finalised, finalisedScrollRef)
    }

    return (
      <ReservationsGridRoot kanbanHeight={kanbanHeight} gettingReservations={gettingReservations}>
        <ReservationsStateTypeColumn kanbanHeight={kanbanHeight}>
          <FormLabelBlueDark style={{cursor: 'pointer'}} onClick={toggleSortType}>PEDIDAS</FormLabelBlueDark>
          <Separator />
          {requestedReservationsContent}
        </ReservationsStateTypeColumn>
        <ReservationsStateTypeColumn kanbanHeight={kanbanHeight}>
          <FormLabelBlueDark style={{cursor: 'pointer'}} onClick={toggleSortType}>PREPARADAS</FormLabelBlueDark>
          <Separator />
          {preparedReservationsContent}
        </ReservationsStateTypeColumn>
        <ReservationsStateTypeColumn kanbanHeight={kanbanHeight}>
          <FormLabelBlueDark style={{cursor: 'pointer'}} onClick={toggleSortType}>FINALIZADAS</FormLabelBlueDark>
          <Separator />
          {finalisedReservationsContent}
        </ReservationsStateTypeColumn>
      </ReservationsGridRoot>
    )
  }
}

export default ReservationsKanban

const ReservationsGridRoot = styled.div(props => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  opacity: props.gettingReservations ? '0.3' : '1',
  pointerEvents: props.gettingReservations ? 'none' : 'auto',
  height: props.kanbanHeight ? `${props.kanbanHeight}px` : 'auto',
}))

const ReservationsStateTypeColumn = styled.div(props => ({
  width: '33.3%',
  display: 'flex',
  flexDirection: 'column',
  height: props.kanbanHeight ? `${props.kanbanHeight}px` : 'auto',
  padding: '8px',
  paddingTop: '14px',
  position: 'relative',
  [tablet_max]: {
    padding: '6px',
    paddingTop: '12px',
  },
  [phablet_max]:{
    padding: '4px',
    paddingTop: '10px',
  },
  [phone_max]:{
    padding: '2px',
    paddingTop: '10px',
  },
}))

const Separator = styled.div({
  display: 'block',
  width: '100%',
  height: '2px',
  background:'#C9DDFB',
  marginBottom: '20px',
  [tablet_max]: {
    marginBottom: '15px',
  },
  [phablet_max]:{
    marginBottom: '10px',
  },
  [phone_max]:{
    marginBottom: '6px',
  },
})
