import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
// import { connect } from 'react-redux'
import styled from '@emotion/styled';
import { isMobileDeviceBrowser } from '../utils/screen'

// @connect(
//   store => ({
//     mobileNavOpen: store.system.mobileNavOpen
//   }),
//   null
// )

class MagicLayout extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   intervalId: null,
    // }
    this.layout = React.createRef()
  }
  componentDidMount() {
    if (!isMobileDeviceBrowser()) {
      // this.setState({intervalId: setInterval(this.adjustHeight, 500)});
      // window.addEventListener('resize', this.adjustHeight);
    }
    // this.adjustHeight()
  }

  // adjustHeight = () => {
  //   try {
  //     this.layout.current.style.minHeight = `${window.innerHeight}px`
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  render() {
    return (
      <Layout ref={this.layout}>
        {this.props.children}
      </Layout>
    );
  }
}

export default withRouter(MagicLayout)

const Layout = styled.div(props => ({
  position: 'relative',
  // left: props.mobileNavOpen ? '-180px' : '0',
  width: '100%',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  WebkitTransition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out',
  MozTransition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out',
  Otransition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out',
  transition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out',
}))

// const Layout = styled.div(props => ({
//   position: 'relative',
//   left: props.mobileNavOpen ? '-180px' : '0',
//   width: '100%',
//   display: 'flex',
//   height: '100%',
//   flexDirection: 'column',
//   justifyContent: 'space-between',
//   WebkitTransition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out 0.1s',
//   MozTransition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out 0.1s',
//   Otransition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out 0.1s',
//   transition: props.mobileNavOpen ? 'left 0.4s ease-in-out' : 'left 0.3s ease-out 0.1s',
// }))