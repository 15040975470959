import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { PWhite } from '../../ui/elements/typography'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import Background from '../../components_system/background'
import Header from '../Header'
import SingleReservatioDisplay from '../widget/components/SingleReservatioDisplay'
import { CustomRoundButtonRed, CustomRoundButtonWhite } from '../../app_ui/buttons'
import { Section, Container, FlexDiv } from '../../ui/elements/layout'
import { red_light } from '../../ui/defaults/colors'
import { phablet_max } from '../../ui/defaults/media-queries'
import {
  triggerConfirmDialog,
} from '../../redux/dialog/actions'
import {
  getClientDataForPersonalPage, 
  cancelReservationFromWidget,
  removeProductFromReservation
} from '../../redux/shop/actions'
import { WidgetPageRoot } from '../../app_ui/layout'

@connect(
  store => ({
    clientReservations: store.shop.clientReservations,
    shopAccount: store.shop.shopAccount,
    shopProducts: store.shop.shopProducts,
  }),
  dispatch => ({
    actions: bindActionCreators({
      getClientDataForPersonalPage,
      cancelReservationFromWidget,
      triggerConfirmDialog,
      removeProductFromReservation,
    }, dispatch)
  })
)

class ClientSingleReservationManager extends Component {

  componentDidMount() {
    const {
      client_short_id,
      shop_slug,
    } = this.props.match.params

    this.props.actions.getClientDataForPersonalPage(client_short_id, shop_slug)
  }

  handleDeleteReservation = (reservationId) => {
    const {
      shopAccount,
    } = this.props

    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja apagar esta reserva?',
      confirmText: 'Sim, apagar',
      cancelText: 'Cancelar operação',
      confirmCallBack: () => this.props.actions.cancelReservationFromWidget(shopAccount._id, reservationId),
    })
  }

  handleInvokeRemoveProductFromReservation = (reservationId, productId) => {
    const {
      shopAccount,
    } = this.props
    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja remover este produto da reserva?',
      confirmText: 'Sim, remover',
      cancelText: 'Cancelar operação',
      confirmCallBack: () => this.props.actions.removeProductFromReservation(shopAccount._id, reservationId, productId),
    })
  }

  prepareProductsData = (clientReservation) => {
    for(const index in clientReservation.products) {
      const prodEntry = clientReservation.products[index]
      const newProduct = this.props.shopProducts.find(product => product._id === prodEntry._id)
      clientReservation.products[index].title_pt = newProduct.title_pt
      clientReservation.products[index].image_url = newProduct.image_url
    }
    return clientReservation
  }

  render() {
    const {
      client_short_id,
      shop_slug,
      reservation_id,
    } = this.props.match.params
    const {
      shopAccount,
      clientReservations,
    } = this.props
    if (!clientReservations || !shopAccount) return null
    const reservationInURL = clientReservations.find(r => r._id === reservation_id)
    let preparedReservation
    if (reservationInURL) {
      preparedReservation = this.prepareProductsData(reservationInURL)
    }
    return (
      <Section>
        <Container>
          <WidgetPageRoot>
            <Helmet>
              <title>As minhas reservas | asminhasreservas.pt</title>
            </Helmet>
            <div style={{position: 'relative'}}>
              <Header
                accountLogo={shopAccount.account_logo}
                businessName={shopAccount.business_name}
                omitCloseButton={true}
                subline='As minhas reservas'
              />
              {!preparedReservation && <PWhite>Não foi encontrada a reserva.</PWhite>}
              {preparedReservation && !preparedReservation.cancelled && <PWhite>Detalhe da reserva:</PWhite>}
              {preparedReservation && preparedReservation.cancelled && <PWhite style={{color: red_light}}>Esta reserva foi cancelada.</PWhite>}
              <br />
              { preparedReservation &&
                <SingleReservatioDisplay
                  showState={!preparedReservation.cancelled}
                  cancelled={preparedReservation.cancelled}
                  reservations={[preparedReservation]}
                  handleDeleteProductFromReservation={this.handleInvokeRemoveProductFromReservation}
                  showObservations={true}
                />
              }
              <br />
              { !preparedReservation.cancelled &&
                <div>
                  <PWhite>Está a ver o detalhe de uma reserva. Clique no botão abaixo para ver a lista completa das suas reservas.</PWhite>
                  <br />
                </div>
              }
              <ButtonsFlexDiv>
                <Link to={'/loja/' + shop_slug + '/' + client_short_id} style={{display: 'inline-flex'}}>
                  <CustomRoundButtonWhite>
                    Ver todas as minhas reservas
                  </CustomRoundButtonWhite>
                </Link>
                {!preparedReservation.cancelled &&
                  <CustomRoundButtonRed className='btn2x' onClick={() => this.handleDeleteReservation(reservation_id)}>
                    Cancelar reserva
                  </CustomRoundButtonRed>
                }
              </ButtonsFlexDiv>
              <br />
              <br />
              <br />
            </div>
          </WidgetPageRoot>
        <Background bgColor='#242424' />
        </Container>
      </Section>
    )
  }
}

export default withRouter(ClientSingleReservationManager)

const ButtonsFlexDiv = styled(FlexDiv)({
  '& .btn2x': {
    display: 'inline-flex',
    marginLeft: '10px',
  },
  [phablet_max]: {
    flexDirection: 'column',
    '& .btn2x': {
      display: 'inline-flex',
      marginLeft: '0',
      marginRight: 'auto',
      order: '-1',
      marginBottom: '10px',
    }
  }
})
