import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { PSmall } from '../../../../ui/elements/typography'
import { desktop_max, tablet_max, phablet_max } from '../../../../ui/defaults/media-queries'
import { green_shiny, red_light } from '../../../../ui/defaults/colors'
import Spinner from '../../../../ui/components/spinner'

const ProductWeekAvailabilityControl = props =>
  <BaseLayout>
    <PSmall onClick={props.handleClickTitle} style={{cursor: 'pointer', borderBottom: '1px solid #cdddf5', marginBottom: '5px'}}>{props.title_pt}</PSmall>
    <LayoutDays numColumns={props.weekDaysData.length}>
      {props.weekDaysData.map((dayData, index) =>
        <WeekdayBlock key={index} last={index === props.weekDaysData.length-1}>
          <WeekdayBlockContent active={!dayData.inactive}>
            <Link to={`/reservas/${dayData.URLDate}`}>
              <PDayLabel>{ dayData.label } { dayData.weekDayNum }</PDayLabel>
              { props.loadingReservations ?
                <div>
                  <br />
                  <Spinner color='#ccc' radius={20} />
                </div>
                :
                <div>
                  { !dayData.inactive && <StockNumberDisplay>{dayData.stock_reserved}<span>/{dayData.stock_total}</span></StockNumberDisplay> }
                  { !dayData.inactive && <PDayLabel>Restam <span style={{color: dayData.stock_available === 0 ? red_light : green_shiny}}>{dayData.stock_available}</span></PDayLabel> }
                </div>
              }
            </Link>
          </WeekdayBlockContent>
        </WeekdayBlock>
      )}
    </LayoutDays>
  </BaseLayout>

export default ProductWeekAvailabilityControl

const BaseLayout = styled.div({
  width: '100%',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  marginBottom: '46px',
  [tablet_max]: {
    marginBottom: '36px',
  },
  [phablet_max]: {
    marginBottom: '26px',
  },
  '@media only screen and (max-width: 550px)': {
    marginBottom: '16px',
  },
})

const PDayLabel = styled.p({
  fontSize: '17px',
  [tablet_max]: {
    fontSize: '15px',
  },
  [phablet_max]: {
    fontSize: '13px',
  },
  '@media only screen and (max-width: 550px)': {
    fontSize: '9px',
  },
})

const LayoutDays = styled.div(props => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.numColumns}, auto)`,
}))

const WeekdayBlock = styled.div(props => ({
  borderTop: '2px solid #E3EDFD',
  borderRight: props.last ? 'none' : '2px solid #E3EDFD',
}))

const WeekdayBlockContent = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  background: props.active ? 'white' : 'rgba(255, 255, 255, 0.4)',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '20px',
  [desktop_max]: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
  },
  [tablet_max]: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '14px',
  },
  [phablet_max]: {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '12px',
  },
  '@media only screen and (max-width: 550px)': {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '8px',
  },
}))

const StockNumberDisplay = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  fontSize: '25px',
  '& span': {
    fontSize: '23px',
    color: '#8BADE5',
  },
  '@media only screen and (max-width: 550px)': {
    fontSize: '15px',
    '& span': {
      fontSize: '15px',
      color: '#8BADE5',
    },
  },
})
