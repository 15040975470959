import React, { Component } from 'react'
import styled from '@emotion/styled'
import { grey_disabled, green_shiny } from '../../defaults/colors'
import {  tablet_max, phablet_max } from '../../defaults/media-queries'

export default class Switch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: props.active,
      label: props.label,
      switchHandler: props.onClick,
      size: props.size ? props.size : 'medium',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active && this.state.active !== this.props.active) {
      this.setState((state, props) => ({
        active: props.active
      }))
    }
  }
  
  handleTrigger = () => {
    if (this.props.onClick) {
      this.props.onClick(!this.state.active)
    }
    this.setState((state) => ({
      active: !state.active
    }))
  }
  
  renderLarge = (active) => {
    return (
      <SwitchElementLarge active={active} onClick={this.handleTrigger} className='switch'>
        <SwitchHandleLarge active={active} />
      </SwitchElementLarge>
    )
  }
  
  renderMedium = (active) => {
    return (
      <SwitchElementMedium active={active} onClick={this.handleTrigger} className='switch'>
        <SwitchHandleMedium active={active} />
      </SwitchElementMedium>
    )
  }
  
  renderSmall = (active) => {
    return (
      <SwitchElementSmall active={active} onClick={this.handleTrigger} className='switch'>
        <SwitchHandleSmall active={active} />
      </SwitchElementSmall>
    )
  }
  
  renderResponsiveLarge = (active) => {
    return (
      <SwitchElementLargeResponsive active={active} onClick={this.handleTrigger} className='switch'>
        <SwitchHandleLargeResponsive active={active} />
      </SwitchElementLargeResponsive>
    )
  }

  render() {
    const { size, active } = this.state
    switch(size) {
      case 'reponsive_large':
        return this.renderResponsiveLarge(active)
      case 'small':
        return this.renderSmall(active)
      case 'large':
        return this.renderLarge(active)
      default:
        return this.renderMedium(active)
    }
  }
}

const SwitchElement = styled.div(props => ({
  position: 'relative',
  backgroundColor: props.active ? green_shiny : grey_disabled,
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '30px',
  marginBottom: '1px',
  boxShadow: 'inset 0 1px 2px 0 rgba(0, 0, 0, 0.04)s',
  WebkitTransition: 'all 0.2s ease-out',
  MozTransition: 'all 0.2s ease-out',
  Otransition: 'all 0.2s ease-out',
  transition: 'all 0.2s ease-out',
}))

const SwitchElementSmall = styled(SwitchElement)( {
  width: '34px',
  height: '19px',
})
const SwitchElementMedium = styled(SwitchElement)( {
  width: '47px',
  height: '25px',
})
const SwitchElementLarge = styled(SwitchElement)( {
  width: '67px',
  height: '37px',
})

const SwitchHandle = styled.div({
  backgroundColor: 'white',
  borderRadius: '13px',
  position: 'absolute',
  WebkitTransition: 'all 0.2s ease-out',
  MozTransition: 'all 0.2s ease-out',
  Otransition: 'all 0.2s ease-out',
  transition: 'all 0.2s ease-out',
})


const SwitchHandleSmall = styled(SwitchHandle)(props => ({
  width: '13px',
  height: '13px',
  top: '3px',
  left: props.active ? '18px' : '4px',
}))
const SwitchHandleMedium = styled(SwitchHandle)(props => ({
  width: '17px',
  height: '17px',
  top: '4px',
  left: props.active ? '25px' : '5px',
}))
const SwitchHandleLarge = styled(SwitchHandle)(props => ({
  width: '26px',
  height: '26px',
  top: '5px',
  left: props.active ? '35px' : '7px',
}))

const SwitchElementLargeResponsive = styled(SwitchElement)( {
  width: '67px',
  height: '37px',
  [tablet_max]: {
    width: '47px',
    height: '25px',
  },
  [phablet_max]: {
    width: '34px',
    height: '19px',
  },
})

const SwitchHandleLargeResponsive = styled(SwitchHandle)(props => ({
  width: '26px',
  height: '26px',
  top: '5px',
  left: props.active ? '35px' : '7px',
  [tablet_max]: {
    width: '17px',
    height: '17px',
    top: '4px',
    left: props.active ? '25px' : '6px',
  },
  [phablet_max]: {
    width: '13px',
    height: '13px',
    top: '3px',
    left: props.active ? '18px' : '4px',
  },
}))
