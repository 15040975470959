import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { P, PSmall } from '../../../../ui/elements/typography'
import { blue_strong } from '../../../../ui/defaults/colors'
import { WhiteBox, Content } from '../../../../ui/elements/layout'
import Switch from '../../../../ui/components/switch'
// import DarkRectImageUpload from '../../../../ui/components/image'
import { Input, StyledFormDiv } from '../../../../ui/elements/forms'
import { red_light, grey_border } from '../../../../ui/defaults/colors'
import {
  updateBusinessName,
  deleteAccount,
  updateAccountEmail,
  updateAccountHostname,
  updateAccountActive,
  updateAccountWidgetBtnBgColor,
  updateAccountWidgetBtnTxtColor,
  updateAccountWidgetBtnIconColor,
  uploadAccountLogo,
} from '../../../../redux/account/actions'
import { triggerConfirmDialog } from '../../../../redux/dialog/actions'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        updateBusinessName,
        updateAccountEmail,
        updateAccountHostname,
        updateAccountActive,
        updateAccountWidgetBtnBgColor,
        updateAccountWidgetBtnTxtColor,
        updateAccountWidgetBtnIconColor,
        deleteAccount,
        uploadAccountLogo,
        triggerConfirmDialog,
      },
      dispatch,
    ),
  }),
)
class AccountEditBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _id: this.props._id,
      date_created: this.props.date_created,
      business_name: this.props.business_name,
      account_email: this.props.account_email,
      account_hostname: this.props.account_hostname,
      widget_btn_bg_color: this.props.widget_btn_bg_color,
      widget_btn_txt_color: this.props.widget_btn_txt_color,
      widget_btn_icon_color: this.props.widget_btn_icon_color,
      active: this.props.active,
      app_id: this.props.app_id,
      // image_url: this.props.image_url,
    }
  }

  handleBusinessNameInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleAccountEmailInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleAccountHostInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleWidgetBtnBgInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleWidgetBtnTxtInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleWidgetBtnIconInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // handleInputChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }

  handleBusinessNameKeyDown = e => {
    if (e.which === 13 && this.state._id) {
      this.props.actions.updateBusinessName(this.state._id, this.state.business_name)
    }
  }
  handleAccountEmailKeyDown = e => {
    if (e.which === 13 && this.state._id) {
      this.props.actions.updateAccountEmail(this.state._id, this.state.account_email)
    }
  }
  handleAccountHostKeyDown = e => {
    if (e.which === 13 && this.state._id) {
      this.props.actions.updateAccountHostname(this.state._id, this.state.account_hostname)
    }
  }
  handleWidgetBtnBgKeyDown = e => {
    if (e.which === 13 && this.state._id) {
      this.props.actions.updateAccountWidgetBtnBgColor(this.state._id, e.target.value)
    }
  }
  handleWidgetBtnTxtKeyDown = e => {
    if (e.which === 13 && this.state._id) {
      this.props.actions.updateAccountWidgetBtnTxtColor(
        this.state._id,
        this.state.widget_btn_txt_color,
      )
    }
  }
  handleWidgetBtnIconKeyDown = e => {
    if (e.which === 13 && this.state._id) {
      this.props.actions.updateAccountWidgetBtnIconColor(
        this.state._id,
        this.state.widget_btn_icon_color,
      )
    }
  }

  handleDeleteAccount = e => {
    this.props.actions.triggerConfirmDialog({
      question: 'Tem a certeza que deseja remover esta conta?',
      confirmText: 'Sim, tenho',
      cancelText: 'Cancelar operação',
      confirmCallBack: this.handleConfirmDeleteAccountCallBack,
    })
  }

  handleConfirmDeleteAccountCallBack = () => {
    this.props.actions.deleteAccount(this.state._id)
  }

  handleToggleAccountActive = () => {
    this.props.actions.updateAccountActive(this.state._id, !this.state.active)
  }

  handleLogoUploadTrigger = imageFileData => {
    this.props.actions.uploadAccountLogo(this.state._id, imageFileData)
  }

  render() {
    const {
      business_name,
      account_email,
      account_hostname,
      widget_btn_bg_color,
      widget_btn_txt_color,
      widget_btn_icon_color,
      active,
      app_id,
    } = this.state

    return (
      <CustomWhiteBox>
        <SVGImageUploadDisplay>
          <StyledFormDiv>
            <Content>
              <FileInputElement
                type="file"
                onChange={e => this.handleLogoUploadTrigger(e.target.files[0])}
              />
              <label className="form-label">LOGO</label>
              <img src={this.props.account_logo} alt={this.props.account_logo} />
            </Content>
          </StyledFormDiv>
        </SVGImageUploadDisplay>
        <br />
        <Content>
          <StyledFormDiv>
            <label className="form-label">BUSINESS NAME</label>
            <CustomInput
              type="text"
              name="business_name"
              placeholder="Nome da empresa"
              onKeyDown={this.handleBusinessNameKeyDown}
              value={business_name}
              onChange={this.handleBusinessNameInputChange}
              hasChanges={this.state.business_name !== this.props.business_name}
            />
            <br />
            <label className="form-label">ACCOUNT EMAIL</label>
            <CustomInput
              type="text"
              name="account_email"
              placeholder="Email da empresa"
              onKeyDown={this.handleAccountEmailKeyDown}
              value={account_email}
              onChange={this.handleAccountEmailInputChange}
              hasChanges={this.state.account_email !== this.props.account_email}
            />
            <br />
            <label className="form-label">ACCOUNT HOST NAME</label>
            <CustomInput
              type="text"
              name="account_hostname"
              placeholder="Website da instalação"
              onKeyDown={this.handleAccountHostKeyDown}
              value={account_hostname}
              onChange={this.handleAccountHostInputChange}
              hasChanges={this.state.account_hostname !== this.props.account_hostname}
            />
            <br />

            <label className="form-label">WIDGET BG COLOR</label>
            <CustomInput
              type="text"
              name="widget_btn_bg_color"
              placeholder="Cor de fundo do botão"
              onKeyDown={this.handleWidgetBtnBgKeyDown}
              value={widget_btn_bg_color}
              onChange={this.handleWidgetBtnBgInputChange}
              hasChanges={widget_btn_bg_color !== this.props.widget_btn_bg_color}
            />
            <br />
            <label className="form-label">WIDGET TXT COLOR</label>
            <CustomInput
              type="text"
              name="widget_btn_txt_color"
              placeholder="Cor do texto do botão"
              onKeyDown={this.handleWidgetBtnTxtKeyDown}
              value={widget_btn_txt_color}
              onChange={this.handleWidgetBtnTxtInputChange}
              hasChanges={widget_btn_txt_color !== this.props.widget_btn_txt_color}
            />
            <br />
            <label className="form-label">WIDGET ICON COLOR</label>
            <CustomInput
              type="text"
              name="widget_btn_icon_color"
              placeholder="Cor do ícone do botão"
              onKeyDown={this.handleWidgetBtnIconKeyDown}
              value={widget_btn_icon_color}
              onChange={this.handleWidgetBtnIconInputChange}
              hasChanges={widget_btn_icon_color !== this.props.widget_btn_icon_color}
            />
            <br />

            <label className="form-label">ACCOUNT APP ID</label>
            <P>{app_id}</P>
            <br />
            <label className="form-label">ACCOUNT active</label>
            <Switch active={active} onClick={this.handleToggleAccountActive} />
            <br />
            <DeleteButton onClick={this.handleDeleteAccount}>
              <PSmall>Remover</PSmall>
              <FAIcon
                icon="faTrash"
                family="solid"
                style={{
                  fontSize: '16px',
                  marginLeft: '10px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
            </DeleteButton>
          </StyledFormDiv>
        </Content>
      </CustomWhiteBox>
    )
  }
}

export default AccountEditBox

const CustomWhiteBox = styled(WhiteBox)({
  borderRadius: '5px',
  width: '40%',
  marginBottom: '40px',
  display: 'inline-flex',
  flexDirection: 'column',
  marginRight: '30px',
})

const CustomInput = styled(Input)(props => ({
  color: props.hasChanges ? `${blue_strong} !important` : 'auto',
}))

const DeleteButton = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  padding: '6px 14px 4px 14px',
  cursor: 'pointer',
  backgroundColor: grey_border,
  borderRadius: '4px',
  svg: {
    fontSize: '16px',
  },
  ':hover': {
    backgroundColor: red_light,
    'p, svg': {
      color: 'white',
    },
  },
})

const FileInputElement = styled.input({
  height: '100%',
  width: '100%',
  borderRadius: '33px',
  background: '#E8EFF9',
  border: 'none',
  opacity: '0',
})

const SVGImageUploadDisplay = styled.div(props => ({
  backgroundColor: '#242424',
  position: 'relative',
  '& input': {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  },
  '& label': {
    display: 'block',
  },
  '& img': {
    display: 'block',
  },
}))
