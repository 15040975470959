import React, { Component } from 'react'
import styled from '@emotion/styled'
import { LEVEL_BACKGROUND } from 'app-levels'

class BackgroundComponent extends Component {
  render(bg) {
    const RootDiv = styled.div({
      width: '100%',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      top: '0',
      left: '0',
      zIndex: LEVEL_BACKGROUND,
      background: this.props.bgColor,
    })

    return (
      <RootDiv id='background'></RootDiv>
    );
  }
}

export default BackgroundComponent;
