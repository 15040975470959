import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import BackgroundComponent from './BackgroundComponent'

class Background extends Component {
  render() {    
    return (
      <BackgroundComponent bgColor={this.props.bgColor} />
    );
  }
}

const BackgroundWithRouter = withRouter(Background);
export default BackgroundWithRouter;
