import axios from 'axios'
import { toast } from 'react-toastify'
import setAuthorizationToken from '../utils/setAuthorizationToken'
import {
  GET_RESERVATION,
  GET_RESERVATION_SUCCESS,
  GET_RESERVATION_ERROR,

  GET_RESERVATIONS,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_ERROR,

  CANCEL_RESERVATION,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_ERROR,

  UNCANCEL_RESERVATION,
  UNCANCEL_RESERVATION_SUCCESS,
  UNCANCEL_RESERVATION_ERROR,

  SAVE_RESERVATION,
  SAVE_RESERVATION_SUCCESS,
  SAVE_RESERVATION_ERROR,

  GET_RECENTLY_CHANGED_RESERVATIONS,
  GET_RECENTLY_CHANGED_RESERVATIONS_SUCCESS,
  GET_RECENTLY_CHANGED_RESERVATIONS_ERROR,

  // CREATE_RESERVATION,
  // CREATE_RESERVATION_SUCCESS,
  // CREATE_RESERVATION_ERROR,

  RESERVATION_STATE_FORWARD,
  RESERVATION_STATE_FORWARD_SUCCESS,
  RESERVATION_STATE_FORWARD_ERROR,

  RESERVATION_STATE_BACKWARD,
  RESERVATION_STATE_BACKWARD_SUCCESS,
  RESERVATION_STATE_BACKWARD_ERROR,

  DELETE_RESERVATION,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_ERROR,

  ADD_YEARMONTHDAY_TO_RESERVATION,
  ADD_YEARMONTHDAY_TO_RESERVATION_SUCCESS,
  ADD_YEARMONTHDAY_TO_RESERVATION_ERROR,
} from '../action-types'

const tokenName = 'reservasToken'

export function deleteReservation(accountId, _id) {
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: DELETE_RESERVATION,
    })
    axios.get(`/api/v1/delete-reservation/${accountId}/${_id}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: DELETE_RESERVATION_SUCCESS,
        payload: _id
      })
      toast.info('Reserva removida')
    })
    .catch((err) => {
      dispatch({type: DELETE_RESERVATION_ERROR, payload: err })
      toast.info('Erro ao remover reserva.')
    })
  }
}

export const getReservation = (accountId, reservationId) => {
  if (!accountId) {
    return console.log('accountId missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: GET_RESERVATION,
    })
    axios.get(`/api/v1/get-reservation/${reservationId}/${accountId}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: GET_RESERVATION_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_RESERVATION_ERROR, payload: err })
    })
  }
}

export const getReservations = (datesRangeSlug, accountId) => {
  if (!accountId) {
    return console.log('accountId missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: GET_RESERVATIONS,
    })
    axios.get(`/api/v1/get-reservations/${datesRangeSlug}/${accountId}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: GET_RESERVATIONS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_RESERVATIONS_ERROR, payload: err })
    })
  }
}

export const getRecentlyChangedReservations = (accountId) => {
  if (!accountId) {
    return console.log('accountId missing')
  }
  const dateInMilliseconds = Date.now() - (86400000 * 2) // 86400000 is one day
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: GET_RECENTLY_CHANGED_RESERVATIONS,
    })
    axios.get(`/api/v1/get-recently-changed-reservations/${dateInMilliseconds}/${accountId}`, {
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: GET_RECENTLY_CHANGED_RESERVATIONS_SUCCESS,
        payload: response.data,
      })
    })
    .catch((err) => {
      dispatch({type: GET_RECENTLY_CHANGED_RESERVATIONS_ERROR, payload: err })
    })
  }
}

export function addYearMonthDayToReservation(accountId, _id, newYearMonthDate) {
  if (!_id) {
    return console.log('_id missing')
  }
  if (!accountId) {
    return console.log('accountId missing')
  }
  if (!newYearMonthDate) {
    return console.log('newYearMonthDate missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: ADD_YEARMONTHDAY_TO_RESERVATION,
    })
    return axios.get(`/api/v1/add-yearmonthday-to-reservation/${accountId}/${_id}/${newYearMonthDate}`).then(response => {
      dispatch({
        type: ADD_YEARMONTHDAY_TO_RESERVATION_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: ADD_YEARMONTHDAY_TO_RESERVATION_ERROR, payload: err })
    });
  }
}

export function reservationStateForward(accountId, _id) {
  if (!_id) {
    return console.log('_id missing')
  }
  if (!accountId) {
    return console.log('accountId missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: RESERVATION_STATE_FORWARD,
      reservationId: _id,
    })
    return axios.get(`/api/v1/move-reservation-state-forward/${accountId}/${_id}`).then(response => {
      dispatch({
        type: RESERVATION_STATE_FORWARD_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: RESERVATION_STATE_FORWARD_ERROR, payload: err })
    });
  }
}

export function reservationStateBackward(accountId, _id) {
  if (!_id) {
    return console.log('_id missing')
  }
  if (!accountId) {
    return console.log('accountId missing')
  }
  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: RESERVATION_STATE_BACKWARD,
      reservationId: _id,
    })
    return axios.get(`/api/v1/move-reservation-state-backward/${accountId}/${_id}`).then(response => {
      dispatch({
        type: RESERVATION_STATE_BACKWARD_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: RESERVATION_STATE_BACKWARD_ERROR, payload: err })
    });
  }
}


export function cancelReservation(accountId, reservationId) {
  if (!accountId) { return console.log('accountId missing') }
  if (!reservationId) { return console.log('reservationId missing') }

  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: CANCEL_RESERVATION,
      reservationId: reservationId,
    })
    return axios.get(`/api/v1/cancel-reservation/${accountId}/${reservationId}/${Date.now()}`).then(response => {
      dispatch({
        type: CANCEL_RESERVATION_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: CANCEL_RESERVATION_ERROR, payload: err })
    });
  }
}

export function unCancelReservation(accountId, reservationId) {
  if (!accountId) { return console.log('accountId missing') }
  if (!reservationId) { return console.log('reservationId missing') }

  return dispatch => {
    const token = localStorage.getItem(tokenName)
    setAuthorizationToken(token)
    dispatch({
      type: UNCANCEL_RESERVATION,
      reservationId: reservationId,
    })
    return axios.get(`/api/v1/uncancel-reservation/${accountId}/${reservationId}/${Date.now()}`).then(response => {
      dispatch({
        type: UNCANCEL_RESERVATION_SUCCESS,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({type: UNCANCEL_RESERVATION_ERROR, payload: err })
    });
  }
}

export function saveReservation(accountId, reservation) {
  if (!accountId) { return console.log('accountId missing') }
  if (!reservation) { return console.log('reservation missing') }

  reservation.date_updated = Date.now()

  return dispatch => {
    dispatch({ type: SAVE_RESERVATION })
    axios.post(`/api/v1/save-reservation/${accountId}`, { reservation: reservation }, {
      withCredentials: false,
    }).then((response) => {
      dispatch({
        type: SAVE_RESERVATION_SUCCESS,
        payload: response.data,
      })
      toast.info('Reserva actualizada')
      // history.push(`/shop/${appId}/save-reservation-success`)
    })
    .catch((err) => {
      dispatch({type: SAVE_RESERVATION_ERROR, payload: err.response.data })
      toast.error('Erro ao guardar reserva!!')
      // history.push(`/shop/${appId}/save-reservation-error`)
    })
  }
}
