import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import WeekPagerDay from './WeekPagerDay'
import WeekPagerDayLoading from './WeekPagerDayLoading'
import { phablet_max } from '../../../../ui/defaults/media-queries'
import FAIcon from '../../../../app_ui/svgs/icons/FAIcon'

class WeekPager extends Component {
  render() {
    const boxWidth = `${Math.round(100 / this.props.days.length)}%`
    return (
      <RootDiv>
        <ArrowDivContainer to={this.props.previousWeekdateURL}>
          <ArrowDiv onClick={this.props.handleMoveWeekBackward}>
            <FAIcon icon="faChevronLeft" family="regular" />
          </ArrowDiv>
        </ArrowDivContainer>
        <WeekPagerDaysContainer>
          {this.props.days.length === 0 && <WeekPagerDayLoading />}
          {this.props.days.map((boxData, index) => (
            <WeekPagerDay
              key={index}
              dayName={boxData.dayName}
              dayNameShort={boxData.dayNameShort}
              monthDayNumber={boxData.monthDayNumber}
              isToday={boxData.isToday}
              weekDate={boxData.weekDate}
              selected={boxData.selected}
              numRequested={boxData.numRequested}
              numPrepared={boxData.numPrepared}
              numFinalised={boxData.numFinalised}
              numCancelled={boxData.numCancelled}
              hasCancelled={boxData.hasCancelled}
              boxWidth={boxWidth}
            />
          ))}
        </WeekPagerDaysContainer>
        <ArrowDivContainer to={this.props.nextWeekdateURL}>
          <ArrowDiv onClick={this.props.handleMoveWeekForward}>
            <FAIcon icon="faChevronRight" family="regular" />
          </ArrowDiv>
        </ArrowDivContainer>
      </RootDiv>
    )
  }
}

export default WeekPager

const RootDiv = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
})

const WeekPagerDaysContainer = styled.div({
  display: 'flex',
  width: 'calc(100% - 100px)',
  justifyContent: 'space-between',
  [phablet_max]: {
    width: 'calc(100% - 40px)',
  },
})

const ArrowDivContainer = styled(NavLink)({
  display: 'inline-flex',
  background: '#f7fafe',
  width: '50px',
  justifyContent: 'center',
  alignItems: 'center',
  [phablet_max]: {
    width: '20px',
  },
  ':hover': {
    background: '#dae0e8',
  },
  ':active': {
    background: '#b6cae6',
  },
})

const ArrowDiv = styled.div({
  cursor: 'pointer',
  // background: '#657286',
  borderRadius: '6px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '31px',
  width: '20px',
  alignSelf: 'center',
  '& svg': {
    color: '#657286',
    fontSize: '19px',
  },
})
