import { 
  TRIGGER_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
} from '../action-types'

export const triggerConfirmDialog = dialogData => {
  return {
    type: TRIGGER_CONFIRM_DIALOG,
    question: dialogData.question,
    confirmText: dialogData.confirmText,
    cancelText: dialogData.cancelText,
    confirmCallback: dialogData.confirmCallBack,
  }
}

export const closeConfirmDialog = () => {
  return {
    type: CLOSE_CONFIRM_DIALOG,
    payload: null
  }
}