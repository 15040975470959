import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Stats extends Component {
  componentDidMount() {
    this.props.history.push('/relatorios/reservas')
  }

  render() {
    return null
  }
}

export default withRouter(Stats)
