import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import Spinner from '../../ui/components/spinner'
import { darken, lighten } from 'polished'
import { format, addDays, formatDistanceToNow, getMonth, getYear } from 'date-fns'
import { pt } from 'date-fns/locale'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Section, Container, ContentSmall, WhiteBox } from '../../ui/elements/layout'
import { FormLabelBlue } from '../../ui/elements/forms'
import { H3, P, PSmall } from '../../ui/elements/typography'
import { tablet_max, phablet_max } from '../../ui/defaults/media-queries'
import { getProducts } from '../../redux/product/actions'
import { getReservations } from '../../redux/reserva/actions'
import PageHeader from './components/PageHeader'
import {
  blue_shiny,
  green,
  orange_gold,
  purple_strong,
  pink_strong,
  dark_red,
  blue_metallic,
  grey_dark_blueish,
} from '../../ui/defaults/colors'
import { getURLFormattedDate, getDatesArraySlug } from '../../utils/date'
import { cycleDataUpdateInterval } from '../../utils/defaults'
import { getMonthDaysFromAnyDate } from '../../utils/dayHelpers'
import { sortArrayByNumericPropertyReverse } from '../../utils/array'
import BackofficePage from './components_shared/BackofficePage'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

const colorsArray = [
  blue_shiny,
  green,
  orange_gold,
  purple_strong,
  pink_strong,
  dark_red,
  blue_metallic,
  grey_dark_blueish,
  darken(0.2, blue_shiny),
  darken(0.2, green),
  darken(0.2, orange_gold),
  darken(0.2, purple_strong),
  darken(0.2, pink_strong),
  darken(0.2, dark_red),
  darken(0.2, blue_metallic),
  darken(0.2, grey_dark_blueish),
  lighten(0.2, blue_shiny),
  lighten(0.2, green),
  lighten(0.2, orange_gold),
  lighten(0.2, purple_strong),
  lighten(0.2, pink_strong),
  lighten(0.2, dark_red),
  lighten(0.2, blue_metallic),
  lighten(0.2, grey_dark_blueish),
]

const CustomTooltip = ({ payload, label, active, monthName }) => {
  if (active) {
    // let total = 0
    // if (payload && payload[0] && payload[0].value) {
    //   for(const entry of payload) {
    //     total = total += entry.value
    //   }
    // }
    // console.log('payload:')
    // console.dir(payload)
    return (
      <WhiteBox style={{ display: 'inline-flex' }}>
        <ContentSmall>
          <FormLabelBlue>
            {monthName} {label}
          </FormLabelBlue>
          {payload.map((entry, index) => (
            <PSmall key={index}>
              {entry.name}: {entry.value}
            </PSmall>
          ))}
          {/*<PSmall>Total: {total}</PSmall>*/}
        </ContentSmall>
      </WhiteBox>
    )
  }

  return null
}

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    products: store.product.products,
    reservations: store.reserva.reservations,
    loadingReservations: store.reserva.loadingReservations,
    loading: store.reserva.loading,
    loadingProducts: store.product.loading,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getProducts,
        getReservations,
      },
      dispatch,
    ),
  }),
)
class StatsProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelectedDate: new Date(),
      requestedProducts: false,
      requestedAllData: false,
      selectedProductId: null,
    }
    this.containerRef = React.createRef()
  }

  requestaAllData = datesString => {
    try {
      this.props.actions.getReservations(datesString, this.props.user.account._id)
      this.props.actions.getProducts(this.props.user.account._id)
      if (!this.state.requestedAllData) {
        this.setState({ requestedAllData: true })
        this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
      }
    } catch (error) {
      console.log('error: ' + error)
    }
  }

  initFunction = () => {
    const { user, match } = this.props
    const { monthYearURLDate } = this.state

    const hasUser = user && user.account && user.account._id
    if (!hasUser) return

    const year_exists = match && match.params && match.params.year
    const current_year = year_exists ? match.params.year : null
    const month_exists = match && match.params && match.params.month
    const current_month = month_exists ? match.params.month : null

    if (!year_exists || !month_exists) {
      return this.props.history.push(`/relatorios/produtos/${format(new Date(), 'yyyy/MM')}`)
    }

    if (current_year + '/' + current_month !== monthYearURLDate) {
      this.setOpenDays()
    }
  }

  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate() {
    this.initFunction()
  }

  setOpenDays = () => {
    // get url date
    const year = this.props.match.params.year
    const month = this.props.match.params.month
    const currentWeekDate = new Date(year, parseInt(month) - 1, 1)
    // generate business days
    const viewingMonthDates = getMonthDaysFromAnyDate(currentWeekDate)
    const datesString = getDatesArraySlug(viewingMonthDates)
    const firstDayDate = viewingMonthDates[0].dayDate
    // capitalise monthname
    let monthName = format(firstDayDate, 'MMMM', { locale: pt })
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1)
    this.setState({
      monthYearURLDate: year + '/' + month,
      currentMonthStartDate: firstDayDate,
      currentSelectedDate: firstDayDate,
      monthName: monthName,
      viewingMonthDates: viewingMonthDates,
      numDaysInMonth: viewingMonthDates.length,
      datesString: datesString,
    })
    this.requestaAllData(datesString)
  }

  cycleDataUpdate() {
    const { loading, loadingProducts, loadingReservations, movingStateReservationId } = this.props
    if (loading) return
    if (loadingReservations) return
    if (loadingProducts) return
    if (movingStateReservationId) return
    if (!this.props.user && !this.props.user.account) return
    this.requestaAllData(this.state.datesString)
  }

  resetUpdateCycleTimer = () => {
    clearInterval(this.timerID)
    this.timerID = setInterval(() => this.cycleDataUpdate(), cycleDataUpdateInterval)
  }

  getFormattedCreatedTime = date_created => {
    const dateCreated = format(new Date(date_created), 'EEEE dd ', { locale: pt })
    const dateCreateAgo = formatDistanceToNow(new Date(date_created), {
      locale: pt,
      addSuffix: true,
    })
    const finalString = `${dateCreated} (${dateCreateAgo})`
    return finalString
  }

  handlePageMonthBackward = () => {
    const { loadingReservations, loadingProducts, currentSelectedDate } = this.state
    if (loadingReservations) return
    if (loadingProducts) return
    if (!currentSelectedDate) return
    const month = getMonth(currentSelectedDate) + 1
    let year = getYear(currentSelectedDate)
    let nextMonth = month - 1
    if (nextMonth === 0) {
      nextMonth = 12
      year--
    }
    this.props.history.push(`/relatorios/produtos/${year}/${nextMonth}`)
    this.resetUpdateCycleTimer()
  }

  handlePageMonthForward = () => {
    const { loadingReservations, loadingProducts, currentSelectedDate } = this.state
    if (loadingReservations) return
    if (loadingProducts) return
    if (!currentSelectedDate) return
    const month = getMonth(currentSelectedDate) + 1
    let year = getYear(currentSelectedDate)
    let nextMonth = month + 1
    if (nextMonth === 13) {
      nextMonth = 1
      year++
    }
    this.props.history.push(`/relatorios/produtos/${year}/${nextMonth}`)
    this.resetUpdateCycleTimer()
  }

  renderMonthPager = () => {
    const { monthName } = this.state
    return (
      <div style={{ display: 'flex' }}>
        <P style={{ marginLeft: '10px', lineHeight: '100%', marginRight: '10px' }}>{monthName}</P>
        <FAIcon
          icon="faChevronCircleLeft"
          family="light"
          style={{ cursor: 'pointer', color: '#242424' }}
          onClick={this.handlePageMonthBackward}
        />
        <FAIcon
          icon="faChevronCircleRight"
          family="light"
          style={{ cursor: 'pointer', marginLeft: '4px', color: '#242424' }}
          onClick={this.handlePageMonthForward}
        />
      </div>
    )
  }

  renderProductPlaques = productsData => {
    let { selectedProductId } = this.state
    if (!productsData) return null
    // sort by amount
    const sortedProductsData = sortArrayByNumericPropertyReverse(productsData, 'amount')
    return (
      <LayoutThree ref={this.containerRef}>
        {sortedProductsData.map(product => (
          <CustomWhiteBox
            key={product._id}
            onClick={() =>
              this.setState({
                selectedProductId: selectedProductId === product._id ? null : product._id,
              })
            }
            selected={selectedProductId === product._id}
          >
            <FormLabelBlue style={{ color: '#627490' }}>{product.title_pt}</FormLabelBlue>
            <H3 style={{ fontWeight: '500' }}>{product.amount}</H3>
          </CustomWhiteBox>
        ))}
      </LayoutThree>
    )
  }

  renderSingleProductGraph = (daysProductAmountsData, numMax, productsData, monthName) => {
    return (
      <div style={{ width: '100%' }}>
        {!daysProductAmountsData && <Spinner color="black" radius={40} />}
        <GraphRootElement style={{ height: '300px', margin: '0 auto' }}>
          <ResponsiveContainer>
            <BarChart
              data={daysProductAmountsData}
              margin={{
                left: -20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis dataKey="name" type="number" domain={[0, numMax]} />
              <Tooltip content={<CustomTooltip monthName={monthName} />} />
              <Legend />
              <Bar
                isAnimationActive={false}
                dataKey="finalised"
                name="Finalizadas"
                stackId="b"
                fill={colorsArray[1]}
              />
              <Bar
                isAnimationActive={false}
                dataKey="prepared"
                name="Preparadas"
                stackId="b"
                fill={colorsArray[2]}
              />
              <Bar
                isAnimationActive={false}
                dataKey="requested"
                name="Pedidas"
                stackId="b"
                fill={colorsArray[0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </GraphRootElement>
      </div>
    )
  }

  renderAllProductsGraph = (daysProductAmountsData, numMax, productsData, monthName) => {
    return (
      <div style={{ width: '100%' }}>
        {!daysProductAmountsData && <Spinner color="black" radius={40} />}
        <GraphRootElement style={{ height: '300px', margin: '0 auto' }}>
          <ResponsiveContainer>
            <BarChart
              data={daysProductAmountsData}
              margin={{
                left: -20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis dataKey="name" type="number" domain={[0, numMax]} />
              <Tooltip content={<CustomTooltip monthName={monthName} />} />
              <Legend />
              {productsData.map((product, index) => (
                <Bar
                  isAnimationActive={false}
                  key={index}
                  dataKey={product.title_pt}
                  stackId="a"
                  fill={colorsArray[index]}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </GraphRootElement>
      </div>
    )
  }

  prepareProductsData = thisMonthReservations => {
    const { products } = this.props

    const productsData = []
    if (!products) return []

    for (const currentReservation of thisMonthReservations) {
      for (const currentProduct of currentReservation.products) {
        const currentProductId = currentProduct._id
        const currentProductAmount = currentProduct.amount

        // db product data
        const dbProduct = products.find(product => product._id === currentProductId)

        if (dbProduct) {
          const productTitle = dbProduct.title_pt

          let processingProduct = productsData.find(product => product._id === currentProductId)
          if (processingProduct) {
            const objIndex = productsData.findIndex(obj => obj._id === currentProductId)
            productsData[objIndex] = {
              _id: currentProductId,
              amount: processingProduct.amount + currentProductAmount,
              title_pt: productTitle,
            }
          } else {
            productsData.push({
              _id: currentProductId,
              amount: currentProductAmount,
              title_pt: productTitle,
            })
          }
        }
      }
    }

    return productsData
  }

  prepareSingleProductData = (
    thisMonthReservations,
    currentStartOfMonthDate,
    currentMonthNumDays,
    productsData,
  ) => {
    const { products } = this.props
    const { selectedProductId } = this.state

    let numEntriesMaxInADay = 0
    const daysProductAmountsData = []

    const product = products.find(product => product._id === selectedProductId)
    if (!product) return null

    for (var i = 1; i <= currentMonthNumDays; i++) {
      const currentDate =
        i === 1 ? currentStartOfMonthDate : addDays(currentStartOfMonthDate, i - 1)

      const weekdayName = format(currentDate, 'EEEE')
      const weekdayNameLowerCase = weekdayName.toLowerCase()
      const stockThisDay = product[`stock_${weekdayNameLowerCase}`]

      // maybe update graph ceiling
      const wouldBeCeiling = stockThisDay + 10
      if (wouldBeCeiling > numEntriesMaxInADay) {
        numEntriesMaxInADay = wouldBeCeiling
      }

      const reservedThisDay = thisMonthReservations.filter(
        reservation =>
          reservation.yearMonthDay === getURLFormattedDate(currentDate) && !reservation.cancelled,
      )

      // get all finalised
      let totalFinalisedThisDay = 0
      let totalRequestedThisDay = 0
      let totalPreparedThisDay = 0
      for (const reservation of reservedThisDay) {
        for (const productEntry of reservation.products) {
          if (productEntry._id === product._id) {
            switch (reservation.state) {
              case 'finalised':
                totalFinalisedThisDay = totalFinalisedThisDay + productEntry.amount
                break
              case 'prepared':
                totalPreparedThisDay = totalPreparedThisDay + productEntry.amount
                break
              default:
                totalRequestedThisDay = totalRequestedThisDay + productEntry.amount
                break
            }
          }
        }
      }

      const total = totalRequestedThisDay + totalPreparedThisDay + totalFinalisedThisDay
      // console.log('total: ' + (totalRequestedThisDay + totalPreparedThisDay + totalFinalisedThisDay))

      const newDayObjectProductsAmount = {
        name: i,
        date: currentDate,
        requested: totalRequestedThisDay,
        prepared: totalPreparedThisDay,
        finalised: totalFinalisedThisDay,
        total: total,
        // not_finalised: isPast(currentDate) ? (stockThisDay - totalFinalisedThisDay) : 0,
      }

      daysProductAmountsData.push(newDayObjectProductsAmount)
    }

    return {
      items: daysProductAmountsData,
      numMax: numEntriesMaxInADay,
    }
  }

  prepareAllProductsData = (
    thisMonthReservations,
    currentStartOfMonthDate,
    currentMonthNumDays,
    productsData,
  ) => {
    let numProductsMaxInADay = 0
    const daysProductAmountsData = []

    for (var i = 1; i <= currentMonthNumDays; i++) {
      let numProductsToday = 0
      const currentDate =
        i === 1 ? currentStartOfMonthDate : addDays(currentStartOfMonthDate, i - 1)
      const reservedThisDay = thisMonthReservations.filter(
        reservation => reservation.yearMonthDay === getURLFormattedDate(currentDate),
      )

      const newDayObjectProductsAmount = {
        name: i,
      }

      for (const processingProduct of productsData) {
        const processingProductId = processingProduct._id
        const processingProductTitle = processingProduct.title_pt
        newDayObjectProductsAmount[processingProductTitle] = 0
        if (reservedThisDay && reservedThisDay.length > 0) {
          let totalAmount = 0
          for (const currentReservationB of reservedThisDay) {
            for (const currentProductB of currentReservationB.products) {
              const currentProductBId = currentProductB._id
              if (currentProductBId === processingProductId) {
                totalAmount = totalAmount + currentProductB.amount
              }
            }
          }
          numProductsToday = numProductsToday + totalAmount

          newDayObjectProductsAmount[processingProductTitle] = totalAmount
        }
      }

      if (numProductsToday > numProductsMaxInADay) {
        numProductsMaxInADay = numProductsToday
      }

      daysProductAmountsData.push(newDayObjectProductsAmount)
    }

    return {
      items: daysProductAmountsData,
      numMax: numProductsMaxInADay,
    }
  }

  renderContent = () => {
    let {
      requestedAllData,
      selectedProductId,
      currentSelectedDate,
      currentMonthStartDate,
      numDaysInMonth,
      monthName,
    } = this.state
    const { reservations, loadingReservations, loadingProducts, products } = this.props

    // let plaquesData = null
    let plaquesJSX = null
    let graphData = null
    let graphJSX = null
    let productsData = null
    if (
      requestedAllData &&
      currentSelectedDate &&
      reservations &&
      reservations.length &&
      reservations.length > 0
    ) {
      const thisMonthReservations = reservations

      const currentStartOfMonthDate = currentMonthStartDate
      const currentMonthNumDays = numDaysInMonth
      productsData = this.prepareProductsData(thisMonthReservations)
      plaquesJSX = this.renderProductPlaques(productsData)
      graphData = selectedProductId
        ? this.prepareSingleProductData(
            thisMonthReservations,
            currentStartOfMonthDate,
            currentMonthNumDays,
            productsData,
          )
        : this.prepareAllProductsData(
            thisMonthReservations,
            currentStartOfMonthDate,
            currentMonthNumDays,
            productsData,
          )
      // console.log('graphData:')
      // console.dir(graphData)
      graphJSX = selectedProductId
        ? this.renderSingleProductGraph(graphData.items, graphData.numMax, productsData, monthName)
        : this.renderAllProductsGraph(graphData.items, graphData.numMax, productsData, monthName)
    }

    const spinnerJSX = <Spinner color="black" radius={40} />

    const stillLoading = !graphJSX || loadingReservations || loadingProducts
    const graphContent = stillLoading ? spinnerJSX : graphJSX
    const plaquesContent = stillLoading ? null : plaquesJSX
    let subtitleString = selectedProductId
      ? 'Produto: ' + products.find(product => product._id === selectedProductId).title_pt
      : 'Todos os produtos'
    if (stillLoading) {
      subtitleString = 'A carregar...'
    }

    return (
      <Section>
        <Container>
          <br />
          <br />
          <LayoutBase>
            <P>Relatórios: Produtos</P>
            <PSmall>{subtitleString}</PSmall>
          </LayoutBase>
          <br />
          <br />
          {graphContent}
          <br />
          <br />
          {plaquesContent}
          <br />
          <br />
        </Container>
      </Section>
    )
  }

  render() {
    let title = 'Relatórios: Produtos | Asminhasreservas'
    let categoryElementsNav = null
    let content = null

    if (this.props.isAuthenticated) {
      content = this.renderContent()
      let addDateToURL = ''
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.year &&
        this.props.match.params.month
      ) {
        addDateToURL = `/${this.props.match.params.year}/${this.props.match.params.month}`
      }
      categoryElementsNav = (
        <CategoryElementsNav>
          <NavLink to={`/relatorios/reservas${addDateToURL}`}>
            <PageHeaderNavLink active={false} first>
              Reservas
            </PageHeaderNavLink>
          </NavLink>
          <NavLink to={`/relatorios/produtos${addDateToURL}`}>
            <PageHeaderNavLink active={true}>Produtos</PageHeaderNavLink>
          </NavLink>
          <NavLink to={`/relatorios/clientes${addDateToURL}`}>
            <PageHeaderNavLink active={false}>Clientes</PageHeaderNavLink>
          </NavLink>
        </CategoryElementsNav>
      )
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    const extraFirstElement = (
      <PageHeader
        key="extraFirstElement"
        pageTitle="Relatórios: Produtos"
        rightSideElement={this.renderMonthPager()}
      />
    )

    const extraFirstElement2 = (
      <PageHeader key="extraFirstElement2" headlineAlternativeElement={categoryElementsNav} />
    )

    const extreElement = [extraFirstElement, extraFirstElement2]

    return <BackofficePage title={title} content={content} extraFirstElement={extreElement} />
  }
}

export default StatsProducts

const LayoutBase = styled.div({
  width: '100%',
  margin: '0 auto',
})

const LayoutThree = styled(LayoutBase)({
  display: 'flex',
  flexDirection: 'column',
})

const GraphRootElement = styled.div({
  '& .recharts-legend-item-text': {
    fontSize: '17px',
    [tablet_max]: {
      fontSize: '15px',
    },
    [phablet_max]: {
      fontSize: '13px',
    },
  },
})

const CustomWhiteBox = styled(WhiteBox)(props => ({
  padding: '12px',
  paddingBottom: '6px',
  borderRadius: '3px',
  display: 'inline-flex',
  flexDirection: 'column',
  marginRight: '10px',
  marginBottom: '10px',
  cursor: 'pointer',
  background: props.selected ? blue_shiny : 'white',
  '& label, h3': {
    lineHeight: '100%',
    color: props.selected ? 'white !important' : '#2E2E2E !important',
  },
  [tablet_max]: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  [phablet_max]: {
    marginRight: '6px',
    marginBottom: '6px',
  },
}))

const CategoryElementsNav = styled.div({
  display: 'flex',
})

const PageHeaderNavLink = styled(PSmall)(props => ({
  cursor: 'pointer',
  display: 'inline-flex',
  marginLeft: props.first ? '0' : '14px',
  textDecoration: props.active ? 'underline' : 'none',
}))
