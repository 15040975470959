import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageHeader from './components/PageHeader'
import ProductListItem from './components/products/ProductListItem'
import WeekdaysAvailability from './components/settings/WeekdaysAvailability'
import { Section, Container } from '../../ui/elements/layout'
import { P } from '../../ui/elements/typography'
import { blue_shiny } from '../../ui/defaults/colors'
import {
  updateAccountOpeningDays,
  getProductsOrder,
  updateAccountWeekdaysAvailability
} from '../../redux/account/actions'
import {
  orderProduct,
} from '../../redux/product/actions'
import { getProducts } from '../../redux/product/actions'
import BackofficePage from './components_shared/BackofficePage'
import Spinner from '../../ui/components/spinner'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    products: store.product.products,
    shop_products_order: store.product.shop_products_order,
  }),
  dispatch => ({
    actions: bindActionCreators({
      getProducts,
      updateAccountOpeningDays,
      getProductsOrder,
      updateAccountWeekdaysAvailability,
      orderProduct,
    }, dispatch)
  })
)
class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestedAllData: false,
    }
  }
  
  componentDidMount() {
    this.initFunction()
  }

  componentDidUpdate() {
    this.initFunction()
  }
  
  initFunction = () => {
    this.requestaAllData()
  }

  requestaAllData = () => {
    if (this.state.requestedAllData) return
    try {
      this.props.actions.getProducts(this.props.user.account._id)
      this.props.actions.getProductsOrder(this.props.user.account._id)
      if (!this.state.requestedAllData) {
        this.setState({ requestedAllData: true })
      }
    } catch(error) {
      console.log('error: ' + error)
    }
  }
  
  compareArrays(array1, array2) {
    if(array1.sort().join(',') === array2.sort().join(',')) {
      return true
    }
    return false
  }

  renderProductsOrder = () => {
    const {
      products,
      shop_products_order,
    } = this.props
    
    if (!shop_products_order || shop_products_order.length === 0) return null

    const activeProducts = products.filter(product => product.sale_active === true)
    const orderedProductsArray = []
    shop_products_order.map(id => orderedProductsArray.push(activeProducts.find(product => product._id === id)))

    if (!orderedProductsArray || orderedProductsArray.length === 0) return null

    return (
      <div>
        <br />
        <br />
        <P>Ordem dos produtos na loja</P>
        <br />
        {orderedProductsArray.map(product => 
          <ProductListItem
            key={product._id}
            _id={product._id}
            date_created={product.date_created}
            title_pt={product.title_pt}
            sale_active={product.sale_active}
            image_url={product.image_url}
            handleProductOrderChange={this.triggerProductOrderChange}
          />
        )}
      </div>
    )
  }

  triggerProductOrderChange = (productId, direction) => {
    this.props.actions.orderProduct(this.props.user.account._id, productId, direction)
  }

  handleUpdateWeekdaysAvailability = (dayName, dayPart, newValue) => {
    const newOpeningDaysParts = this.props.user.account.weekdays_availability
    newOpeningDaysParts[dayName][dayPart] = newValue
    this.props.actions.updateAccountWeekdaysAvailability(
      this.props.user.account,
      newOpeningDaysParts,
    )
    // check if day will become completely empty of completely full
    // save opening_days accordingly
    let valueToCompare
    if (dayPart === 'morning') {
      valueToCompare = this.props.user.account.weekdays_availability[dayName].afternoon
    } else {
      valueToCompare = this.props.user.account.weekdays_availability[dayName].morning
    }
    const bothAreSameValue = newValue === valueToCompare
    const newOpeningDays = this.props.user.account.opening_days
    if (bothAreSameValue) {
      newOpeningDays[dayName] = newValue
      this.props.actions.updateAccountOpeningDays(
        this.props.user.account,
        newOpeningDays,
      )
    } else {
      if (!newOpeningDays[dayName]) {
        newOpeningDays[dayName] = true
        this.props.actions.updateAccountOpeningDays(
          this.props.user.account,
          newOpeningDays,
        )
      }
    }
  }

  renderContent() {
    const { shop_products_order } = this.props
    if (!this.props.user) return null
    let openingDays = this.props.user.account ? this.props.user.account.opening_days : null
    let weekdaysAvailability = this.props.user.account ? this.props.user.account.weekdays_availability : null
    if (!openingDays || !weekdaysAvailability) return null
    return (
      <Section>
        <Container>
          <br />
          <P>Disponibilidade da loja</P>
          <br />
          <WeekdaysAvailability
            handleDayPartClick={(lowerCaseWeekdayName, dayPartLowerCase, newDayValue) => this.handleUpdateWeekdaysAvailability(lowerCaseWeekdayName, dayPartLowerCase, newDayValue)}
            weekdays_availability={weekdaysAvailability}
            openingDays={openingDays}
          />
          { shop_products_order && this.renderProductsOrder() }
          <br />
          <br />
        </Container>
      </Section>
    )
  }

  render() {
    let content = null
    let title = 'Asminhasreservas - Produtos'

    if (this.props.isAuthenticated) {
      content = this.renderContent()
    } else {
      content = <Spinner color={blue_shiny} radius={40} />
    }

    const extraFirstElement = (
      <PageHeader
        pageTitle='Definições'
      />
    )

    return (
      <BackofficePage
        title={title}
        content={content}
        extraFirstElement={extraFirstElement}
      />
    )
  }
}

export default withRouter(Settings)

