import styled from '@emotion/styled'
import { tablet_max, phablet_max, phone_max } from '@time-with/media-queries'
import { blue_metallic, black } from '../../defaults/colors'
import { lighten } from 'polished'
import { pSmallStyle } from '../typography'

export const placeholderStyle = {
  '::placeholder': { color: lighten(0.2, blue_metallic) },
  '::-webkit-input-placeholder': { color: lighten(0.2, blue_metallic) },
  '::-moz-placeholder': { color: lighten(0.2, blue_metallic) },
  ':-ms-input-placeholder': { color: lighten(0.2, blue_metallic) },
}

export const placeholderStyleDark = {
  '::placeholder': { color: `${lighten(0.5, 'white')} !important` },
  '::-webkit-input-placeholder': { color: `${lighten(0.5, 'white')} !important` },
  '::-moz-placeholder': { color: `${lighten(0.5, 'white')} !important` },
  ':-ms-input-placeholder': { color: `${lighten(0.5, 'white')} !important` },
}

export const placeholderStyleTextarea = {
  '::placeholder': { color: lighten(0.8, 'black') },
  '::-webkit-input-placeholder': { color: lighten(0.8, 'black') },
  '::-moz-placeholder': { color: lighten(0.8, 'black') },
  ':-ms-input-placeholder': { color: lighten(0.8, 'black') },
}

export const placeholderStyleTextareaDark = {
  '::placeholder': { color: lighten(0.5, 'black') },
  '::-webkit-input-placeholder': { color: lighten(0.5, 'black') },
  '::-moz-placeholder': { color: lighten(0.5, 'black') },
  ':-ms-input-placeholder': { color: lighten(0.5, 'black') },
}

export const inputTextStyle = {
  color: black,
  fontFamily: 'Satoshi',
  borderBottom: '2px solid #c7d6f0',
  fontSize: '19px',
  fontWeight: '400',
}

export const inputStyle = {
  boxShadow: 'none',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderRadius: '0',
  paddingLeft: '0',
  paddingRight: '0',
  paddingTop: '0',
  paddingBottom: '5px',
  outline: 'none',
  borderBottom: '2px solid #c7d6f0',
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  ...inputTextStyle,
  ...placeholderStyle,
}

export const inputStyleStripeField = {
  base: {
    ...inputStyle,
  }
}
export const formLabelStyle = {
  lineHeight: '100%',
  color: '#637189',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: '600',
  marginBottom: '10px',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  [tablet_max]: {
    fontSize: '13px',
  },
  [phablet_max]: {
    fontSize: '11px',
  },
  [phone_max]: {
    fontSize: '10px',
  },
}
export const FormLabel = styled.label({
  ...formLabelStyle,
})
export const FormLabelBlue = styled.label({
  ...formLabelStyle,
  color: '#2D9CDB',
})
export const FormLabelBlueDark = styled.label({
  ...formLabelStyle,
  color: '#46556a',
})
const styledFormStyle = {
  display: 'flex',
  flexDirection: 'column',
  // minWidth: '260px',
  "& .form-label": {
    ...formLabelStyle,
  },
  '& input[type="text"]': {
    ...inputStyle
  },
  '& input[type="password"]': {
    ...inputStyle
  },
}

export const StyledFormDiv = styled.div(styledFormStyle)
export const StyledForm = styled.form(styledFormStyle)

export const Input = styled.input({
  ...inputStyle
})

export const TextArea = styled.textarea(props => ({
  ...inputStyle,
  ...pSmallStyle,
  ...placeholderStyleTextarea,
  background: props.hasChanges ? '#fefaeb' : '#e8eff9',
  border: 'none',
  padding: '10px 14px',
  borderRadius: '6px',
  resize: 'none',
  lineHeight: '130%',
  WebkitTransition: 'background 0.2s ease-out',
  MozTransition: 'background 0.2s ease-out',
  Otransition: 'background 0.2s ease-out',
  transition: 'background 0.2s ease-out',
  fontSize: '17px !important',
  [phone_max]: {
    fontSize: '15px !important',
  },
}))
// export const Input = styled.input({
//   color: grey,
//   width: '100%',
//   marginBottom: '14px',
//   border: '1px solid rgba(43,64,125,0.15)',
//   boxShadow: 'inset 2px 2px 3px 0 rgba(144,144,144,0.32)',
//   padding: '12px 16px',
//   fontSize: '20px',
//   borderRadius: '5px',
//   fontWeight: 'bold',
//   '::placeholder': {
//     color: '#BDBDBD'
//   },
//   [tablet_max]: {
//     borderRadius: '4px',
//     padding: '10px 14px',
//     fontSize: '19px',
//   },
//   [phablet_max]: {
//     borderRadius: '3px',
//     padding: '8px 12px',
//     fontSize: '18px',
//   },
// })