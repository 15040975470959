import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { Section, Container } from '../../ui/elements/layout'
import { P } from '../../ui/elements/typography'
import { blue_strong } from '../../ui/defaults/colors'
import { FatButton } from '../../ui/components/buttons'
import { createAccount, getAccounts } from '../../redux/account/actions'
import { uploadAccountLogo } from '../../redux/account/actions'
import PageHeader from './components/PageHeader'
import AccountEditBox from './components/accounts/AccountEditBox'
import Navbar from './components_shared/Navbar'
import { toggleMobileNav } from '../../redux/system/actions'
import { logoutUser } from '../../redux/user/actions'
import SidebarMobileInstance from './components_shared/SidebarMobileInstance'
import { verifyToken } from '../../redux/user/actions'
import FAIcon from '../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
    accounts: store.account.accounts,
    mobileNavOpen: store.system.mobileNavOpen,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getAccounts,
        createAccount,
        uploadAccountLogo,
        logoutUser,
        toggleMobileNav,
        verifyToken,
      },
      dispatch,
    ),
  }),
)
class Accounts extends Component {
  componentDidMount() {
    this.props.actions.getAccounts()
    // do not check login in Loja route
    const isLoja = window.location.pathname.indexOf('/shop') > -1
    if (!isLoja) {
      this.props.actions.verifyToken()
    }
  }
  renderContent() {
    return (
      <div>
        <br />
        <PageHeader
          pageTitle="Contas de cliente"
          rightSideElement={
            <FatButton
              label="Novo cliente"
              bgColor="white"
              iconSide="left"
              icon={<FAIcon icon="faPlusCircle" family="solid" />}
              iconColor={blue_strong}
              onClick={this.props.actions.createAccount}
            />
          }
        />
        <br />
        <ContentRootDiv>
          {this.props.accounts &&
            this.props.accounts.length > 0 &&
            this.props.accounts.map((account, index) => (
              <AccountEditBox
                key={index}
                _id={account._id}
                date_created={account.date_created}
                business_name={account.business_name}
                active={account.active}
                account_email={account.account_email}
                account_hostname={account.account_hostname}
                account_logo={account.account_logo}
                widget_btn_bg_color={account.widget_btn_bg_color}
                widget_btn_txt_color={account.widget_btn_txt_color}
                widget_btn_icon_color={account.widget_btn_icon_color}
                app_id={account.app_id}
                // image_url={account.image_url}
              />
            ))}
        </ContentRootDiv>
      </div>
    )
  }

  handleLogout = () => {
    this.props.actions.toggleMobileNav()
    this.props.actions.logoutUser(this.props.history)
  }

  render() {
    const isAdmin = this.props.user && this.props.user.admin
    if (!isAdmin) {
      return <p>This page is exclusive to administrators.</p>
    }

    return (
      <Section>
        <Helmet>
          <title>Contas | As minhas reservas</title>
        </Helmet>
        <Navbar maxWidth="1280px" />
        <Container>
          {!this.props.isAuthenticated && <P>You must be logged in to view this page</P>}
          {this.props.isAuthenticated && this.renderContent()}
        </Container>
        {this.props.isAuthenticated && (
          <SidebarMobileInstance
            mobileNavOpen={this.props.mobileNavOpen}
            handleToggleMobileNav={() => this.props.actions.toggleMobileNav()}
            handleLogout={this.handleLogout}
          />
        )}
      </Section>
    )
  }
}

export default Accounts

const ContentRootDiv = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
})
