import React, { Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import FAIcon from '../../../app_ui/svgs/icons/FAIcon'

@connect(
  store => ({
    user: store.user.userData,
    isAuthenticated: store.user.isAuthenticated,
  }),
  null,
)
class NavbarHome extends Component {
  render() {
    const { user, isAuthenticated, isLoginPage } = this.props
    const isAdmin = user && user.admin === true
    return (
      <StyledNavbarMicro>
        {isAuthenticated && isAdmin && <StyledLink to="/accounts">Admin</StyledLink>}
        {isAuthenticated && (
          <StyledLink to="/vista-geral">
            Entrar <FAIcon icon="faDoorOpen" family="solid" />
          </StyledLink>
        )}
        {!isAuthenticated && isLoginPage && (
          <StyledLink to="/registo">
            Registo <FAIcon icon="faKey" family="solid" />
          </StyledLink>
        )}
        {!isAuthenticated && !isLoginPage && (
          <StyledLink to="/entrar">
            Login <FAIcon icon="faDoorClosed" family="solid" />
          </StyledLink>
        )}
      </StyledNavbarMicro>
    )
  }
}

export default withRouter(NavbarHome)

const StyledNavbarMicro = styled.div({
  display: 'flex',
  alignItems: 'center',
  // position: 'fixed',
  position: 'absolute',
  right: '25px',
  top: '20px',
  width: 'auto',
})

const StyledLink = styled(NavLink)({
  textDecoration: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  '&.selected': {
    textDecoration: 'underline',
  },
})
