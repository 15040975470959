import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'

// solid
import { faPlusCircle as faPlusCircleSolid } from './pro-solid-svg-icons/faPlusCircle'
import { faSpinnerThird as faSpinnerThirdSolid } from './pro-solid-svg-icons/faSpinnerThird'
import { faPenSquare as faPenSquareSolid } from './pro-solid-svg-icons/faPenSquare'
import { faTrash as faTrashSolid } from './pro-solid-svg-icons/faTrash'
import { faCamera as faCameraSolid } from './pro-solid-svg-icons/faCamera'
import { faSave as faSaveSolid } from './pro-solid-svg-icons/faSave'
import { faArrowLeft as faArrowLeftSolid } from './pro-solid-svg-icons/faArrowLeft'
import { faArrowRight as faArrowRightSolid } from './pro-solid-svg-icons/faArrowRight'
import { faArrowUp as faArrowUpSolid } from './pro-solid-svg-icons/faArrowUp'
import { faArrowDown as faArrowDownSolid } from './pro-solid-svg-icons/faArrowDown'
import { faChevronUp as faChevronUpSolid } from './pro-solid-svg-icons/faChevronUp'
import { faChevronDown as faChevronDownSolid } from './pro-solid-svg-icons/faChevronDown'
import { faChevronLeft as faChevronLeftSolid } from './pro-solid-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRightSolid } from './pro-solid-svg-icons/faChevronRight'
import { faComment as faCommentSolid } from './pro-solid-svg-icons/faComment'
import { faTimes as faTimesSolid } from './pro-solid-svg-icons/faTimes'
import { faPlus as faPlusSolid } from './pro-solid-svg-icons/faPlus'
import { faCheck as faCheckSolid } from './pro-solid-svg-icons/faCheck'
import { faUndo as faUndoSolid } from './pro-solid-svg-icons/faUndo'
import { faTimesCircle as faTimesCircleSolid } from './pro-solid-svg-icons/faTimesCircle'
import { faPowerOff as faPowerOffSolid } from './pro-solid-svg-icons/faPowerOff'
import { faBars as faBarsSolid } from './pro-solid-svg-icons/faBars'
import { faKey as faKeySolid } from './pro-solid-svg-icons/faKey'
import { faDoorClosed as faDoorClosedSolid } from './pro-solid-svg-icons/faDoorClosed'
import { faDoorOpen as faDoorOpenSolid } from './pro-solid-svg-icons/faDoorOpen'
import { faHome as faHomeSolid } from './pro-solid-svg-icons/faHome'
import { faStar as faStarSolid } from './pro-solid-svg-icons/faStar'
import { faCog as faCogSolid } from './pro-solid-svg-icons/faCog'
import { faChartBar as faChartBarSolid } from './pro-solid-svg-icons/faChartBar'
import { faTh as faThSolid } from './pro-solid-svg-icons/faTh'
import { faCheckCircle as faCheckCircleSolid } from './pro-solid-svg-icons/faCheckCircle'
import { faInfoCircle as faInfoCircleSolid } from './pro-solid-svg-icons/faInfoCircle'
import { faUserCircle as faUserCircleSolid } from './pro-solid-svg-icons/faUserCircle'

// light
import { faChevronDown as faChevronDownLight } from './pro-light-svg-icons/faChevronDown'
import { faChevronUp as faChevronUpLight } from './pro-light-svg-icons/faChevronUp'
import { faChevronLeft as faChevronLeftLight } from './pro-light-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRightLight } from './pro-light-svg-icons/faChevronRight'

import { faArrowDown as faArrowDownLight } from './pro-light-svg-icons/faArrowDown'
import { faArrowUp as faArrowUpLight } from './pro-light-svg-icons/faArrowUp'
import { faArrowLeft as faArrowLeftLight } from './pro-light-svg-icons/faArrowLeft'
import { faArrowRight as faArrowRightLight } from './pro-light-svg-icons/faArrowRight'
import { faChevronCircleLeft as faChevronCircleLeftLight } from './pro-light-svg-icons/faChevronCircleLeft'
import { faChevronCircleRight as faChevronCircleRightLight } from './pro-light-svg-icons/faChevronCircleRight'
import { faCaretUp as faCaretUpLight } from './pro-light-svg-icons/faCaretUp'
import { faCaretDown as faCaretDownLight } from './pro-light-svg-icons/faCaretDown'
import { faTimes as faTimesLight } from './pro-light-svg-icons/faTimes'

// regular
import { faChevronDown } from './pro-regular-svg-icons/faChevronDown'
import { faChevronUp } from './pro-regular-svg-icons/faChevronUp'
import { faChevronLeft } from './pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from './pro-regular-svg-icons/faChevronRight'
import { faLightSwitchOn } from './pro-regular-svg-icons/faLightSwitchOn'
import { faLightSwitchOff } from './pro-regular-svg-icons/faLightSwitchOff'
import { faTrashAlt } from './pro-regular-svg-icons/faTrashAlt'
import { faExternalLinkSquareAlt } from './pro-regular-svg-icons/faExternalLinkSquareAlt'

const icons = {
  light: {
    faChevronDown: faChevronDownLight,
    faChevronUp: faChevronUpLight,
    faChevronLeft: faChevronLeftLight,
    faChevronRight: faChevronRightLight,
    faChevronCircleLeft: faChevronCircleLeftLight,
    faChevronCircleRight: faChevronCircleRightLight,
    faCaretUp: faCaretUpLight,
    faCaretDown: faCaretDownLight,
    faTimes: faTimesLight,
    faArrowLeft: faArrowLeftLight,
    faArrowRight: faArrowRightLight,
    faArrowUp: faArrowUpLight,
    faArrowDown: faArrowDownLight,
  },
  solid: {
    faPlusCircle: faPlusCircleSolid,
    faSpinnerThird: faSpinnerThirdSolid,
    faPenSquare: faPenSquareSolid,
    faTrash: faTrashSolid,
    faCamera: faCameraSolid,
    faSave: faSaveSolid,

    faArrowLeft: faArrowLeftSolid,
    faArrowRight: faArrowRightSolid,
    faArrowUp: faArrowUpSolid,
    faArrowDown: faArrowDownSolid,

    faChevronLeft: faChevronLeftSolid,
    faChevronRight: faChevronRightSolid,
    faChevronUp: faChevronUpSolid,
    faChevronDown: faChevronDownSolid,

    faComment: faCommentSolid,
    faTimes: faTimesSolid,
    faPlus: faPlusSolid,
    faCheck: faCheckSolid,
    faUndo: faUndoSolid,
    faTimesCircle: faTimesCircleSolid,
    faBars: faBarsSolid,
    faPowerOff: faPowerOffSolid,
    faKey: faKeySolid,
    faDoorClosed: faDoorClosedSolid,
    faDoorOpen: faDoorOpenSolid,
    faHome: faHomeSolid,
    faStar: faStarSolid,
    faCog: faCogSolid,
    faChartBar: faChartBarSolid,
    faTh: faThSolid,
    faCheckCircle: faCheckCircleSolid,
    faInfoCircle: faInfoCircleSolid,
    faUserCircle: faUserCircleSolid,
  },
  regular: {
    faLightSwitchOn: faLightSwitchOn,
    faLightSwitchOff: faLightSwitchOff,
    faTrashAlt: faTrashAlt,
    faExternalLinkSquareAlt: faExternalLinkSquareAlt,
    faChevronDown: faChevronDown,
    faChevronUp: faChevronUp,
    faChevronLeft: faChevronLeft,
    faChevronRight: faChevronRight,
  },
}
class StockMonitor extends Component {
  render() {
    const { icon, family, ...props } = this.props
    console.log('icon', icon)
    console.log('family', family)
    console.log('icons[family][icon]', icons[family][icon])
    const svgPathData = icons[family][icon].icon[4]
    return (
      <FAIconElement
        xmlns="http://www.w3.org/2000/svg"
        ariaHidden="true"
        data-icon="th"
        data-prefix="fas"
        viewBox="0 0 512 512"
        {...props}
      >
        <path fill="currentColor" d={svgPathData}></path>
      </FAIconElement>
    )
  }
}

export default withRouter(StockMonitor)

const FAIconElement = styled.svg(props => ({
  height: '20px',
  width: '20px',
}))
